"use client";

import { useEffect, useState } from "react";
import {
  FaSearch,
  FaSyncAlt,
  FaPrint,
  FaMoon,
  FaQuestionCircle,
  FaSignOutAlt,
  FaHome,
  FaLongArrowAltDown,
  FaUser,
} from "react-icons/fa";
import { MdDashboard, MdManageSearch, MdReceipt } from "react-icons/md"; 
import Manage_Product from "./Manage_Product/Manage_Product";
import { Link, useNavigate, useParams } from "react-router-dom";
import { CiLogout } from "react-icons/ci";
export default function SupplierDashboard() {

  const { id } = useParams();
  // Sample data - in a real app this would come from an API
  const metrics = [
    {
      title: "Total Revenue",
      value: "₹1,25,450",
      change: "+10.5%",
      subtext: "(+₹12,500)",
    },
    {
      title: "Repeat Purchase Rate",
      value: "82.34%",
      change: "+15.8%",
      subtext: "(+₹8,200)",
    },
    {
      title: "Average Order Value",
      value: "₹1,545.50",
      change: "+25.3%",
      subtext: "(+₹325)",
    },
    {
      title: "Conversion Rate",
      value: "45.20%",
      change: "-8.6%",
      subtext: "",
    },
  ];

  const recentOrders = [
    {
      product: "Brass Diya Set",
      customer: "Rajesh Sharma",
      orderId: "#1001543",
      date: "27 Jun 2025",
      status: "Pending",
    },
    {
      product: "Sandalwood Incense Sticks",
      customer: "Anita Verma",
      orderId: "#2458751",
      date: "26 Jun 2025",
      status: "Delivered",
    },
    {
      product: "Cotton Wicks Pack",
      customer: "Priya Menon",
      orderId: "#7852103",
      date: "25 Jun 2025",
      status: "Shipped",
    },
  ];

  const [activeItem, setActiveItem] = useState("Dashboard");

  const navigationItems = [
    { name: "Dashboard", icon: <MdDashboard /> },
    { name: "Manage Product", icon: <MdManageSearch /> },
    { name: "Manage Order", icon: <MdReceipt /> }, // New menu item
    { name: "Logout", icon: <CiLogout /> }, // New menu item
  ];

  const navigate = useNavigate(); // Hook for navigation

  const handleLogout = () => {
    navigate('/'); // Redirect to home page ('/')
    localStorage.clear();
  };


  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`http://127.0.0.1:8000/api/users/${id}`);
        if (!response.ok) {
          throw new Error("Failed to fetch user data");
        }
        const data = await response.json();
        setUserData(data); // Update state with the fetched user data
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [id]);  


 

  return (
    <div className="flex h-screen bg-gray-50">
      {/* Sidebar */}
      <aside className="w-64 bg-[#002538] text-white">
        <div className="p-4 flex items-center gap-2">
          <div className="w-8 h-8 bg-white rounded-2xl" />
          <span className="font-semibold">VPandit - Supplier</span>
        </div>

        <div className="mt-8">
          <div className="px-4 py-2 text-gray-400 text-sm">GENERAL</div>
          <nav className="space-y-1">
            {navigationItems.map((item) => (
              <a
                key={item.name}
                href="#"
                onClick={() => {
                  if (item.name === "Logout") {
                    handleLogout(); // Call handleLogout for Logout item
                  } else {
                    setActiveItem(item.name); // Update active state on click for other items
                  }
                }}
                className={`flex items-center gap-3 px-4 py-2 text-sm ${
                  activeItem === item.name
                    ? "bg-gray-100 text-[#002538] rounded-l-3xl"
                    : "hover:bg-gray-800 text-gray-400 hover:rounded-l-3xl"
                }`}
              >
                <span className="text-lg">{item.icon}</span>
                <span>{item.name}</span>
              </a>
            ))}
          </nav>
        </div>

        <div className="absolute bottom-4 w-64">
          <a
            href="#"
            className="flex items-center px-4 py-2 text-sm hover:bg-gray-800"
          >
            <FaSignOutAlt className="mr-2" />
            Logout
          </a>
        </div>
      </aside>

      {/* Main Content */}
      <main className="flex-1 overflow-auto">
        {/* Header */}
        <header className="bg-white border-b sticky top-0">
          <div className="flex items-center justify-between p-4">
            {/* Search Input */}
            <div className="relative flex-1 max-w-md">
              <input
                type="search"
                placeholder="Search"
                className="w-full pl-10 pr-4 py-2 rounded-lg border focus:outline-none focus:border-gray-400"
              />
              <FaSearch className="absolute left-3 top-2.5 text-gray-400" />
            </div>

            {/* Icons */}
            <div className="flex items-center gap-4">
              {/* Home Icon */}
              <Link to="/" className="p-2 hover:bg-gray-100 rounded-lg">
                <FaHome className="text-gray-900 hover:text-gray-900" />
              </Link>

              <div className="w-8 h-8 rounded-full bg-gray-200 flex items-center justify-center">
                <FaUser />
              </div>
            </div>
          </div>
        </header>

        {/* Content */}
        {activeItem === "Dashboard" && (
          <div className="p-5">
            <div className="flex justify-between items-center mb-6">
              <div>
                <h1 className="text-2xl font-semibold">
                  Welcome Back, {userData?.U_Name}!
                </h1>
                <p className="text-gray-500">
                  Here's what happening with your store today
                </p>
              </div>
              <div className="flex gap-4">
                <select className="px-4 py-2 border rounded-lg bg-white">
                  <option>Previous Year</option>
                </select>
                <div className="px-4 py-2 bg-[#002538] text-white rounded-lg">
                  View All Time
                </div>
              </div>
            </div>

            {/* Metrics */}
            <div className="grid grid-cols-4 gap-4 mb-8">
              {metrics.map((metric) => (
                <div
                  key={metric.title}
                  className="bg-white p-4 rounded-xl border"
                >
                  <div className="text-sm text-gray-500 mb-2">
                    {metric.title}
                  </div>
                  <div className="text-2xl font-semibold mb-2">
                    {metric.value}
                  </div>
                  <div className="flex items-center gap-2">
                    <span
                      className={`text-sm ₹{
                      metric.change.startsWith("+")
                        ? "text-green-500"
                        : "text-red-500"
                    }`}
                    >
                      {metric.change}
                    </span>
                    {metric.subtext && (
                      <span className="text-sm text-gray-400">
                        {metric.subtext}
                      </span>
                    )}
                  </div>
                </div>
              ))}
            </div>

            {/* Recent Orders */}
            <div className="bg-white rounded-xl border p-6 mb-8">
              <div className="flex justify-between items-center mb-6">
                <h2 className="text-lg font-semibold">Recent Orders</h2>
                <div className="text-blue-500 text-sm">View All</div>
              </div>
              <table className="w-full">
                <thead>
                  <tr className="text-left text-sm text-gray-500">
                    <th className="pb-4">Product</th>
                    <th className="pb-4">Customer</th>
                    <th className="pb-4">Order ID</th>
                    <th className="pb-4">Date</th>
                    <th className="pb-4">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {recentOrders.map((order) => (
                    <tr key={order.orderId} className="border-t">
                      <td className="py-4">
                        <div className="flex items-center gap-3">
                          <div className="w-10 h-10 bg-gray-100 rounded-lg" />
                          {order.product}
                        </div>
                      </td>
                      <td className="py-4 text-blue-500">{order.customer}</td>
                      <td className="py-4">{order.orderId}</td>
                      <td className="py-4">{order.date}</td>
                      <td className="py-4">
                        <span
                          className={`px-2 py-1 rounded-full text-xs ₹{
                          order.status === "Shipped"
                            ? "bg-green-100 text-green-600"
                            : order.status === "Pending"
                            ? "bg-yellow-100 text-yellow-600"
                            : "bg-red-100 text-red-600"
                        }`}
                        >
                          {order.status}
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}

        {activeItem === "Manage Product" && <Manage_Product id={id} />}
      </main>
    </div>
  );
}
