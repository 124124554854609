import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2"; // Import SweetAlert2

export default function Kundli() {
  const [formData, setFormData] = useState({
    name: "",
    gender: "",
    dateOfBirth: "",
    timeOfBirth: "",
    birthPlace: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const [submissionStatus, setSubmissionStatus] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("user"));
    if (userData) {
      setFormData(prev => ({
        ...prev,
        name: userData.U_Name || "",
        gender: userData.U_Gender === "M" ? "male" : userData.U_Gender === "F" ? "female" : "other",
        dateOfBirth: userData.U_DOB || "",
      }));
    }
  }, []);

  const validateForm = () => {
    const errors = {};
    if (!formData.name.trim()) errors.name = "Name is required.";
    if (!formData.gender) errors.gender = "Gender is required.";
    if (!formData.dateOfBirth) errors.dateOfBirth = "Date of birth is required.";
    else if (new Date(formData.dateOfBirth) > new Date()) errors.dateOfBirth = "Date of birth cannot be in the future.";
    if (!formData.timeOfBirth) errors.timeOfBirth = "Time of birth is required.";
    else if (new Date(`${formData.dateOfBirth}T${formData.timeOfBirth}`) > new Date()) errors.timeOfBirth = "Time of birth cannot be in the future.";
    if (!formData.birthPlace.trim()) errors.birthPlace = "Birthplace is required.";
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmissionStatus(null);

    // Check if user is logged in
    const userData = JSON.parse(localStorage.getItem("user"));
    if (!userData) {
      // Show SweetAlert and redirect to login
      Swal.fire({
        icon: "warning",
        title: "Login Required",
        text: "Please login first to generate your Kundli!",
        confirmButtonText: "Go to Login",
        confirmButtonColor: "#f97316", // Orange color to match theme
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/login");
        }
      });
      return;
    }

    if (validateForm()) {
      console.log("Form submitted:", formData);
      setSubmissionStatus("success");
      handleKundli();
    } else {
      setSubmissionStatus("error");
    }
  };

  const [suggestions, setSuggestions] = useState([]);
  const [loadingSuggestions, setLoadingSuggestions] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    if (name === "birthPlace") fetchCitySuggestions(value);
  };

  const fetchCitySuggestions = async (query) => {
    if (!query) {
      setSuggestions([]);
      return;
    }
    setLoadingSuggestions(true);
    try {
      const response = await axios.get(
        `https://api.opencagedata.com/geocode/v1/json?q=${encodeURIComponent(query)}&key=8a8bf13b0b694e5c99c719d998a9ff6c`
      );
      const results = response.data.results.map((result) => ({
        city: result.components.city || result.components.town || result.components.village,
        country: result.components.country,
      }));
      setSuggestions(results.filter((item) => item.city));
    } catch (error) {
      console.error("Error fetching city suggestions:", error);
      setSuggestions([]);
    } finally {
      setLoadingSuggestions(false);
    }
  };

  const handleSuggestionClick = (city) => {
    setFormData({ ...formData, birthPlace: city });
    setSuggestions([]);
  };

  const handleKundli = () => {
    const query = new URLSearchParams({
      name: formData.name,
      dob: formData.dateOfBirth,
      gender: formData.gender,
      timeOfBirth: formData.timeOfBirth,
      birthPlace: formData.birthPlace,
    }).toString();
    navigate(`/kundli/check?${query}`);
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <nav className="flex mb-4 text-sm">
        <Link to="/" className="text-orange-500 hover:text-orange-600 font-bold">Home</Link>
        <span className="mx-2 text-gray-500">&gt;</span>
        <span className="text-gray-600 font-bold">Kundli</span>
      </nav>

      <h1 className="text-3xl font-bold text-gray-900 mb-4">Unlock Your Destiny with Accurate Kundli Predictions</h1>
      <p className="text-lg text-gray-700 leading-relaxed mb-8">
        Discover the secrets of your life with our free online Kundli tool, crafted in collaboration with expert astrologers.
      </p>

      <form onSubmit={handleSubmit} className="container mx-auto space-y-6">
        <div className="grid md:grid-cols-2 gap-6">
          <div>
            <label className="block text-sm font-medium mb-2">Enter Name<span className="text-red-500">*</span></label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-orange-500"
              placeholder="Enter Name"
            />
            {formErrors.name && <p className="text-red-500 text-sm">{formErrors.name}</p>}
          </div>
          <div>
            <label className="block text-sm font-medium mb-2">Gender<span className="text-red-500">*</span></label>
            <select
              name="gender"
              value={formData.gender}
              onChange={handleInputChange}
              className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-orange-500"
            >
              <option value="">Select Gender</option>
              <option value="male">Male</option>
              <option value="female">Female</option>
              <option value="other">Other</option>
            </select>
            {formErrors.gender && <p className="text-red-500 text-sm">{formErrors.gender}</p>}
          </div>
          <div>
            <label className="block text-sm font-medium mb-2">Date of birth<span className="text-red-500">*</span></label>
            <input
              type="date"
              name="dateOfBirth"
              value={formData.dateOfBirth}
              onChange={handleInputChange}
              className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-orange-500"
            />
            {formErrors.dateOfBirth && <p className="text-red-500 text-sm">{formErrors.dateOfBirth}</p>}
          </div>
          <div>
            <label className="block text-sm font-medium mb-2">Time of Birth (Standard Time)<span className="text-red-500">*</span></label>
            <input
              type="time"
              name="timeOfBirth"
              value={formData.timeOfBirth}
              onChange={handleInputChange}
              className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-orange-500"
            />
            {formErrors.timeOfBirth && <p className="text-red-500 text-sm">{formErrors.timeOfBirth}</p>}
          </div>
          <div className="md:col-span-2 relative">
            <label className="block text-sm font-medium mb-2">Place of Birth<span className="text-red-500">*</span></label>
            <input
              type="text"
              name="birthPlace"
              value={formData.birthPlace}
              onChange={handleInputChange}
              className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-orange-500"
              placeholder="Enter birth place"
            />
            {formErrors.birthPlace && <p className="text-red-500 text-sm">{formErrors.birthPlace}</p>}
            {loadingSuggestions && <p className="text-sm text-gray-500">Loading...</p>}
            {suggestions.length > 0 && (
              <ul className="absolute z-10 bg-orange-50 border rounded w-full mt-1 max-h-48 overflow-y-auto shadow-lg">
                {suggestions.map((suggestion, index) => (
                  <li
                    key={index}
                    onClick={() => handleSuggestionClick(suggestion.city)}
                    className="p-2 hover:bg-orange-100 cursor-pointer"
                  >
                    {suggestion.city}, {suggestion.country}
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
        <div className="text">
          <button
            type="submit"
            className="bg-orange-500 text-white px-8 py-3 rounded hover:bg-orange-600 transition-colors font-bold"
          >
            GENERATE KUNDLI
          </button>
        </div>
        {submissionStatus === "success" && (
          <p className="text-green-500 text-sm mt-4">Form successfully submitted! Generating Kundli...</p>
        )}
        {submissionStatus === "error" && (
          <p className="text-red-500 text-sm mt-4">Please correct the errors above.</p>
        )}
      </form>
    </div>
  );
}