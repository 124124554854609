import React, { useEffect, useState } from "react";
import axios from "axios";

const Add_Material = ({ subCatId, onBack }) => {
  const [materials, setMaterials] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [pooja, setPooja] = useState(null);

  useEffect(() => {
    const fetchPooja = async () => {
      try {
        const response = await fetch(
          `https://vpandit.caxis.ca/public/api/subcategories/${subCatId}`
        );
        const data = await response.json();
        setPooja(data);
      } catch (error) {
        console.error("Error fetching pooja data:", error);
      }
    };
    fetchPooja();
  }, [subCatId]);

  useEffect(() => {
    const fetchMaterials = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          "https://vpandit.caxis.ca/public/api/product-masters"
        );

        // Filter materials where SubCat_Id matches the provided subCatId
        const filteredMaterials = response.data.filter(
          (item) => String(item.SubCat_Id) === String(subCatId) // Ensu re type match
        );

        setMaterials(filteredMaterials);
      } catch (err) {
        setError("Failed to fetch materials.");
      } finally {
        setLoading(false);
      }
    };
    fetchMaterials();
  }, [subCatId]);

  const handleSubmit = () => {
    alert("Working");
  };

  return (
    <div>
      <div className="flex items-center justify-between mb-4">
        <h2 className="text-2xl font-bold text-gray-700">
          Add Materials - {pooja?.SubCat_Name} - {subCatId}
        </h2>
        <div
          className="cursor-pointer bg-orange-500 text-white px-4 py-2 rounded shadow hover:bg-orange-600"
          onClick={onBack}
        >
          Back
        </div>
      </div>  

      {loading ? (
        <p>Loading materials...</p>
      ) : error ? (
        <p className="text-red-500">{error}</p>
      ) : (
        <div className="grid lg:grid-cols-4 md:grid-cols-3 grid-cols-2 gap-4">
          {materials.length > 0 ? (
            materials.map((material, index) => (
              <div
                key={index}
                className="p-4 border rounded shadow bg-white flex flex-col space-y-2"
              >
                <h3 className="text-gray-700 font-semibold">
                  Material ID: {material.PM_Seq_Id}
                </h3>

                {/* Display the Prd_Ids list */}
                {material.Prd_Ids && material.Prd_Ids.length > 0 ? (
                  <ul className="text-gray-600 list-disc list-inside">
                    {material.Prd_Ids.map((prd, idx) => (
                      <li key={idx} className="py-1">
                        <span className="font-bold text-gray-800">
                          Product ID:
                        </span>{" "}
                        {prd.Prd_Id} -
                        <span className="text-blue-600"> {prd.Prd_Qty}</span>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p className="text-gray-500">No Products Available</p>
                )}
              </div>
            ))
          ) : (
            <p className="text-gray-500">No materials available.</p>
          )}
        </div>
      )}

      <button
        className="mt-4 px-6 py-2 bg-orange-500 text-white rounded hover:bg-orange-600 rounded-3xl"
        onClick={handleSubmit}
      >
        Submit Materials
      </button>
    </div>
  );
};

export default Add_Material;
