"use client";

import { useEffect, useState } from "react";
import axios from "axios";
import {
  Calendar,
  IndianRupee,
  MapPin,
  AlertCircle,
  ArrowRight,
} from "lucide-react";
import Booking_Pooja_Details from "./Booking_Pooja_Details/Booking_Pooja_Details";

const MyBooking = ({ id }) => {
  const [bookings, setBookings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [BookingId, setBookingId] = useState(null);
  const [seletedDetails, setseletedDetails] = useState(false);

  useEffect(() => {
    const fetchBookings = async () => {
      try {
        const response = await axios.get(
          "https://vpandit.caxis.ca/public/api/inquiries"
        );
        const allBookings = Array.isArray(response.data) ? response.data : [];

        // Filter based on matching U_Id
        const filteredBookings = allBookings.filter(
          (booking) => booking.U_Id.toString() === id.toString()
        );

        setBookings(filteredBookings);
      } catch (err) {
        setError("Failed to fetch bookings.");
      } finally {
        setLoading(false);
      }
    };

    fetchBookings();
  }, [id]);

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="flex items-center gap-2 text-red-600">
          <AlertCircle className="w-6 h-6" />
          <span>{error}</span>
        </div>
      </div>
    );
  }

 

  function ShowDetails(Booking_Id){
    setBookingId(Booking_Id);
    setseletedDetails(true);
  }

  const HandleClose = () =>{
    setseletedDetails(false);
  }

  return (
    <div className="min-h-screen bg-gray-50 p-4">
      <div className="container mx-auto">
        {seletedDetails ? (
          <Booking_Pooja_Details
            BookingId={BookingId}
            HandleClose={HandleClose}
          />
        ) : (
          <div>
            <div className="flex items-center justify-between w-full">
              <h1 className="text-2xl md:text-3xl font-bold text-gray-900">
                My Pooja Bookings
              </h1>
              <span className="text-sm text-gray-500 ml-5">
                Total Bookings: {bookings.length}
              </span>
            </div>

            {bookings.length > 0 ? (
              <div className="grid gap-9 grid-col-1 md:grid-cols-3 lg:grid-cols-4 mt-6">
                {bookings.map((booking) => (
                  <div
                    key={booking.Booking_Id}
                    className="bg-white rounded-xl shadow-sm border border-gray-100 overflow-hidden hover:shadow-md transition-shadow w-[390px] flex flex-col justify-between"
                  >
                    <div className="p-6">
                      <div className="flex items-center justify-between mb-4">
                        <span className="text-sm text-gray-500">
                          Booking #{booking.Booking_Id}
                        </span>
                        <span className="inline-flex items-center gap-1.5 px-3 py-1.5 rounded-full text-xs font-medium bg-yellow-200 text-black">
                          Pending
                        </span>
                      </div>

                      <div className="space-y-4">
                        <div className="flex items-start gap-3">
                          <Calendar className="w-5 h-5 text-gray-400 mt-0.5" />
                          <div className="flex-1">
                            <p className="text-sm font-medium text-gray-700">
                              Booking Date
                            </p>
                            <p className="text-sm text-gray-600">
                              {new Date(booking.created_at).toDateString()}
                            </p>
                          </div>
                        </div>

                        <div className="flex items-start gap-3">
                          <IndianRupee className="w-5 h-5 text-gray-400 mt-0.5" />
                          <div>
                            <p className="text-sm font-medium text-gray-700">
                              Pooja Price
                            </p>
                            <p className="mt-1 text-sm text-gray-600">
                              ₹{booking.sub_category?.Price}
                            </p>
                          </div>
                        </div>

                        <div className="flex items-start gap-3">
                          <MapPin className="w-5 h-5 text-gray-400 mt-0.5" />
                          <div>
                            <p className="text-sm font-medium text-gray-700">
                              Pooja Address
                            </p>
                            <p className="mt-1 text-sm text-gray-600">
                              {booking.Pooja_Address}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="p-6">
                      <button
                        onClick={() => ShowDetails(booking.Booking_Id)}
                        className="w-full flex items-center justify-center gap-2 px-4 py-2 bg-[#ea580c] text-white text-sm font-medium rounded-full shadow hover:bg-orange-600 transition"
                      >
                        See Details
                        <ArrowRight className="w-4 h-4" />
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="bg-white rounded-xl shadow-sm border border-gray-100 p-8 text-center mt-10">
                <div className="max-w-md mx-auto">
                  <Calendar className="w-12 h-12 mx-auto text-gray-400" />
                  <h3 className="mt-4 text-lg font-medium text-gray-900">
                    No Bookings Found
                  </h3>
                  <p className="mt-2 text-sm text-gray-500">
                    You haven't made any pooja bookings yet.
                  </p>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default MyBooking;
