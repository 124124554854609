import React, { useEffect } from 'react';

const TawkToChat = () => {
  useEffect(() => {
    // Check if the Tawk.to script is already added
    if (window.Tawk_API) return;

    // Create the script element
    const script = document.createElement("script");
    script.async = true;
    script.src = "https://embed.tawk.to/678f7b203a84273260723700/1ii47hv4a";
    script.charset = "UTF-8";
    script.setAttribute("crossorigin", "*");

    // Append the script to the DOM
    const firstScript = document.getElementsByTagName("script")[0];
    firstScript.parentNode.insertBefore(script, firstScript);

    // Cleanup when the component unmounts
    return () => {
      const addedScript = document.querySelector(`script[src="${script.src}"]`);
      if (addedScript) addedScript.remove();
    };
  }, []);

  return null; // No UI for the widget
};

export default TawkToChat;
