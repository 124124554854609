import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { FaEdit, FaTrash } from "react-icons/fa";
import { UserPlus } from "lucide-react";
import Add_Personal_Month_Numerology from "./Add_Personal_Month_Numerology";
import CommongPreloader from "../CommongPreloader/CommongPreloader";
import HTMLReactParser from "html-react-parser/lib/index";
import { BsSortNumericUp } from "react-icons/bs";

const Personal_Month_Numerology = () => {
  const [numerologyData, setNumerologyData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showAddNumerology, setShowAddNumerology] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  // Fetch Data with Pagination
  const fetchData = async (page = 1) => {
    try {
      setLoading(true);
      const response = await axios.get(
        `https://vpandit.caxis.ca/public/api/personalnumcom?page=${page}`
      );

      if (response.data && response.data.data) {
        const formattedData = response.data.data.map((item) => ({
          pNumComId: item.p_numcom, // Adjusted field name for clarity
          numerologyNumber1: item.Value1,
          numerologyNumber2: item.Value2,
          numerologyNumber3: item.Value3,
          numerologyNumber4: item.Value4,
          numerologyNumber5: item.Value5,
          numerologyNumber6: item.Value6,
          title: item.desc_title,
          description: item.desc_details,
        }));

        setNumerologyData(formattedData);
        setTotalPages(response.data.total_pages); // Assuming 'total_pages' is returned by the API
      } else {
        throw new Error("Invalid data format received");
      }
    } catch (err) {
      setError("Failed to fetch data. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage]);

  // Handle Add Click
  const handleAddClick = () => {
    setSelectedData(null);
    setIsEditing(false);
    setShowAddNumerology(true);
    fetchData(currentPage);
  };

  // Handle Edit Click
  const handleEditClick = (data) => {
    setSelectedData(data);
    setIsEditing(true);
    setShowAddNumerology(true);
    fetchData(currentPage);
  };

  // Handle Delete Click
  const handleDeleteClick = async (p_numcom) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 1000,
      timerProgressBar: true,
    });

    try {
      await axios.delete(
        `https://vpandit.caxis.ca/public/api/personalnumcom/${p_numcom}`
      );
      setNumerologyData((prevData) =>
        prevData.filter((item) => item.pNumComId !== p_numcom)
      );

      Toast.fire({
        icon: "success",
        title: "Record deleted successfully",
      });

      fetchData(currentPage);
    } catch (err) {
      console.error("Error deleting data:", err);
      Toast.fire({
        icon: "error",
        title: "Failed to delete the record. Please try again.",
      });
    }
  };

  // Handle Page Change
  const handlePageChange = (pageNumber) => {
    if (pageNumber < 1 || pageNumber > totalPages) return;
    setCurrentPage(pageNumber);
  };

  // Generate Page Buttons
  const generatePageButtons = () => {
    let pageButtons = [];
    for (let i = 1; i <= totalPages; i++) {
      pageButtons.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          className={`px-4 py-2 border rounded-md ${
            currentPage === i ? "bg-blue-500 text-white" : "bg-white text-black"
          }`}
        >
          {i}
        </button>
      );
    }
    return pageButtons;
  };

  return (
    <div>
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Personal Month Numerology</h1>
        <div
          onClick={handleAddClick}
          className="cursor-pointer bg-orange-500 hover:bg-orange-600 text-white px-4 py-2 rounded-3xl flex items-center"
        >
          <BsSortNumericUp className="mr-2 h-4 w-4" /> Add Numerology
        </div>
      </div>

      {loading ? (
        <CommongPreloader />
      ) : error ? (
        <p className="text-red-500 text-center">{error}</p>
      ) : showAddNumerology ? (
        <Add_Personal_Month_Numerology
          onClose={() => setShowAddNumerology(false)}
          setNumerologyComboData={setNumerologyData}
          numerologyComboData={numerologyData}
          selectedData={selectedData}
          isEditing={isEditing}
        />
      ) : (
        <div className="rounded-lg overflow-hidden">
          {numerologyData.length === 0 ? (
            <p className="p-4 text-center text-gray-500">No Data Found</p>
          ) : (
            <div>
              <table className="w-full bg-white">
                <thead>
                  <tr className="border-b border-gray-700">
                    <th className="px-6 py-3 text-left text-xs font-medium text-black uppercase tracking-wider">
                      Value1
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-black uppercase tracking-wider">
                      Value2
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-black uppercase tracking-wider">
                      Value3
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-black uppercase tracking-wider">
                      Value4
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-black uppercase tracking-wider">
                      Value5
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-black uppercase tracking-wider">
                      Value6
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-black uppercase tracking-wider">
                      Title
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-black uppercase tracking-wider">
                      Description
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-black uppercase tracking-wider">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-700">
                  {numerologyData.map((item, index) => (
                    <tr key={index} className="hover:bg-gray-50">
                      <td className="px-6 py-4">{item.numerologyNumber1}</td>
                      <td className="px-6 py-4">{item.numerologyNumber2}</td>
                      <td className="px-6 py-4">{item.numerologyNumber3}</td>
                      <td className="px-6 py-4">{item.numerologyNumber4}</td>
                      <td className="px-6 py-4">{item.numerologyNumber5}</td>
                      <td className="px-6 py-4">{item.numerologyNumber6}</td>
                      <td className="px-6 py-4">{item.title}</td>
                      <td className="px-6 py-4">
                        {item.description
                          ? HTMLReactParser(item.description)
                          : "No description available"}
                      </td>
                      <td className="px-6 py-4">
                        <div className="flex space-x-3">
                          <FaEdit
                            className="text-green-600 cursor-pointer"
                            onClick={() => handleEditClick(item)}
                          />
                          <FaTrash
                            className="text-red-600 cursor-pointer"
                            onClick={() => handleDeleteClick(item.pNumComId)}
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <div className="flex justify-between mt-4 space-x-2 items-center">
                <div className="">
                  <p className="font-bold">
                    Current Page: {currentPage} / 5971
                  </p>
                </div>

                <div className="flex items-center gap-2">
                  <div
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                    className="px-4 py-2 bg-gray-300 rounded-md disabled:bg-gray-400 cursor-pointer"
                  >
                    Previous
                  </div>
                  {generatePageButtons()}
                  <div
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                    className="px-4 py-2 bg-gray-300 rounded-md disabled:bg-gray-400 cursor-pointer"
                  >
                    Next
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Personal_Month_Numerology;
