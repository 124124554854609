import React from "react";
import { Clock } from "lucide-react";

export default function BLog_Details() {
  return (
    <div className="container mx-auto px-4 py-8">
      {/* Title Section */}
      <h1 className="text-3xl md:text-4xl font-bold mb-8">
        Top 5 Benefits, Key Factors, and Core Elements of Astrology in 2024
      </h1>

      {/* Main Content Grid */}
      <div className="grid md:grid-cols-[300px_1fr] gap-8">
        {/* Sidebar Navigation */}
        <aside className="space-y-4">
          {/* Author Info */}
          <div className="flex items-center gap-3 mb-6">
            <img
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRFCzxivJXCZk0Kk8HsHujTO3Olx0ngytPrWw&s"
              alt="Author avatar"
              width={40}
              height={40}
              className="rounded-full"
            />
            <div>
              <h2 className="font-medium">Faizan Khan</h2>
              <div className="flex items-center text-sm text-muted-foreground">
                <Clock className="w-4 h-4 mr-1" />
                <span>29 minutes read</span>
              </div>
            </div>
          </div>

          {/* Table of Contents */}
          <nav className="space-y-4">
            <h3 className="font-bold text-orange-600 mb-3 border-b pb-2">
              What Are The Benefits Of Astrology:
            </h3>
            <ul className="space-y-3 text-sm">
              <li className="text-muted-foreground hover:text-primary cursor-pointer border-b pb-2">
                Understand Your Life's Purpose
              </li>
              <li className="text-muted-foreground hover:text-primary cursor-pointer border-b pb-2">
                Gain Insights into Your Relationships
              </li>
              <li className="text-muted-foreground hover:text-primary cursor-pointer border-b pb-2">
                Maximize Personal Growth
              </li>
              <li className="text-muted-foreground hover:text-primary cursor-pointer border-b pb-2">
                Enhance Career Opportunities
              </li>
              <li className="text-muted-foreground hover:text-primary cursor-pointer border-b pb-2">
                Predict Future Events
              </li>
              <li className="text-muted-foreground hover:text-primary cursor-pointer border-b pb-2">
                Explore Your Spiritual Path
              </li>
              <li className="text-muted-foreground hover:text-primary cursor-pointer border-b pb-2">
                Harness the Power of Your Zodiac Sign
              </li>
              <li className="text-muted-foreground hover:text-primary cursor-pointer border-b pb-2">
                Key Factors in Astrology
              </li>
            </ul>
          </nav>
        </aside>

        {/* Main Content */}
        <article className="prose prose-gray max-w-none">
          <section className="mb-6">
            <h2 className="text-2xl font-bold text-primary mb-4 border-b pb-2 text-orange-600">
              What Are The Benefits Of Astrology:
            </h2>
            <p className="text-muted-foreground">
              Astrology is the study of the influence that the stars, planets,
              and other celestial bodies have on human life. In this article,
              we'll explore how astrology can help individuals gain deeper
              insight into various aspects of life.
            </p>
          </section>

          <section className="mb-6">
            <h3 className="text-xl font-bold text-primary mb-4 border-b pb-2">
              Understand Your Life's Purpose:
            </h3>
            <p className="text-muted-foreground">
              Astrology provides a roadmap to your life by revealing your soul’s
              purpose, the challenges you’re likely to face, and the strengths
              you possess to overcome them.
            </p>
          </section>

          <section className="mb-6">
            <h3 className="text-xl font-bold text-primary mb-4 border-b pb-2">
              Gain Insights into Your Relationships:
            </h3>
            <p className="text-muted-foreground">
              By studying your natal chart and those of your loved ones,
              astrology helps you understand your relationship dynamics,
              compatibility, and how to nurture healthier connections.
            </p>
          </section>

          <section className="mb-6">
            <h3 className="text-xl font-bold text-primary mb-4 border-b pb-2">
              Maximize Personal Growth:
            </h3>
            <p className="text-muted-foreground">
              Astrology can reveal your personal strengths, weaknesses, and
              potential for growth. By understanding these aspects, you can
              better navigate life’s challenges and improve yourself.
            </p>
          </section>

          <section className="mb-6">
            <h3 className="text-xl font-bold text-primary mb-4 border-b pb-2">
              Enhance Career Opportunities:
            </h3>
            <p className="text-muted-foreground">
              Your astrological chart can provide guidance on the career paths
              that are most aligned with your natural talents and tendencies,
              helping you make more informed career decisions.
            </p>
          </section>

          <section className="mb-6">
            <h3 className="text-xl font-bold text-primary mb-4 border-b pb-2">
              Predict Future Events:
            </h3>
            <p className="text-muted-foreground">
              Astrology helps you understand the cyclical nature of life. By
              analyzing planetary transits and progressions, astrologers can
              offer predictions on upcoming opportunities or challenges.
            </p>
          </section>
        </article>
      </div>
    </div>
  );
}
