import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Inquiryform = () => {
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("token");
    const userID = localStorage.getItem("userID");
    setIsAuthenticated(!!(token && userID));
  }, []);

  const [formState, setFormState] = useState({
    expertFor: "",
    date: "",
    time: "",
    place: ""
  });

  const [suggestions, setSuggestions] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    setFormState({ ...formState, [e.target.id]: e.target.value });

    if (e.target.id === "place") {
      fetchLocationSuggestions(e.target.value);
    }
  };

  const fetchLocationSuggestions = async (query) => {
    if (!query) {
      setSuggestions([]);
      return;
    }

    try {
      const response = await fetch(
        `https://api.opencagedata.com/geocode/v1/json?q=${query}&key=8a8bf13b0b694e5c99c719d998a9ff6c&limit=8`
      );
      const data = await response.json();

      if (data.results) {
        setSuggestions(data.results.map((result) => result.formatted));
      }
    } catch (error) {
      console.error("Error fetching location suggestions:", error);
    }
  };

  const handleLocationSelect = (location) => {
    setFormState({ ...formState, place: location });
    setSuggestions([]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isAuthenticated) {
    //   alert("Please log in to submit the form.");
      navigate("/login");
      return;
    }

    setIsSubmitting(true);

    const payload = {
      Name: formState.firstName + " " + formState.lastName,
      Email: formState.email,
      Mobile: formState.phone || "Not provided",
      Subject: "Astrological Inquiry",
      Message: formState.message,
      Date: formState.date,
      Time: formState.time,
      Location: formState.place,
    };

    try {
      const response = await fetch("https://vpandit.com/public/api/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        const data = await response.json();
        console.log("Success:", data);
        alert("Your message has been sent successfully!");
      } else {
        const error = await response.json();
        console.error("Error:", error);
        alert("Failed to send your message. Please try again.");
      }
    } catch (error) {
      console.error("Network error:", error);
      alert("An error occurred while sending your message.");
    } finally {
      setIsSubmitting(false);
      setFormState({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        message: "",
        date: "",
        time: "",
        place: "",
      });
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="space-y-6 bg-white shadow-lg rounded-lg p-6 border border-[#002538] border-y-2"
    >
      <h2 className="text-2xl font-bold text-[#002538] underline underline-offset-4 mb-4">
        Need Expert?
      </h2>

      <div className="space-y-2">
        <label htmlFor="expertFor" className="block text-sm font-medium text-gray-700">
          Expert For?
        </label>
        <select
          id="expertFor"
          value={formState.expertFor}
          onChange={handleChange}
          required
          className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-[#002538]"
        >
          <option value="">Select an Expertise</option>
          <option value="Karma Kand">Karma Kand</option>
          <option value="Vastu Sastra">Vastu Sastra</option>
          <option value="Numerology">Numerology</option>
          <option value="Astrology">Astrology</option>
        </select>
      </div>

      <div className="space-y-2">
        <label htmlFor="date" className="block text-sm font-medium text-gray-700">
          Date
        </label>
        <input
          id="date"
          type="date"
          value={formState.date}
          onChange={handleChange}
          required
          className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-[#002538]"
        />
      </div>

      <div className="space-y-2">
        <label htmlFor="time" className="block text-sm font-medium text-gray-700">
          Time
        </label>
        <input
          id="time"
          type="time"
          value={formState.time}
          onChange={handleChange}
          required
          className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-[#002538]"
        />
      </div>

      <div className="space-y-2 relative">
        <label htmlFor="place" className="block text-sm font-medium text-gray-700">
          Location
        </label>
        <input
          id="place"
          type="text"
          placeholder="Enter your location"
          value={formState.place}
          onChange={handleChange}
          required
          className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-[#002538]"
        />
        {suggestions.length > 0 && (
          <ul className="absolute z-10 bg-white border border-gray-300 w-full mt-1 rounded-md shadow-lg">
            {suggestions.map((suggestion, index) => (
              <li
                key={index}
                className="px-3 py-2 cursor-pointer hover:bg-gray-200"
                onClick={() => handleLocationSelect(suggestion)}
              >
                {suggestion}
              </li>
            ))}
          </ul>
        )}
      </div>
      <button
        type="submit"
        className="w-full border-none bg-[#002538] text-white hover:bg-[#183a4b] py-2 px-4 rounded-md focus:outline-none"
      >
        {isSubmitting ? "Submitting..." : "Inquire Now"}
      </button>
    </form>
  );
};

export default Inquiryform;
