import React, { useState } from "react";
import Swal from "sweetalert2";

const Forgot_Password = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");

  const handleInputChange = (e) => {
    setEmail(e.target.value);
  };

  const validateEmail = () => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    if (!email || !emailRegex.test(email)) {
      setError("Valid email is required.");
      return false;
    }
    setError("");
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateEmail()) {
      const swalInstance = Swal.fire({
        title: "Processing...",
        text: "Please wait while we process your request.",
        icon: "info",
        showConfirmButton: false,
        willOpen: () => {
          Swal.showLoading();
        },
      });

      try {
        const response = await fetch(
          "https://vpandit.caxis.ca/public/api/forgot-password",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ email }),
          }
        );

        if (response.ok) {
          const data = await response.json();
          swalInstance.close();

          Swal.fire({
            icon: "success",
            title: "Success",
            text:
              data.message ||
              "A password reset link has been sent to your email.",
            confirmButtonText: "OK",
          });
        } else {
          const errorData = await response.json();
          swalInstance.close();

          Swal.fire({
            icon: "error",
            title: "Request Failed",
            text: errorData.message || "Failed to process your request.",
            confirmButtonText: "Try Again",
          });
        }
      } catch (error) {
        swalInstance.close();

        Swal.fire({
          icon: "error",
          title: "Network Error",
          text: "Please check your internet connection and try again.",
          confirmButtonText: "Close",
        });
      }
    }
  };

  return (
    <div className="h-auto container mx-auto my-16 px-4">
      <div className="flex flex-col lg:flex-row items-center lg:items-start">
        {/* Left Section - Forgot Password Introduction */}
        <div className="flex flex-col bg-gradient-to-r w-full lg:w-1/2 mb-6 lg:mb-0 p-6 lg:p-10">
          <h2 className="text-3xl font-bold mb-6 lg:text-left">
            Reset Your Password with Ease
          </h2>
          <p className="text-lg mb-6 lg:text-left">
            Forgot your password? No worries! We’ll help you get back into your
            account quickly and securely.
          </p>
          <p className="text-sm mb-6 lg:text-left">
            Simply enter the email address you used to register, and we’ll send
            you a link to reset your password. It’s quick, safe, and
            hassle-free.
          </p>
          <p className="text-sm text-gray-500 lg:text-left">
            If you’re having trouble accessing your email or didn’t receive the
            reset link, contact our support team for assistance.
          </p>
        </div>

        {/* Right Section - Forgot Password Form */}
        <div className="flex items-center w-full lg:w-1/2 md:pl-24 md:pt-0 pt-10">
          <div className="w-full max-w-md space-y-8">
            <div className="lg:text-left">
              <h2 className="text-2xl font-semibold mb-2">Forgot Password</h2>
            </div>

            <form className="space-y-6" onSubmit={handleSubmit}>
              <div>
                <label className="text-xs uppercase text-gray-500 mb-1 block">
                  Email ID
                </label>
                <input
                  type="email"
                  value={email}
                  onChange={handleInputChange}
                  className="w-full border-b-2 border-gray-300 focus:border-orange-600 focus:outline-none px-0 py-2 rounded-sm text-gray-800 placeholder-gray-400 focus:ring-0"
                  placeholder="Enter your email"
                />
                {error && <p className="text-red-500 text-xs mt-1">{error}</p>}
              </div>

              <button
                type="submit"
                className="w-full bg-orange-600 hover:bg-orange-700 text-white rounded-full py-2"
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Forgot_Password;
