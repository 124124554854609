"use client";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const SlotPicker = ({ handleBookPoojaClick, subcatid, price, U_id,panditid }) => {
  const [step, setStep] = useState(1); // Step to manage views
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    address: "",
    city: "",
    postalCode: "",
  });

  const [formErrors, setFormErrors] = useState({});
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [userId, serUserId] = useState(null);
  const [poojanmae,setPoojaName] = useState(null)

  // Load user data from localStorage
  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("user"));
    if (userData) {
      setFormData((prevData) => ({
        ...prevData,
        name: userData.U_Name || "",
        phone: userData.U_Mobile || "",
      }));
    }
  }, []);

  useEffect(() => {
    const userId = localStorage.getItem("userID");
    serUserId(userId);
  }, [U_id]);

  // Handle form field changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    setFormErrors((prevErrors) => ({ ...prevErrors, [name]: "" })); // Clear the error for the field
  };

  // Validation for form fields
  const validateForm = () => {
    const errors = {};
    if (!formData.name.trim()) errors.name = "Full name is required.";
    if (!formData.phone.trim()) errors.phone = "Phone number is required.";
    if (!formData.address.trim()) errors.address = "Address is required.";
    if (!formData.city.trim()) errors.city = "City is required.";
    if (!formData.postalCode.trim())
      errors.postalCode = "Postal code is required.";
    return errors;
  };

  // Proceed to the next step
  const handleNext = () => {
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }
    setStep(2);
  };

  // Handle date change
  const handleDateChange = (e) => {
    const selectedDate = new Date(e.target.value);
    setSelectedDate(selectedDate);
  };

  // Handle time change
  const handleTimeChange = (e) => {
    setSelectedTime(e.target.value);  
  };

  const isBookingDisabled = () => {
    return !selectedDate || !selectedTime; // Disable booking if no date or time is selected
  };


   const fetchData = async () => {
      try {
        const response = await fetch(
          `https://vpandit.caxis.ca/public/api/subcategories/${subcatid}`
        );
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setPoojaName(data.SubCat_Name);
      } catch (error) {
        console.error("Error fetching subcategories:", error);
      }
    };
  
    useEffect(() => {
      fetchData();
    }, []);


  const navigate = useNavigate();

  function formatDateToDMY(inputDate) {
    const date = new Date(inputDate);

    // Extract day, month, and year
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const year = date.getFullYear();

    // Combine in d/m/Y format
    return `${day}/${month}/${year}`;
  }

  function formatTimeToHi(inputTime) {
    const date = new Date(inputTime);

    // Extract hours and minutes
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    // Combine in H:i format
    return `${hours}:${minutes}`;
}

  const handleBook = async () => {
    alert("Hello");
    const payload = {
      U_Id: userId,
      Pandit_Id: panditid,
      SubCat_Id: subcatid,
      Pooja_Address: formData.address,
      Pooja_Location: formData.city,
    };

    console.log(payload);

    try {
      const response = await axios.post(
        "https://vpandit.caxis.ca/public/api/inquiries",
        payload,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      navigate(`/bookedsuccessfully?pid=${subcatid}`);
      console.log("Booking Successful:", response.data);
    } catch (error) {
      if (error.response) {
        // Server responded with a status other than 2xx
        console.error("Failed to book pandit:", error.response.statusText);
      } else if (error.request) {
        // Request was made but no response received
        console.error("No response received:", error.request);
      } else {
        // Error setting up the request
        console.error("Error occurred during booking:", error.message);
      }
    }
  };
  


  return (
    <div className="max-w-md bg-white rounded-lg">
      {step === 1 && (
        <div>
          {/* Header */}
          <div className="flex items-center justify-between mb-6">
            <div className="flex items-center">
              <div
                className="hover:bg-gray-100 rounded-full cursor-pointer"
                onClick={handleBookPoojaClick}
              >
                <svg
                  className="w-6 h-6 text-gray-600"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24" 
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M15 19l-7-7 7-7"
                  />
                </svg>
              </div>
              <h1 className="text-xl font-semibold ml-2">
                Address Information
              </h1>
            </div>
          </div>

          {/* Name Input */}
          <div className="mb-4">
            <div className="text-sm text-gray-500 mb-2">Full Name</div>
            <input
              type="text"
              name="name"
              onChange={handleChange}
              value={formData.name}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-500"
              placeholder="Enter your full name"
            />
            {formErrors.name && (
              <div className="text-sm text-red-500 mt-1">{formErrors.name}</div>
            )}
          </div>

          {/* Phone Input */}
          <div className="mb-4">
            <div className="text-sm text-gray-500 mb-2">Phone Number</div>
            <input
              type="tel"
              name="phone"
              onChange={handleChange}
              value={formData.phone}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-500"
              placeholder="Enter your phone number"
            />
            {formErrors.phone && (
              <div className="text-sm text-red-500 mt-1">
                {formErrors.phone}
              </div>
            )}
          </div>

          {/* Address Input */}
          <div className="mb-4">
            <div className="text-sm text-gray-500 mb-2">Home Address</div>
            <textarea
              name="address"
              onChange={handleChange}
              value={formData.address}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-500"
              rows="3"
              placeholder="Enter your home address"
            />
            {formErrors.address && (
              <div className="text-sm text-red-500 mt-1">
                {formErrors.address}
              </div>
            )}
          </div>

          {/* City Input */}
          <div className="mb-4">
            <div className="text-sm text-gray-500 mb-2">City</div>
            <input
              type="text"
              name="city"
              onChange={handleChange}
              value={formData.city}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-500"
              placeholder="Enter your city"
            />
            {formErrors.city && (
              <div className="text-sm text-red-500 mt-1">{formErrors.city}</div>
            )}
          </div>

          
          {/* Next Button */}
          <button
            onClick={handleBook}
            className={`w-[90%] mt-4 ml-5 text-center text-white bg-orange-600 px-4 py-2 rounded-md cursor-pointer absolute bottom-10 left-2 `}
          >
            Book Appointment
          </button>
        </div>
      )}
    </div>
  );
};

export default SlotPicker;
