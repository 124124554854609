import React, { useEffect, useState } from "react";
import axios from "axios";
import CommongPreloader from "../../AdminPanel/CommongPreloader/CommongPreloader";
import { useParams } from "react-router-dom";

const My_Pooja = ({ userData }) => {
  const [poojas, setPoojas] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const [price, setPrice] = useState("");
  const { id } = useParams(); // Get the id from the URL params

  const [panditId, setPanditId] = useState(null); // State to store the Pandit_Id

  useEffect(() => {
    // Function to fetch pandits and filter by U_Id
    const fetchPandits = async () => {
      try {
        const response = await axios.get(
          "https://vpandit.caxis.ca/public/api/pandit-masters"
        );

        console.log("API Response:", response.data);

        const panditData = response.data.pandits;

        console.log("URL id:", id);

        const selectedPandit = panditData.find((pandit) => {
          console.log("Comparing:", pandit.user.U_Id, "with", id);
          return String(pandit.user.U_Id) === String(id);
        });

        if (selectedPandit) {
          console.log("Selected Pandit:", selectedPandit);
          setPanditId(selectedPandit.Pandit_Id);
        } else {
          console.log("Pandit not found for the given U_Id");
        }
      } catch (error) {
        console.error("Error fetching pandits:", error);
      }
    };

    // Only fetch when the id is available
    if (id) {
      fetchPandits();
    }
  }, [id]);

  const fetchPoojaData = async () => {
    try {
      const response = await axios.get(
        "https://vpandit.caxis.ca/public/api/pandit-skills"
      );
      const filteredData = response.data.filter(
        (item) => item.pandit?.U_Id === String(userData.U_Id)
      );
      const poojaList = filteredData.map((item) => ({
        name: item.sub_category?.SubCat_Name,
        price: item.Price,
      }));
      setPoojas(poojaList);
    } catch (err) {
      setError("Failed to fetch data. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (userData?.U_Id) {
      fetchPoojaData();
    }
  }, [userData]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(
          "https://vpandit.caxis.ca/public/api/categories"
        );
        setCategories(response.data);
      } catch (err) {
        setError("Failed to fetch categories.");
      }
    };
    fetchCategories();
  }, []);

  useEffect(() => {
    const fetchAllSubCategories = async () => {
      try {
        const response = await axios.get(
          "https://vpandit.caxis.ca/public/api/subcategories"
        );
        const filteredSubCategories = selectedCategory
          ? response.data.filter(
              (sub) => sub.Cat_Id === String(selectedCategory)
            )
          : [];
        setSubCategories(filteredSubCategories);
      } catch (err) {
        setError("Failed to fetch subcategories.");
      }
    };
    if (selectedCategory) {
      fetchAllSubCategories();
    } else {
      setSubCategories([]);
    }
  }, [selectedCategory]);

  const handleCategorySelect = (catId) => {
    setSelectedCategory(catId);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedCategory(null);
    setSubCategories([]);
    setPrice("");
  };

  const handleSubCategorySelect = (subCategory) => {
    setSelectedSubCategory(subCategory);
  };

  const handlePriceChange = (e) => {
    setPrice(e.target.value);
  };

  const handleSubmit = async () => {
    if (!price || !selectedSubCategory) {
      setError("Please enter a price and select a subcategory.");
      return;
    }

    const payload = {
      Pandit_Id: panditId,
      Skills: [
        {
          SubCat_ID: selectedSubCategory.SubCat_Id,
          Price: parseInt(price),
        },
      ],
    };

    console.log(payload);

    try {
      const response = await axios.post(
        "https://vpandit.caxis.ca/public/api/pandit-skills",
        payload
      );
      fetchPoojaData()
      alert("Pooja Addedd Successully");
      closeModal(); // Close modal on success
    } catch (error) {
      console.error("Error submitting data:", error);
      setError("Failed to submit the data. Please try again.");
    }
  };

  return (
    <div className="bg-gray-50">
      <div className="flex items-center justify-between">
        <h2 className="text-2xl font-bold mb-4">My Poojas</h2>
        <div
          onClick={openModal}
          className="px-4 py-2 mb-4 text-sm font-medium text-white bg-orange-600 rounded-lg hover:bg-orange-700 transition-colors cursor-pointer"
        >
          Add New Pooja
        </div>
      </div>

      {loading ? (
        <CommongPreloader />
      ) : error ? (
        <p className="text-red-500">{error}</p>
      ) : poojas.length > 0 ? (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-6">
          {poojas.map((pooja, index) => (
            <div
              key={index}
              className="relative p-4 cursor-pointer rounded-lg bg-gray-100  border border-gray-400"
            >
              <h3 className="text-xl font-bold text-gray-800 hover:text-orange-500 hover:underline transition-all">
                {pooja.name}
              </h3>
              <div className="text-sm text-gray-900 font-bold mt-2">
                ₹ {pooja.price}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <p>No matching Poojas found.</p>
      )}

      {/* Modal for Adding New Pooja */}
      {isModalOpen && (
        <div className="fixed inset-0 flex justify-center items-center bg-gray-500 bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-lg w-2/3 max-w-[1000px] max-h-[90vh] overflow-y-auto">
            <h3 className="text-xl font-bold mb-4">Select Category</h3>

            <div className="space-y-4">
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4 overflow-y-auto max-h-[300px]">
                {categories.map((category) => (
                  <div
                    key={category.Cat_Id}
                    onClick={() => handleCategorySelect(category.Cat_Id)}
                    className="cursor-pointer p-6 bg-gray-100 hover:bg-gray-200 rounded-lg font-bold w-full"
                  >
                    {category.Cat_Name}
                  </div>
                ))}
              </div>
            </div>

            {selectedCategory && subCategories.length > 0 && (
              <div className="mt-4">
                <h3 className="text-xl font-bold mb-4">Subcategories</h3>
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4 overflow-y-auto max-h-[300px]">
                  {subCategories.map((subCategory) => (
                    <div
                      key={subCategory.SubCat_Id}
                      onClick={() => handleSubCategorySelect(subCategory)}
                      className="p-4 bg-gray-100 hover:bg-gray-200 rounded-lg font-bold cursor-pointer"
                    >
                      {subCategory.SubCat_Name}
                    </div>
                  ))}
                </div>

                {selectedSubCategory && (
                  <div className="mt-4">
                    <input
                      type="number"
                      value={price}
                      onChange={handlePriceChange}
                      placeholder="Enter price"
                      className="w-full p-2 border rounded-md"
                    />
                  </div>
                )}
              </div>
            )}

            <div className="flex justify-between mt-4">
              <div
                onClick={closeModal}
                className="px-4 py-2 text-white bg-orange-600 rounded-lg hover:bg-orange-700 cursor-pointer"
              >
                Close
              </div>
              <div
                onClick={handleSubmit}
                className="px-4 py-2 text-white bg-green-600 rounded-lg hover:bg-green-700 cursor-pointer"
              >
                Submit
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default My_Pooja;
