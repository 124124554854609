import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FaBook } from "react-icons/fa";

export default function Pandit_List({ SubCat_Id }) {
  const [astrologers, setAstrology] = useState([]); // State to store astrologers

  useEffect(() => {
    const fetchAndFilterData = async () => {
      try {
        // Step 1: Fetch the pandit-skills data
        const response = await fetch(
          "https://vpandit.caxis.ca/public/api/pandit-skills"
        );
        const data = await response.json();

        // Step 2: Filter the data based only on SubCat_Id
        const filteredResults = data.filter(
          (item) => String(item.SubCat_ID) === String(SubCat_Id)
        );

        if (filteredResults.length === 0) {
          setAstrology([]); // No matches found
          return;
        }

        // Step 3: Extract unique U_Id values
        const userIds = [
          ...new Set(filteredResults.map((item) => item.pandit.U_Id)),
        ];

        // Step 4: Fetch user details for each U_Id and filter by Role_Id
        const userPromises = userIds.map(async (id) => {
          const userResponse = await fetch(
            `https://vpandit.caxis.ca/public/api/users/${id}`
          );
          const userData = await userResponse.json();

          // Ensure the user has Role_Id === "2"
          if (userData.Role_Id !== "2") {
            return null; // Exclude users who don't match the role
          }

          // Match skills for this user with the filtered results
          const userSkills = filteredResults.filter(
            (skill) => skill.pandit.U_Id === id
          );

          return {
            ...userData,
            matchedSkills: userSkills,
          };
        });

        // Step 5: Resolve all user promises, remove null values, and update state
        const userDetails = (await Promise.all(userPromises)).filter(
          (user) => user !== null
        );

        setAstrology(userDetails);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchAndFilterData();
  }, [SubCat_Id]);

  return (
    <>
      <div className="h-auto py-16 container mx-auto md:p-0 p-4 ">
        {/* Introductory Section */}
        <div className="relative bg-gradient-to-r from-orange-600 to-orange-300 md:p-12 mb-8">
          <div className="container mx-auto">
            <div className="flex flex-col md:flex-row justify-between items-center">
              <div className="mb-8 md:mb-0">
                <h1 className="text-3xl md:text-4xl font-bold text-white mb-4">
                  Connect With India&apos;s Best Pandits
                </h1>
                <p className="text-xl text-white">
                  Consult expert Pandits for your Pooja needs on VPandit
                </p>
              </div>
              <div className="flex flex-col space-y-4">
                <button className="bg-white text-orange-600 hover:bg-orange-100 py-3 px-6 rounded-lg shadow-md font-bold">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.aark.vpandit&pcampaignid=web_share"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Download App
                  </a>
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Astrologers List - Only show if astrologers exist */}
        {astrologers.length > 0 && (
          <div className="container mx-auto px-4 pb-16">
            <div className="overflow-x-auto">
              <div className="flex space-x-6 mt-5">
                {astrologers.map((astrologer, index) => (
                  <div
                    key={index}
                    className="bg-white shadow-lg rounded-lg p-5 border border-gray-200 flex-shrink-0 flex  gap-4 items-center"
                    style={{ minWidth: "250px" }} // Ensures consistent width for each card
                  >
                    {/* Image Section */}
                    <div className="flex justify-center mb-4">
                      <img
                        src="https://cdn-icons-png.flaticon.com/512/8792/8792047.png"
                        alt={astrologer.U_Name}
                        className="w-20 h-20 rounded-full object-cover"
                      />
                    </div>

                    {/* Name Section */}
                    <div>
                      <h3 className="text-lg font-semibold text-left">
                        {astrologer.U_Name}
                      </h3>

                      {/* Skills and Booking Section */}
                      <div className="mt-2">
                        {astrologer.matchedSkills.map((skill, idx) => (
                          <div key={idx} className="mb-3">
                            <p className="text-xl font-semibold">
                              Price: ₹
                              {skill.Price ? skill.Price : "Not Available"}
                            </p>
                            <Link
                              to={`/pandit/${astrologer.U_Name}?P_id=${astrologer.U_Id}&price=${skill.Price}&pjid=${SubCat_Id}`}
                              className="inline-block mt-2 px-4 py-2 bg-orange-500 text-white rounded-full hover:bg-orange-600"
                            >
                              <FaBook className="inline-block mr-2" />
                              Book Now
                            </Link>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
