"use client";

import { useEffect, useState } from "react";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { Link, useLocation, useParams } from "react-router-dom";
import Pandit_List from "./Pandit_List/Pandit_List";
import axios from "axios";
import { FaAngleRight, FaPlayCircle, FaShareAlt } from "react-icons/fa";
import ReactPlayer from "react-player";
import Modal from "react-modal";
import jsPDF from "jspdf";
import HTMLReactParser from "html-react-parser";
import Supplier_List from "../../../Supplier_List/Supplier_List";

export default function GaneshPuja() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const sid = queryParams.get("sid");
  const [activeTab, setActiveTab] = useState("description");
  const [quantity, setQuantity] = useState(3);
  const [currentImage, setCurrentImage] = useState(0);

  const images = [
    "https://cheetah.cherishx.com/uploads/1657016860_large.jpg",
    "https://cdn.togetherv.com/ganpati-puja-setup-floral-arrangement-main_1693203387.webp",
    "https://cheetah.cherishx.com/uploads/1657709959_webp_original.webp",
  ];

  const reviews = Array(16).fill(null);

  const [openIndex, setOpenIndex] = useState(null);

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  // API CALLING START
  const { details } = useParams();
  const { category } = useParams();

  const [subcategories, setSubcategories] = useState(null);
  const [materai, setMaterai] = useState([]);
  const [selectedMaterials, setSelectedMaterials] = useState([]);

  useEffect(() => {
    if (Array.isArray(materai) && materai.length > 0) {
      const parsedMaterials = materai.map((item) => {
        try {
          const parsed = typeof item === "string" ? JSON.parse(item) : item;
          return parsed.name || null;
        } catch (error) {
          console.error("Error parsing material:", error);
          return null;
        }
      });
      setSelectedMaterials(parsedMaterials.filter(Boolean));
    }
  }, [materai]);

  const handleCheckboxChange = (materialName) => {
    setSelectedMaterials((prevSelected) =>
      prevSelected.includes(materialName)
        ? prevSelected.filter((name) => name !== materialName)
        : [...prevSelected, materialName]
    );
  };

  useEffect(() => {
    const fetchSubcategories = async () => {
      try {
        const response = await axios.get(
          `https://vpandit.caxis.ca/public/api/subcategories/${sid}`
        );
        const data = response.data;
        setSubcategories(data);
        setMaterai(Array.isArray(data.material) ? data.material : []);
      } catch (error) {
        console.error("Error fetching subcategories:", error);
      }
    };

    fetchSubcategories();
  }, [sid]);

  const [expandedSections, setExpandedSections] = useState(["getting-started"]);
  const [expandedLessons, setExpandedLessons] = useState([
    "secret-of-good-design",
  ]);

  const toggleSection = (sectionId) => {
    setExpandedSections((prev) =>
      prev.includes(sectionId)
        ? prev.filter((id) => id !== sectionId)
        : [...prev, sectionId]
    );
  };

  const toggleLesson = (lessonId) => {
    setExpandedLessons((prev) =>
      prev.includes(lessonId)
        ? prev.filter((id) => id !== lessonId)
        : [...prev, lessonId]
    );
  };

  const [sections, setSections] = useState([]);
  const [CirculamId, setCirculamId] = useState(null);

  useEffect(() => {
    if (subcategories) {
      setCirculamId(subcategories.SubCat_Id);
      console.log(subcategories.SubCat_Id);
    }
  }, [subcategories]);

  useEffect(() => {
    const fetchSections = async () => {
      if (!CirculamId) return;
      try {
        const response = await fetch(
          "https://vpandit.caxis.ca/public/api/curriculum-sections"
        );
        const data = await response.json();

        const filteredSections = data.filter(
          (section) => section.SubCat_Id === CirculamId
        );

        const transformedSections = filteredSections.map((section) => ({
          id: section.CS_Id,
          title: section.Name,
          lectures: section.lessons ? section.lessons.length : 0,
          lessons: section.lessons
            ? section.lessons.map((lesson) => ({
              id: lesson.CL_Id,
              title: lesson.Name,
              type: "video",
              summary: lesson.Summary || "No summary available",
              content: lesson.Content || "No content available",
            }))
            : [],
        }));

        setSections(transformedSections);
      } catch (error) {
        console.error("Error fetching curriculum sections:", error);
      }
    };

    fetchSections();
  }, [CirculamId]);

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "700px",
      height: "400px",
      padding: "0",
      border: "none",
      borderRadius: "12px",
      overflow: "hidden",
      boxShadow: "0 10px 25px rgba(0, 0, 0, 0.2)",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.75)",
    },
  };
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleShareClick = () => {
    const doc = new jsPDF();
    const orangeColor = "#FF6B00";

    doc.setFillColor(orangeColor);
    doc.rect(0, 0, 210, 20, "F");
    doc.setFontSize(16);
    doc.setTextColor(255, 255, 255);
    doc.text("VPandit - Your Trusted Partner for Rituals", 105, 12, {
      align: "center",
    });

    doc.setTextColor(0, 0, 0);
    doc.setFontSize(12);

    const subCatName = subcategories?.SubCat_Name || "Default Subcategory";
    const benefitsOfPooja =
      subcategories?.benefits_of_pooja || "No benefits available";
    const processOfPooja =
      subcategories?.process_of_pooja || "No process available";
    const requirement =
      subcategories?.requirement || "No requirements specified";

    doc.setFont("Helvetica", "normal");
    doc.text(`Pooja Name: ${subCatName}`, 20, 40);
    doc.text(`Benefits of Pooja: ${benefitsOfPooja}`, 20, 60);
    doc.text(`Process of Pooja: ${processOfPooja}`, 20, 80);
    doc.text(`Requirements: ${requirement}`, 20, 100);

    let yPosition = 120;
    doc.setFont("Helvetica", "bold");
    doc.text("Materials List:", 20, yPosition);
    yPosition += 10;

    if (Array.isArray(materai) && materai.length > 0) {
      materai.forEach((item, index) => {
        const parsedItem = typeof item === "string" ? JSON.parse(item) : item;
        doc.setFont("Helvetica", "normal");
        doc.text(
          `${index + 1}. ${parsedItem.title || "Unknown"}`,
          20,
          yPosition
        );
        yPosition += 10;
      });
    } else {
      doc.setFont("Helvetica", "normal");
      doc.text("No materials available.", 20, yPosition);
    }

    doc.setDrawColor(orangeColor);
    doc.setLineWidth(0.5);
    doc.line(20, 280, 190, 280);
    doc.setFontSize(10);
    doc.text("Contact us: +91 99987 13312 | www.vpandit.com", 105, 285, {
      align: "center",
    });

    doc.setFillColor(orangeColor);
    doc.rect(0, 290, 210, 10, "F");

    doc.save(`${subCatName}.pdf`);
  };

  // Helper function to check if process_of_pooja is meaningfully available
  const isProcessAvailable = (process) => {
    if (!process) return false; // null or undefined
    const str = String(process).trim();
    return str.length > 0 && str !== "[]" && str !== '""';
  };

  return (
    <>
      <div className="container mx-auto px-4 py-12 bg-gradient-to-b from-gray-50 to-white">
        {/* Breadcrumb */}
        <nav className="flex mb-8 text-sm font-semibold text-gray-700">
          <Link to="/" className="text-orange-600 hover:text-orange-700">
            Home
          </Link>
          <span className="mx-2 text-gray-400">/</span>
          <Link to="/pooja-vidhi" className="text-gray-600 hover:text-gray-800">
            Pooja Vidhi
          </Link>
          <span className="mx-2 text-gray-400">/</span>
          <Link
            to={`/pooja-vidhi/${category}`}
            className="text-gray-600 hover:text-gray-800"
          >
            {category}
          </Link>
          <span className="mx-2 text-gray-400">/</span>
          <span className="text-gray-800">{details}</span>
        </nav>

        <div className="grid md:grid-cols-2 gap-12 mb-16">
          {/* Product Images */}
          <div className="space-y-6">
            <div className="relative rounded-xl overflow-hidden shadow-lg transform transition-transform duration-300">
              <img
                src={
                  subcategories?.SubCat_Image ||
                  "https://cheetah.cherishx.com/uploads/1657016860_large.jpg"
                }
                alt="Pooja Image"
                className="object-cover h-[450px] w-full"
                onError={(e) =>
                (e.target.src =
                  "https://cheetah.cherishx.com/uploads/1657016860_large.jpg")
                }
              />
            </div>
          </div>

          {/* Product Info */}
          <div className="bg-white p-6 rounded-xl shadow-md">
            {subcategories ? (
              <>
                <div className="flex items-center justify-between mb-4">
                  <h1 className="text-4xl font-bold text-orange-600 tracking-tight">
                    {subcategories.SubCat_Name}
                  </h1>
                  <div className="flex gap-3">
                    <FaPlayCircle
                      onClick={openModal}
                      className="text-orange-600 text-3xl cursor-pointer hover:text-orange-700 transition-colors"
                    />
                    <FaShareAlt
                      onClick={handleShareClick}
                      className="text-orange-600 text-3xl cursor-pointer hover:text-orange-700 transition-colors"
                    />
                  </div>
                </div>
                <div className="prose max-w-none mb-6 text-gray-700">{/* max-h-64 overflow-y-auto for scrollable content  */}
                  {HTMLReactParser(
                    subcategories.SubCat_Desc || "No description available"
                  )}
                </div>
                <div className="flex items-baseline gap-4">
                  <span className="text-3xl font-bold text-orange-600">
                    {subcategories.Price ? `₹${subcategories.Price}` : ""}
                  </span>
                </div>
              </>
            ) : (
              <p className="text-gray-500">Loading...</p>
            )}
          </div>
        </div>

        {/* Tabs */}
        <div className="border-b mb-10">
          <div className="flex gap-10">
            {["description", "Pooja Process", "Material"].map((tab) => (
              <div
                key={tab}
                onClick={() => setActiveTab(tab)}
                className={`py-4 font-semibold capitalize cursor-pointer transition-all duration-300 ${activeTab === tab
                    ? "text-orange-600 border-b-4 border-orange-600"
                    : "text-gray-600 hover:text-orange-500"
                  }`}
              >
                {tab}
              </div>
            ))}
          </div>
        </div>

        {/* Tab Content */}
        <div className="prose max-w-none bg-white p-3 rounded-xl shadow-lg">
          {activeTab === "description" && (
            <div className="prose max-w-none text-gray-700">
              {subcategories?.process_of_pooja ? (
                (() => {
                  try {
                    // Check if process_of_pooja is a string and parse it
                    const parsedProcess = JSON.parse(subcategories.process_of_pooja);
                    // Ensure parsed result is a string before passing to HTMLReactParser
                    if (typeof parsedProcess === "string") {
                      return HTMLReactParser(parsedProcess);
                    } else {
                      return <p>Invalid process data</p>;
                    }
                  } catch (error) {
                    console.error("Error parsing process_of_pooja:", error);
                    return <p>Error loading process</p>;
                  }
                })()
              ) : (
                <p>No process available</p>
              )}
            </div>
          )}

          {activeTab === "Pooja Process" && (
            <div>
              {subcategories?.process_of_pooja ? (
                <div className="space-y-6">
                  {sections.length > 0 ? (
                    <div className="space-y-4">
                      {sections.map((section) => (
                        <div
                          key={section.id}
                          className="border border-gray-200 rounded-lg shadow-sm hover:shadow-md transition-shadow"
                        >
                          <div
                            onClick={() => toggleSection(section.id)}
                            className="w-full flex items-center justify-between p-4 bg-gray-50 hover:bg-gray-100 cursor-pointer"
                          >
                            <div className="flex items-center gap-3">
                              <FaAngleRight
                                className={`text-orange-600 transform transition-transform ${expandedSections.includes(section.id)
                                    ? "rotate-90"
                                    : ""
                                  }`}
                              />
                              <span className="font-semibold text-gray-800">
                                {section.title}
                              </span>
                            </div>
                            <span className="text-sm text-gray-500">
                              {section.lectures}
                            </span>
                          </div>
                          {expandedSections.includes(section.id) &&
                            section.lessons.length > 0 && (
                              <div className="border-t border-gray-200">
                                {section.lessons.map((lesson) => (
                                  <div key={lesson.id}>
                                    <div
                                      onClick={() => toggleLesson(lesson.id)}
                                      className="w-full flex items-center justify-between p-4 hover:bg-gray-50 cursor-pointer"
                                    >
                                      <div className="flex items-center gap-3">
                                        <FaAngleRight
                                          className={`text-orange-600 transform transition-transform ${expandedLessons.includes(lesson.id)
                                              ? "rotate-90"
                                              : ""
                                            }`}
                                        />
                                        <span className="text-orange-600 font-medium">
                                          {lesson.title}
                                        </span>
                                      </div>
                                    </div>
                                    {expandedLessons.includes(lesson.id) && (
                                      <div className="p-4 pl-12 bg-gray-50 text-gray-700 space-y-2">
                                        <div>
                                          {HTMLReactParser(lesson.summary)}
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                ))}
                              </div>
                            )}
                        </div>
                      ))}
                    </div>
                  ) : (
                    <p className="text-gray-600">
                      No process details available
                    </p>
                  )}
                </div>
              ) : (
                <p className="text-gray-600">No process available</p>
              )}
            </div>
          )}

          {activeTab === "Material" && (
            <div className="space-y-6">
              <h2 className="text-2xl font-bold text-gray-900">Materials</h2>
              {Array.isArray(materai) && materai.length > 0 ? (
                materai
                  .map((item, key) => {
                    try {
                      const parsedItem =
                        typeof item === "string" ? JSON.parse(item) : item;
                      return (
                        <div
                          key={key}
                          className="bg-gray-50 p-4 rounded-lg shadow-sm flex items-center gap-3"
                        >
                          <input
                            type="checkbox"
                            checked={selectedMaterials.includes(
                              parsedItem.name
                            )}
                            onChange={() =>
                              handleCheckboxChange(parsedItem.name)
                            }
                            className="form-checkbox h-5 w-5 text-orange-600"
                          />
                          <span className="text-gray-800 font-medium">
                            {parsedItem.name} - {parsedItem.qty || "N/A"}
                          </span>
                        </div>
                      );
                    } catch (error) {
                      console.error("Error parsing material:", error);
                      return null;
                    }
                  })
                  .filter(Boolean)
              ) : (
                <div className="bg-gray-50 p-4 rounded-lg shadow-sm">
                  <h3 className="text-lg font-semibold text-gray-800">
                    No products needed
                  </h3>
                </div>
              )}
              <div className="text-center mt-6">
                <button
                  onClick={() =>
                  (window.location.href =
                    "https://vpandit.caxis.ca/vshop/product#")
                  }
                  className="bg-orange-600 text-white py-2 px-6 rounded-lg hover:bg-orange-700 transition-colors duration-300"
                >
                  Get Product
                </button>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Modal for Video Player */}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        style={customStyles}
        ariaHideApp={false}
      >
        <div className="relative w-full h-full">
          <div
            onClick={closeModal}
            className="absolute top-2 right-2 text-white bg-gray-800 hover:bg-gray-900 p-2 rounded-full cursor-pointer z-10"
          >
            ✕
          </div>
          <ReactPlayer
            url={
              subcategories?.pooja_overview_provider ||
              "https://youtu.be/GQBfQkCaiJQ"
            }
            controls
            playing
            width="100%"
            height="100%"
          />
        </div>
      </Modal>

      {subcategories && subcategories.SubCat_Id ? (
        <Pandit_List SubCat_Id={subcategories.SubCat_Id} />
      ) : (
        <p className="text-center text-gray-500 py-8">
          No subcategory available
        </p>
      )}

      {activeTab === "Material" &&
        Array.isArray(materai) &&
        materai.length > 0 && (
          <Supplier_List
            materai={materai}
            selectedMaterials={selectedMaterials}
          />
        )}
    </>
  );
}