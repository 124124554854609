import { Phone, Sunrise, Sunset, Moon, Sun } from "lucide-react";
import "./About.css";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { useState, useEffect } from "react";
import { calculatePanchangData, calculateGujaratiMonth } from "../Panchang/Panchang";
import axios from "axios";
import sun from "../../Images/sun-removebg-preview (1).png";
import moon from "../../Images/moon-removebg-preview (2).png";
import mars from "../../Images/mars-removebg-preview (3).png";
import mercury from "../../Images/mercury-removebg-preview (2).png";
import jupiter from "../../Images/jupiter-removebg-preview.png";
import venus from "../../Images/venus-removebg-preview (1).png";
import saturn from "../../Images/saturn.jpg";
import rahu from "../../Images/rahu-removebg-preview.png";
import ketu from "../../Images/ketu-removebg-preview (2).png";
import add from "../../Assets/diwali.png";
import add2 from "../../Assets/diwali2.png";
import add3 from "../../Assets/shivratri.png";
import add4 from "../../Assets/shivratri2.png";
import Inquiryform from "../Inquiry/Inquiryform";

// Constants
const PI = Math.PI;
const RAD = PI / 180;
const DEG = 180 / PI;

const RASHI_NAMES = [
  "Aries", "Taurus", "Gemini", "Cancer", "Leo", "Virgo",
  "Libra", "Scorpio", "Sagittarius", "Capricorn", "Aquarius", "Pisces",
];

const NAKSHATRA_NAMES = [
  "Ashwini", "Bharani", "Krittika", "Rohini", "Mrigashira", "Ardra",
  "Punarvasu", "Pushya", "Ashlesha", "Magha", "Purva Phalguni", "Uttara Phalguni",
  "Hasta", "Chitra", "Swati", "Vishakha", "Anuradha", "Jyeshtha",
  "Mula", "Purva Ashadha", "Uttara Ashadha", "Shravana", "Dhanishta", "Shatabhisha",
  "Purva Bhadrapada", "Uttara Bhadrapada", "Revati",
];

const NAKSHATRA_LORDS = [
  "Ketu", "Venus", "Sun", "Moon", "Mars", "Rahu", "Jupiter", "Saturn", "Mercury",
  "Ketu", "Venus", "Sun", "Moon", "Mars", "Rahu", "Jupiter", "Saturn", "Mercury",
  "Ketu", "Venus", "Sun", "Moon", "Mars", "Rahu", "Jupiter", "Saturn", "Mercury",
];

const PLANET_DATA = {
  Mercury: { L0: 252.250906, dL: 149472.6746358, M0: 178.179078, dM: 149474.071, e0: 0.205635, de: 0.0000209, a: 0.38709927 },
  Venus: { L0: 181.979801, dL: 58517.8156760, M0: 48.339618, dM: 58519.213, e0: 0.006792, de: -0.000047, a: 0.723326 },
  Mars: { L0: 355.433000, dL: 19141.6964471, M0: 19.373481, dM: 19140.302, e0: 0.093405, de: 0.000090, a: 1.523668 },
  Jupiter: { L0: 34.351519, dL: 3036.3027748, M0: 19.895036, dM: 3034.906, e0: 0.048498, de: -0.000163, a: 5.202887 },
  Saturn: { L0: 50.077444, dL: 1223.5110686, M0: 316.967, dM: 1222.114, e0: 0.055548, de: -0.000346, a: 9.536675 },
};

// Helper Functions from AstrologyCalculations
const calculateJulianDay = (date) => {
  const year = date.getUTCFullYear();
  const month = date.getUTCMonth() + 1;
  const day = date.getUTCDate();
  const hour = date.getUTCHours();
  const minute = date.getUTCMinutes();
  const second = date.getUTCSeconds();
  const decimalDay = day + (hour + minute / 60 + second / 3600) / 24;

  let y = year;
  let m = month;
  if (month <= 2) {
    y -= 1;
    m += 12;
  }
  const a = Math.floor(y / 100);
  const b = 2 - a + Math.floor(a / 4);
  return Math.floor(365.25 * (y + 4716)) + Math.floor(30.6001 * (m + 1)) + decimalDay + b - 1524.5;
};

const calculateAyanamsa = (jd) => {
  const T = (jd - 2451545.0) / 36525;
  const baseAyanamsa = 23.853;
  const precessionRate = 50.27 / 3600;
  const yearsSinceJ2000 = T * 100;
  return baseAyanamsa + (precessionRate * yearsSinceJ2000);
};

const calculateSunPosition = (jd) => {
  const T = (jd - 2451545.0) / 36525.0;
  let M = 357.5291 + 35999.0503 * T - 0.0001537 * T * T;
  M = M % 360;
  if (M < 0) M += 360;
  let L0 = 280.46646 + 36000.76983 * T + 0.0003032 * T * T;
  L0 = L0 % 360;
  if (L0 < 0) L0 += 360;
  const e = 0.016708634 - 0.000042037 * T;
  const C = (1.914602 - 0.004817 * T) * Math.sin(M * RAD) +
    (0.019993 - 0.000101 * T) * Math.sin(2 * M * RAD);
  const trueL = (L0 + C) % 360;
  return trueL >= 0 ? trueL : trueL + 360;
};

const calculateEarthHeliocentric = (jd) => {
  const T = (jd - 2451545.0) / 36525.0;
  let M = 357.5291 + 35999.0503 * T - 0.0001537 * T * T;
  M = M % 360;
  if (M < 0) M += 360;
  let L0 = 280.46646 + 36000.76983 * T + 0.0003032 * T * T;
  L0 = L0 % 360;
  if (L0 < 0) L0 += 360;
  const e = 0.016708634 - 0.000042037 * T;
  const C = (1.914602 - 0.004817 * T) * Math.sin(M * RAD) +
    (0.019993 - 0.000101 * T) * Math.sin(2 * M * RAD);
  const trueL = (L0 + C) % 360;
  const v = M + C;
  const r_e = (1 - e * e) / (1 + e * Math.cos(v * RAD));
  const λ_e = (trueL + 180) % 360;
  return [λ_e >= 0 ? λ_e : λ_e + 360, r_e];
};

const calculateMoonPosition = (jd) => {
  const T = (jd - 2451545.0) / 36525.0;
  let L = 218.3164591 + 481267.88134236 * T - 0.0013268 * T * T + T * T * T / 538841;
  L = L % 360;
  if (L < 0) L += 360;
  let D = 297.8502042 + 445267.1115168 * T - 0.0016300 * T * T + T * T * T / 545868;
  D = D % 360;
  if (D < 0) D += 360;
  let M = 357.5291092 + 35999.0502909 * T - 0.0001536 * T * T;
  M = M % 360;
  if (M < 0) M += 360;
  let M_moon = 134.9634114 + 477198.8676313 * T + 0.0089970 * T * T + T * T * T / 69699;
  M_moon = M_moon % 360;
  if (M_moon < 0) M_moon += 360;
  let F = 93.2720993 + 483202.0175273 * T - 0.0034029 * T * T - T * T * T / 3526000;
  F = F % 360;
  if (F < 0) F += 360;

  let longitude = L +
    6.288750 * Math.sin(M_moon * RAD) +
    1.274018 * Math.sin((2 * D - M_moon) * RAD) +
    0.658309 * Math.sin(2 * D * RAD) +
    0.213616 * Math.sin(2 * M_moon * RAD) -
    0.185596 * Math.sin(M * RAD);

  longitude = longitude % 360;
  return longitude >= 0 ? longitude : longitude + 360;
};

const calculatePlanetHeliocentric = (jd, planetData) => {
  const T = (jd - 2451545.0) / 36525.0;
  let L = planetData.L0 + planetData.dL * T;
  L = L % 360;
  if (L < 0) L += 360;
  let M = planetData.M0 + planetData.dM * T;
  M = M % 360;
  if (M < 0) M += 360;
  const e = planetData.e0 + planetData.de * T;
  let E = M;
  for (let i = 0; i < 5; i++) {
    E = M + e * DEG * Math.sin(E * RAD);
  }
  const v = 2 * Math.atan2(Math.sqrt((1 + e) / (1 - e)) * Math.sin(E * RAD / 2), Math.cos(E * RAD / 2)) * DEG;
  let helio_long = (L + v - M) % 360;
  const a = planetData.a;
  const r_p = a * (1 - e * e) / (1 + e * Math.cos(v * RAD));
  return [helio_long >= 0 ? helio_long : helio_long + 360, r_p];
};

const calculateGeocentricLongitude = (planetHelio, earthHelio) => {
  const [λ_p, r_p] = planetHelio;
  const [λ_e, r_e] = earthHelio;
  const dx = r_p * Math.cos(λ_p * RAD) - r_e * Math.cos(λ_e * RAD);
  const dy = r_p * Math.sin(λ_p * RAD) - r_e * Math.sin(λ_e * RAD);
  let λ_geo = Math.atan2(dy, dx) * DEG;
  return λ_geo >= 0 ? λ_geo : λ_geo + 360;
};

const calculateRahuKetu = (jd) => {
  const T = (jd - 2451545.0) / 36525.0;
  let omega = 125.04452 - 1934.136261 * T + 0.0020708 * T * T;
  omega = omega % 360;
  if (omega < 0) omega += 360;
  const rahuTropical = omega;
  const ketuTropical = (omega + 180) % 360;
  return [rahuTropical, ketuTropical];
};

const getNakshatraInfo = (longitude) => {
  const nakshatraSpan = 360.0 / 27;
  const nakshatraIndex = Math.floor(longitude / nakshatraSpan);
  const padaSpan = nakshatraSpan / 4;
  const positionInNakshatra = longitude % nakshatraSpan;
  const pada = Math.floor(positionInNakshatra / padaSpan) + 1;
  return {
    name: NAKSHATRA_NAMES[nakshatraIndex] || "Unknown",
    pada: pada,
    lord: NAKSHATRA_LORDS[nakshatraIndex] || "-",
  };
};

const formatDegrees = (degrees) => {
  const deg = Math.floor(degrees);
  const minTemp = (degrees - deg) * 60;
  const min = Math.floor(minTemp);
  const sec = Math.floor((minTemp - min) * 60);
  const rashi = Math.floor(deg / 30);
  const rashiDeg = deg % 30;
  return `${rashiDeg}° ${RASHI_NAMES[rashi]} ${min}' ${sec}"`;
};

// Choghadiya data
const Choghdiye = [
  { day: "Monday", Din: ["Amrit", "Kaal", "Shubh", "Rog", "Udveg", "Chal", "Labh", "Amrit"], Raat: ["Chal", "Rog", "Kaal", "Labh", "Udveg", "Shubh", "Amrit", "Chal"] },
  { day: "Tuesday", Din: ["Rog", "Udveg", "Chal", "Labh", "Amrit", "Kaal", "Shubh", "Rog"], Raat: ["Kaal", "Labh", "Udveg", "Shubh", "Amrit", "Chal", "Rog", "Kaal"] },
  { day: "Wednesday", Din: ["Labh", "Amrit", "Kaal", "Shubh", "Rog", "Udveg", "Chal", "Labh"], Raat: ["Udveg", "Shubh", "Amrit", "Chal", "Rog", "Kaal", "Labh", "Udveg"] },
  { day: "Thursday", Din: ["Shubh", "Rog", "Udveg", "Chal", "Labh", "Amrit", "Kaal", "Shubh"], Raat: ["Amrit", "Chal", "Rog", "Kaal", "Labh", "Udveg", "Shubh", "Amrit"] },
  { day: "Friday", Din: ["Chal", "Labh", "Amrit", "Kaal", "Shubh", "Rog", "Udveg", "Chal"], Raat: ["Rog", "Kaal", "Labh", "Udveg", "Shubh", "Amrit", "Chal", "Rog"] },
  { day: "Saturday", Din: ["Kaal", "Shubh", "Rog", "Udveg", "Chal", "Labh", "Amrit", "Kaal"], Raat: ["Labh", "Udveg", "Shubh", "Amrit", "Chal", "Rog", "Kaal", "Labh"] },
  { day: "Sunday", Din: ["Udveg", "Chal", "Labh", "Labh", "Kaal", "Shubh", "Rog", "Udveg"], Raat: ["Shubh", "Amrit", "Chal", "Rog", "Kaal", "Labh", "Udveg", "Shubh"] },
];

const adImages = [add, add2, add3, add4];

// Time conversion utilities
const timeStringToMinutes = (timeStr) => {
  if (!timeStr) return 0;
  const [time, period] = timeStr.split(' ');
  let [hours, minutes] = time.split(':').map(Number);
  if (period === 'PM' && hours !== 12) hours += 12;
  if (period === 'AM' && hours === 12) hours = 0;
  return hours * 60 + minutes;
};

const minutesToTimeString = (minutes) => {
  let hours = Math.floor(minutes / 60) % 24;
  const mins = Math.floor(minutes % 60);
  const period = hours >= 12 ? 'PM' : 'AM';
  if (hours > 12) hours -= 12;
  if (hours === 0) hours = 12;
  return `${hours.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')} ${period}`;
};

// Slider Settings
const sliderSettings = {
  infinite: true,
  speed: 1000,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
  arrows: false,
};

export default function About() {
  const [planetPositions, setPlanetPositions] = useState([]);
  const [panchangData, setPanchangData] = useState({});
  const [gujaratiMonth, setGujaratiMonth] = useState("");
  const [celestialTimes, setCelestialTimes] = useState({ sunrise: "", sunset: "", moonrise: "", moonset: "" });
  const [currentChoghadiya, setCurrentChoghadiya] = useState({ period: "", name: "", startTime: "", endTime: "" });

  useEffect(() => {
    const today = new Date();
    const calculatedPanchangData = calculatePanchangData(today);
    setPanchangData(calculatedPanchangData);
    setGujaratiMonth(calculateGujaratiMonth(today));
  }, []);

  useEffect(() => {
    const calculatePositions = () => {
      const date = new Date();
      const jd = calculateJulianDay(date);
      const ayanamsa = calculateAyanamsa(jd);
      const [λ_e, r_e] = calculateEarthHeliocentric(jd);

      const planets = [
        { name: "Sun", image: sun, positionFunc: () => calculateSunPosition(jd), isGeocentric: true },
        { name: "Moon", image: moon, positionFunc: () => calculateMoonPosition(jd), isGeocentric: true },
        { name: "Mercury", image: mercury, positionFunc: () => calculatePlanetHeliocentric(jd, PLANET_DATA.Mercury), isGeocentric: false },
        { name: "Venus", image: venus, positionFunc: () => calculatePlanetHeliocentric(jd, PLANET_DATA.Venus), isGeocentric: false },
        { name: "Mars", image: mars, positionFunc: () => calculatePlanetHeliocentric(jd, PLANET_DATA.Mars), isGeocentric: false },
        { name: "Jupiter", image: jupiter, positionFunc: () => calculatePlanetHeliocentric(jd, PLANET_DATA.Jupiter), isGeocentric: false },
        { name: "Saturn", image: saturn, positionFunc: () => calculatePlanetHeliocentric(jd, PLANET_DATA.Saturn), isGeocentric: false },
        { name: "Rahu", image: rahu, positionFunc: () => calculateRahuKetu(jd)[0], isGeocentric: true },
        { name: "Ketu", image: ketu, positionFunc: () => calculateRahuKetu(jd)[1], isGeocentric: true },
      ];

      const newPositions = planets.map((planet) => {
        let tropicalLong;
        if (planet.isGeocentric) {
          tropicalLong = planet.positionFunc();
        } else {
          const [helio_long, r_p] = planet.positionFunc();
          tropicalLong = calculateGeocentricLongitude([helio_long, r_p], [λ_e, r_e]);
        }
        let siderealLong = (tropicalLong - ayanamsa) % 360;
        if (siderealLong < 0) siderealLong += 360;
        const nakshatraInfo = getNakshatraInfo(siderealLong);
        return {
          name: planet.name,
          position: formatDegrees(siderealLong),
          image: planet.image,
          nakshatra: nakshatraInfo.name,
          lord: nakshatraInfo.lord,
        };
      });

      setPlanetPositions(newPositions);
    };

    calculatePositions();
    const interval = setInterval(calculatePositions, 60000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const fetchAstronomyData = async () => {
      try {
        const response = await axios.get("https://api.weatherapi.com/v1/astronomy.json", {
          params: {
            key: "ca380313c26445359c951955242611",
            q: "Ahmedabad",
            dt: new Date().toISOString().split("T")[0],
          },
        });
        const { astronomy } = response.data;
        const { sunrise, sunset, moonrise, moonset } = astronomy.astro;
        setCelestialTimes({ sunrise, sunset, moonrise, moonset });
      } catch (error) {
        console.error("Error fetching astronomical data:", error);
      }
    };

    fetchAstronomyData();
  }, []);

  useEffect(() => {
    const calculateCurrentChoghadiya = () => {
      if (!celestialTimes.sunrise || !celestialTimes.sunset) return;

      const now = new Date();
      const currentDay = now.toLocaleDateString("en-US", { weekday: "long" });
      const currentMinutes = now.getHours() * 60 + now.getMinutes();
      const sunriseMinutes = timeStringToMinutes(celestialTimes.sunrise);
      const sunsetMinutes = timeStringToMinutes(celestialTimes.sunset);

      const dayDuration = (sunsetMinutes - sunriseMinutes) / 8;
      const nightDuration = (24 * 60 - (sunsetMinutes - sunriseMinutes)) / 8;

      const dayChoghadiyaData = Choghdiye.find(c => c.day === currentDay)?.Din || [];
      const nightChoghadiyaData = Choghdiye.find(c => c.day === currentDay)?.Raat || [];

      let period = "";
      let name = "";
      let startTime = "";
      let endTime = "";

      if (currentMinutes >= sunriseMinutes && currentMinutes < sunsetMinutes) {
        const periodIndex = Math.floor((currentMinutes - sunriseMinutes) / dayDuration);
        period = "Day";
        name = dayChoghadiyaData[periodIndex] || "";
        startTime = minutesToTimeString(sunriseMinutes + dayDuration * periodIndex);
        endTime = minutesToTimeString(sunriseMinutes + dayDuration * (periodIndex + 1));
      } else {
        let nightMinutes = currentMinutes;
        if (currentMinutes < sunriseMinutes) {
          nightMinutes += 24 * 60;
        }
        const periodIndex = Math.floor((nightMinutes - sunsetMinutes) / nightDuration) % 8;
        period = "Night";
        name = nightChoghadiyaData[periodIndex] || "";
        startTime = minutesToTimeString(sunsetMinutes + nightDuration * periodIndex);
        endTime = minutesToTimeString(sunsetMinutes + nightDuration * (periodIndex + 1));
      }

      setCurrentChoghadiya({ period, name, startTime, endTime });
    };

    calculateCurrentChoghadiya();
    const interval = setInterval(calculateCurrentChoghadiya, 60000);
    return () => clearInterval(interval);
  }, [celestialTimes]);

  return (
    <div className="relative h-auto overflow-hidden">
      <div className="flex flex-col items-center">
        <div className="text-2xl font-bold md:mt-0"></div>
      </div>
      <div className="container mx-auto px-4 md:py-14">
        <div className="grid lg:grid-cols-2 gap-6 items-start">
          <div className="bg-white shadow-lg rounded-lg p-6 border border-orange-500 border-y-2 h-fit">
            <h2 className="text-2xl font-bold text-orange-500 underline underline-offset-4 mb-6 text-center sm:text-left">
              Today's Panchang
            </h2>
            <div className="flex flex-col sm:flex-row gap-3 text-center sm:text-left justify-center sm:justify-start">
              <p className="font-semibold">
                {new Date().toLocaleDateString("en-US", { weekday: "long" })},
              </p>
              <p className="font-semibold">
                {new Date().toLocaleDateString("en-US", { year: "numeric", month: "long", day: "numeric" })}
              </p>
            </div>
            <div className="mt-3 border-t-2 border-gray-300 pt-4">
              <div className="grid grid-cols-2 sm:grid-cols-4 gap-4 text-center">
                <div className="flex flex-col items-center">
                  <Sunrise className="w-6 h-6 text-orange-500 mb-1" />
                  <p className="font-semibold">Sunrise</p>
                  <p>{celestialTimes.sunrise}</p>
                </div>
                <div className="flex flex-col items-center">
                  <Sunset className="w-6 h-6 text-orange-500 mb-1" />
                  <p className="font-semibold">Sunset</p>
                  <p>{celestialTimes.sunset}</p>
                </div>
                <div className="flex flex-col items-center">
                  <Moon className="w-6 h-6 text-blue-500 mb-1" />
                  <p className="font-semibold">Moonrise</p>
                  <p>{celestialTimes.moonrise}</p>
                </div>
                <div className="flex flex-col items-center">
                  <Moon className="w-6 h-6 text-blue-500 mb-1" />
                  <p className="font-semibold">Moonset</p>
                  <p>{celestialTimes.moonset}</p>
                </div>
              </div>
            </div>
            <div className="mt-4 border-t-2 border-gray-300 pt-4">
              <div className="grid grid-cols-2 sm:grid-cols-3 gap-4 text-center">
                <div className="flex flex-col gap-1">
                  <p className="font-semibold">Gujarati Month:</p>
                  <p>{gujaratiMonth}</p>
                </div>
                <div className="flex flex-col gap-1">
                  <p className="font-semibold">Paksha:</p>
                  <p>{panchangData.paksha}</p>
                </div>
                <div className="flex flex-col gap-1">
                  <p className="font-semibold">Tithi:</p>
                  <p>{panchangData.tithi}</p>
                </div>
                <div className="flex flex-col gap-1">
                  <p className="font-semibold">Nakshatra:</p>
                  <p>{panchangData.nakshatra}</p>
                </div>
                <div className="flex flex-col gap-1">
                  <p className="font-semibold">Yog:</p>
                  <p>{panchangData.yoga}</p>
                </div>
                <div className="flex flex-col gap-1">
                  <p className="font-semibold">Karana:</p>
                  <p>{panchangData.karana}</p>
                </div>
              </div>
              {currentChoghadiya.name && (
                <div className="mb-4 mt-4 p-4 bg-orange-50 rounded-lg border border-orange-200 flex flex-col sm:flex-row gap-4 items-center justify-center text-center">
                  <div className="flex items-center gap-2">
                    {currentChoghadiya.period === "Day" ? (
                      <Sun className="w-5 h-5 text-orange-500" />
                    ) : (
                      <Moon className="w-5 h-5 text-blue-500" />
                    )}
                    <h3 className="font-medium">Current Choghadiya</h3>
                  </div>
                  <p
                    className="text-center font-semibold text-md"
                    style={{
                      color: ["Labh", "Shubh", "Amrit"].includes(currentChoghadiya.name)
                        ? "#4CAF50"
                        : currentChoghadiya.name === "Chal"
                          ? "#2196F3"
                          : "#F44336",
                    }}
                  >
                    {currentChoghadiya.name}
                  </p>
                  <p className="text-center text-md font-medium">
                    {currentChoghadiya.startTime} - {currentChoghadiya.endTime}
                  </p>
                </div>
              )}
            </div>
            <div className="mt-6">
              <Link to="/panchang">
                <button className="bg-orange-500 hover:bg-orange-600 text-white font-bold py-2 w-full rounded">
                  Read More
                </button>
              </Link>
            </div>
          </div>
          <Inquiryform />
        </div>
        <section className="bg-orange-100 rounded-lg p-8 h-fit w-full mt-8" aria-labelledby="planets-title">
          <div className="grid grid-cols-3 sm:grid-cols-4 md:flex md:flex-wrap justify-center gap-4 sm:gap-6">
            {planetPositions.map((planet, index) => (
              <div key={index} className="flex flex-col items-center">
                <img
                  src={planet.image || "/placeholder.svg"}
                  className="w-14 h-14 sm:w-16 sm:h-16 object-cover rounded-full border-2 border-orange-400"
                  alt={planet.name}
                />
                <p className="text-xs sm:text-sm font-semibold mt-2">{planet.name}</p>
                <p className="text-[10px] sm:text-xs text-gray-700">{planet.nakshatra || "Undefined"}</p>
                <p className="text-[10px] sm:text-xs font-medium text-orange-700">{planet.position}</p>
              </div>
            ))}
          </div>
        </section>
        <section className="container mt-10 rounded-lg overflow-hidden w-full h-auto" aria-label="Featured Services">
          <Slider {...sliderSettings}>
            {adImages.map((image, index) => (
              <div key={index}>
                <img src={image || "/placeholder.svg"} alt={`Featured service ${index + 1}`} className="w-full h-full object-fill" />
              </div>
            ))}
          </Slider>
        </section>
      </div>
    </div>
  );
}