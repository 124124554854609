import { useState, useEffect } from "react";
// import { Calendar } from 'lucide-react';
import { Link, useNavigate } from 'react-router-dom';
import "./Numerology.css";
import { FaCheckDouble, FaTimes } from "react-icons/fa";
import HTMLReactParser from 'html-react-parser'
// import jsPDF from "jspdf";
// import html2canvas from "html2canvas";
// import html2pdf from "html2pdf.js";


export default function Numerology() {
  const [showPopup, setShowPopup] = useState(false);
  const [selectedNumberType, setSelectedNumberType] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate(); // Use useNavigate hook for navigation
  const [loading, setLoading] = useState(true);
  const [numerologyData, setNumerologyData] = useState(null);
  const [myValue, setMyValue] = useState("0");
  const [isCalculated, setIsCalculated] = useState(false);

  // Parse and format date from DD/MM/YYYY to YYYY-MM-DD sometime previous code throw error invalid time
  // const parseDate = (dateString) => {
  //   if (!dateString) return ""; // Handle empty or undefined U_DOB
  //   const [day, month, year] = dateString.split("/"); // Split DD/MM/YYYY
  //   if (!day || !month || !year) return ""; // Ensure the format is correct
  //   return new Date(`${year}-${month}-${day}`).toISOString().split("T")[0]; // Convert to YYYY-MM-DD
  // };

  // Load user details from localStorage
  const userdetails = JSON.parse(localStorage.getItem("user")) || {};
  // const formattedU_DOB = userdetails?.U_DOB ? parseDate(userdetails.U_DOB) : "";

  // State to manage user data
  const [userData, setUserData] = useState({
    name: userdetails?.U_Name || "",
    U_DOB: userdetails?.U_DOB || "",  // Directly assign without formatting
    U_Gender: userdetails?.U_Gender
      ? userdetails.U_Gender === "M"
        ? "Male"
        : userdetails.U_Gender === "F"
          ? "Female"
          : userdetails.U_Gender
      : "",
    U_Mobile: userdetails?.U_Mobile || "",
    mulank: null,
    bhagyank: null,
    loShuGrid: null,
    karmicNumbers: [],
    personalYear: null,
    personalMonth: null,
    personalDay: null,
    vehicleNumber: "",
    vehicleNumberResult: null, // Separate field to store the calculated value
    masterNumbers: [],
    nameNumber: null,
    U_MobileNumber: null,
    houseNumberResult: null,
    luckyNumber: null,
    magicNumber: null,
    healthNumber: null,
  });

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("numerologyData"));
    if (storedData) {
      setUserData((prev) => ({
        ...prev,
        ...storedData,

      }));
    }
  }, []);

  const handlePopup = (type) => {
    setSelectedNumberType(type);
    setShowPopup(true);
  };

  // Close the popup
  const handleClosePopup = () => {
    setShowPopup(false);
    setInputValue('');
    setErrorMessage('');
  };

  // Handle the change in the input value
  const handleInputchange = (e) => {
    const value = e.target.value;
    setInputValue(value);

    // Validate if the input is a 4-digit number for vehicle number
    if (selectedNumberType === "Vehicle Number") {
      if (!/^\d{0,4}$/.test(value)) {
        setErrorMessage("enter exactly 4 digits.");
      } else {
        setErrorMessage('');
      }
    }
  };

  // const handleDownloadPDF = async () => {
  //   try {
  //     const response = await fetch("https://vpandit.caxis.ca/public/api/numerologies");
  //     const apiData = await response.json();

  //     const getDescription = (topicNo) => {
  //       const entry = apiData.find((item) => item.topic_no === String(topicNo));
  //       return entry ? entry.desc_details : "Description not available";
  //     };

  //     const element = document.createElement("div");
  //     element.style.fontFamily = "Arial, sans-serif";
  //     element.style.lineHeight = "1.8";
  //     element.style.padding = "10px";
  //     element.style.width = "100%";  // Full width ensure karne ke liye

  //     // Title Page
  //     element.innerHTML = `
  //       <div style="text-align: center; page-break-after: always;">
  //         <h1>Numerology Report</h1>
  //         <p><strong>Name:</strong> ${userData.name}</p>
  //         <p><strong>Date of Birth:</strong> ${userData.U_DOB}</p>
  //         <p><strong>Gender:</strong> ${userData.U_Gender}</p>
  //       </div>
  //     `;

  //     // Numerology Sections
  //     results.forEach((item, index) => {
  //       element.innerHTML += `
  //         <div style=" page-break-inside: avoid;">
  //           <h3>${item.label}</h3>
  //           <p><strong>Value:</strong> ${item.value}</p>
  //           <div>${getDescription(item.topc_no)}</div>
  //         </div>
  //       `;

  //       // Har 3 sections ke baad page break daalne ka logic
  //       if ((index + 1) % 3 === 0) {
  //         element.innerHTML += `<div style="page-break-after: always;"></div>`;
  //       }
  //     });

  //     // Master Numbers Section (Agar exist kare to page break daalna)
  //     if (userData.MasterNumbers && userData.MasterNumbers.length > 0) {
  //       element.innerHTML += `
  //         <div style="page-break-before: always;">
  //           <h2>Master Numbers</h2>
  //           <p>${userData.MasterNumbers.join(", ")}</p>
  //         </div>
  //       `;
  //     }

  //     // Convert HTML to PDF
  //     html2pdf()
  //       .from(element)
  //       .set({
  //         margin: 10,
  //         filename: `Numerology_Report_${userData.name.replace(/\s+/g, '_')}.pdf`,
  //         image: { type: "jpeg", quality: 0.98 },
  //         html2canvas: { scale: 2 },
  //         jsPDF: { orientation: "portrait", unit: "mm", format: "a4" },
  //       })
  //       .save();

  //   } catch (error) {
  //     console.error("Error generating PDF:", error);
  //   }
  // };


  // Calculate and update the house or vehicle number and navigate

  const handleSubmit = () => {
    let calculatedValue;

    // Validation for 4 digits for vehicle number
    if (selectedNumberType === "Vehicle Number" && inputValue.length !== 4) {
      setErrorMessage("Vehicle number must be exactly 4 digits.");
      return; // Prevent submission if validation fails
    }

    // Calculate value based on the type
    if (selectedNumberType === "House Number") {
      calculatedValue = calculateHouseNumber(inputValue);
      setUserData((prevData) => {
        const updatedData = { ...prevData, houseNumberResult: calculatedValue };
        // Save to localStorage
        localStorage.setItem('numerologyData', JSON.stringify(updatedData));
        return updatedData;
      });
    } else if (selectedNumberType === "Vehicle Number") {
      calculatedValue = calculateVehicleNumber(inputValue);
      setUserData((prevData) => {
        const updatedData = { ...prevData, vehicleNumberResult: calculatedValue };
        // Save to localStorage
        localStorage.setItem('numerologyData', JSON.stringify(updatedData));
        return updatedData;
      });
    }

    // Close the popup after submission
    handleClosePopup();

    // Navigate to the inner page with the calculated value
    const path = `/numerology/${selectedNumberType}?value=${calculatedValue}&topc_no=12`;
    navigate(path); // Use navigate instead of history.push
  };


  //********************************* another master number logic based on dob (day) only ******************************** 

  // const calculateMasterNumbersFromDOB = (dob) => {
  //   if (!dob) return [];

  //   // Extract the day from the DOB (assuming the format is YYYY-MM-DD)
  //   const day = parseInt(dob.split('-')[2], 10);

  //   // If the day is 11, 22, or 33, consider it a master number
  //   if ([11, 22, 33].includes(day)) {
  //     return [day];  // Return the master number based on the day
  //   }

  //   return [];  // No master number if the day is not 11, 22, or 33
  // };

  const calculateMasterNumbersFromDOB = (dob) => {
    if (!dob) return [];

    const [year, month, day] = dob.split('-').map(Number);
    let masterNumbers = [];

    // Check if the day itself is a master number
    if ([11, 22, 33].includes(day)) {
      masterNumbers.push(day);
    }

    // Combine full DOB into a single number (YYYYMMDD)
    const dobString = `${year}${month}${day}`;
    let sum = dobString.split('').reduce((acc, num) => acc + Number(num), 0);

    // Check for master numbers in the full sum
    if ([11, 22, 33].includes(sum)) {
      masterNumbers.push(sum);
    }

    // Keep reducing while checking for master numbers
    while (sum > 9) {
      sum = sum
        .toString()
        .split("")
        .map(Number)
        .reduce((acc, digit) => acc + digit, 0);

      if ([11, 22, 33].includes(sum) && !masterNumbers.includes(sum)) {
        masterNumbers.push(sum);
      }
    }

    return masterNumbers;
  };



  // Pyramid Number Calculation Function
  const calculatePyramidNumber = (dob) => {
    if (!dob) return null

    // Extract day, month, and year from the DOB
    const [year, month, day] = dob.split("-").map(Number)

    // Get single digits
    const dayDigit = calculateNumber(day)
    const monthDigit = calculateNumber(month)
    const yearDigit = calculateNumber(year)

    // First calculation: month - day (ensure positive)
    const result1 = Math.abs(monthDigit - dayDigit)

    // Second calculation: day - year (ensure positive)
    const result2 = Math.abs(dayDigit - yearDigit)

    // Final calculation: subtract the two results (ensure positive)
    const pyramidNumber = Math.abs(result1 - result2)

    return pyramidNumber
  }

  const calculateBhagyank = (dob) => {
    if (!dob) return null;
    const numbers = dob.replace(/-/g, '').split('').map(Number);
    let sum = numbers.reduce((acc, num) => acc + num, 0);
    return calculateNumber(sum);
  };

  const calculateNumber = (input) => {
    let sum = input
      .toString()
      .split("")
      .map(Number)
      .reduce((acc, digit) => acc + digit, 0);

    // Check for master numbers before reducing
    // if (sum === 11 || sum === 22 || sum === 33) {
    //   return sum;
    // }

    // Keep reducing until sum becomes a single digit
    while (sum > 9) {
      sum = sum
        .toString()
        .split("")
        .map(Number)
        .reduce((acc, digit) => acc + digit, 0);
    }

    return sum;
  };


// Updated Health Number Calculation
const calculateHealthnumber = (dob) => {
  if (!dob) return null;

  const [year, month, day] = dob.split("-").map(Number);
  
  // Mulank (based on day)
  const mulank = calculateNumber(day);
  
  // Bhagyank (based on full DOB)
  const bhagyank = calculateBhagyank(dob);
  
  // Personal Year (based on DOB and current year)
  const today = new Date();
  const currentYear = today.getFullYear();
  const personalYear = calculateNumber(`${day}${month}${currentYear}`);

  // Combine Mulank, Bhagyank, and Personal Year for Health Number
  let healthSum = mulank + bhagyank + personalYear;

  // Reduce to a single digit
  while (healthSum > 9) {
    healthSum = healthSum
      .toString()
      .split("")
      .map(Number)
      .reduce((acc, digit) => acc + digit, 0);
  }

  return healthSum;
};

  // const identifyMasterNumbers = (numbers) => {
  //   return numbers.filter((num) => [11, 22, 33].includes(num));
  // };

  const calculateLoShuGrid = (U_DOB) => {
    const digits = U_DOB.replace(/-/g, "").split("").map(Number);
    const grid = [4, 9, 2, 3, 5, 7, 8, 1, 6];
    const filledGrid = Array(9).fill(0);

    digits.forEach((digit) => {
      if (digit >= 1 && digit <= 9) {
        const index = grid.indexOf(digit);
        filledGrid[index]++;
      }
    });

    return filledGrid;
  };

  const calculateKarmicNumbers = (dob) => {
    if (!dob) return [];

    // Define karmic numbers
    const karmicNumbers = [13, 14, 16, 19];

    // Extract the day part from the DOB
    const day = parseInt(dob.split('-')[2], 10);

    // Check if the day is a karmic number
    return karmicNumbers.includes(day) ? [day] : [];
  };

  const calculatePersonalNumbers = (U_DOB) => {
    const today = new Date();
    const currentYear = today.getFullYear();
    const currentMonth = today.getMonth() + 1;

    const [year, month, day] = U_DOB.split("-").map(Number);

    const personalYearCalc = calculateNumber(`${day}${month}${currentYear}`);
    const personalMonthCalc = calculateNumber(personalYearCalc + currentMonth);

    return { personalYear: personalYearCalc, personalMonth: personalMonthCalc };
  };

  const calculateNameNumber = (name) => {
    const letterValues = {
      A: "1",
      I: "1",
      J: "1",
      Q: "1",
      Y: "1",
      B: "2",
      K: "2",
      R: "2",
      C: "3",
      G: "3",
      L: "3",
      S: "3",
      D: "4",
      M: "4",
      T: "4",
      E: "5",
      H: "5",
      N: "5",
      X: "5",
      U: "6",
      V: "6",
      W: "6",
      O: "7",
      Z: "7",
      F: "8",
      P: "8",
    };
    const letters = name
      .toUpperCase()
      .replace(/[^A-Z]/g, "")
      .split("");
    const sum = letters.reduce(
      (acc, letter) => acc + (letterValues[letter] || 0),
      0
    );
    return calculateNumber(sum);
  };

  const calculateU_MobileNumber = (U_Mobile) => {
    const digits = U_Mobile.replace(/\D/g, "");
    return calculateNumber(digits);
  };

  const calculateHouseNumber = (houseNumber) => {
    const number = houseNumber.replace(/\D/g, "");
    return calculateNumber(number);
  };

  const calculateVehicleNumber = (vehicleNumber) => {
    if (!vehicleNumber || typeof vehicleNumber !== "string") return null; // Handle null or non-string values
    const number = vehicleNumber.replace(/\D/g, ""); // Extract digits only
    return calculateNumber(number); // Use your calculateNumber function to reduce to a single digit
  };

  // Function to calculate Personal Day
  const calculatePersonalDay = (input) => {
    let sum = input
    .toString()
    .split("")
    .map(Number)
    .reduce((acc, digit) => acc + digit, 0);

  // Check for master numbers before reducing
  // if (sum === 11 || sum === 22 || sum === 33) {
  //   return sum;
  // }

  // Keep reducing until sum becomes a single digit
  while (sum > 9) {
    sum = sum
      .toString()
      .split("")
      .map(Number)
      .reduce((acc, digit) => acc + digit, 0);
  }

  return sum;
  };


  const calculateluckyNumber = (dob, gender) => {
    if (!dob || !gender) return null;
    const [year] = dob.split("-").map(Number);
    const yearSum = calculateNumber(year);
    let luckyNumber;

    if (gender.toLowerCase() === 'male') {
      luckyNumber = 11 - yearSum;
    } else if (gender.toLowerCase() === 'female') {
      luckyNumber = yearSum + 4;
    }
    else {
      luckyNumber = "";
    }

    // Reduce to a single digit if not 11
    return luckyNumber === 11 ? 11 : calculateNumber(luckyNumber);
  };


  const calculateMagicNumber = (dob) => {
    if (!dob) return null;
    const dateSum = dob.split('-').join('').split('').reduce((sum, digit) => sum + parseInt(digit), 0);
    return calculateNumber(dateSum);
  };

  const handleInputChange = (key, value) => {
    // Update userData state without saving to localStorage
    const updatedData = { ...userData, [key]: value };
    setUserData(updatedData);
  };


  useEffect(() => {
    // Clear localStorage when the user leaves the page
    const handleBeforeUnload = () => {
      localStorage.removeItem("numerologyData");
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);


  const handleCalculate = () => {
    if (!userData.U_DOB) return;

    // Extract date components
    const [year, month, day] = userData.U_DOB.split("-").map(Number);


    // Perform calculations
    const originalDay = day; // Store the original day value
    const calculatedPyramidNumber = calculatePyramidNumber(userData.U_DOB);
    const calculatedMulank = calculateNumber(day);
    const calculatedBhagyank = calculateBhagyank(userData.U_DOB);
    const calculatedLoShuGrid = calculateLoShuGrid(userData.U_DOB);
    const calculatedKarmicNumbers = calculateKarmicNumbers(userData.U_DOB);
    const { personalYear, personalMonth } = calculatePersonalNumbers(userData.U_DOB);
    const calculatedPersonalDay = calculatePersonalDay(day);
    const calculatedNameNumber = userData.name
      ? calculateNameNumber(userData.name)
      : null;
    const calculatedU_MobileNumber = userData.U_Mobile
      ? calculateU_MobileNumber(userData.U_Mobile)
      : null;
    const calculatedHouseNumberResult = userData.houseNumber
      ? calculateHouseNumber(userData.houseNumber)
      : null;

    const calculatedVehicleNumberResult = userData.vehicleNumber
      ? calculateVehicleNumber(userData.vehicleNumber)
      : null;

    const calculatedHealthNumber =calculateHealthnumber(userData.U_DOB);
    const calculatedLuckyNumber = calculateluckyNumber(userData.U_DOB, userData.U_Gender);
    const calculatedMagicNumber = calculateMagicNumber(userData.U_DOB);
    const calculatedMasterNumbersFromDOB = calculateMasterNumbersFromDOB(userData.U_DOB);

    // Consolidate calculated data
    const calculatedData = {
      pyramidNumber: calculatedPyramidNumber, // Add Pyramid Number here
      originalDay, // Store the original day
      mulank: calculatedMulank,
      bhagyank: calculatedBhagyank,
      loShuGrid: calculatedLoShuGrid,
      karmicNumbers: calculatedKarmicNumbers,
      masterNumbers: calculatedMasterNumbersFromDOB,
      personalYear,
      personalMonth,
      personalDay: calculatedPersonalDay,
      nameNumber: calculatedNameNumber,
      U_MobileNumber: calculatedU_MobileNumber,
      houseNumberResult: calculatedHouseNumberResult,
      vehicleNumberResult: calculatedVehicleNumberResult,
      luckyNumber: calculatedLuckyNumber,
      magicNumber: calculatedMagicNumber,
      healthNumber: calculatedHealthNumber,
    };

    // Update state and persist to localStorage
    const newUserData = { ...userData, ...calculatedData };
    setUserData(newUserData);
    localStorage.setItem("numerologyData", JSON.stringify(newUserData));
    setIsCalculated(true);

  };

  // Use useEffect to clear the house and vehicle number when the component is mounted
  useEffect(() => {
    // Check if we're on the main page (not the inner page)
    if (window.location.pathname.includes('/numerology/')) {
      const updatedData = { ...userData, houseNumberResult: null, vehicleNumberResult: null };
      setUserData(updatedData);
      localStorage.setItem('numerologyData', JSON.stringify(updatedData));
    }
  }, []);

  const results = [
    {
      label: "Mulank",
      value: userData.mulank,
      originalValue: userData.originalDay, // Add original value for Mulank
      description: "Number associated with your birth day.",
      topc_no: 1,
    },
    {
      label: "Bhagyank",
      value: userData.bhagyank,
      description: "Indicates your life's destiny and opportunities.",
      topc_no: 2,
    },
    {
      label: "Lucky Number",
      value: userData.luckyNumber,
      description: "Your natural abilities and talents",
      topc_no: 3,
    },
    {
      label: "Lo Shu Grid",
      value: Array.isArray(userData.loShuGrid) ? (
        <FaCheckDouble size={42} className="text-orange-500" />
      ) : (
        <FaTimes size={42} className="text-orange-500" />
      ),
      description: "Grid revealing insights into your personality",
      topc_no: 4,
    },
    {
      label: "Karmic Numbers",
      value: userData.karmicNumbers && userData.karmicNumbers.length > 0
        ? userData.karmicNumbers.join(", ")
        : <FaTimes size={42} />,
      description: "karmic lessons from past lives.",
      topc_no: 5,
    },
    {
      label: "Master Numbers",
      value: userData.masterNumbers && userData.masterNumbers.length > 0
        ? userData.masterNumbers.join(", ")
        : <FaTimes size={42} />, // Fallback icon if no master numbers
      description: "Numbers with higher spiritual.",
      topc_no: 6,
    },
    {
      label: "Personal Year Number",
      value: userData.personalYear,
      description: "Represents the energy of the current year.",
      topc_no: 7,
    },
    {
      label: "Personal Month Number",
      value: userData.personalMonth,
      description: "Reflects the energy of the current month.",
      topc_no: 8,
    },
    {
      label: "Personal Day",
      value: userData.personalDay,
      description: "Reflects the energy of today.",
      topc_no: 9,
    },
    {
      label: "Name Number",
      value: userData.nameNumber,
      description: "Character and traits derived from your name.",
      topc_no: 10,
    },
    {
      label: "Mobile Number",
      value: userData.U_MobileNumber,
      description: "Numerology influence of your mobile number.",
      topc_no: 11,
    },
    {
      label: "House Number",
      value: userData.houseNumberResult,
      description: "Numerological influence of your home address.",
      topc_no: 12,
    },
    {
      label: "Vehicle Number",
      value: userData.vehicleNumberResult,
      description: "Numerological influence of your vehicle number.",
      topc_no: 13,
    },
    {
      label: "Health Number",
      value: userData.healthNumber,  // Ensure it's a number, not JSX
      description: "Reveals your inner desires and motivations",
      topc_no: 14,
    },
    {
      label: "Numbers Pyramid",
      value: userData.pyramidNumber,  // Ensure it's a number, not JSX
      description: "provide a deeper layer of insight into a person’s life",
      topc_no: 15,
    }

  ];


  // Use useEffect to call the function when the component mounts
  useEffect(() => {
    //fetch numerologydata
    const fetchNumerologyData = async () => {
      try {
        setLoading(true); // Start loading

        const topicNo = 99; // Hardcoded topic number 99
        const response = await fetch(
          `https://vpandit.caxis.ca/public/api/numerologies/${topicNo}/${myValue}` // Use topicNo here
        );

        if (response.ok) {
          const data = await response.json();
          console.log("Numerology Data:", data);
          setNumerologyData(data); // Set data in the state
        } else {
          console.warn("Numerology API returned 404 or another error");
          setNumerologyData([]); // Set empty if no data
        }
      } catch (error) {
        console.error("Error fetching numerology data:", error);
        setNumerologyData([]); // Set empty on error
      } finally {
        setLoading(false); // Stop loading
      }
    };

    fetchNumerologyData(); // Call the fetch function on component mount
  }, [myValue]); // If myValue changes, it will trigger the effect again

  const renderDescDetails = (descDetails) => {
    try {
      // If descDetails is not in HTML format, try parsing it
      if (descDetails) {
        return <p className="text-gray-700 leading-relaxed mb-6">{HTMLReactParser(descDetails)}</p>;
      }
    } catch (error) {
      console.error("Error rendering desc_details:", error);
      return (
        <p className="text-gray-700 leading-relaxed mb-6">{descDetails}</p>
      );
    }
    return null;
  };

  return (
    <div className="min-h-screen">
      <div className="container mx-auto px-4 py-8">
        {/* Breadcrumb */}
        <nav className="flex mb-4 text-sm">
          <Link
            to="/"
            className="text-orange-500 hover:text-orange-600 font-bold"
          >
            Home
          </Link>
          <span className="mx-2 text-gray-500">&gt;</span>
          <span className="text-gray-600 font-bold">Numerology</span>
        </nav>

        {/* Main Content */}
        <div className="space-y-8 flex flex-col md:flex-row justify-between gap-8">
          {/* Header */}
          <div className="container w-full md:w-[50%]">
            {/* Numerology Data */}
            {loading ? (
              <div className="flex justify-center items-center h-[200px]">
                <div className="w-12 h-12 border-4 border-t-4 border-orange-500 border-solid rounded-md animate-spin"></div>
              </div>
            ) : numerologyData && numerologyData.length > 0 ? (
              <>
                {numerologyData.map((item, key) => (
                  <div key={key} className="mb-10 mt-3">
                    <h2 className="text-2xl font-bold text-gray-900 mb-4">{renderDescDetails(item.desc_title)}</h2>
                    <p className="text-gray-700 leading-relaxed">{renderDescDetails(item.desc_details)}</p>
                  </div>
                ))}
              </>
            )
              : (
                <p className="text-start text-gray-800"></p>
              )}
          </div>
          {/* Calculator Section */}
          <div className="w-full md:w-[50%]">
            <div className="bg-white w-full rounded-lg border border-orange-500 border-y-4 p-4">
              <h2 className="text-2xl font-semibold text-center mb-6">
                Your Numerology Predictions
              </h2>

              <div className="container flex flex-col items-center space-y-4">
                {/* First Row - Date of Birth and Gender (1 Column on 768px) */}
                <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-4 w-full">
                  {/* Date of Birth */}
                  <div className="w-full">
                    <label className="block mb-2 text-gray-700 font-medium text-start">
                      Date Of Birth
                    </label>
                    <input
                      type="date"
                      value={userData.U_DOB}
                      max={new Date().toISOString().split("T")[0]}
                      onChange={(e) => {
                        const selectedDate = new Date(e.target.value);
                        const today = new Date();
                        if (selectedDate > today) {
                          alert("You cannot select a future date.");
                          return;
                        }
                        handleInputChange("U_DOB", e.target.value);
                      }}
                      className="p-3 text-lg border  border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500 w-[95%]"
                    />
                  </div>

                  {/* Gender */}
                  <div className="w-full">
                    <label className="block mb-2 text-gray-700 font-medium text-start">
                      Gender
                    </label>
                    <select
                      value={userData.U_Gender}
                      disabled
                      onChange={(e) => handleInputChange("U_Gender", e.target.value)}
                      className="p-3 text-lg border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500 w-[95%]"
                    >
                      <option value="" disabled>Select Your Gender</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                    </select>
                  </div>
                </div>

                {/* Second Row - Name and Mobile Number */}
                <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-4 w-full">
                  {/* Name */}
                  <div className="w-full">
                    <label className="block mb-2 text-gray-700 font-medium text-start">
                      Name
                    </label>
                    <input
                      type="text"
                      value={userData.name}
                      onChange={(e) => handleInputChange("name", e.target.value)}
                      className="p-3 text-lg border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500 w-[95%]"
                    />
                  </div>

                  {/* Mobile Number */}
                  <div className="w-full">
                    <label className="block mb-2 text-gray-700 font-medium text-start">
                      Mobile Number
                    </label>
                    <input
                      type="text"
                      value={userData.U_Mobile}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (/^\d{0,10}$/.test(value)) {
                          handleInputChange("U_Mobile", value);
                        }
                      }}
                      className="p-3 text-lg border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500 w-[95%]"
                    />
                  </div>
                </div>
              </div>

              {/* Calculate Button */}
              <div className="flex flex-wrap gap-2 lg:gap-10 md:gap-8 justify-center" >
                <button
                  onClick={handleCalculate}
                  className="mt-6 lg:px-20 md:px-16  py-2 text-orange-500 hover:text-white bg-white border border-orange-500 rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500"
                >
                  CALCULATE
                </button>

                {/* <button
                  onClick={isCalculated ? handleDownloadPDF : null}
                  disabled={!isCalculated}
                  className="mt-6 lg:px-20 md:px-16  py-2 text-orange-500 hover:text-white bg-white border border-orange-500 rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500"
                >
                  Download
                </button> */}
              </div>
            </div>

            <div className="parent mt-10 gap-6">
              {results
                .filter((data) => data.topc_no)
                .map((data) => (
                  <Link
                    to={`/numerology/${data.label}?value=${data.originalValue || data.value}&topc_no=${data.topc_no}`}
                    className="card w-full sm:w-1/2 md:w-1/3 xl:w-1/4"
                    key={data.label}
                    onClick={(e) => {
                      if (data.label === "House Number" || data.label === "Vehicle Number") {
                        e.preventDefault();
                        handlePopup(data.label);
                      }
                    }}
                  >
                    <div className="content-box">
                      <span className="card-title text-base">{data.label}</span>
                      <p className="card-content">{data.description}</p>
                      {/* <Link
                        to={`/numerology/${data.label}?value=${data.originalValue || data.value}&topc_no=${data.topc_no}`}
                      > */}
                      <span className="see-more">See More</span>
                      {/* </Link> */}
                    </div>
                    <div className="date-box flex justify-center items-center">
                      <span className="date">{data.value}</span> {/* Display reduced Mulank */}
                    </div>
                  </Link>
                ))}
            </div>
          </div>
        </div>

        {/* Number Cards */}
        {showPopup && (
          <div className="popup">
            <div className="popup-content">
              <h3>Enter your {selectedNumberType}</h3>
              <input
                type="text"
                placeholder={`Enter ${selectedNumberType}`}
                value={inputValue}
                onChange={handleInputchange}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSubmit();
                  }
                }}
              />
              {errorMessage && <p className="error-message">{errorMessage}</p>}
              <button onClick={handleSubmit}>Submit</button>
              <button onClick={handleClosePopup}>Close</button>
            </div>
          </div>
        )}

      </div>
    </div>
  );
}

