import React from 'react'
import underline from "../../Assets/underline.png";
const Our_Astrologer = () => {
    return (
      <div className="px-4 py-16 bg-white">
        {/* Main Header Section */}
        <div className="container mx-auto text-center">
          <h1 className="text-4xl font-bold  mb-4 text-gray-900 cursor-pointer">
            Our <span className="text-orange-500">Astrologers</span>
          </h1>

          <div className="h-auto w-full mx-auto mt-4 flex justify-center">
            <img src={underline} alt="" className="object-contain" />
          </div>
          <p className="text-gray-900 max-w-2xl mx-auto mt-4">
            Get Consultation From India's Best Astrologers At Affordable Price!
          </p>
        </div>

        {/* Description Paragraphs */}
        <div className="container mx-auto space-y-6 mb-16 md:px-4 mt-6">
          <p className="text-gray-700 leading-relaxed text-justify">
            Our astrologers speak with years of experience and advanced
            astrological knowledge. They are the best astrologers across India
            who provide predictions and remedies on love, relationships,
            marriage, career growth, money advice, business predictions,
            education guidance, and other personal and professional issues.
          </p>

          <p className="text-gray-700 leading-relaxed text-justify">
            Connect to our top Vedic astrologers, Numerologists, Vastu experts,
            Tarot readers, etc. anytime and anywhere. You can consult them in
            any way that suits your needs - by call or online chat and connect
            Live also. To top that, get insights and answers in English, Hindi,
            Tamil, Punjabi, and many other regional languages.
          </p>

          <p className="text-gray-700 leading-relaxed text-justify">
            Be a part of our extended family and relive the joy that our happy
            customers have cherished. Get accurate astrology predictions, kundli
            readings, horoscope reports, birth chart analysis and more.
          </p>
        </div>

        {/* Why Talk To Our Astrologers Section */}
        <div className="container mx-auto md:px-4">
          <h2 className="text-3xl font-bold text-center text-gray-900 mb-12">
            Why Talk To Our Astrologers?
          </h2>

          {/* Features Grid */}
          <div className="grid md:grid-cols-2 gap-6">
            {/* Feature 1 */}
            <div className="bg-gray-100 p-6 rounded-lg text-center">
              <h3 className="text-lg font-semibold text-gray-900">
                1500+ expert astrologers
              </h3>
            </div>

            {/* Feature 2 */}
            <div className="bg-gray-100 p-6 rounded-lg text-center">
              <h3 className="text-lg font-semibold text-gray-900">
                Get a better understanding & guidance
              </h3>
            </div>

            {/* Feature 3 */}
            <div className="bg-gray-100 p-6 rounded-lg text-center">
              <h3 className="text-lg font-semibold text-gray-900">
                Instant solutions on the phone
              </h3>
            </div>

            {/* Feature 4 */}
            <div className="bg-gray-100 p-6 rounded-lg text-center">
              <h3 className="text-lg font-semibold text-gray-900">
                Private and confidential
              </h3>
            </div>

            {/* Feature 5 */}
            <div className="bg-gray-100 p-6 rounded-lg text-center">
              <h3 className="text-lg font-semibold text-gray-900">
                Live astrology consultation anytime, anywhere
              </h3>
            </div>

            {/* Feature 6 */}
            <div className="bg-gray-100 p-6 rounded-lg text-center">
              <h3 className="text-lg font-semibold text-gray-900">
                Instant solutions in difficult time
              </h3>
            </div>
          </div>
        </div>
      </div>
    );
}

export default Our_Astrologer