'use client';

import React, { useState, useEffect } from 'react';
import { FaEdit, FaTrash, FaCheck } from 'react-icons/fa';
import Swal from 'sweetalert2';
import axios from 'axios';
import AddUser from '../UserMaster/AddUser';
import { ImCross } from "react-icons/im";

const Supplier_Master = () => {
  const [viewType, setViewType] = useState("table");
  const [users, setUsers] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [activeTab, setActiveTab] = useState("active"); // 'active' or 'request'

  const fetchUsers = async () => {
    try {
      const response = await axios.get("https://vpandit.caxis.ca/public/api/users");

      // Filter users with Role_Id === 4
      const filteredUsers = response.data.filter(
        (user) => user.role.Role_Id === 4
      );

      setUsers(filteredUsers);
      console.log("Filtered Users:", filteredUsers);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const fetchVendors = async () => {
    try {
      const response = await axios.get(
        "https://vpandit.caxis.ca/public/api/vendor-profiles"
      );

      const specificVendors = response.data.filter(
        (vendor) => vendor.Ven_Approve === 2
      );

      setVendors(specificVendors);
      console.log(specificVendors);
    } catch (error) {
      console.error("Error fetching vendors:", error);
    }
  };

  useEffect(() => {
    if (activeTab === "active") {
      fetchUsers();
    } else if (activeTab === "request") {
      fetchVendors();
    }
  }, [activeTab]);

  const handleAddUserClick = () => {
    setIsEditing(false);
    setViewType("addUser");
    setSelectedUser(null);
    fetchUsers();
  };

  const handleEditUser = (user) => {
    setIsEditing(true);
    setSelectedUser(user);
    setViewType("addUser");
    fetchUsers();
  };

  const handleDeleteUser = (userId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to recover this user!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`https://vpandit.caxis.ca/public/api/users/${userId}`)
          .then(() => {
            setUsers(users.filter((user) => user.id !== userId));
            fetchUsers();
            Swal.fire("Deleted!", "The user has been deleted.", "success");
          })
          .catch((error) => {
            console.error("Error deleting user", error);
            Swal.fire("Error", "There was an issue deleting the user", "error");
          });
      }
    });
  };

  const handleVerifyVendor = async (V_Id, U_Id) => {
    try {
      // Make a GET request to fetch vendor details
      const vendorResponse = await axios.get(
        `https://vpandit.caxis.ca/public/api/vendor-profiles/${V_Id}`
      );
      const vendorData = vendorResponse.data;

      console.log("Fetched Vendor Data:", vendorData);

      // Proceed with the PUT request to verify the vendor
      await axios.put(
        `https://vpandit.caxis.ca/public/api/vendor-profiles/${V_Id}`,
        {
          U_Id: U_Id,
          Ven_Approve: "1",
          Bussi_Add: vendorData.data.Bussi_Add,
          Bussi_State: vendorData.data.Bussi_State,
          Bussi_City: vendorData.data.Bussi_City,
          Zipcode: vendorData.data.Zipcode,
          Ven_Bussi_Name: vendorData.data.Ven_Bussi_Name,
          Ven_Bussi_Regis: vendorData.data.Ven_Bussi_Regis,
          Ven_Bussi_Add: vendorData.data.Ven_Bussi_Add,
          Bussi_Con: vendorData.data.Bussi_Con,
        }
      );

      Swal.fire(
        "Verified!",
        "Vendor has been successfully verified.",
        "success"
      );

      fetchVendors(); // Refresh vendor list
    } catch (error) {
      console.error("Error verifying vendor:", error);
      Swal.fire("Error", "Unable to verify vendor.", "error");
    }
  };

  const handlecencelVendor = async (V_Id, U_Id) => {
    try {
      // Make a GET request to fetch vendor details
      const vendorResponse = await axios.get(
        `https://vpandit.caxis.ca/public/api/vendor-profiles/${V_Id}`
      );
      const vendorData = vendorResponse.data;

      console.log("Fetched Vendor Data:", vendorData);

      // Proceed with the PUT request to verify the vendor
      await axios.put(
        `https://vpandit.caxis.ca/public/api/vendor-profiles/${V_Id}`,
        {
          U_Id: U_Id,
          Ven_Approve: 3,
          Bussi_Add: vendorData.data.Bussi_Add,
          Bussi_State: vendorData.data.Bussi_State,
          Bussi_City: vendorData.data.Bussi_City,
          Zipcode: vendorData.data.Zipcode,
          Ven_Bussi_Name: vendorData.data.Ven_Bussi_Name,
          Ven_Bussi_Regis: vendorData.data.Ven_Bussi_Regis,
          Ven_Bussi_Add: vendorData.data.Ven_Bussi_Add,
          Bussi_Con: vendorData.data.Bussi_Con,
        }
      );

      Swal.fire(
        "Verified!",
        "Vendor Rejected successfully verified.",
        "success"
      );

      fetchVendors(); // Refresh vendor list
    } catch (error) {
      console.error("Error verifying vendor:", error);
      Swal.fire("Error", "Unable to verify vendor.", "error");
    }
  };

  const handleCloseForm = () => {
    setViewType("table");
    fetchUsers();
  };

  return (
    <div className="min-h-screen">
      {/* Header */}
      <div className="flex items-center justify-between mb-6">
        <div className="flex items-center space-x-4">
          <h1 className="text-2xl font-bold pt-2">Supplier Management</h1>
        </div>
        <div className="flex items-center space-x-4">
          <div className="relative">
            <input
              type="text"
              placeholder="Search"
              className="text-black px-4 py-2 pr-10 rounded focus:outline-none focus:ring-2 focus:ring-orange-600"
            />
            <svg
              className="w-5 h-5 absolute right-3 top-2.5 text-black"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              />
            </svg>
          </div>

          <div
            onClick={handleAddUserClick}
            className="flex items-center bg-orange-500 text-white px-4 py-2 rounded-full shadow-lg hover:bg-orange-600 cursor-pointer font-bold"
          >
            Add User
          </div>
        </div>
      </div>

      {/* Tabs */}
      <div className="flex mb-4 border-b">
        <div
          className={`px-4 py-2 cursor-pointer font-bold ${
            activeTab === "active"
              ? "border-orange-600 text-orange-600 border-b-2"
              : "text-gray-500"
          }`}
          onClick={() => setActiveTab("active")}
        >
          Active Supplier
        </div>
        <div
          className={`px-4 py-2 cursor-pointer font-bold ${
            activeTab === "request"
              ? "border-orange-500 text-orange-500 border-b-2"
              : "text-gray-500"
          }`}
          onClick={() => setActiveTab("request")}
        >
          Request Supplier
        </div>
      </div>

      {/* Conditional rendering */}
      {viewType === "table" ? (
        <div className="rounded-lg overflow-hidden">
          <table className="w-full bg-white">
            <thead>
              <tr className="border-b border-gray-700">
                <th className="px-6 py-3 text-left text-xs font-medium text-black uppercase tracking-wider">
                  Name
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-black uppercase tracking-wider">
                  Email
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-black uppercase tracking-wider">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-700">
              {(activeTab === "active" ? users : vendors).length > 0 ? (
                (activeTab === "active" ? users : vendors).map((item) => (
                  <tr key={item.U_Id || item.V_Id} className="hover:bg-gray-50">
                    <td className="px-6 py-4">
                      {activeTab === "active"
                        ? item.U_Name || "Not Provided"
                        : item.Ven_Bussi_Name || "Not Provided"}
                    </td>

                    <td className="px-6 py-4">
                      {activeTab === "active"
                        ? item.user?.U_Email || "Not Provided"
                        : item.U_Email || "Not Provided"}
                    </td>

                    <td className="px-6 py-4">
                      <div className="flex space-x-3">
                        {activeTab === "active" && (
                          <>
                            <FaEdit
                              className="text-green-600 cursor-pointer"
                              onClick={() => handleEditUser(item)}
                            />
                            <FaTrash
                              className="text-red-600 cursor-pointer"
                              onClick={() =>
                                handleDeleteUser(item.U_Id || item.V_Id)
                              }
                            />
                          </>
                        )}

                        {activeTab === "request" && (
                          <>
                            <FaCheck
                              className="text-green-600 cursor-pointer"
                              onClick={() =>
                                handleVerifyVendor(item.Vendor_Id, item.U_ID)
                              }
                            />
                            <ImCross
                              className="text-red-600 cursor-pointer"
                              onClick={() =>
                                handlecencelVendor(item.Vendor_Id, item.U_ID)
                              }
                            />
                          </>
                        )}
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={3} className="text-center text-gray-500 py-4">
                    Data Not Available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      ) : (
        <AddUser
          onClose={handleCloseForm}
          setUsers={setUsers}
          users={users}
          selectedUser={selectedUser}
          isEditing={isEditing}
        />
      )}
    </div>
  );
};

export default Supplier_Master;
