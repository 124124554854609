"use client";

import React, { useEffect, useState } from "react";
import Preloader from "../Pandit_register/Preloader";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

export default function SupplierRegister() {
  useEffect(() => {
    const role = localStorage.getItem("role");

    if (role === "1") {
      navigate("/");
    }
  },[]);

  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const user = JSON.parse(localStorage.getItem("user"));
  const navigate = useNavigate();

  const userId = localStorage.getItem("userID");
  const [formData, setFormData] = useState({
    fullName: user?.U_Name, // U_Name can be fetched and set accordingly if needed
    contactNumber: user?.U_Mobile, // U_Mobile can be fetched and set accordingly if needed
    email: user?.U_Email,
    businessName: "",
    businessAddress: "",
    city: "",
    state: "",
    postalCode: "",
    country: "",
  });

  const handleNext = () => {
    if (step === 4) {
      handleSubmit();
    } else {
      setStep((prev) => Math.min(prev + 1, 5));
    }
  };

  const handlePrevious = () => setStep((prev) => Math.max(prev - 1, 1));

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const [productData, setProductData] = useState(null);
  const [selectedProducts, setSelectedProducts] = useState([]); // Track selected products
  const [isModalOpen, setIsModalOpen] = useState(false); // Manage modal visibility
  const [selectedProduct, setSelectedProduct] = useState(null); // Track currently selected product
  const [vendorId, setVendorId] = useState(""); // For Vendor ID input

  useEffect(() => {
    // Fetch product data from the API
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://vpandit.caxis.ca/public/api/product-masters"
        );
        const data = await response.json();
        setProductData(data); // Assuming the data contains the product information
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // Handle checkbox selection
  const handleCheckboxChange = (product) => {
    setSelectedProduct(product); // Set the selected product for the modal
    setIsModalOpen(true); // Open the modal
  };

  const handleSave = () => {
    setSelectedProducts((prevSelected) => {
      const productExists = prevSelected.some(
        (item) => item.Prd_Id === selectedProduct.Prd_Id
      );

      if (productExists) {
        return prevSelected.map((item) =>
          item.Prd_Id === selectedProduct.Prd_Id ? selectedProduct : item
        );
      } else {
        return [...prevSelected, selectedProduct];
      }
    });

    setIsModalOpen(false); // Close the modal after saving
  };

  // Handle input changes in the modal
  const sethandleInputChange = (field, value) => {
    setSelectedProduct((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleSubmit = async () => {
    const payload = {
      U_Id: userId,
      Bussi_Add: formData.businessAddress,
      Bussi_State: formData.state,
      Bussi_City: formData.city,
      Bussi_Con: formData.country,
      Zipcode: formData.postalCode,
      Ven_Bussi_Name: formData.businessName,
      Ven_Bussi_Add: formData.businessAddress,
      Ven_Bussi_Regis: "nmber",
      Ven_Approve: "2",
    };

    try {
      // First API Call
      const response = await axios.post(
        "https://vpandit.caxis.ca/public/api/vendor-profiles",
        payload
      );

      setVendorId(response.data.Vendor_Id);
      console.log(response.data.Vendor_Id);

      Swal.fire({
        icon: "success",
        title: "Vendor data submitted successfully",
        showConfirmButton: false,
        timer: 1500,
      });
    } catch (error) {
      console.error("Error during API calls:", error);
      Swal.fire({
        icon: "error",
        title: "An error occurred",
        text: "Unable to submit the vendor data. Please try again.",
      });
      return; // Stop execution on error
    }

    if (!vendorId) {
      console.error("Vendor ID is required.");
      Swal.fire({
        icon: "error",
        title: "Vendor ID missing",
        text: "Please provide a valid Vendor ID.",
      });
      return; // Stop execution if vendorId is invalid
    }

    // Process selected products
    for (const product of selectedProducts) {
      const productPayload = {
        Prd_Id: product.Prd_Id,
        Vendor_Id: vendorId, // vendorId is added here
        Prd_Brand: product.brand_name,
        Prd_Qty: product.qty,
        Prd_Price: product.price,
        Prd_Alt: product.alt_qty || null,
      };

      try {
        // Call the API for each product
        const response = await fetch(
          "https://vpandit.caxis.ca/public/api/prod-vendor-price-list",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(productPayload),
          }
        );

        const result = await response.json();

        if (response.ok) {
          console.log(
            `Product with ID ${product.Prd_Id} submitted successfully`,
            result
          );
        } else {
          console.error(
            `Error submitting product with ID ${product.Prd_Id}`,
            result
          );
        }
      } catch (error) {
        console.error(
          `Error submitting product with ID ${product.Prd_Id}`,
          error
        );
      }
    }

    // All processes completed
    Swal.fire({
      icon: "success",
      title: "Your request has been sent successfully!",
      text: "You have become a supplier.",
    }).then(() => {
      navigate("/"); // Redirect to home page
    });
  };

  useEffect(() => {
    setTimeout(() => setLoading(false), 2000);
  }, []);

  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <div className="md:h-[70vh] h-screen py-16 flex container mx-auto">
          {/* Sidebar */}
          <div className="w-64 bg-white border-r border-gray-200">
            <div className="p-6">
              <h1 className="text-xl font-semibold text-gray-900">
                Supplier Registration
              </h1>
            </div>
            <nav className="px-4 space-y-1">
              {[
                "Personal Details",
                "Business Information",
                "Address Details",
                "Product/Service Details",
              ].map((title, index) => (
                <div
                  key={index}
                  className={`flex items-center px-2 py-3 text-gray-600 rounded-lg ${
                    step === index + 1 ? "bg-orange-50" : "hover:bg-gray-100"
                  }`}
                >
                  <div
                    className={`flex items-center justify-center w-8 h-8 rounded-full ${
                      step === index + 1
                        ? "bg-orange-600 text-white"
                        : "bg-gray-200 text-gray-600"
                    } mr-3`}
                  >
                    <span>{index + 1}</span>
                  </div>
                  <span>{title}</span>
                </div>
              ))}
            </nav>
          </div>

          {/* Main Content */}
          <div className="flex-1 flex flex-col overflow-auto">
            <div className="p-8 flex-grow">
              {/* Form Steps */}
              {step === 1 && (
                <div>
                  <h2 className="text-2xl font-semibold text-gray-900 mb-6">
                    Personal Details
                  </h2>
                  <div className="space-y-6">
                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        Full Name
                      </label>
                      <input
                        type="text"
                        name="fullName"
                        disabled
                        value={formData.fullName}
                        onChange={handleInputChange}
                        placeholder="Enter your full name"
                        className="mt-2 block w-full px-4 py-2 rounded-lg border border-gray-300 shadow-sm focus:outline-none focus:ring-2 focus:ring-orange-500 focus:border-orange-500 sm:text-sm"
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        Contact Number
                      </label>
                      <input
                        type="tel"
                        name="contactNumber"
                        disabled
                        value={formData.contactNumber}
                        onChange={handleInputChange}
                        placeholder="Enter your contact number"
                        className="mt-2 block w-full px-4 py-2 rounded-lg border border-gray-300 shadow-sm focus:outline-none focus:ring-2 focus:ring-orange-500 focus:border-orange-500 sm:text-sm"
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        Email Address
                      </label>
                      <input
                        type="email"
                        name="email"
                        disabled
                        value={formData.email}
                        onChange={handleInputChange}
                        placeholder="Enter your email address"
                        className="mt-2 block w-full px-4 py-2 rounded-lg border border-gray-300 shadow-sm focus:outline-none focus:ring-2 focus:ring-orange-500 focus:border-orange-500 sm:text-sm"
                      />
                    </div>
                  </div>
                </div>
              )}

              {step === 2 && (
                <div>
                  <h2 className="text-2xl font-semibold text-gray-900 mb-6">
                    Business Information
                  </h2>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Business Name
                    </label>
                    <input
                      type="text"
                      name="businessName"
                      value={formData.businessName}
                      onChange={handleInputChange}
                      placeholder="Enter your business name"
                      className="mt-2 block w-full px-4 py-2 rounded-lg border border-gray-300 shadow-sm focus:outline-none focus:ring-2 focus:ring-orange-500 focus:border-orange-500 sm:text-sm"
                    />
                  </div>
                </div>
              )}

              {step === 3 && (
                <div>
                  <h2 className="text-2xl font-semibold text-gray-900 mb-6">
                    Address Details
                  </h2>
                  <div className="space-y-6">
                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        Business Address
                      </label>
                      <input
                        type="text"
                        name="businessAddress"
                        value={formData.businessAddress}
                        onChange={handleInputChange}
                        placeholder="Enter your business address"
                        className="mt-2 block w-full px-4 py-2 rounded-lg border border-gray-300 shadow-sm focus:outline-none focus:ring-2 focus:ring-orange-500 focus:border-orange-500 sm:text-sm"
                      />
                    </div>
                    <div className="grid grid-cols-3 gap-4">
                      <input
                        type="text"
                        name="city"
                        value={formData.city}
                        onChange={handleInputChange}
                        placeholder="City"
                        className="mt-2 block w-full px-4 py-2 rounded-lg border border-gray-300 shadow-sm focus:outline-none focus:ring-2 focus:ring-orange-500 focus:border-orange-500 sm:text-sm"
                      />
                      <input
                        type="text"
                        name="state"
                        value={formData.state}
                        onChange={handleInputChange}
                        placeholder="State"
                        className="mt-2 block w-full px-4 py-2 rounded-lg border border-gray-300 shadow-sm focus:outline-none focus:ring-2 focus:ring-orange-500 focus:border-orange-500 sm:text-sm"
                      />
                      <input
                        type="text"
                        name="postalCode"
                        value={formData.postalCode}
                        onChange={handleInputChange}
                        placeholder="Postal Code"
                        className="mt-2 block w-full px-4 py-2 rounded-lg border border-gray-300 shadow-sm focus:outline-none focus:ring-2 focus:ring-orange-500 focus:border-orange-500 sm:text-sm"
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        Country
                      </label>
                      <select
                        name="country"
                        value={formData.country}
                        onChange={handleInputChange}
                        className="mt-2 block w-full px-4 py-2 rounded-lg border border-gray-300 shadow-sm focus:outline-none focus:ring-2 focus:ring-orange-500 focus:border-orange-500 sm:text-sm"
                      >
                        <option value="">Select Country</option>
                        <option value="India">India</option>
                        <option value="USA">USA</option>
                        <option value="Canada">Canada</option>
                      </select>
                    </div>
                  </div>
                </div>
              )}

              <div>
                {step === 4 && (
                  <div>
                    <h1 className="text-2xl font-semibold">
                      Product / Services
                    </h1>
                    {loading ? (
                      <p>Loading...</p>
                    ) : (
                      productData && (
                        <div className="grid grid-cols-4 gap-4 mt-4">
                          {productData.map((product) => (
                            <div
                              key={product.id}
                              className="border p-4 rounded-lg shadow-lg flex  gap-2 items-center"
                            >
                              <input
                                type="checkbox"
                                onChange={() => handleCheckboxChange(product)}
                                className=""
                                checked={selectedProducts.some(
                                  (item) => item.Prd_Id === product.Prd_Id
                                )}
                              />

                              <p className="text-gray-600">
                                {product.Prd_Desc}
                              </p>
                            </div>
                          ))}
                        </div>
                      )
                    )}
                  </div>
                )}

                {/* Modal for Product Details */}
                {isModalOpen && selectedProduct && (
                  <div className="fixed inset-0 bg-gray-900 bg-opacity-75 flex justify-center items-center z-50">
                    <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full">
                      <h2 className="text-xl font-semibold">Product Details</h2>
                      <div className="mt-4">
                        <label className="block text-sm">
                          Product Brand Name
                        </label>
                        <input
                          type="text"
                          value={selectedProduct.brand_name || ""}
                          onChange={(e) =>
                            sethandleInputChange("brand_name", e.target.value)
                          }
                          className="w-full border p-2 mt-1 rounded"
                        />
                      </div>
                      <div className="mt-4">
                        <label className="block text-sm">Product Qty</label>
                        <input
                          type="text"
                          value={selectedProduct.qty}
                          onChange={(e) =>
                            sethandleInputChange("qty", e.target.value)
                          }
                          className="w-full border p-2 mt-1 rounded"
                        />
                      </div>
                      <div className="mt-4">
                        <label className="block text-sm">Product Price</label>
                        <input
                          type="text"
                          value={selectedProduct.price}
                          onChange={(e) =>
                            sethandleInputChange("price", e.target.value)
                          }
                          className="w-full border p-2 mt-1 rounded"
                        />
                      </div>

                      <div className="mt-6 flex justify-end">
                        <div
                          onClick={() => setIsModalOpen(false)}
                          className="bg-gray-500 text-white px-4 py-2 rounded-3xl mr-2 cursor-pointer"
                        >
                          Close
                        </div>
                        <div
                          className="bg-orange-500 text-white px-4 py-2 rounded-3xl cursor-pointer"
                          onClick={handleSave}
                        >
                          Save
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* Modal for Price and Quantity */}

            {/* Navigation divs */}
            <div className="flex justify-between px-8">
              <div
                className={`py-2 px-8 rounded-lg ${
                  step === 1
                    ? "bg-gray-400 text-gray-200 cursor-not-allowed"
                    : "bg-gray-600 text-white cursor-pointer"
                }`}
                onClick={step > 1 ? handlePrevious : undefined} // Disable onClick if step is 1
              >
                Previous
              </div>
              <div
                className="py-2 px-8 bg-orange-600 text-white rounded-lg cursor-pointer"
                onClick={handleNext}
              >
                {step === 4 ? "Submit" : "Next"}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
