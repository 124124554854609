import React, { useState } from "react";
import axios from "axios";

const Add_Manage_Category = ({
  onClose,
  selectedData,
  fetchData,
  isEditing,
}) => {
  const [name, setName] = useState(selectedData ? selectedData.name : "");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Handle Form Submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      if (isEditing) {
        // PUT Request for updating
        await axios.put(
          `https://vpandit.caxis.ca/public/api/prod-categories/${selectedData.id}`,
          {
            PrCat_Name: name,
          }
        );
      } else {
        // POST Request for adding
        await axios.post("https://vpandit.caxis.ca/public/api/prod-categories", {
          PrCat_Name: name,
        });
      }
      fetchData(); // Refresh the data after the operation
      onClose(); // Close the modal/form
    } catch (err) {
      setError("Failed to save category. Please try again.");
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="p-6 bg-white rounded-lg shadow-md">
      <h2 className="text-xl font-bold mb-4">
        {isEditing ? "Edit Category" : "Add Category"}
      </h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label
            htmlFor="category-name"
            className="block text-sm font-medium text-gray-700"
          >
            Category Name
          </label>
          <input
            id="category-name"
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="mt-1 block p-2 border border-gray-300 rounded-md shadow-sm focus:ring-red-500 focus:border-red-500 sm:text-sm w-full"
            required
          />
        </div>
        {error && <p className="text-red-500 text-sm mb-4">{error}</p>}
        <div className="mt-4 flex gap-4 w-full">
          <button
            type="submit"
            className="bg-orange-500 text-white p-2 rounded-full"
          >
            {isEditing ? "Update Product" : "Add Product"}
          </button>
          <button
            type="button"
            onClick={onClose}
            className="bg-gray-500 text-white p-2 rounded-full"
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default Add_Manage_Category;
