import { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { calculateKundliData, RASHI_NAMES } from "./astrology-utils";


export default function KundliDetails() {
  const [activeTab, setActiveTab] = useState("basis");
  const [birthDetails, setBirthDetails] = useState(null);
  const [kundliData, setKundliData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const d1ChartRef = useRef(null); // Reference for D1 chart
  const d9ChartRef = useRef(null); // Reference for D9 chart

  const fetchDetails = async () => {
    try {
      setIsLoading(true);
      const name = params.get("name") || "N/A";
      const dateOfBirth = params.get("dob") || "";
      const timeOfBirth = params.get("timeOfBirth") || "";
      const birthPlace = params.get("birthPlace") || "";
      const gender = params.get("gender") || "";

      const geoResponse = await fetch(
        `https://api.opencagedata.com/geocode/v1/json?q=${encodeURIComponent(birthPlace)}&key=8a8bf13b0b694e5c99c719d998a9ff6c`
      );
      const geoData = await geoResponse.json();

      if (!geoData.results || geoData.results.length === 0) {
        throw new Error("Unable to fetch latitude and longitude.");
      }

      const { lat: latitude, lng: longitude } = geoData.results[0].geometry;
      const timezone = geoData.results[0].annotations.timezone;
      const offsetString = timezone.offset_string;

      const [year, month, day] = dateOfBirth.split('-').map(Number);
      const [hours, minutes] = timeOfBirth.split(':').map(Number);
      const tempDate = new Date(Date.UTC(year, month - 1, day, hours, minutes));
      const offsetSec = geoData.results[0].annotations.timezone.offset_sec;
      const utcTimestamp = tempDate.getTime() - offsetSec * 1000;
      const birthDateTime = new Date(utcTimestamp);

      const birthDetailsData = {
        name,
        date: dateOfBirth,
        time: timeOfBirth,
        place: birthPlace,
        gender,
        latitude: latitude.toFixed(2),
        longitude: longitude.toFixed(2),
        timezone: `UTC${offsetString}`,
      };
      setBirthDetails(birthDetailsData);

      if (dateOfBirth && timeOfBirth) {
        const kundliData = calculateKundliData(birthDateTime, latitude, longitude);
        setKundliData(kundliData);
      }
    } catch (error) {
      console.error("Error fetching details:", error);
      alert("Failed to generate Kundli. Please check your input and try again.");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const birthPlace = params.get("birthPlace");
    if (birthPlace) {
      fetchDetails();
    } else {
      setIsLoading(false);
    }
  }, [location.search]);

  const panchangDetails = kundliData?.panchang || {
    tithi: "N/A",
    karan: "N/A",
    yog: "N/A",
    nakshatra: "N/A",
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <nav className="flex text-sm mb-5">
        <Link to="/" className="text-orange-500 hover:text-orange-600 font-bold">Home</Link>
        <span className="mx-2 text-gray-500">&gt;</span>
        <Link className="text-gray-600 font-bold" to={"/kundli"}>Kundli</Link>
        <span className="mx-2 text-gray-500">&gt;</span>
        <span className="text-gray-600 font-bold">Kundli Details</span>
      </nav>

      <div className="flex flex-wrap gap-2 mb-8">
        {["Basis", "Kundli"].map((tab) => (
          <div
            key={tab}
            onClick={() => setActiveTab(tab.toLowerCase())}
            className={`px-6 py-2 rounded-md transition-colors cursor-pointer ${activeTab === tab.toLowerCase() ? "bg-orange-500 text-white" : "bg-gray-100 text-gray-600 hover:bg-gray-200"}`}
          >
            {tab}
          </div>
        ))}
      </div>

      {isLoading ? (
        <div className="flex justify-center items-center h-64">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-orange-500"></div>
        </div>
      ) : (
        <>
          {activeTab === "basis" && (
            <div className="grid md:grid-cols-2 gap-4">
              {birthDetails ? (
                <div className="bg-white rounded-lg overflow-hidden border">
                  <h2 className="text-xl font-semibold mb-4 px-4 py-2 border-b bg-gray-100">Birth Details</h2>
                  <div className="grid divide-y">
                    {Object.entries(birthDetails).map(([key, value]) => (
                      <div key={key} className="grid grid-cols-2 py-3 px-4 even:bg-gray-50 hover:bg-gray-200 transition-colors">
                        <span className="font-medium capitalize">{key}</span>
                        <span>{value}</span>
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <div className="text-center py-4">No birth details available</div>
              )}
              <div className="bg-white rounded-lg overflow-hidden border">
                <h2 className="text-xl font-semibold mb-4 px-4 py-2 border-b bg-gray-100">Panchang Details</h2>
                <div className="grid divide-y">
                  {Object.entries(panchangDetails).map(([key, value]) => (
                    <div key={key} className="grid grid-cols-2 py-3 px-4 even:bg-gray-50 hover:bg-gray-200 transition-colors">
                      <span className="font-medium capitalize">{key}</span>
                      <span>{value}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}

          {activeTab === "kundli" && (
            <div className="space-y-8">
              <div className="grid md:grid-cols-2 gap-8">
                <div ref={d1ChartRef}>
                  <h3 className="text-lg font-semibold mb-4">Lagna/Ascendant (D1) Chart</h3>
                  {kundliData ? (
                    <KundliChart planetPositions={kundliData.planetPositions} ascendant={kundliData.ascendant} />
                  ) : (
                    <div className="aspect-square border border-orange-200 flex items-center justify-center">
                      <p className="text-gray-500">No Kundli data available</p>
                    </div>
                  )}
                </div>
                {/* <div ref={d9ChartRef}>
                  <h3 className="text-lg font-semibold mb-4">Navamsa (D9) Chart</h3>
                  {kundliData ? (
                    <NavamsaChart planetPositions={kundliData.planetPositions} ascendant={kundliData.ascendant} />
                  ) : (
                    <div className="aspect-square border border-orange-200 flex items-center justify-center">
                      <p className="text-gray-500">No Navamsa data available</p>
                    </div>
                  )}
                </div> */}
              </div>
              {kundliData && (
                <div className="overflow-x-auto mt-8">
                  <div className="flex justify-end mb-4">
                  </div>
                  <h3 className="text-lg font-semibold mb-4">Planet Positions</h3>
                  <table className="w-full border-collapse">
                    <thead>
                      <tr className="bg-gray-50">
                        <th className="border p-2 text-left">Planet</th>
                        <th className="border p-2 text-left">Sign</th>
                        <th className="border p-2 text-left">Degree</th>
                        <th className="border p-2 text-left">Nakshatra</th>
                        <th className="border p-2 text-left">Pada</th>
                        <th className="border p-2 text-left">House</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="bg-gray-100 font-medium">
                        <td className="border p-2">Ascendant</td>
                        <td className="border p-2">{kundliData.ascendant.sign}</td>
                        <td className="border p-2">{kundliData.ascendant.degree}°</td>
                        <td className="border p-2">{kundliData.ascendant.nakshatra}</td>
                        <td className="border p-2">{kundliData.ascendant.pada}</td>
                        <td className="border p-2">1</td>
                      </tr>
                      {kundliData.planetPositions.map((planet, index) => (
                        <tr key={index} className="even:bg-gray-50">
                          <td className="border p-2">{planet.name}</td>
                          <td className="border p-2">{planet.sign}</td>
                          <td className="border p-2">{planet.degree}°</td>
                          <td className="border p-2">{planet.nakshatra}</td>
                          <td className="border p-2">{planet.pada}</td>
                          <td className="border p-2">{planet.house}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          )}

          {/* {activeTab === "planets" && (
            <div className="space-y-8">
              {kundliData?.planetPositions && (
                <div className="overflow-x-auto">
                  <table className="w-full border-collapse">
                    <thead>
                      <tr className="bg-gray-50">
                        <th className="border p-2 text-left">Planets</th>
                        <th className="border p-2 text-left">Sign</th>
                        <th className="border p-2 text-left">Sign Lord</th>
                        <th className="border p-2 text-left">Degree</th>
                        <th className="border p-2 text-left">Nakshatra</th>
                        <th className="border p-2 text-left">Pada</th>
                        <th className="border p-2 text-left">Nak Lord</th>
                        <th className="border p-2 text-left">House</th>
                        <th className="border p-2 text-left">Motion</th>
                      </tr>
                    </thead>
                    <tbody>
                      {kundliData.planetPositions.map((planet, index) => (
                        <tr key={index} className="even:bg-gray-50">
                          <td className="border p-2">{planet.name}</td>
                          <td className="border p-2">{planet.sign}</td>
                          <td className="border p-2">{planet.signLord}</td>
                          <td className="border p-2">{planet.degree}°</td>
                          <td className="border p-2">{planet.nakshatra}</td>
                          <td className="border p-2">{planet.pada}</td>
                          <td className="border p-2">{planet.nakshatraLord}</td>
                          <td className="border p-2">{planet.house}</td>
                          <td className="border p-2">{planet.motionStatus}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          )} */}
        </>
      )}
    </div>
  );
}

function KundliChart({ planetPositions, ascendant }) {
  const canvasRef = useRef(null);
  const RAD = Math.PI / 180;

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    const resizeCanvas = () => {
      const canvasParentWidth = canvas.parentElement.offsetWidth;
      canvas.style.width = `${canvasParentWidth - 10}px`;
      canvas.style.height = `${canvasParentWidth - 10}px`;
      canvas.width = canvasParentWidth - 10;
      canvas.height = canvasParentWidth - 10;
      drawChart();
    };

    resizeCanvas();
    window.addEventListener("resize", resizeCanvas);
    return () => window.removeEventListener("resize", resizeCanvas);

    function drawChart() {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.textAlign = "center";

      drawSquareHouse(ctx, 50, 0, 75, 25, 50, 50, 25, 25);
      drawTriangleHouse(ctx, 0, 0, 50, 0, 25, 25);
      drawTriangleHouse(ctx, 0, 0, 25, 25, 0, 50);
      drawSquareHouse(ctx, 25, 25, 50, 50, 25, 75, 0, 50);
      drawTriangleHouse(ctx, 0, 50, 25, 75, 0, 100);
      drawTriangleHouse(ctx, 25, 75, 50, 100, 0, 100);
      drawSquareHouse(ctx, 50, 50, 75, 75, 50, 100, 25, 75);
      drawTriangleHouse(ctx, 75, 75, 100, 100, 50, 100);
      drawTriangleHouse(ctx, 75, 75, 100, 50, 100, 100);
      drawSquareHouse(ctx, 75, 25, 100, 50, 75, 75, 50, 50);
      drawTriangleHouse(ctx, 100, 0, 100, 50, 75, 25);
      drawTriangleHouse(ctx, 50, 0, 100, 0, 75, 25);

      const ascendantIndex = RASHI_NAMES.indexOf(ascendant.sign);
      const houseSigns = Array(12).fill().map((_, i) => RASHI_NAMES[(ascendantIndex + i) % 12]);

      const signNumberPositions = [
        { x: 50, y: 15 }, { x: 25, y: 10 }, { x: 10, y: 25 }, { x: 15, y: 50 },
        { x: 10, y: 75 }, { x: 25, y: 90 }, { x: 50, y: 85 }, { x: 75, y: 90 },
        { x: 90, y: 75 }, { x: 85, y: 50 }, { x: 90, y: 25 }, { x: 75, y: 10 },
      ];

      houseSigns.forEach((sign, index) => {
        const pos = signNumberPositions[index];
        const signNumber = (RASHI_NAMES.indexOf(sign) + 1).toString();
        setText(ctx, pos.x, pos.y, signNumber, "sign");
      });

      const planetsByHouse = Array(12).fill().map(() => []);
      planetPositions.forEach((planet) => {
        const signIndex = RASHI_NAMES.indexOf(planet.sign);
        planetsByHouse[signIndex].push(planet.name.substring(0, 2));
      });

      const housePlanetPositions = [
        { x: 50, y: 30 }, { x: 25, y: 15 }, { x: 15, y: 30 }, { x: 25, y: 50 },
        { x: 15, y: 70 }, { x: 25, y: 85 }, { x: 50, y: 70 }, { x: 75, y: 85 },
        { x: 85, y: 70 }, { x: 75, y: 50 }, { x: 85, y: 30 }, { x: 75, y: 15 },
      ];

      planetsByHouse.forEach((planets, rashiIndex) => {
        const chartHouseIndex = (rashiIndex - ascendantIndex + 12) % 12;
        const pos = housePlanetPositions[chartHouseIndex];

        if (rashiIndex === ascendantIndex) planets.unshift("As");

        if (planets.length === 0) return;

        ctx.font = "14px Arial";
        ctx.fillStyle = "#F97316";

        const arrangeInGrid = planets.length > 3;

        if (arrangeInGrid) {
          const cols = Math.ceil(Math.sqrt(planets.length));
          const rows = Math.ceil(planets.length / cols);

          let baseX = (pos.x * canvas.width) / 100;
          let baseY = (pos.y * canvas.height) / 100;

          const isTop = pos.y < 40;
          const isBottom = pos.y > 60;
          const isLeft = pos.x < 40;
          const isRight = pos.x > 60;

          const xSpacing = 15;
          const ySpacing = 12;

          let startX = baseX - ((cols - 1) * xSpacing) / 2;
          let startY = baseY - ((rows - 1) * ySpacing) / 2;

          if (isTop) startY += 10;
          if (isBottom) startY -= 10;
          if (isLeft) startX += 10;
          if (isRight) startX -= 10;

          planets.forEach((planet, i) => {
            const col = i % cols;
            const row = Math.floor(i / cols);
            const x = startX + col * xSpacing;
            const y = startY + row * ySpacing;
            ctx.fillText(planet, x, y);
          });
        } else {
          let baseX = (pos.x * canvas.width) / 100;
          let baseY = (pos.y * canvas.height) / 100;

          const isTop = pos.y < 80;
          const isBottom = pos.y > 60;
          const isLeft = pos.x < 40;
          const isRight = pos.x > 60;

          const xOffset = 14;

          if (planets.length === 1) {
            ctx.fillText(planets[0], baseX, baseY);
          } else if (planets.length === 2) {
            ctx.fillText(planets[0], baseX - xOffset, baseY);
            ctx.fillText(planets[1], baseX + xOffset, baseY);
          } else {
            if (isTop) {
              ctx.fillText(planets[0], baseX, baseY);
              ctx.fillText(planets[1], baseX - xOffset, baseY + 12);
              ctx.fillText(planets[2], baseX + xOffset, baseY + 12);
            } else if (isBottom) {
              ctx.fillText(planets[0], baseX, baseY);
              ctx.fillText(planets[1], baseX - xOffset, baseY - 12);
              ctx.fillText(planets[2], baseX + xOffset, baseY - 12);
            } else if (isLeft) {
              ctx.fillText(planets[0], baseX, baseY);
              ctx.fillText(planets[1], baseX + xOffset, baseY - 8);
              ctx.fillText(planets[2], baseX + xOffset, baseY + 8);
            } else if (isRight) {
              ctx.fillText(planets[0], baseX, baseY);
              ctx.fillText(planets[1], baseX - xOffset, baseY - 8);
              ctx.fillText(planets[2], baseX - xOffset, baseY + 8);
            } else {
              ctx.fillText(planets[0], baseX - xOffset, baseY);
              ctx.fillText(planets[1], baseX, baseY);
              ctx.fillText(planets[2], baseX + xOffset, baseY);
            }
          }
        }
      });
    }

    function drawSquareHouse(context, x1, y1, x2, y2, x3, y3, x4, y4) {
      context.beginPath();
      context.moveTo((x1 * canvas.width) / 100, (y1 * canvas.height) / 100);
      context.lineTo((x2 * canvas.width) / 100, (y2 * canvas.height) / 100);
      context.lineTo((x3 * canvas.width) / 100, (y3 * canvas.height) / 100);
      context.lineTo((x4 * canvas.width) / 100, (y4 * canvas.height) / 100);
      context.closePath();
      context.lineWidth = 1;
      context.strokeStyle = "red";
      context.stroke();
    }

    function drawTriangleHouse(context, x1, y1, x2, y2, x3, y3) {
      context.beginPath();
      context.moveTo((x1 * canvas.width) / 100, (y1 * canvas.height) / 100);
      context.lineTo((x2 * canvas.width) / 100, (y2 * canvas.height) / 100);
      context.lineTo((x3 * canvas.width) / 100, (y3 * canvas.height) / 100);
      context.closePath();
      context.lineWidth = 1;
      context.strokeStyle = "red";
      context.stroke();
    }

    function setText(context, x, y, maintext, type) {
      if (type === "sign") {
        context.font = "15px Arial";
        context.fillStyle = "#002538";
      }
      context.fillText(maintext, (x * canvas.width) / 100, (y * canvas.height) / 100);
    }
  }, [planetPositions, ascendant]);

  return (
    <div className="w-full max-w-md bg-white p-6 rounded-lg">
      <canvas ref={canvasRef} className="w-full h-auto shadow-xl" />
    </div>
  );
}

// function NavamsaChart({ planetPositions, ascendant }) {
//   const canvasRef = useRef(null);

//   const calculateNavamsa = (signIndex, degreeInSign) => {
//     const degreesPerNavamsa = 30 / 9; // 3.333°

//     let startingNavamsa;
//     if ([0, 3, 6, 9].includes(signIndex)) { // Movable: Aries(0), Cancer(3), Libra(6), Capricorn(9)
//       startingNavamsa = signIndex;
//     } else if ([1, 4, 7, 10].includes(signIndex)) { // Fixed: Taurus(1), Leo(4), Scorpio(7), Aquarius(10)
//       startingNavamsa = (signIndex + 8) % 12; // 9th sign
//     } else { // Dual: Gemini(2), Virgo(5), Sagittarius(8), Pisces(11)
//       startingNavamsa = (signIndex + 4) % 12; // 5th sign
//     }

//     const navamsaDivision = Math.floor(degreeInSign / degreesPerNavamsa);
//     const navamsaSignIndex = (startingNavamsa + navamsaDivision) % 12;

//     return navamsaSignIndex;
//   };

//   useEffect(() => {
//     const canvas = canvasRef.current;
//     const ctx = canvas.getContext("2d");

//     const resizeCanvas = () => {
//       const canvasParentWidth = canvas.parentElement.offsetWidth;
//       canvas.style.width = `${canvasParentWidth - 10}px`;
//       canvas.style.height = `${canvasParentWidth - 10}px`;
//       canvas.width = canvasParentWidth - 10;
//       canvas.height = canvasParentWidth - 10;
//       drawChart();
//     };

//     resizeCanvas();
//     window.addEventListener("resize", resizeCanvas);
//     return () => window.removeEventListener("resize", resizeCanvas); // Fixed typo here

//     function drawChart() {
//       ctx.clearRect(0, 0, canvas.width, canvas.height);
//       ctx.textAlign = "center";

//       drawSquareHouse(ctx, 50, 0, 75, 25, 50, 50, 25, 25);
//       drawTriangleHouse(ctx, 0, 0, 50, 0, 25, 25);
//       drawTriangleHouse(ctx, 0, 0, 25, 25, 0, 50);
//       drawSquareHouse(ctx, 25, 25, 50, 50, 25, 75, 0, 50);
//       drawTriangleHouse(ctx, 0, 50, 25, 75, 0, 100);
//       drawTriangleHouse(ctx, 25, 75, 50, 100, 0, 100);
//       drawSquareHouse(ctx, 50, 50, 75, 75, 50, 100, 25, 75);
//       drawTriangleHouse(ctx, 75, 75, 100, 100, 50, 100);
//       drawTriangleHouse(ctx, 75, 75, 100, 50, 100, 100);
//       drawSquareHouse(ctx, 75, 25, 100, 50, 75, 75, 50, 50);
//       drawTriangleHouse(ctx, 100, 0, 100, 50, 75, 25);
//       drawTriangleHouse(ctx, 50, 0, 100, 0, 75, 25);

//       const ascendantSignIndex = RASHI_NAMES.indexOf(ascendant.sign);
//       const ascendantDegree = parseFloat(ascendant.degree);
//       const ascendantNavamsaIndex = calculateNavamsa(ascendantSignIndex, ascendantDegree);
//       const houseSigns = Array(12).fill().map((_, i) => RASHI_NAMES[(ascendantNavamsaIndex + i) % 12]);

//       const signNumberPositions = [
//         { x: 50, y: 15 }, { x: 25, y: 10 }, { x: 10, y: 25 }, { x: 15, y: 50 },
//         { x: 10, y: 75 }, { x: 25, y: 90 }, { x: 50, y: 85 }, { x: 75, y: 90 },
//         { x: 90, y: 75 }, { x: 85, y: 50 }, { x: 90, y: 25 }, { x: 75, y: 10 },
//       ];

//       houseSigns.forEach((sign, index) => {
//         const pos = signNumberPositions[index];
//         const signNumber = (RASHI_NAMES.indexOf(sign) + 1).toString();
//         setText(ctx, pos.x, pos.y, signNumber, "sign");
//       });

//       const planetsByNavamsaHouse = Array(12).fill().map(() => []);
//       planetPositions.forEach((planet) => {
//         const signIndex = RASHI_NAMES.indexOf(planet.sign);
//         const degree = parseFloat(planet.degree);
//         const navamsaSignIndex = calculateNavamsa(signIndex, degree);
//         planetsByNavamsaHouse[navamsaSignIndex].push(planet.name.substring(0, 2));
//       });

//       const housePlanetPositions = [
//         { x: 50, y: 30 }, { x: 25, y: 15 }, { x: 15, y: 30 }, { x: 25, y: 50 },
//         { x: 15, y: 70 }, { x: 25, y: 85 }, { x: 50, y: 70 }, { x: 75, y: 85 },
//         { x: 85, y: 70 }, { x: 75, y: 50 }, { x: 85, y: 30 }, { x: 75, y: 15 },
//       ];

//       planetsByNavamsaHouse.forEach((planets, navamsaIndex) => {
//         const chartHouseIndex = (navamsaIndex - ascendantNavamsaIndex + 12) % 12;
//         const pos = housePlanetPositions[chartHouseIndex];

//         if (navamsaIndex === ascendantNavamsaIndex) planets.unshift("As");

//         if (planets.length === 0) return;

//         ctx.font = "14px Arial";
//         ctx.fillStyle = "#F97316";

//         const arrangeInGrid = planets.length > 3;

//         if (arrangeInGrid) {
//           const cols = Math.ceil(Math.sqrt(planets.length));
//           const rows = Math.ceil(planets.length / cols);

//           let baseX = (pos.x * canvas.width) / 100;
//           let baseY = (pos.y * canvas.height) / 100;

//           const isTop = pos.y < 40;
//           const isBottom = pos.y > 60;
//           const isLeft = pos.x < 40;
//           const isRight = pos.x > 60;

//           const xSpacing = 15;
//           const ySpacing = 12;

//           let startX = baseX - ((cols - 1) * xSpacing) / 2;
//           let startY = baseY - ((rows - 1) * ySpacing) / 2;

//           if (isTop) startY += 10;
//           if (isBottom) startY -= 10;
//           if (isLeft) startX += 10;
//           if (isRight) startX -= 10;

//           planets.forEach((planet, i) => {
//             const col = i % cols;
//             const row = Math.floor(i / cols);
//             const x = startX + col * xSpacing;
//             const y = startY + row * ySpacing;
//             ctx.fillText(planet, x, y);
//           });
//         } else {
//           let baseX = (pos.x * canvas.width) / 100;
//           let baseY = (pos.y * canvas.height) / 100;

//           const isTop = pos.y < 40;
//           const isBottom = pos.y > 60;
//           const isLeft = pos.x < 40;
//           const isRight = pos.x > 60;

//           const xOffset = 14;

//           if (planets.length === 1) {
//             ctx.fillText(planets[0], baseX, baseY);
//           } else if (planets.length === 2) {
//             ctx.fillText(planets[0], baseX - xOffset, baseY);
//             ctx.fillText(planets[1], baseX + xOffset, baseY);
//           } else {
//             if (isTop) {
//               ctx.fillText(planets[0], baseX, baseY);
//               ctx.fillText(planets[1], baseX - xOffset, baseY + 12);
//               ctx.fillText(planets[2], baseX + xOffset, baseY + 12);
//             } else if (isBottom) {
//               ctx.fillText(planets[0], baseX, baseY);
//               ctx.fillText(planets[1], baseX - xOffset, baseY - 12);
//               ctx.fillText(planets[2], baseX + xOffset, baseY - 12);
//             } else if (isLeft) {
//               ctx.fillText(planets[0], baseX, baseY);
//               ctx.fillText(planets[1], baseX + xOffset, baseY - 8);
//               ctx.fillText(planets[2], baseX + xOffset, baseY + 8);
//             } else if (isRight) {
//               ctx.fillText(planets[0], baseX, baseY);
//               ctx.fillText(planets[1], baseX - xOffset, baseY - 8);
//               ctx.fillText(planets[2], baseX - xOffset, baseY + 8);
//             } else {
//               ctx.fillText(planets[0], baseX - xOffset, baseY);
//               ctx.fillText(planets[1], baseX, baseY);
//               ctx.fillText(planets[2], baseX + xOffset, baseY);
//             }
//           }
//         }
//       });
//     }

//     function drawSquareHouse(context, x1, y1, x2, y2, x3, y3, x4, y4) {
//       context.beginPath();
//       context.moveTo((x1 * canvas.width) / 100, (y1 * canvas.height) / 100);
//       context.lineTo((x2 * canvas.width) / 100, (y2 * canvas.height) / 100);
//       context.lineTo((x3 * canvas.width) / 100, (y3 * canvas.height) / 100);
//       context.lineTo((x4 * canvas.width) / 100, (y4 * canvas.height) / 100);
//       context.closePath();
//       context.lineWidth = 1;
//       context.strokeStyle = "red";
//       context.stroke();
//     }

//     function drawTriangleHouse(context, x1, y1, x2, y2, x3, y3) {
//       context.beginPath();
//       context.moveTo((x1 * canvas.width) / 100, (y1 * canvas.height) / 100);
//       context.lineTo((x2 * canvas.width) / 100, (y2 * canvas.height) / 100);
//       context.lineTo((x3 * canvas.width) / 100, (y3 * canvas.height) / 100);
//       context.closePath();
//       context.lineWidth = 1;
//       context.strokeStyle = "red";
//       context.stroke();
//     }

//     function setText(context, x, y, maintext, type) {
//       if (type === "sign") {
//         context.font = "15px Arial";
//         context.fillStyle = "#002538";
//       }
//       context.fillText(maintext, (x * canvas.width) / 100, (y * canvas.height) / 100);
//     }
//   }, [planetPositions, ascendant]);

//   return (
//     <div className="w-full max-w-md bg-white p-6 rounded-lg">
//       <canvas ref={canvasRef} className="w-full h-auto shadow-xl" />
//     </div>
//   );
// }