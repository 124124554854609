'use client'

import { useState, useEffect } from 'react'
import { FaStar, FaUser, FaChevronDown } from 'react-icons/fa'
import { FaPhoneAlt, FaComments, FaRobot } from "react-icons/fa";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import {  FaRegClock, FaMoneyBillWave, FaLanguage } from 'react-icons/fa';
import { Link } from "react-router-dom";
export default function Talk_With_Astrologer() {
  const [activeTab, setActiveTab] = useState("call");
  const [expertiseOpen, setExpertiseOpen] = useState(true);
  const [languageOpen, setLanguageOpen] = useState(true);
  const [priceOpen, setPriceOpen] = useState(false);

  // Mock data for astrologers
  const astrologers = {
    call: [
      {
        id: 1,
        name: "Acharya Sudhir",
        image:
          "https://yt3.googleusercontent.com/Xydn0l_gresiJDZWc_7HgVuCSloQK_3wLONwwiKU5rwe4l_S_oR5nAA-Cxo8zB5P2q-IGsQchs4=s900-c-k-c0x00ffffff-no-rj",
        expertise: "Vedic",
        languages: ["English", "Hindi"],
        freeTime: "32/min",
        price: "15",
        rating: 4.8,
        followers: 2648,
        skills: ["Vedic Astrology"],
      },
      {
        id: 2,
        name: "Acharya Raghven",
        image:
          "https://aap.astroarunpandit.org/wp-content/uploads/2024/04/Group-37.png",
        expertise: "Vedic, Numerology, Tarot Reading",
        languages: ["Hindi"],
        freeTime: "43/min",
        price: "15",
        rating: 4.7,
        followers: 1001,
        skills: ["Vedic Astrology", "Numerology", "Tarot Reading"],
      },
      {
        id: 3,
        name: "Acharya Anjali",
        image:
          "https://panditjioncall.com//upload/astrologer/10-06-22-0747128005-min-min.jpeg",
        expertise: "Numerology, Tarot Reading",
        languages: ["English"],
        freeTime: "25/min",
        price: "10",
        rating: 4.6,
        followers: 1578,
        skills: ["Numerology", "Tarot Reading"],
      },
      {
        id: 4,
        name: "Acharya Shreya",
        image:
          "https://aws.astrotalk.com/images/17e27a77-bf76-4aaf-ad7b-fe2017d6c977.jpeg",
        expertise: "Vedic, Palmistry",
        languages: ["English", "Hindi", "Tamil"],
        freeTime: "30/min",
        price: "12",
        rating: 4.9,
        followers: 3502,
        skills: ["Vedic Astrology", "Palmistry"],
      },
      {
        id: 5,
        name: "Acharya Dev",
        image:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRCnffgPexyx7tkDNDf6O22Duetazc7yIOvaMIyywYkbnxp6tHHi8UmnaLHgPu7g3p_iTY&usqp=CAU",
        expertise: "Vedic, Kundli",
        languages: ["Hindi"],
        freeTime: "40/min",
        price: "15",
        rating: 4.5,
        followers: 900,
        skills: ["Vedic Astrology", "Kundli Matching"],
      },
      {
        id: 6,
        name: "Acharya Priya",
        image:
          "https://www.sanatanjyoti.com/static/media/pandit%20ji%202.db852fa4d401aac4d710.png",
        expertise: "Vedic, Numerology",
        languages: ["English", "Hindi"],
        freeTime: "28/min",
        price: "13",
        rating: 4.7,
        followers: 2200,
        skills: ["Vedic Astrology", "Numerology"],
      },
      {
        id: 7,
        name: "Acharya Kunal",
        image:
          "https://occultbhagvat.com/wp-content/uploads/2024/06/Group-48095796.png",
        expertise: "Vedic, Tarot Reading",
        languages: ["Hindi"],
        freeTime: "35/min",
        price: "11",
        rating: 4.8,
        followers: 1500,
        skills: ["Vedic Astrology", "Tarot Reading"],
      },
      {
        id: 8,
        name: "Acharya Meera",
        image:
          "https://occultbhagvat.com/wp-content/uploads/2023/10/image1.png",
        expertise: "Vedic, Tarot Reading, Numerology",
        languages: ["English", "Hindi", "Bengali"],
        freeTime: "38/min",
        price: "14",
        rating: 4.9,
        followers: 4100,
        skills: ["Vedic Astrology", "Tarot Reading", "Numerology"],
      },
    ],
    chat: [
      {
        id: 1,
        name: "Acharya Biswaji",
        image: "/placeholder.svg?height=80&width=80",
        expertise: "Vedic, Numerology, Vastu",
        languages: ["English", "Hindi", "Bengali"],
        freeTime: "66/min",
        price: "15",
        rating: 4.7,
        followers: 2132,
        skills: ["Vedic Astrology", "Numerology", "Vastu"],
      },
      {
        id: 2,
        name: "Acharya Sneha",
        image: "/placeholder.svg?height=80&width=80",
        expertise: "Tarot Reading, Numerology",
        languages: ["English", "Hindi"],
        freeTime: "45/min",
        price: "12",
        rating: 4.8,
        followers: 1800,
        skills: ["Tarot Reading", "Numerology"],
      },
      {
        id: 3,
        name: "Acharya Omkar",
        image: "/placeholder.svg?height=80&width=80",
        expertise: "Vedic, Palmistry",
        languages: ["Hindi", "Marathi"],
        freeTime: "50/min",
        price: "14",
        rating: 4.6,
        followers: 1523,
        skills: ["Vedic Astrology", "Palmistry"],
      },
      {
        id: 4,
        name: "Acharya Pooja",
        image: "/placeholder.svg?height=80&width=80",
        expertise: "Numerology, Vastu",
        languages: ["English", "Hindi"],
        freeTime: "30/min",
        price: "10",
        rating: 4.9,
        followers: 2500,
        skills: ["Numerology", "Vastu"],
      },
      {
        id: 5,
        name: "Acharya Rajesh",
        image: "/placeholder.svg?height=80&width=80",
        expertise: "Vedic, Kundli",
        languages: ["Hindi"],
        freeTime: "55/min",
        price: "16",
        rating: 4.7,
        followers: 2000,
        skills: ["Vedic Astrology", "Kundli Matching"],
      },
      {
        id: 6,
        name: "Acharya Kavita",
        image: "/placeholder.svg?height=80&width=80",
        expertise: "Vedic, Numerology",
        languages: ["English", "Hindi"],
        freeTime: "35/min",
        price: "13",
        rating: 4.5,
        followers: 1750,
        skills: ["Vedic Astrology", "Numerology"],
      },
      {
        id: 7,
        name: "Acharya Aniket",
        image: "/placeholder.svg?height=80&width=80",
        expertise: "Tarot Reading, Vastu",
        languages: ["Hindi", "Gujarati"],
        freeTime: "60/min",
        price: "14",
        rating: 4.8,
        followers: 1980,
        skills: ["Tarot Reading", "Vastu"],
      },
      {
        id: 8,
        name: "Acharya Rohit",
        image: "/placeholder.svg?height=80&width=80",
        expertise: "Palmistry, Vedic",
        languages: ["English", "Hindi"],
        freeTime: "40/min",
        price: "11",
        rating: 4.7,
        followers: 2200,
        skills: ["Palmistry", "Vedic Astrology"],
      },
      {
        id: 9,
        name: "Acharya Alka",
        image: "/placeholder.svg?height=80&width=80",
        expertise: "Numerology, Tarot Reading, Vastu",
        languages: ["English", "Hindi", "Tamil"],
        freeTime: "25/min",
        price: "15",
        rating: 4.9,
        followers: 3000,
        skills: ["Numerology", "Tarot Reading", "Vastu"],
      },
    ],
    ai: [
      {
        id: 1,
        name: "Acharya Sunil K.",
        image: "/placeholder.svg?height=80&width=80",
        expertise: "Predictive Astrology",
        languages: ["English", "Hindi", "Kannada"],
        freeTime: "29/min",
        price: "8",
        rating: 4.8,
        followers: 1589,
        skills: ["AI Astrology", "Predictive Analysis"],
      },
      {
        id: 2,
        name: "Acharya Rekha S.",
        image: "/placeholder.svg?height=80&width=80",
        expertise: "Numerology, Vedic",
        languages: ["English", "Hindi"],
        freeTime: "35/min",
        price: "10",
        rating: 4.7,
        followers: 1780,
        skills: ["AI Numerology", "Vedic Astrology"],
      },
      {
        id: 3,
        name: "Acharya Rajiv P.",
        image: "/placeholder.svg?height=80&width=80",
        expertise: "Vastu, Palmistry",
        languages: ["Hindi", "Punjabi"],
        freeTime: "40/min",
        price: "9",
        rating: 4.6,
        followers: 2030,
        skills: ["AI Vastu", "AI Palmistry"],
      },
      {
        id: 4,
        name: "Acharya Neha D.",
        image: "/placeholder.svg?height=80&width=80",
        expertise: "Tarot Reading, Predictive Analysis",
        languages: ["English", "Hindi", "Gujarati"],
        freeTime: "33/min",
        price: "12",
        rating: 4.9,
        followers: 2540,
        skills: ["AI Tarot Reading", "Predictive Analysis"],
      },
      {
        id: 5,
        name: "Acharya Varun T.",
        image: "/placeholder.svg?height=80&width=80",
        expertise: "Vedic, Numerology",
        languages: ["English", "Tamil"],
        freeTime: "28/min",
        price: "7",
        rating: 4.8,
        followers: 1678,
        skills: ["AI Vedic Astrology", "Numerology"],
      },
      {
        id: 6,
        name: "Acharya Snehal R.",
        image: "/placeholder.svg?height=80&width=80",
        expertise: "Palmistry, Predictive Astrology",
        languages: ["Hindi", "Marathi"],
        freeTime: "31/min",
        price: "10",
        rating: 4.5,
        followers: 1985,
        skills: ["AI Palmistry", "Predictive AI Astrology"],
      },
      {
        id: 7,
        name: "Acharya Kavita L.",
        image: "/placeholder.svg?height=80&width=80",
        expertise: "Tarot Reading, Numerology",
        languages: ["English", "Hindi"],
        freeTime: "27/min",
        price: "8",
        rating: 4.7,
        followers: 2140,
        skills: ["AI Tarot Reading", "Numerology Analysis"],
      },
      {
        id: 8,
        name: "Acharya Mohan B.",
        image: "/placeholder.svg?height=80&width=80",
        expertise: "Vastu, Predictive Analysis",
        languages: ["Hindi", "Bengali"],
        freeTime: "36/min",
        price: "11",
        rating: 4.9,
        followers: 2800,
        skills: ["AI Vastu Consultation", "Predictive Astrology"],
      },
      {
        id: 9,
        name: "Acharya Priya G.",
        image: "/placeholder.svg?height=80&width=80",
        expertise: "Numerology, Vedic",
        languages: ["English", "Kannada", "Telugu"],
        freeTime: "30/min",
        price: "9",
        rating: 4.6,
        followers: 1950,
        skills: ["AI Numerology", "Vedic Predictions"],
      },
    ],
  };



  const expertise = [
    'Vedic Astrology', 'KP System', 'Lal Kitab', 'Numerology',
    'Tarot Reading', 'Marriage Matching', 'Vastu', 'Reiki',
    'Feng Shui', 'Horary', 'Nadi Astrology'
  ]

  const languages = [
    'English', 'Hindi (हिंदी)', 'Gujarati (ગુજરાતી)', 'Marathi (मराठी)',
    'Kannada (ಕನ್ನಡ)', 'Malayalam (മലയാളം)', 'Odia (ଓଡ଼ିଆ)',
    'Bengali (বাংলা)', 'Punjabi (ਪੰਜਾਬੀ)', 'Tamil (தமிழ்)',
    'Telugu (తెలుగు)', 'Urdu (اردو)'
  ]

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 4000); // Simulate a 2-second load time
  }, []);

  return (
    <div className="container mx-auto px-4 py-6">
      <div className="flex flex-col lg:flex-row min-h-screen bg-gray-50">
        {/* Sidebar Filters */}
        <aside className="w-full lg:w-64 bg-white p-4 border">
          <h2 className="text-lg font-semibold mb-4">Filter</h2>

          {/* Expertise Filter */}
          <div className="mb-6 ">
            <div
              className="flex items-center justify-between w-full py-2 text-left font-medium cursor-pointer"
              onClick={() => setExpertiseOpen(!expertiseOpen)}
            >
              <span>Expertise</span>
              <FaChevronDown
                className={`transform transition-transform ${
                  expertiseOpen ? "rotate-180" : ""
                }`}
              />
            </div>
            {expertiseOpen && (
              <div className="space-y-2 mt-2  pb-4">
                {expertise.map((item) => (
                  <label key={item} className="flex items-center space-x-2">
                    <input
                      type="checkbox"
                      className="rounded border-gray-300"
                    />
                    <span className="text-sm">{item}</span>
                  </label>
                ))}
              </div>
            )}
          </div>

          {/* Language Filter */}
          <div className=" mb-6">
            <div
              className="flex items-center justify-between w-full py-2 text-left font-medium cursor-pointer"
              onClick={() => setLanguageOpen(!languageOpen)}
            >
              <span>Language</span>
              <FaChevronDown
                className={`transform transition-transform ${
                  languageOpen ? "rotate-180" : ""
                }`}
              />
            </div>
            {languageOpen && (
              <div className="space-y-2 mt-2">
                {languages.map((language) => (
                  <label key={language} className="flex items-center space-x-2">
                    <input
                      type="checkbox"
                      className="rounded border-gray-300"
                    />
                    <span className="text-sm">{language}</span>
                  </label>
                ))}
              </div>
            )}
          </div>

          {/* Price & Rating Filter */}
          <div className=" mb-6 ">
            <div
              className="flex items-center justify-between w-full py-2 text-left font-medium cursor-pointer"
              onClick={() => setPriceOpen(!priceOpen)}
            >
              <span>Price & Rating</span>
              <FaChevronDown
                className={`transform transition-transform ${
                  priceOpen ? "rotate-180" : ""
                }`}
              />
            </div>
            {priceOpen && (
              <div className="space-y-2 mt-2">
                <label className="flex items-center space-x-2">
                  <input
                    type="radio"
                    name="price"
                    className="rounded border-gray-300"
                  />
                  <span className="text-sm">Price Low To High</span>
                </label>
                <label className="flex items-center space-x-2">
                  <input
                    type="radio"
                    name="price"
                    className="rounded border-gray-300"
                  />
                  <span className="text-sm">Price High To Low</span>
                </label>
                <label className="flex items-center space-x-2">
                  <input
                    type="radio"
                    name="price"
                    className="rounded border-gray-300"
                  />
                  <span className="text-sm">Rating High To Low</span>
                </label>
              </div>
            )}
          </div>
        </aside>

        {/* Main Content */}
        <main className="flex-1">
          <h1 className="md:text-xl text-sm font-bold pl-5 py-4 bg-white border">
            TALK TO ASTROLOGER - ALL ASTROSAGE CERTIFIED ASTROLOGERS
          </h1>

          {/* Tabs */}
          <div className="grid grid-cols-3 border-b bg-white cursor-pointer md:pl-5 pl-2 gap-4">
            {[
              { id: "call", label: "Call with Astrologers" },
              { id: "chat", label: "Chat with Astrologers" },
              { id: "ai", label: "AI Astrologers" },
            ].map((tab) => (
              <div
                key={tab.id}
                onClick={() => setActiveTab(tab.id)}
                className={`py-4 font-medium md:text-[20px] text-[14px] transition-colors
                  ${
                    activeTab === tab.id
                      ? "text-orange-600 border-b-2 border-orange-600"
                      : "text-gray-400 hover:text-orange-600"
                  }`}
              >
                {tab.label}
              </div>
            ))}
          </div>

          {/* Astrologer Grid */}
          <div className="md:p-4 p-0 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 md:pt-5 pt-5">
            {loading
              ? // Render Skeletons when loading
                Array.from({ length: 9 }).map((_, index) => (
                  <div
                    key={index}
                    className="bg-white p-4 rounded-lg shadow-sm border flex gap-4"
                  >
                    <Skeleton circle height={80} width={80} />
                    <div className="flex-1">
                      <Skeleton width="80%" height={20} />
                      <Skeleton width="60%" height={16} />
                      <Skeleton width="40%" height={16} />
                      <Skeleton width="50%" height={20} />
                    </div>
                  </div>
                ))
              : astrologers[activeTab].map((astrologer) => (
                  <Link
                    to={`/pandit/${astrologer.name}`}
                    key={astrologer.id}
                    className="bg-white p-4 rounded-lg shadow-sm border flex gap-4"
                  >
                    <div className="relative">
                      <img
                        src={astrologer.image}
                        alt={astrologer.name}
                        className="w-20 h-20 rounded-full object-cover"
                      />
                    </div>

                    <div className="flex-1">
                      <div className="flex items-start justify-between">
                        <div>
                          <h3 className="font-semibold text-orange-600 flex items-center gap-1">
                            <span className="w-2 h-2 bg-orange-600 rounded-full"></span>
                            {astrologer.name}
                          </h3>
                          <p className="text-xs text-gray-600 mt-1">
                            {astrologer.expertise}
                          </p>
                        </div>
                        <div>
                          <div className="flex items-center gap-1 text-xs">
                            <FaStar className="text-yellow-500" />
                            <span>{astrologer.rating}</span>
                          </div>
                          <div className="flex items-center gap-1 text-xs">
                            <FaUser className="text-gray-400" />
                            <span>{astrologer.followers}</span>
                          </div>
                        </div>
                      </div>

                      {/* Add Free Time, Price, Languages */}
                      <div className="mt-2 flex gap-4">
                        <div className="flex items-center gap-1 text-xs text-gray-500">
                          <FaRegClock />
                          <span>{astrologer.freeTime}</span>
                        </div>
                        <div className="flex items-center gap-1 text-xs text-gray-500">
                          <FaMoneyBillWave />
                          <span>{astrologer.price}</span>
                        </div>
                        <div className="flex items-center gap-1 text-xs text-gray-500">
                          <FaLanguage />
                          <span>{astrologer.languages.join(", ")}</span>
                        </div>
                      </div>

                      {/* Button section */}
                      <div className="mt-2 flex gap-4">
                        <div className="mt-3 w-full bg-orange-600 text-white py-2 rounded hover:bg-orange-700 transition-colors px-4 flex items-center justify-center gap-2">
                          {activeTab === "call" && (
                            <>
                              <FaPhoneAlt /> Call Now
                            </>
                          )}
                          {activeTab === "chat" && (
                            <>
                              <FaComments /> Chat Now
                            </>
                          )}
                          {activeTab === "ai" && (
                            <>
                              <FaRobot /> Connect Now
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </Link>
                ))}
          </div>
        </main>
      </div>
    </div>
  );
}
