import React, { useState, useEffect } from "react";
import { FaEdit, FaTrash } from "react-icons/fa";
import Add_Pooja from "./Add_Pooja";
import CommongPreloader from "../CommongPreloader/CommongPreloader";
import Swal from "sweetalert2";
import Sub_Category_Pooja_Vidhi_Admin from "./Sub_Category_Pooja_Vidhi_Admin/Sub_Category_Pooja_Vidhi_Admin";
import Super_Category_For_Admin from "./Super_Category_For_Admin/Super_Category_For_Admin";
import HTMLReactParser from "html-react-parser";

const Add_New_Pooja = () => {
  const [isAddUser, setIsAddUser] = useState(false);
  const [poojas, setPoojas] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [selectedSuperCategory, setSelectedSuperCategory] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchPoojas = async () => {
    setLoading(true);
    try {
      const response = await fetch("https://vpandit.caxis.ca/public/api/categories");
      const data = await response.json();
      const formattedData = data.map((pooja) => ({
        id: pooja.Cat_Id,
        name: pooja.Cat_Name,
        description: pooja.Cat_Desc || "", // Ensure description is a string
        image: pooja.Cat_Image,
      }));
      setPoojas(formattedData);
    } catch (error) {
      console.error("Error fetching poojas:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPoojas();
  }, []);

  const handleAddPoojaClick = () => {
    setIsAddUser(true);
  };

  const handleClose = () => {
    setIsAddUser(false);
    fetchPoojas();
  };

  const handleDeletePooja = async (id) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "This will delete the pooja category permanently.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
    });

    if (result.isConfirmed) {
      try {
        const response = await fetch(
          `https://vpandit.caxis.ca/public/api/categories/${id}`,
          {
            method: "DELETE",
          }
        );

        if (response.ok) {
          Swal.fire(
            "Deleted!",
            "The pooja category has been deleted.",
            "success"
          );
          setPoojas((prevPoojas) =>
            prevPoojas.filter((pooja) => pooja.id !== id)
          );
        } else {
          Swal.fire("Error!", "Failed to delete the pooja category.", "error");
        }
      } catch (error) {
        console.error("Error deleting pooja:", error);
        Swal.fire(
          "Error!",
          "An error occurred while deleting the pooja category.",
          "error"
        );
      }
    }
  };

  const handleEditPooja = (id) => {
    setSelectedCategoryId(id);
  };

  const handleEditSuperCategory = (category) => {
    setSelectedSuperCategory(category);
  };

  return (
    <>
      {loading ? (
        <CommongPreloader />
      ) : (
        <>
          {isAddUser ? (
            <Add_Pooja
              onClose={handleClose}
              setUsers={setPoojas}
              users={poojas}
            />
          ) : selectedCategoryId ? (
            <Sub_Category_Pooja_Vidhi_Admin
              category={selectedCategoryId}
              onBack={() => setSelectedCategoryId(null)}
            />
          ) : selectedSuperCategory ? (
            <Super_Category_For_Admin
              category={selectedSuperCategory}
              onBack={() => setSelectedSuperCategory(null)}
              onUpdate={fetchPoojas}
            />
          ) : (
            <div className="mx-auto py-2">
              <div className="flex justify-between items-center mb-6">
                <h2 className="text-2xl font-bold">Pooja Management</h2>
                <div
                  className="flex items-center bg-orange-500 text-white px-4 py-2 rounded-full shadow-lg hover:bg-orange-600 cursor-pointer font-bold"
                  onClick={handleAddPoojaClick}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-5 h-5 mr-2"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M12 5v14M5 12h14" />
                  </svg>
                  Add New Pooja
                </div>
              </div>

              <div className="grid grid-cols-2 sm:grid-cols-4 lg:grid-cols-5 gap-3">
                {poojas.map((pooja, key) => (
                  <div
                    key={pooja.id}
                    className="w-full bg-white shadow-[0px_0px_15px_rgba(0,0,0,0.09)] p-9 space-y-3 relative overflow-hidden cursor-pointer"
                  >
                    <div className="w-24 h-24 bg-orange-500 rounded-full absolute -right-5 -top-7">
                      <p className="absolute bottom-6 left-7 text-white text-2xl font-semibold">
                        {String(key + 1).padStart(2, "0")}
                      </p>
                    </div>
                    <h1
                      className="font-bold text-xl"
                      onClick={() => handleEditPooja(pooja.id)}
                    >
                      {pooja.name}
                    </h1>
                    <p className="text-sm text-zinc-500 leading-6">
                      {typeof pooja.description === "string" &&
                      pooja.description
                        ? HTMLReactParser(
                            pooja.description.substring(0, 300) +
                              (pooja.description.length > 30 ? "..." : "")
                          )
                        : "No description available"}
                    </p>

                    <div className="absolute bottom-5 right-0 m-2 flex items-center space-x-3">
                      <div className="text-gray-500 hover:text-gray-700 cursor-pointer">
                        <FaEdit
                          onClick={() => handleEditSuperCategory(pooja)}
                        />
                      </div>
                      <div
                        className="text-gray-500 hover:text-gray-700 cursor-pointer"
                        onClick={() => handleDeletePooja(pooja.id)}
                      >
                        <FaTrash />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Add_New_Pooja;