import React from 'react'
import "./Preloader.css";
const Preloader = () => {
  return (
    <div className="h-[70vh] w-[100%] flex items-center justify-center">
      <div class="spinner"></div>
    </div>
  );
};

export default Preloader;