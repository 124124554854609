import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";

export default function Add_Inventory_Management({
  handleCloseForm,
  setItems,
  items,
  isEditing,
  selectedProduct,
}) {
  const [formData, setFormData] = useState({
    Prd_Desc: "",
    PRCat_ID: "", // Use PRCat_ID for category selection
  });

  const [categories, setCategories] = useState([]);

  // Fetch categories for the dropdown
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(
          "https://vpandit.caxis.ca/public/api/prod-categories"
        );
        setCategories(response.data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);

  // Populate form fields if editing a product
  useEffect(() => {
    if (isEditing && selectedProduct) {
      setFormData({
        Prd_Desc: selectedProduct.Prd_Desc || "",
        PRCat_ID: selectedProduct.PRCat_ID || "", // Correctly use PRCat_ID
      });
    }
  }, [isEditing, selectedProduct]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    try {
      const payload = {
        Prd_Desc: formData.Prd_Desc,
        PRCat_ID: formData.PRCat_ID,
      };
  
      if (isEditing && selectedProduct) {
        await axios.put(
          `https://vpandit.caxis.ca/public/api/product-masters/${selectedProduct.Prd_Id}`,
          payload
        );
        Swal.fire({
          icon: "success",
          title: "Product Updated Successfully",
        });
        handleCloseForm();
      } else {
        const response = await axios.post(
          "https://vpandit.caxis.ca/public/api/product-masters",
          payload
        );
  
        // Add category manually if API doesn't return it
        const newProduct = {
          ...response.data,
          category: categories.find(
            (cat) => cat.PRCat_ID === parseInt(formData.PRCat_ID)
          ),
        };
  
        Swal.fire({
          icon: "success",
          title: "Product Added Successfully",
        });
        setItems([...items, newProduct]);
        handleCloseForm();
      }
    } catch (error) {
      console.error("Error saving product:", error);
      Swal.fire({
        icon: "error",
        title: "Error saving the product",
      });
    }
  };
  
  return (
    <div className="p-6 bg-white rounded-lg shadow-lg">
      <div className="flex mb-6">
        <h1 className="text-2xl font-bold text-orange-500">
          {isEditing ? "Update Product" : "Add New Product"}
        </h1>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="grid grid-cols-1 gap-4">
          {/* Product Description Field */}
          <div>
            <label
              htmlFor="Prd_Desc"
              className="block text-sm font-medium text-gray-700"
            >
              Product Description
            </label>
            <input
              type="text"
              id="Prd_Desc"
              name="Prd_Desc"
              value={formData.Prd_Desc}
              onChange={handleChange}
              required
              className="mt-1 block p-2 border border-gray-300 rounded-md shadow-sm focus:ring-red-500 focus:border-red-500 sm:text-sm w-full"
            />
          </div>

          {/* Category Dropdown */}
          <div>
            <label
              htmlFor="PRCat_ID"
              className="block text-sm font-medium text-gray-700"
            >
              Category
            </label>
            <select
              id="PRCat_ID"
              name="PRCat_ID"
              value={formData.PRCat_ID}
              onChange={handleChange}
              required
              className="mt-1 block p-2 border border-gray-300 rounded-md shadow-sm focus:ring-red-500 focus:border-red-500 sm:text-sm w-full"
            >
              <option value="" disabled>
                Select a category
              </option>
              {categories.map((category) => (
                <option key={category.PRCat_ID} value={category.PRCat_ID}>
                  {category.PrCat_Name}
                </option>
              ))}
            </select>
          </div>
        </div>

        {/* Submit and Cancel Buttons */}
        <div className="mt-4 flex gap-4 w-full">
          <button
            type="submit"
            className="bg-orange-500 text-white p-2 rounded-full"
          >
            {isEditing ? "Update Product" : "Add Product"}
          </button>
          <button
            type="button"
            onClick={handleCloseForm}
            className="bg-gray-500 text-white p-2 rounded-full"
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
}
