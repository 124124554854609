import React, { useState } from "react";
import JoditEditor from "jodit-react";

const Super_Category_For_Admin = ({ category, onBack, onUpdate }) => {
  const [formData, setFormData] = useState({
    name: category.name || "",
    description: category.description || "",
  });
  const [isUpdating, setIsUpdating] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleDescriptionChange = (content) => {
    // Update description field using Jodit Editor content
    setFormData({ ...formData, description: content });
  };

  const handleUpdate = async () => {
    if (!formData.name.trim() || !formData.description.trim()) {
      alert("Please fill out all fields.");
      return;
    }

    const updatedCategory = {
      Cat_Name: formData.name,
      Cat_Desc: formData.description,
    };

    setIsUpdating(true);

    try {
      const response = await fetch(
        `https://vpandit.caxis.ca/public/api/categories/${category.id}`,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(updatedCategory),
        }
      );

      if (response.ok) {
        const result = await response.json();
        console.log("Category updated successfully:", result);
        onUpdate(result);
        onBack();
      } else {
        const errorData = await response.json();
        alert(`Failed to update: ${errorData.message || "Unknown error"}`);
      }
    } catch (error) {
      console.error("Update error:", error);
      alert("Error updating category.");
    } finally {
      setIsUpdating(false);
    }
  };

  return (
    <div className="p-6 bg-white shadow-md rounded-lg">
      <h1 className="text-2xl font-bold mb-6">Edit Super Category</h1>
      <form>
        <div className="mb-4">
          <label className="block text-gray-700 font-bold mb-2">Name</label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            className="w-full px-4 py-2 border rounded-lg"
            disabled={isUpdating}
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 font-bold mb-2">
            Description
          </label>
          <JoditEditor
            value={formData.description}
            onChange={handleDescriptionChange}
            config={{
              readonly: isUpdating, // Disable editing when updating
              placeholder: "Enter the category description here...",
            }}
          />
        </div>
        <div className="flex space-x-4">
          <button
            type="button"
            onClick={handleUpdate}
            className="bg-orange-500 text-white px-6 py-2 rounded-md"
            disabled={isUpdating}
          >
            {isUpdating ? "Updating..." : "Update"}
          </button>
          <button
            type="button"
            onClick={onBack}
            className="bg-gray-300 text-black px-6 py-2 rounded-md"
            disabled={isUpdating}
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default Super_Category_For_Admin;
