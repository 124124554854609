'use client';

import React, { useState, useEffect } from 'react';
import { FaCheck, FaTimes, FaEdit, FaTrash } from 'react-icons/fa';
import Swal from 'sweetalert2';
import axios from 'axios';
import AddUser from "./AddUser";

const Pandit_Master = () => {
  const [viewType, setViewType] = useState("table");
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [activeTab, setActiveTab] = useState("activePandit");

  // Fetch data based on the active tab
  const fetchData = async () => {
    try {
        const response = await axios.get("https://vpandit.caxis.ca/public/api/users");

        if (activeTab === "activePandit") {
          // Filter for active Pandits
          const filteredUsers = response.data.filter(
            (user) => user.role.Role_Id === 2
          );
          setUsers(filteredUsers);
        } else if (activeTab === "requestPandit") {
          // Fetch data from pandit-masters API
          const panditResponse = await axios.get(
            "https://vpandit.caxis.ca/public/api/pandit-masters"
          );

          const filteredPandits = panditResponse.data.pandits.filter(
            (pandit) => pandit.Pan_Approve === 2
          );

          setUsers(filteredPandits);
        }
    } catch (error) {
        console.error("Error fetching users:", error);
    }
};

  useEffect(() => {
    fetchData();
  }, [activeTab]);

  const handleAddUserClick = () => {
    setIsEditing(false);
    setViewType("addUser");
    setSelectedUser(null);
  };

  const handleEditUser = (user) => {
    setIsEditing(true);
    setSelectedUser(user);
    setViewType("addUser");
  };

  const handleDeleteUser = (userId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to recover this user!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`https://vpandit.caxis.ca/public/api/users/${userId}`)
          .then(() => {
            fetchData();
            Swal.fire("Deleted!", "The user has been deleted.", "success");
          })
          .catch((error) => {
            console.error("Error deleting user:", error);
            Swal.fire("Error", "There was an issue deleting the user", "error");
          });
      }
    });
  };

  const handleAcceptRequest = async (userId, panditId,user) => {
    try {
      console.log("User ID:", userId);
      console.log("Pandit ID:", panditId);
      console.log("user:", user);

      const response = await axios.put(
        `https://vpandit.caxis.ca/public/api/pandit-masters/${panditId}`,
        {
          Pan_Approve: "1",
          U_Id: userId,
          Address: user.Address,
          City: user.City,
          State: user.State,
          Country: user.Country,
          Zipcode: user.Zipcode,
          Occupation: user.Occupation,
        }
      );

      console.log("Request Accepted:", response.data);

      // Optionally update the UI after success
      setUsers((prevUsers) =>
        prevUsers.filter((user) => user.Pandit_Id !== panditId)
      );

      alert("Pandit request approved successfully!");
    } catch (error) {
        console.error("Error approving request:", error);
        alert("Failed to approve request.");
    }
};


  const handleCancelRequest = async (userId, panditId,user) => {
    try {
      console.log("User ID:", userId);
      console.log("Pandit ID:", panditId);
      console.log("user:", user);

      const response = await axios.put(
        `https://vpandit.caxis.ca/public/api/pandit-masters/${panditId}`,
        {
          Pan_Approve: "3",
          U_Id: userId,
          Address: user.Address,
          City: user.City,
          State: user.State,
          Country: user.Country,
          Zipcode: user.Zipcode,
          Occupation: user.Occupation,
        }
      );

      console.log("Request Cancelled:", response.data);

      // Optionally update the UI after success
      setUsers((prevUsers) =>
        prevUsers.filter((user) => user.Pandit_Id !== panditId)
      );

      alert("Pandit request Cancelled successfully!");
    } catch (error) {
      console.error("Error approving request:", error);
      alert("Failed to approve request.");
    }
  };

  const handleCloseForm = () => {
    setViewType("table");
    fetchData();
  };

  return (
    <div className="min-h-screen">
      {/* Header */}
      <div className="flex items-center justify-between mb-6">
        <h1 className="text-2xl font-bold pt-2">Pandit Management</h1>
        <div className="flex items-center space-x-4">
          <div className="relative">
            <input
              type="text"
              placeholder="Search"
              className="text-black px-4 py-2 pr-10 rounded focus:outline-none focus:ring-2 focus:ring-orange-600"
            />
            <svg
              className="w-5 h-5 absolute right-3 top-2.5 text-black"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              />
            </svg>
          </div>
          <div
            onClick={handleAddUserClick}
            className="flex items-center bg-orange-500 text-white px-4 py-2 rounded-full shadow-lg hover:bg-orange-600 cursor-pointer font-bold"
          >
            Add User
          </div>
        </div>
      </div>

      {/* Tabs */}
      <div className="flex border-b border-gray-300 mb-4">
        <div
          className={`px-4 py-2 font-bold cursor-pointer ${
            activeTab === "activePandit"
              ? "text-orange-600 border-b-2 border-orange-600"
              : "text-gray-500"
          }`}
          onClick={() => setActiveTab("activePandit")}
        >
          Active Pandit
        </div>
        <div
          className={`px-4 py-2 font-bold cursor-pointer ${
            activeTab === "requestPandit"
              ? "text-orange-600 border-b-2 border-orange-600"
              : "text-gray-500"
          }`}
          onClick={() => setActiveTab("requestPandit")}
        >
          Request Pandit
        </div>
      </div>

      {/* Conditional rendering */}
      {viewType === "table" ? (
        <div className="rounded-lg overflow-hidden">
          <table className="w-full bg-white">
            <thead>
              <tr className="border-b border-gray-700">
                <th className="px-6 py-3 text-left text-xs font-medium text-black uppercase tracking-wider">
                  Name
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-black uppercase tracking-wider">
                  Email
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-black uppercase tracking-wider">
                  Gender
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-black uppercase tracking-wider">
                  Mobile Number
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-black uppercase tracking-wider">
                  Date Of Birth
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-black uppercase tracking-wider">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-700">
              {users.length === 0 ? (
                <tr>
                  <td
                    colSpan="6"
                    className="px-6 py-4 text-center text-gray-500"
                  >
                    No data available
                  </td>
                </tr>
              ) : (
                users.map((user) => (
                  <tr key={user.id} className="hover:bg-gray-50">
                    <td className="px-6 py-4">
                      <div className="flex items-center">
                        <span>
                          {activeTab === "activePandit"
                            ? user.U_Name
                            : user.user?.U_Name || "Not Provided"}
                        </span>
                      </div>
                    </td>
                    <td className="px-6 py-4">
                      <span className="text-black">
                        {activeTab === "activePandit"
                          ? user.U_Email
                          : user.user?.U_Email || "Not Provided"}
                      </span>
                    </td>
                    <td className="px-6 py-4">
                      {activeTab === "activePandit"
                        ? user.U_Gender
                        : user.user?.U_Gender || "Not Provided"}
                    </td>
                    <td className="px-6 py-4">
                      {activeTab === "activePandit"
                        ? user.U_Mobile
                        : user.user?.U_Mobile || "Not Provided"}
                    </td>
                    <td className="px-6 py-4">
                      {activeTab === "activePandit"
                        ? user.U_DOB
                        : user.user?.U_DOB || "Not Provided"}
                    </td>
                    <td className="px-6 py-4">
                      <div className="flex space-x-3">
                        {activeTab === "requestPandit" ? (
                          <>
                            <FaCheck
                              className="text-green-600 cursor-pointer"
                              onClick={() =>
                                handleAcceptRequest(
                                  user.user?.U_Id,
                                  user.Pandit_Id,
                                  user
                                )
                              }
                            />
                            <FaTimes
                              className="text-red-600 cursor-pointer"
                              onClick={() =>
                                handleCancelRequest(
                                  user.user?.U_Id,
                                  user.Pandit_Id,
                                  user
                                )
                              }
                            />
                          </>
                        ) : (
                          <>
                            <FaEdit
                              className="text-green-600 cursor-pointer"
                              onClick={() => handleEditUser(user)}
                            />
                            <FaTrash
                              className="text-red-600 cursor-pointer"
                              onClick={() => handleDeleteUser(user.U_Id)}
                            />
                          </>
                        )}
                      </div>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      ) : (
        <AddUser
          onClose={handleCloseForm}
          setUsers={setUsers}
          users={users}
          selectedUser={selectedUser}
          isEditing={isEditing}
        />
      )}
    </div>
  );
};

export default Pandit_Master;
