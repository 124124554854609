"use client";

import React, { useState, useEffect } from "react";
import AddUser from "./AddUser";
import { FaEdit, FaTrash, FaBan, FaCheck } from "react-icons/fa";
import Swal from "sweetalert2";
import axios from "axios";

const UserMaster = () => {
  const [viewType, setViewType] = useState("table");
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isEditing, setIsEditing] = useState(false);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        "https://vpandit.caxis.ca/public/api/users"
      );

      // Filter users with Role_Id equal to "3"
      const filteredUsers = response.data.filter(
        (user) => user.role.Role_Id === 3
      );

      setUsers(filteredUsers); // Set only the filtered users
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleAddUserClick = () => {
    setIsEditing(false);
    setViewType("addUser");
    setSelectedUser(null);
    fetchData();
  };

  const handleEditUser = (user) => {
    setIsEditing(true);
    setSelectedUser(user);
    setViewType("addUser");
    fetchData();
  };

  const handleDeleteUser = (userId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to recover this user!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`https://vpandit.caxis.ca/public/api/users/${userId}`)
          .then(() => {
            setUsers(users.filter((user) => user.id !== userId));
            fetchData();
            Swal.fire("Deleted!", "The user has been deleted.", "success");
          })
          .catch((error) => {
            console.error("Error deleting user", error);
            Swal.fire("Error", "There was an issue deleting the user", "error");
          });
      }
    });
  };

  const handleStatusToggle = async (user) => {
    try {
      const updatedStatus = user.U_Status === "1" ? "0" : "1";

      await axios.put(`https://vpandit.caxis.ca/public/api/users/${user.U_Id}`, {
        ...user,
        U_Status: updatedStatus,
      });

      setUsers(
        users.map((u) =>
          u.U_Id === user.U_Id ? { ...u, U_Status: updatedStatus } : u
        )
      );

      Swal.fire(
        "Updated!",
        `User status has been updated to ${
          updatedStatus === "1" ? "Active" : "Inactive"
        }.`,
        "success"
      );
    } catch (error) {
      console.error("Error updating user status", error);
      Swal.fire("Error", "Unable to update user status.", "error");
    }
  };

  const handleCloseForm = () => {
    setViewType("table"); // Close the AddUser form and return to table view
    fetchData();
  };

  return (
    <div className="min-h-screen">
      {/* Header */}
      <div className="flex items-center justify-between mb-6">
        <div className="flex items-center space-x-4">
          <h1 className="text-2xl font-bold pt-2">User Management</h1>
        </div>
        <div className="flex items-center space-x-4">
          <div className="relative">
            <input
              type="text"
              placeholder="Search"
              className="text-black px-4 py-2 pr-10 rounded focus:outline-none focus:ring-2 focus:ring-orange-600"
            />
            <svg
              className="w-5 h-5 absolute right-3 top-2.5 text-black"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              />
            </svg>
          </div>

          <div
            onClick={handleAddUserClick}
            className="flex items-center bg-orange-500 text-white px-4 py-2 rounded-full shadow-lg hover:bg-orange-600 cursor-pointer font-bold"
          >
            Add User
          </div>
        </div>
      </div>

      {/* Conditional rendering */}
      {viewType === "table" ? (
        // Table
        <div className="rounded-lg overflow-hidden">
          <table className="w-full bg-white">
            <thead>
              <tr className="border-b border-gray-700">
                <th className="px-6 py-3 text-left text-xs font-medium text-black uppercase tracking-wider">
                  Name
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-black uppercase tracking-wider">
                  Email
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-black uppercase tracking-wider">
                  Gender
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-black uppercase tracking-wider">
                  Mobile Number
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-black uppercase tracking-wider">
                  Date Of Birth
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-black uppercase tracking-wider">
                  Status
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-black uppercase tracking-wider">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-700">
              {users.length === 0 ? (
                <tr>
                  <td
                    colSpan="7"
                    className="px-6 py-4 text-center text-gray-500"
                  >
                    No data available
                  </td>
                </tr>
              ) : (
                users.map((user) => (
                  <tr key={user.U_Id} className="hover:bg-gray-50">
                    <td className="px-6 py-4">
                      {user.U_Name || "Not Provided"}
                    </td>
                    <td className="px-6 py-4">
                      {user.U_Email || "Not Provided"}
                    </td>
                    <td className="px-6 py-4">
                      {user.U_Gender || "Not Provided"}
                    </td>
                    <td className="px-6 py-4">
                      {user.U_Mobile || "Not Provided"}
                    </td>
                    <td className="px-6 py-4">
                      {user.U_DOB || "Not Provided"}
                    </td>
                    <td className="px-6 py-4">
                      <span
                        className={`px-2 py-1 text-xs font-bold rounded-full ${
                          user.U_Status === "1"
                            ? "bg-green-100 text-green-700"
                            : "bg-red-100 text-red-700"
                        }`}
                      >
                        {user.U_Status === "1" ? "Active" : "Inactive"}
                      </span>
                    </td>
                    <td className="px-6 py-4">
                      <div className="flex space-x-3">
                        <FaEdit
                          className="text-green-600 cursor-pointer"
                          onClick={() => handleEditUser(user)}
                        />
                        <FaTrash
                          className="text-red-600 cursor-pointer"
                          onClick={() => handleDeleteUser(user.U_Id)}
                        />
                        {user.U_Status === "1" ? (
                          <FaBan
                            className="text-orange-600 cursor-pointer"
                            onClick={() => handleStatusToggle(user)}
                          />
                        ) : (
                          <FaCheck
                            className="text-blue-600 cursor-pointer"
                            onClick={() => handleStatusToggle(user)}
                          />
                        )}
                      </div>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      ) : (
        <AddUser
          onClose={handleCloseForm}
          setUsers={setUsers}
          users={users}
          selectedUser={selectedUser}
          isEditing={isEditing}
        />
      )}
    </div>
  );
};

export default UserMaster;
