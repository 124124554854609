import { UserPlus } from "lucide-react";
import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import CommongPreloader from "../CommongPreloader/CommongPreloader";
import { FaEdit, FaTrash } from "react-icons/fa";
import Add_Mobile_Number from "./Add_Mobile_Number";
import { BsSortNumericUp } from "react-icons/bs";

const Mobile_Number = () => {
  const [numerologies, setNumerologies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showAddNumerology, setShowAddNumerology] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [isEditing, setIsEditing] = useState(false);

  // Fetch Data from the API
  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        "https://vpandit.caxis.ca/public/api/mobile-numerologies"
      );
      if (Array.isArray(response.data)) {
        setNumerologies(response.data);
      } else {
        throw new Error("Invalid data format received");
      }
    } catch (err) {
      setError("Failed to fetch data. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Handle Add Button Click
  const handleAddClick = () => {
    setSelectedData(null);
    setIsEditing(false);
    setShowAddNumerology(true);
  };

  // Handle Edit Button Click
  const handleEditClick = (data) => {
    setSelectedData(data);
    setIsEditing(true);
    setShowAddNumerology(true);
  };

  // Handle Delete Button Click
  const handleDeleteClick = async (id) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 1000,
      timerProgressBar: true,
    });

    try {
      await axios.delete(
        `https://vpandit.caxis.ca/public/api/mobile-numerologies/${id}`
      );
      setNumerologies(numerologies.filter((item) => item.Mn_Num !== id));
      fetchData();
      // Show success toast
      Toast.fire({
        icon: "success",
        title: "Numerology deleted successfully",
      });
    } catch (err) {
      console.error("Error deleting numerology:", err);

      // Show error toast
      Toast.fire({
        icon: "error",
        title: "Failed to delete numerology. Please try again.",
      });
    }
  };

  return (
    <div className="">
      {/* Header Section */}
      <div className="flex justify-between items-center mb-3">
        <h1 className="text-2xl font-bold">Mobile Number Numerology</h1>
        <div
          onClick={handleAddClick}
          className="cursor-pointer bg-orange-500 hover:bg-orange-600 text-white px-4 py-2 rounded-3xl flex items-center"
        >
          <BsSortNumericUp className="mr-2 h-4 w-4" />
          Add Numerology
        </div>
      </div>

      {/* Main Content */}
      {loading ? (
        <CommongPreloader />
      ) : error ? (
        <p className="text-red-500 text-center">{error}</p>
      ) : showAddNumerology ? (
        <Add_Mobile_Number
          onClose={() => setShowAddNumerology(false)}
          selectedData={selectedData}
          fetchData={fetchData}
          isEditing={isEditing}
        />
      ) : (
        <div className="rounded-lg overflow-hidden">
          {numerologies.length === 0 ? (
            <p className="p-4 text-center text-gray-500">No Data Found</p>
          ) : (
            <table className="w-full bg-white">
              <thead>
                <tr className="border-b border-gray-700">
                  <th className="px-6 py-3 text-left text-xs font-medium text-black uppercase tracking-wider">
                    Value
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-black uppercase tracking-wider">
                    Pros
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-black uppercase tracking-wider">
                    Cons
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-black uppercase tracking-wider">
                    Remedies
                  </th>

                  <th className="px-6 py-3 text-left text-xs font-medium text-black uppercase tracking-wider">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-700">
                {numerologies.map((item) => (
                  <tr key={item.Mn_Num} className="hover:bg-gray-50">
                    <td className="px-6 py-4">
                      {item.Value ? ( // Check if item.Value exists and is truthy (not null, undefined, empty string, 0, or false)
                        item.Value
                      ) : (
                        <span className="text-gray-500">Not Provided</span>
                      )}
                    </td> 

                    <td className="px-6 py-4">
                      {item.Pros && item.Pros.length > 0 ? (
                        <ul>
                          {item.Pros.map((pro, idx) => (
                            <li key={idx}>{pro}</li>
                          ))}
                        </ul>
                      ) : (
                        <span className="text-gray-500">Not Provided</span>
                      )}
                    </td>

                    <td className="px-6 py-4">
                      {item.Cons && item.Cons.length > 0 ? (
                        <ul>
                          {item.Cons.map((con, idx) => (
                            <li key={idx}>{con}</li>
                          ))}
                        </ul>
                      ) : (
                        <span className="text-gray-500">Not Provided</span>
                      )}
                    </td>

                    <td className="px-6 py-4">
                      {item.Remedies && item.Remedies.length > 0 ? (
                        <ul>
                          {item.Remedies.map((remedy, idx) => (
                            <li key={idx}>{remedy}</li>
                          ))}
                        </ul>
                      ) : (
                        <span className="text-gray-500">Not Provided</span>
                      )}
                    </td>

                    <td className="px-6 py-4">
                      <div className="flex space-x-3">
                        <div className="text-black hover:text-black">
                          <FaEdit
                            className="text-green-600 cursor-pointer"
                            onClick={() => handleEditClick(item)}
                          />
                        </div>
                        <div className="text-black hover:text-black">
                          <FaTrash
                            className="text-red-600 cursor-pointer"
                            onClick={() => handleDeleteClick(item.Mn_Num)}
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      )}
    </div>
  );
};

export default Mobile_Number;
