"use client";

import { useEffect, useState } from "react";
import { PencilIcon } from "lucide-react";
import { FaCalendarAlt, FaHome, FaRupeeSign, FaUser } from "react-icons/fa";
import { Link, useNavigate, useParams } from "react-router-dom";
import My_Booking from "./My_Booking/My_Booking";
import { CgLogOut } from "react-icons/cg";
export default function UserProfile() {
  const [activeTab, setActiveTab] = useState("profile");
  const { id } = useParams();
  const [userData, setUserData] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({});

  const menuItems = [
    { id: "profile", label: "My Profile", icon: <FaUser /> },
    { id: "My Pooja Booking", label: "My Pooja", icon: <FaCalendarAlt /> },
    { id: "Logout", label: "Logout", icon: <CgLogOut /> },
  ];

  const navigate = useNavigate();
  const handleMenuClick = (id) => {
    if (id === "Logout") {
      // Remove items from localStorage
      localStorage.removeItem("role");
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      localStorage.removeItem("userID");

      // Redirect to the home page
      navigate("/");
    } else {
      setActiveTab(id);
    }
  };

  const fetchUserData = async () => {
    try {
      const response = await fetch(`https://vpandit.caxis.ca/public/api/users/${id}`);
      const data = await response.json();
      setUserData(data);
      setFormData({
        firstName: data.U_Name?.split(" ")[0] || "",
        lastName: data.U_Name?.split(" ")[1] || "",
        email: data.U_Email || "",
        phone: data.U_Mobile || "",
        gender: data.U_Gender || "",
      });
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, [id]);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSave = async () => {
    try {
      const response = await fetch(`https://vpandit.caxis.ca/public/api/users/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          U_Name: `${formData.firstName} ${formData.lastName}`,
          U_Email: formData.email,
          U_Mobile: formData.phone,
          U_Gender: formData.gender,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to update user data");
      }

      const updatedData = await response.json();
      setUserData(updatedData);
      setIsEditing(false);
      fetchUserData();
      alert("Profile updated successfully!");
    } catch (error) {
      console.error("Error updating user data:", error);
      alert("Failed to update profile. Please try again.");
    }
  };

  if (!userData) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="border-t-4 border-orange-500 border-solid w-16 h-16 rounded-full animate-spin"></div>
      </div>
    );
  }

  return (
    <div className="flex min-h-screen bg-gray-50">
      <div className="w-64 bg-white shadow-sm border border-gray-200 rounded-md">
        <div className="p-4 border-b">
          <div className="flex items-center mb-2">
            <div className="w-10 h-10 rounded-full bg-gray-900 flex items-center justify-center">
              <img
                src="https://cdn-icons-png.flaticon.com/512/149/149071.png"
                alt="User Avatar"
                className="rounded-full"
              />
            </div>
            <div className="ml-4">
              <h2 className="text-lg font-semibold text-gray-800">
                {userData?.U_Name || "Unknown User"}
              </h2>
              <p className="text-sm text-gray-500">User ID #{id}</p>
            </div>
          </div>
        </div>

        <nav className="p-4">
          {menuItems.map((item) => (
            <div
              key={item.id}
              onClick={() => handleMenuClick(item.id)}
              className={`w-full text-left px-4 py-2 rounded-lg mb-1 transition-colors ${
                activeTab === item.id
                  ? "bg-orange-600 text-white cursor-pointer"
                  : "text-gray-600 hover:bg-gray-50 cursor-pointer"
              }`}
            >
              <div className="flex items-center">
                <span className="mr-3">{item.icon}</span>
                <span>{item.label}</span>
              </div>
            </div>
          ))}
        </nav>
      </div>

      {activeTab === "profile" && (
        <div className="flex-1 p-4">
          <div className="flex items-center justify-between mb-8">
            {/* Profile Heading */}
            <h1 className="text-2xl md:text-3xl font-bold text-gray-900">
              My Profile
            </h1>

            {/* Right Aligned Links */}
            <div className="flex items-center gap-1">
              {/* Home Icon */}

              {/* Upgrade To Pandit Button */}
              <Link
                to={"/pandit_register"}
                className="flex items-center px-4 py-2 bg-orange-500 text-white font-semibold rounded-3xl hover:bg-orange-600 transition cursor-pointer"
              >
                <FaRupeeSign className="mr-2 w-5 h-5" />
                Upgrade To Pandit
              </Link>

              {/* Earn as Supplier Button */}
              <Link
                to={"/supplier_register"}
                className="flex items-center px-4 py-2 bg-blue-500 text-white font-semibold rounded-3xl hover:bg-blue-600 transition cursor-pointer"
              >
                <FaRupeeSign className="mr-2 w-5 h-5" />
                Earn as Supplier
              </Link>

              {/* Home Icon */}
              <Link
                to="/"
                className="flex items-center px-4 py-2 font-semibold rounded-3xl hover:bg-gray-300 transition cursor-pointer"
              >
                <FaHome className="w-5 h-5" />
              </Link>
            </div>
          </div>

          <div className="bg-white rounded-lg p-6 shadow-sm mb-6 border border-gray-300">
            <div className="flex items-center justify-between ">
              <div className="flex items-center gap-4">
                <img
                  src={`https://cdn-icons-png.flaticon.com/512/149/149071.png`}
                  alt="Profile"
                  className="w-16 h-16 rounded-full"
                />
                <div>
                  <h2 className="text-xl font-semibold">
                    {userData.U_Name
                      ? userData.U_Name.split(" ")[0]
                      : "Not Provided"}
                  </h2>
                </div>
              </div>
              <div className="flex items-center gap-2 text-gray-500 hover:text-gray-700">
                <PencilIcon className="w-4 h-4" />
                <span>Edit</span>
              </div>
            </div>
          </div>

          <div className="bg-white rounded-lg p-6 shadow-sm mb-6 border border-gray-300">
            <div className="flex justify-between items-center mb-6">
              <h3 className="text-lg font-semibold">Personal Information</h3>
              {!isEditing && (
                <div
                  className="flex items-center gap-2 text-gray-500 hover:text-gray-700 cursor-pointer"
                  onClick={handleEditClick}
                >
                  <PencilIcon className="w-4 h-4" />
                  <span>Edit</span>
                </div>
              )}
            </div>

            <div className="grid grid-cols-2 gap-6">
              <div>
                <label className="block text-sm text-gray-500 mb-1">
                  First Name
                </label>
                {isEditing ? (
                  <input
                    type="text"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleInputChange}
                    className="w-full p-2 border rounded"
                  />
                ) : (
                  <p>{formData.firstName || "Not Provided"}</p>
                )}
              </div>
              <div>
                <label className="block text-sm text-gray-500 mb-1">
                  Last Name
                </label>
                {isEditing ? (
                  <input
                    type="text"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleInputChange}
                    className="w-full p-2 border rounded"
                  />
                ) : (
                  <p>{formData.lastName || "Not Provided"}</p>
                )}
              </div>
              <div>
                <label className="block text-sm text-gray-500 mb-1">
                  Email
                </label>
                {isEditing ? (
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    className="w-full p-2 border rounded"
                  />
                ) : (
                  <p>{formData.email || "Not Provided"}</p>
                )}
              </div>
              <div>
                <label className="block text-sm text-gray-500 mb-1">
                  Phone
                </label>
                {isEditing ? (
                  <input
                    type="text"
                    name="phone"
                    value={formData.phone}
                    onChange={handleInputChange}
                    className="w-full p-2 border rounded"
                  />
                ) : (
                  <p>{formData.phone || "Not Provided"}</p>
                )}
              </div>
              <div className="col-span-2">
                <label className="block text-sm text-gray-500 mb-1">
                  Gender
                </label>
                {isEditing ? (
                  <select
                    name="gender"
                    value={formData.gender}
                    onChange={handleInputChange}
                    className="w-full p-2 border rounded"
                  >
                    <option value="M">Male</option>
                    <option value="F">Female</option>
                  </select>
                ) : (
                  <p>{formData.gender === "M" ? "Male" : "Female"}</p>
                )}
              </div>
            </div>

            {isEditing && (
              <div className="mt-4 flex">
                <div
                  onClick={handleSave}
                  className="px-4 py-2 bg-orange-500 text-white rounded mr-2 cursor-pointer"
                >
                  Save
                </div>
                <div
                  onClick={() => setIsEditing(false)}
                  className="px-4 py-2 bg-gray-500 text-white rounded cursor-pointer"
                >
                  Cancel
                </div>
              </div>
            )}
          </div>
        </div>
      )}

      {activeTab === "My Pooja Booking" && <My_Booking id={id} />}
    </div>
  );
}
