import React, { useEffect, useState } from "react";
import Upcoming_Pooja from "./Upcoming_Pooja/Upcoming_Pooja";
import { FaHome, FaCalendarAlt } from "react-icons/fa";
import { CgProfile } from "react-icons/cg";
import { RiLogoutBoxLine } from "react-icons/ri";
import Profile from "./Profile/Profile";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { SiAlchemy } from "react-icons/si";
import My_Pooja from "./My_Pooja/My_Pooja";
const Pandit_Crm = () => {
  const [activeLink, setActiveLink] = useState("Dashboard");
  const navigate = useNavigate();
  const menuItems = [
    { name: "Dashboard", icon: <FaHome /> },
    { name: "Upcoming Pooja", icon: <FaCalendarAlt /> },
    { name: "Profile", icon: <CgProfile /> },
    { name: "My Pooja", icon: <SiAlchemy /> },
    { name: "Logout", icon: <RiLogoutBoxLine /> },
  ];

  const { id } = useParams();
  const [userData, setUserData] = useState(null);

  const fetchUserData = async () => {
    const response = await axios.get(
      `https://vpandit.caxis.ca/public/api/users/${id}`
    );
    setUserData(response.data); 
  };

  useEffect(() => {
    if (id) {
      fetchUserData();
    }
  }, []);

  const handleMenuClick = async (name) => {
    setActiveLink(name);
    await fetchUserData();
    if (name === "Logout") {
      console.log("Logging out...");

      // Clear specific keys from localStorage
      const keysToRemove = [
        "numerologyData",
        "regsiterpandit",
        "role",
        "token",
        "userID",
        "user",
      ];
      keysToRemove.forEach((key) => localStorage.removeItem(key));

      navigate("/"); // Navigate to the home page
    }
  };

  return (
    <div className="flex h-screen bg-gray-100">
      {/* Sidebar */}
      <div className="w-64 bg-orange-500 text-white p-3">
        <div className="mb-8">
          <div className="w-20 h-20 rounded-full bg-white mx-auto mb-4">
            <img
              src="https://image.shutterstock.com/image-photo/indian-male-pandit-smiling-age-260nw-2513079627.jpg"
              alt="Profile"
              className="rounded-full"
            />
          </div>
          <h2 className="text-center font-semibold">{userData?.U_Name}</h2>
          <p className="text-center text-sm text-orange-200">harry@gmail.com</p>
          <p className="text-center text-sm text-orange-200">
            Pandit ID: {userData?.U_Id}
          </p>
        </div>
        <nav>
          <ul className="space-y-2">
            {menuItems.map(({ name, icon }) => (
              <li
                key={name}
                onClick={() => handleMenuClick(name)}
                className={`p-2 rounded cursor-pointer flex items-center gap-2 ${
                  name === activeLink
                    ? "bg-white text-orange-500"
                    : "hover:bg-orange-700"
                }`}
              >
                {icon} {/* Add the icon */}
                <span>{name}</span> {/* Add the menu name */}
              </li>
            ))}
          </ul>
        </nav>
      </div>

      {/* Main Content */}
      {activeLink === "Dashboard" && (
        <div className="flex-1 p-4 overflow-auto">
          <div className="flex justify-between items-center mb-8">
            <h1 className="text-3xl font-bold">Hello , {userData?.U_Name}</h1>
            <div className="flex items-center">
              <img
                src="https://image.shutterstock.com/image-photo/indian-male-pandit-smiling-age-260nw-2513079627.jpg"
                alt="User"
                className="w-10 h-10 rounded-full mr-2"
              />
              <span className="mr-4">{userData?.U_Name}</span>
              <div className="text-gray-600"></div>
            </div>
          </div>

          <p className="text-gray-600 mb-8">
            Welcome to your personalized Pandit Dashboard. Easily manage your
            schedules, track upcoming events, and connect with devotees seeking
            guidance. This platform simplifies your daily tasks, helping you
            focus on what truly matters.
          </p>

          <div className="grid grid-cols-3 gap-6 mb-8">
            <div className="bg-white p-6 rounded-lg shadow">
              <div className="flex justify-between items-center mb-4">
                  <span className="text-4xl font-bold text-orange-500">83%</span>
                <svg
                  className="w-8 h-8 text-orange-500"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                  />
                </svg>
              </div>
              <p className="text-gray-600">Your profile is 83% complete</p>
              <a href="#" className="text-orange-500 hover:underline">
                Update Profile
              </a>
            </div>
            <div className="bg-white p-6 rounded-lg shadow">
              <div className="flex justify-between items-center mb-4">
                <span className="text-4xl font-bold text-green-600">
                  Verify
                </span>
                <svg
                  className="w-8 h-8 text-green-600"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </div>
              <p className="text-gray-600">You need to address verify</p>
              <a href="#" className="text-orange-500 hover:underline">
                Verification
              </a>
            </div>
            <div className="bg-white p-6 rounded-lg shadow">
              <div className="flex justify-between items-center mb-4">
                <span className="text-4xl font-bold text-purple-600">1250</span>
                <svg
                  className="w-8 h-8 text-purple-600"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"
                  />
                </svg>
              </div>
              <p className="text-gray-600">User ID: 12345</p>
              <p className="text-gray-600">7 Dec 2023</p>
            </div>
          </div>

          <div className="grid grid-cols-2 gap-6">
            <div className="bg-white p-6 rounded-lg shadow">
              <h2 className="text-xl font-semibold mb-4">Notifications</h2>
              <ul className="space-y-4">
                {[
                  "You have a new request for performing a Puja on January 15th. Click to view details.",
                  "Your schedule for the upcoming Griha Pravesh ceremony has been confirmed.",
                  "A devotee has sent you a query regarding an auspicious date for marriage.",
                  "Your monthly payment summary has been generated. Click to view.",
                ].map((notification, index) => (
                  <li key={index} className="flex items-start">
                    <div className="w-2 h-2 bg-orange-500 rounded-full mt-2 mr-2 flex-shrink-0"></div>
                    <p className="text-sm text-gray-600">{notification}</p>
                  </li>
                ))}
              </ul>
            </div>

            <div className="bg-white p-6 rounded-lg shadow">
              <h2 className="text-xl font-semibold mb-4">Nearby Pooja</h2>
              <ul className="space-y-4">
                {[
                  "Dhaka - Puja ceremony for a new home (Jan 15th).",
                  "Mymensingh - Wedding ceremony preparations (Jan 20th).",
                  "Puerto Rico - Temple inauguration event (Feb 5th).",
                  "Miami - Vastu consultation for a new office (Feb 10th).",
                ].map((job, index) => (
                  <li key={index} className="flex items-start">
                    <div className="w-2 h-2 bg-green-500 rounded-full mt-2 mr-2 flex-shrink-0"></div>
                    <p className="text-sm text-gray-600">{job}</p>
                  </li>
                ))}
              </ul>
              <div className="mt-6 text-orange-500 text-sm font-medium hover:underline cursor-pointer">
                View Full Report
              </div>
            </div>
          </div>

          <div className="mt-8">
            <h2 className="text-xl font-semibold mb-4">
              Upcoming Rituals & Ceremonies
            </h2>
            <div className="grid grid-cols-2 gap-6">
              {[1, 2].map((_, index) => (
                <div
                  key={index}
                  className="bg-white p-4 rounded-lg shadow flex items-center"
                >
                  <div>
                    <p className="font-semibold">Ritual ID: 78392</p>
                    <p className="text-sm text-gray-600">Griha Pravesh Puja</p>
                    <p className="text-sm text-gray-600">For New Home</p>
                    <p className="text-sm text-gray-600">Location: Dhaka</p>
                    <div className="flex items-center mt-2">
                      <svg
                        className="w-4 h-4 text-orange-500 mr-1"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                      <span className="text-sm text-gray-600">9:00 AM</span>
                      <span className="text-sm text-gray-600 ml-2">
                        Saturday
                      </span>
                      <span className="text-sm text-gray-600 ml-2">
                        Jan 12, 2025
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      {activeLink === "Upcoming Pooja" && (
        <div className="flex-1 p-4 overflow-auto">
          <Upcoming_Pooja id={id} />
        </div>
      )}

      {activeLink === "Profile" && (
        <div className="flex-1 p-4 overflow-auto bg-gray-50">
          <Profile id={id} />
        </div>
      )}

      {activeLink === "My Pooja" && (
        <div className="flex-1 p-4 overflow-auto bg-gray-50">
          <My_Pooja userData={userData} />
        </div>
      )}
    </div>
  );
};

export default Pandit_Crm;
