import React, { useEffect, useState } from "react";

const Supplier_List = ({ materai, selectedMaterials }) => {
  const [filteredData, setFilteredData] = useState([]);
  const [total, setTotal] = useState(0); // State to hold the total amount

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://vpandit.caxis.ca/public/api/vendors');
        const data = await response.json();

        // Parse the materai items and filter based on the parsed name
        const parsedMaterai = materai.map((item) => JSON.parse(item));

        // Filter vendors based on the product name matching any of the parsed materai items
        const filteredVendors = data.vendors.reduce((acc, vendor) => {
          // Filter the products based on the parsed materai names
          const matchingProducts = Object.values(vendor.Product).filter(product =>
            parsedMaterai.some(parsedItem => parsedItem.name === product.name) // Compare with parsed name
          );

          // If there are matching products, add to filteredVendors
          if (matchingProducts.length > 0) {
            const vendorTotal = matchingProducts.reduce(
              (sum, product) => sum + parseFloat(product.price) * parseFloat(product.quantity), 0
            );

            acc.push({
              vendorName: vendor.Bussi_Name,
              products: matchingProducts.map((product) => ({
                name: product.name,
                qty: product.quantity,
                price: parseFloat(product.price), // Ensure price is treated as a number
                subtotal:
                  parseFloat(product.price) * parseFloat(product.quantity), // Calculate the subtotal
              })),
              vendorTotal // Add the total for this vendor
            });
          }
          return acc;
        }, []);

        setFilteredData(filteredVendors);

        // Calculate the total sum of all vendor totals
        const mainTotal = filteredVendors.reduce(
          (sum, vendor) => sum + vendor.vendorTotal, 0
        );
        setTotal(mainTotal); // Set the total sum
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [materai]);

  return (
    <div className="container mx-auto py-8 px-4">
      <div className="relative bg-gradient-to-r from-orange-600 to-orange-300 p-8 md:p-12">
        <div className="container mx-auto">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <div className="mb-8 md:mb-0">
              <h1 className="text-3xl md:text-4xl font-bold text-white mb-4">
                Connect With Our Suppliers
              </h1>
              <p className="text-xl text-white">
                Consult expert Pandits for your Pooja needs on VPandit
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Grid layout with col-grid-4 */}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mt-6">
        {filteredData.map((vendor, key) => {
          return (
            <div
              key={key}
              className="bg-white p-4 rounded-lg border border-gray-5 00 transition duration-300 flex flex-col justify-between h-full" // Flexbox added to push total to bottom
            >
              <div>
                <div className="flex items-center gap-2">
                  <h1 className="text-xl font-semibold text-gray-800">
                    {vendor.vendorName}
                  </h1>
                </div>
                {/* Display each product with its quantity, price, and subtotal */}
                {vendor.products.map((product, index) => (
                  <div key={index} className="my-2">
                    <p>
                      {product.name} - {product.qty} * {product.price}
                    </p>
                  </div>
                ))}
              </div>

              {/* Display Vendor Total at the bottom */}
              <div className="mt-4 text-right font-semibold text-lg bg-orange-500 text-white rounded-lg p-2 cursor-pointer">
                Total: {vendor.vendorTotal}
              </div>
            </div>
          );
        })}
      </div>

      {/* Display Main Total at the bottom */}
    </div>
  );
};

export default Supplier_List;
