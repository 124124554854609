import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2"; // Import SweetAlert2

export default function Login() {
  // State for form fields and error messages
  const [formData, setFormData] = useState({
    Mobile: "",
    password: "",
  });

  const [errors, setErrors] = useState({
    Mobile: "",
    password: "",
  });

  const navigate = useNavigate();

  // Handle input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Validation function
  const validateForm = () => {
    let valid = true;
    const newErrors = {};

    // Mobile Validation
    const mobileRegex = /^[0-9]{10}$/; // Matches exactly 10 digits
    if (!formData.Mobile || !mobileRegex.test(formData.Mobile)) {
      newErrors.Mobile = "Mobile number must be exactly 10 digits.";
      valid = false;
    }

    // Password Validation
    if (!formData.password || formData.password.length < 8) {
      newErrors.password = "Password must be at least 8 characters long.";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      // Create payload with the new key names
      const payload = {
        U_Mobile: formData.Mobile,
        U_Password: formData.password,
      };

      // Show the SweetAlert preloader
      const swalInstance = Swal.fire({
        title: "Logging in...",
        text: "Please wait while we process your login request.",
        icon: "info",
        showConfirmButton: false,
        willOpen: () => {
          Swal.showLoading(); // Show the loading spinner
        },
      });

      try {
        const response = await fetch(
          `https://vpandit.caxis.ca/public/api/login`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
          }
        );

        console.log(response);

        if (response.ok) {
          const data = await response.json();
          console.log("Login successful:", data);

          // Save data in localStorage
          localStorage.setItem("token", data.token);
          localStorage.setItem("role", data.data.Role_Id);
          localStorage.setItem("userID", data.data.U_Id);
          localStorage.setItem("user", JSON.stringify(data.data));

          console.log(JSON.stringify(data.data));

          // Close the preloader before showing the success alert
          swalInstance.close();

          // Show success alert
          if (data.data.role.Role_Id === 1) {
            navigate(`/ultimate-admin/authority/${data.token}`); // Redirect to /ultimate-admin/authority/ if Role_Id is 1
          } else {
            navigate("/"); // Redirect to home page for other roles
          }
        } else {
          const errorData = await response.json();
          console.log("Error logging in:", errorData);

          // Close the preloader before showing the error alert
          swalInstance.close();

          // Show error alert
          Swal.fire({
            icon: "error",
            title: "Login Failed",
            text: errorData.message || "An error occurred during login.",
            confirmButtonText: "Try Again",
          });
        }
      } catch (error) {
        console.error("Error occurred while submitting the form:", error);

        // Close the preloader before showing the network error alert
        swalInstance.close();

        // Show network error alert
        Swal.fire({
          icon: "error",
          title: "Network Error",
          text: "Please check your internet connection and try again.",
          confirmButtonText: "Close",
        });
      }
    }
  };

  return (
    <div className="h-auto container mx-auto my-16 px-4">
      {/* Container for Left and Right Sections */}
      <div className="flex flex-col lg:flex-row items-center lg:items-start">
        {/* Left Section - Astrology Introduction */}
        <div className="flex flex-col bg-gradient-to-r w-full lg:w-1/2 mb-6 lg:mb-0">
          <h2 className="text-3xl font-bold mb-6   lg:text-left">
            Welcome Back to the{" "}
            <span className="text-orange-600">Astrology World</span>
          </h2>
          <p className="text-lg mb-6   lg:text-left">
            Login to explore profound insights into your life path, love
            compatibility, career forecast, and more. Join our community for
            personalized horoscopes, tarot readings, astrological reports, and
            spiritual guidance that can help you navigate your journey and make
            informed decisions.
          </p>
          <div className="  lg:text-left mb-6">
            <p className="text-sm">
              Whether you're a beginner or an experienced seeker, our platform
              offers something for everyone. Start your spiritual journey today!
            </p>
          </div>
        </div>

        {/* Right Section - Login Form */}
        <div className="flex items-center w-full lg:w-1/2 md:pl-24 md:pt-0 pt-10">
          <div className="w-full max-w-md space-y-8">
            <div className="  lg:text-left">
              <h2 className="text-2xl font-semibold mb-2">Login</h2>
            </div>

            <form className="space-y-6" onSubmit={handleSubmit}>
              <div className="space-y-4">
                <div>
                  <label className="text-xs uppercase text-gray-500 mb-1 block">
                    Mobile Number
                  </label>
                  <input
                    type="tel"
                    name="Mobile"
                    value={formData.Mobile}
                    onChange={(e) => {
                      const { value } = e.target;
                      if (value.length <= 10 && /^[0-9]*$/.test(value)) {
                        handleInputChange(e);
                      }
                    }}
                    maxLength={10} // Prevents entering more than 10 digits
                    className="w-full border-b-2 border-gray-300 focus:border-orange-600 focus:outline-none px-0 py-2 rounded-sm text-gray-800 placeholder-gray-400 focus:ring-0"
                    placeholder="Enter your Mobile"
                  />
                  {errors.Mobile && (
                    <p className="text-red-500 text-xs">{errors.Mobile}</p>
                  )}
                </div>

                <div>
                  <label className="text-xs uppercase text-gray-500 mb-1 block">
                    Password
                  </label>
                  <input
                    type="password"
                    name="password"
                    value={formData.password}
                    onChange={handleInputChange}
                    className="w-full border-b-2 border-gray-300 focus:border-orange-600 focus:outline-none px-0 py-2 rounded-sm text-gray-800 placeholder-gray-400 focus:ring-0"
                    placeholder="Enter your password"
                  />
                  {errors.password && (
                    <p className="text-red-500 text-xs">{errors.password}</p>
                  )}
                </div>
              </div>

              {/* Submit Button */}
              <button
                type="submit"
                className="w-full bg-orange-600 hover:bg-orange-700 text-white rounded-full py-2"
              >
                LOGIN
              </button>
            </form>

            {/* Redirect to Register page if not registered */}
            <div className="text-center mt-4">
              <p className="text-sm text-gray-500">
                Don't have an account?{" "}
                <Link
                  to="/register"
                  className="text-orange-600 hover:underline"
                >
                  Register here
                </Link>
              </p>
              <p className="text-sm text-gray-500 mt-2">
                Forgot your password?{" "}
                <Link
                  to="/forgot-password"
                  className="text-blue-600 hover:underline"
                >
                  Reset it here
                </Link>
              </p>
              <p className="text-xs text-gray-400 mt-4">
                Click on "Reset it here" to recover your account. You’ll receive
                an Mobile with instructions to reset your password. If you don’t
                see the Mobile, check your spam folder or contact support.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
