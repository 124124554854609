import React, { useEffect, useRef, useState } from "react";
import {
  ChevronDown,
  Edit2,
  GripVertical,
  LayoutGrid,
  Plus,
  Trash2,
  X,
} from "lucide-react";

import axios from "axios";
import Swal from "sweetalert2";
import JoditEditor from "jodit-react";

function Customdiv({
  children,
  className = "",
  variant = "outline",
  ...props
}) {
  const baseStyle =
    "inline-flex items-center justify-center rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none ring-offset-background";
  const variantStyles = {
    outline: "border border-input hover:bg-accent hover:text-accent-foreground",
    ghost: "hover:bg-accent hover:text-accent-foreground",
  };

  return (
    <div
      className={`${baseStyle} ${variantStyles[variant]} ${className} cursor-pointer`}
      {...props}
    >
      {children}
    </div>
  );
}

function CustomCard({ children, className = "", ...props }) {
  return (
    <div
      className={`bg-white rounded-lg border border-gray-200 shadow-sm ${className}`}
      {...props}
    >
      {children}
    </div>
  );
}

function Modal({ show, onClose, children }) {
  return (
    <div
      className={`fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50 transition-opacity ${
        show ? "opacity-100" : "opacity-0 pointer-events-none"
      }`}
    >
      <div
        className={`bg-white rounded-lg shadow-lg w-full max-w-screen-lg p-6 mx-4 transition-transform relative ${
          show ? "scale-100" : "scale-95"
        }`}
        style={{
          maxHeight: "80vh", // Prevent modal from exceeding viewport height
          overflowY: "auto", // Enable scrolling inside the modal
        }}
      >
        {/* Close Button */}
        <div
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 cursor-pointer"
          onClick={onClose}
        >
          <X className="h-5 w-5" />
        </div>

        {/* Modal Content */}
        <div className="overflow-y-auto" style={{ maxHeight: "70vh" }}>
          {children}
        </div>
      </div>
    </div>
  );
}

export default function Add_Circulam({ data }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLessonModalOpen, setIsLessonModalOpen] = useState(false);
  const [isEditSectionModalOpen, setIsEditSectionModalOpen] = useState(false);
  const [isEditLessonModalOpen, setIsEditLessonModalOpen] = useState(false); // Add state for editing lesson
  const [sections, setSections] = useState([]); // Empty initially, will be set from API
  const [loading, setLoading] = useState(false);

  const [newSectionTitle, setNewSectionTitle] = useState("");
  const [newLesson, setNewLesson] = useState({
    title: "",
    sectionId: "",
    content: "",
    summary: "",
  });

  useEffect(() => {
    console.log(data);
  }, []);

  const [sectionToEdit, setSectionToEdit] = useState({ id: null, title: "" });
  const [lessonToEdit, setLessonToEdit] = useState(null); // Track lesson being edited

  // Fetch curriculum sections from the API
  const fetchSections = async () => {
    try {
      const response = await fetch(
        "https://vpandit.caxis.ca/public/api/curriculum-sections"
      );

      const apiData = await response.json();
      console.log("API Data:", apiData);

      // Ensure data.SubCat_Id is compared as a string
      const filteredSections = apiData.filter(
        (section) => String(section.SubCat_Id) === String(data.SubCat_Id)
      );

      console.log("Filtered Sections:", filteredSections);

      setSections(filteredSections); // Update state with filtered data
    } catch (error) {
      console.error("Error fetching curriculum sections:", error);
    }
};


const editorConfig = {
  height: 300, // Set height in pixels (adjust as needed)
  minHeight: 300, // Minimum height to prevent collapsing
  maxHeight: 500, // Maximum height (optional)
};

  useEffect(() => {
    fetchSections();
  }, [data.SubCat_Id]);

  // Add The New Lesson
  const handleAddLesson = async () => {
    if (!newLesson.title.trim() || !newLesson.sectionId) {
      Swal.fire({
        toast: true,
        position: "top-end",
        icon: "error",
        title: "Please provide a title and select a section",
        showConfirmButton: false,
        timer: 1500,
      });
      return;
    }

    try {
      // Make API request to save the new lesson
      const response = await fetch(
        "https://vpandit.caxis.ca/public/api/curriculum-lessons",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            CS_Id: newLesson.sectionId,
            Name: newLesson.title, // Assuming SubCat_Id comes from data
            Summary: newLesson.summary,
          }),
        }
      );

      if (response.ok) {
        // If the API request is successful
        const newLessonFromAPI = await response.json(); // Assuming the API returns the created lesson
        const updatedSections = sections.map((section) => {
          if (section.CS_Id === parseInt(newLesson.sectionId)) {
            return {
              ...section,
              lessons: [...section.lessons, newLessonFromAPI],
            };
          }
          return section;
        });

        setSections(updatedSections);
        setNewLesson({ title: "", sectionId: "", content: "", summary: "" });

        setIsModalOpen(false);
        setIsLessonModalOpen(false);
        setIsEditSectionModalOpen(false);
        setIsEditLessonModalOpen(false);

        Swal.fire({
          toast: true,
          position: "top-end",
          icon: "success",
          title: "Lesson added successfully",
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        // Handle API errors
        const errorResponse = await response.json();
        Swal.fire({
          toast: true,
          position: "top-end",
          icon: "error",
          title: errorResponse.message || "Failed to add lesson",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    } catch (error) {
      // Handle network or unexpected errors
      Swal.fire({
        toast: true,
        position: "top-end",
        icon: "error",
        title: "An unexpected error occurred",
        showConfirmButton: false,
        timer: 1500,
      });
      console.error("API Error:", error);
    }
  };
  // Add The New Session
  const HandleSessionAdd = async () => {
    try {
      const payload = {
        SubCat_Id: data?.SubCat_Id, // Assuming SubCat_Id comes from data
        Name: newSectionTitle,
      };

      const response = await axios.post(
        "https://vpandit.caxis.ca/public/api/curriculum-sections",
        payload,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 201 || response.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Section Added",
          text: "The new section has been successfully added.",
          timer: 3000,
          position: "top-right",
          showConfirmButton: false,
          toast: true,
        });
        setNewSectionTitle("");

        await fetchSections();
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong. Please try again.",
          timer: 3000,
          position: "top-right",
          showConfirmButton: false,
          toast: true,
        });
      }
    } catch (err) {
      if (err.response?.status === 409) {
        // Assuming 409 is returned for duplicates
        Swal.fire({
          icon: "warning",
          title: "Duplicate Entry",
          text: "This section already exists. Please try adding a different section.",
          timer: 3000,
          position: "top-right",
          showConfirmButton: false,
          toast: true,
        });
      } else {
        const errorMessage =
          err.response?.data?.message ||
          "Something went wrong. Please try again later.";
        Swal.fire({
          icon: "error",
          title: "Error",
          text: errorMessage,
          timer: 3000,
          position: "top-right",
          showConfirmButton: false,
          toast: true,
        });
      }
    } finally {
      setIsModalOpen(false);
      setIsLessonModalOpen(false);
      setIsEditSectionModalOpen(false);
      setIsEditLessonModalOpen(false);
    }
  };

  // Delete The Lession
  const handle_lesson_delete = async (id) => {
    // Show a confirmation dialog
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });

    // If confirmed, proceed with delete
    if (result.isConfirmed) {
      try {
        const response = await fetch(
          `https://vpandit.caxis.ca/public/api/curriculum-lessons/${id}`,
          {
            method: "DELETE",
          }
        );

        if (response.ok) {
          Swal.fire("Deleted!", "The lesson has been deleted.", "success");
          fetchSections();
        } else {
          Swal.fire("Error!", "Failed to delete the lesson.", "error");
        }
      } catch (error) {
        Swal.fire("Error!", "An error occurred while deleting.", "error");
        console.error("Delete Error:", error);
      }
    }
  };
  // Delete The Session
  const Handle_Delete_Lesson = async (id) => {
    // Show a confirmation dialog
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "This action cannot be undone!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });

    if (result.isConfirmed) {
      try {
        // Make the DELETE request
        const response = await fetch(
          `https://vpandit.caxis.ca/public/api/curriculum-sections/${id}`,
          {
            method: "DELETE",
          }
        );

        if (response.ok) {
          // Notify success
          Swal.fire(
            "Deleted!",
            "The section has been successfully deleted.",
            "success"
          );
          fetchSections();
        } else {
          // Notify failure
          Swal.fire(
            "Error!",
            "Failed to delete the section. Please try again.",
            "error"
          );
        }
      } catch (error) {
        // Handle network or other errors
        Swal.fire("Error!", "An unexpected error occurred.", "error");
        console.error("Delete Error:", error);
      }
    }
  };

  // Update The Lessions
  const handleUpdateLesson = async (e) => {
    e.preventDefault();

    if (!lessonToEdit?.id) {
      Swal.fire({
        icon: "error",
        title: "Lesson ID is required",
        text: "Please provide a valid lesson ID to update.",
        position: "top-right",
        showConfirmButton: false,
        timer: 3000,
      });
      return;
    }

    setLoading(true);

    try {
      const response = await axios.put(
        `https://vpandit.caxis.ca/public/api/curriculum-lessons/${lessonToEdit.id}`,
        {
          Name: lessonToEdit.title,
          Summary: lessonToEdit.summary,
        }
      );

      Swal.fire({
        icon: "success",
        title: "Lesson Updated!",
        text: "Your lesson has been updated successfully.",
        position: "top-right",
        showConfirmButton: false,
        timer: 3000,
      });

      fetchSections();

      // Optionally, close the modal after a successful update
      setIsEditLessonModalOpen(false);
    } catch (error) {
      console.error("Error updating lesson:", error);

      Swal.fire({
        icon: "error",
        title: "Update Failed",
        text:
          error?.response?.data?.message ||
          "Failed to update the lesson. Please try again.",
        position: "top-right",
        showConfirmButton: false,
        timer: 3000,
      });
    } finally {
      setLoading(false);
    }
  };

  // Update The Sections
  const handleUpdateSection = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const payload = {
        CS_Id: sectionToEdit.id,
        Name: sectionToEdit.title,
      };

      const response = await axios.put(
        `https://vpandit.caxis.ca/public/api/curriculum-sections/${sectionToEdit.id}`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200 || response.status === 204) {
        Swal.fire({
          icon: "success",
          title: "Section Updated",
          text: "The section has been successfully updated.",
          timer: 3000,
          position: "top-right",
          showConfirmButton: false,
          toast: true,
        });
        fetchSections();
        // Update UI or refresh data here
      } else {
        throw new Error("Failed to update section.");
      }
    } catch (err) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text:
          err.response?.data?.message ||
          "Something went wrong while updating the section.",
        timer: 3000,
        position: "top-right",
        showConfirmButton: false,
        toast: true,
      });
    } finally {
      setLoading(false);
      setIsEditSectionModalOpen(false);
    }
  };

  const editor = useRef();

  return (
    <div className="container">
      <div className="flex flex-wrap gap-2 mb-6">
        <Customdiv
          className="px-3 py-2 text-sm cursor-pointer"
          onClick={() => setIsModalOpen(true)}
        >
          <Plus className="h-4 w-4 mr-1" />
          Add section
        </Customdiv>
        <Customdiv
          className="px-3 py-2 text-sm cursor-pointer"
          onClick={() => setIsLessonModalOpen(true)}
        >
          <Plus className="h-4 w-4 mr-1" />
          Add lesson
        </Customdiv>
      </div>

      <div className="space-y-6">
        {sections.map((section, key) => (
          <CustomCard key={section.id} className="bg-slate-50">
            <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between p-4 pb-2">
              <h3 className="text-base font-medium mb-2 sm:mb-0">
                Section {key + 1}: {section.Name}
              </h3>
              <div className="flex flex-wrap gap-2">
                <Customdiv
                  variant="ghost"
                  className="px-3 py-2 text-sm"
                  onClick={() => {
                    setSectionToEdit({
                      id: section.CS_Id, // Replace with actual section object properties
                      title: section.Name, // Replace with actual section object properties
                    });
                    setIsEditSectionModalOpen(true);
                  }}
                >
                  <Edit2 className="h-4 w-4 mr-1" />
                  Edit section
                </Customdiv>
                <Customdiv
                  variant="ghost"
                  className="px-3 py-2 text-sm"
                  onClick={() => Handle_Delete_Lesson(section.CS_Id)}
                >
                  <Trash2 className="h-4 w-4 mr-1" />
                  Delete section
                </Customdiv>
              </div>
            </div>
            <div className="p-4">
              <div className="space-y-2">
                {section.lessons.map((lesson, key) => (
                  <div
                    key={lesson.CL_Id}
                    className="flex items-center justify-between bg-white p-3 rounded-md shadow-sm"
                  >
                    <div className="flex items-center gap-2">
                      <LayoutGrid className="h-4 w-4 text-gray-500" />
                      <span>
                        Lesson {key + 1}: {lesson.Name}
                      </span>
                    </div>
                    <div className="flex gap-2">
                      <Customdiv
                        variant="ghost"
                        className="p-2"
                        onClick={() => {
                          setLessonToEdit({
                            id: lesson.CL_Id,
                            title: lesson.Name,
                            sectionId: section.CL_Id,
                            summary: lesson.Summary || "",
                          });
                          setIsEditLessonModalOpen(true);
                        }}
                      >
                        <Edit2 className="h-4 w-4" />
                      </Customdiv>
                      <Customdiv variant="ghost" className="p-2">
                        <X
                          className="h-4 w-4"
                          onClick={() => handle_lesson_delete(lesson.CL_Id)}
                        />
                      </Customdiv>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </CustomCard>
        ))}
      </div>

      {/* Modal for adding section */}
      <Modal show={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <h2 className="text-xl font-semibold mb-4">Add New Section</h2>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            // handleAddSection();
          }}
        >
          <div className="mb-4">
            <label className="block text-sm font-medium mb-2">
              Section Title
            </label>
            <input
              type="text"
              value={newSectionTitle}
              onChange={(e) => setNewSectionTitle(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded-md "
              placeholder="Enter section title"
            />
          </div>
          <div className="flex justify-end gap-2">
            <div
              className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-md hover:bg-gray-200 cursor-pointer"
              onClick={() => setIsModalOpen(false)}
            >
              Cancel
            </div>
            <button
              type="submit"
              className="px-4 py-2 text-sm font-medium text-white bg-orange-600 rounded-md hover:bg-orange-700"
              onClick={HandleSessionAdd}
            >
              Save
            </button>
          </div>
        </form>
      </Modal>

      {/* Modal for adding lesson */}
      <Modal
        show={isLessonModalOpen}
        onClose={() => setIsLessonModalOpen(false)}
      >
        <h2 className="text-xl font-semibold mb-4">Add New Lesson</h2>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleAddLesson();
          }}
        >
          <div className="mb-4">
            <label className="block text-sm font-medium mb-2">
              Lesson Title
            </label>
            <input
              type="text"
              value={newLesson.title}
              onChange={(e) =>
                setNewLesson({ ...newLesson, title: e.target.value })
              }
              className="w-full px-3 py-2 border border-gray-300 rounded-md "
              placeholder="Enter lesson title"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-2">
              Select Section
            </label>
            <select
              value={newLesson.sectionId || ""}
              onChange={(e) =>
                setNewLesson({ ...newLesson, sectionId: e.target.value })
              }
              className="w-full px-3 py-2 border rounded-md"
              disabled={sections.length === 0} // Disable if no sections are available
            >
              <option value="" disabled>
                {sections.length === 0
                  ? "No sections available"
                  : "Choose section"}
              </option>
              {sections.map((section) => (
                <option key={section.CS_Id} value={section.CS_Id}>
                  {section.Name}
                </option>
              ))}
            </select>
          </div>

          <div className="mb-4">
            <label className="block text-sm font-medium mb-2">Summary</label>
            <JoditEditor
              ref={editor}
              value={newLesson.summary}
              tabIndex={1} // Tab index of textarea
              onBlur={(newContent) =>
                setNewLesson({ ...newLesson, summary: newContent })
              }
            />
          </div>

          <div className="flex justify-end gap-2">
            <div
              className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-md hover:bg-gray-200 cursor-pointer"
              onClick={() => setIsLessonModalOpen(false)}
            >
              Cancel
            </div>
            <button
              type="submit"
              className="px-4 py-2 text-sm font-medium text-white bg-orange-600 rounded-md hover:bg-orange-700"
              // onClick={HandleSession_in_LessonAdd}
            >
              Save
            </button>
          </div>
        </form>
      </Modal>

      {/* Modal for editing lesson */}
      <Modal
        show={isEditLessonModalOpen}
        onClose={() => setIsEditLessonModalOpen(false)}
      >
        <h2 className="text-xl font-semibold mb-4">Edit Lesson</h2>
        <form onSubmit={handleUpdateLesson}>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-2">
              Lesson Title
            </label>
            <input
              type="text"
              value={lessonToEdit?.title || ""}
              onChange={(e) =>
                setLessonToEdit({ ...lessonToEdit, title: e.target.value })
              }
              className="w-full px-3 py-2 border border-gray-300 rounded-md"
              placeholder="Enter lesson title"
            />
          </div>

          <div className="mb-4">
  <label className="block text-sm font-medium mb-2">Summary</label>
  <JoditEditor
    ref={editor}
    value={lessonToEdit?.summary}
    config={editorConfig} // Apply height configuration
    onBlur={(newContent) =>
      setLessonToEdit({ ...lessonToEdit, summary: newContent })
    }
  />
</div>;

          <div className="flex justify-end gap-2">
            <div
              className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-md hover:bg-gray-200 cursor-pointer"
              onClick={() => setIsEditLessonModalOpen(false)}
            >
              Cancel
            </div>
            <button
              type="submit"
              className={`px-4 py-2 text-sm font-medium text-white bg-orange-600 rounded-md hover:bg-orange-700 ${
                loading ? "opacity-50 cursor-not-allowed" : ""
              }`}
              disabled={loading}
            >
              {loading ? "Saving..." : "Save"}
            </button>
          </div>
        </form>
      </Modal>

      {/* Modal for editing Section */}
      <Modal
        show={isEditSectionModalOpen}
        onClose={() => setIsEditSectionModalOpen(false)}
      >
        <h2 className="text-xl font-semibold mb-4">Edit Section</h2>
        <form onSubmit={handleUpdateSection}>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-2">
              Section Title
            </label>
            <input
              type="text"
              value={sectionToEdit?.title || ""}
              onChange={(e) =>
                setSectionToEdit({ ...sectionToEdit, title: e.target.value })
              }
              className="w-full px-3 py-2 border border-gray-300 rounded-md"
              placeholder="Enter section title"
            />
          </div>
          <div className="flex justify-end gap-2">
            <div
              className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-md hover:bg-gray-200 cursor-pointer"
              onClick={() => setIsEditSectionModalOpen(false)}
            >
              Cancel
            </div>
            <button
              type="submit"
              className="px-4 py-2 text-sm font-medium text-white bg-orange-600 rounded-md hover:bg-orange-700"
            >
              Save
            </button>
          </div>
        </form>
      </Modal>
    </div>
  );
}
