import { Eye, Pencil, Trash2, UserPlus } from "lucide-react";
import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2"; // Import SweetAlert2
import AddNumerology from "./AddNumerology";
import CommongPreloader from "../CommongPreloader/CommongPreloader";
import { FaEdit, FaTrash } from "react-icons/fa";
import HTMLReactParser from "html-react-parser";
import { FiFilter } from "react-icons/fi";
import { BsSortNumericUp, BsSortNumericUpAlt } from "react-icons/bs";
const Numerology = () => {
  const [numerologyData, setNumerologyData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showAddNumerology, setShowAddNumerology] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [topicFilter, setTopicFilter] = useState("");
  const [numerologyFilter, setNumerologyFilter] = useState("");
  const [filtersEnabled, setFiltersEnabled] = useState(false); // For enabling/disabling inputs
  const [showFilterFields, setShowFilterFields] = useState(false); // For toggling filter fields visibility

  // Fetch Data
  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        "https://vpandit.caxis.ca/public/api/numerologies"
      );
      if (Array.isArray(response.data)) {
        const formattedData = response.data.map((item) => ({
          numId: item.Num_Id, // Updated to use Num_Id
          topicNumber: item.topic_no,
          numerologyNumber: item.num_no,
          title: item.desc_title,
          description: item.desc_details,
        }));
        setNumerologyData(formattedData);
        setFilteredData(formattedData); // Initially set filteredData to all data
      } else {
        throw new Error("Invalid data format received");
      }
    } catch (err) {
      setError("Failed to fetch data. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Handle Add div
  const handleAddClick = () => {
    fetchData();
    setSelectedData(null);
    setIsEditing(false);
    setShowAddNumerology(true);
  };

  // Handle Edit div
  const handleEditClick = (data) => {
    fetchData();
    setSelectedData(data);
    setIsEditing(true);
    setShowAddNumerology(true);
  };

  // Handle Delete div with Swal Confirmation
  const handleDeleteClick = async (numId) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 1000,
      timerProgressBar: true,
    });

    try {
      await axios.delete(
        `https://vpandit.caxis.ca/public/api/numerologies/${numId}`
      );

      const updatedData = numerologyData.filter(
        (item) => item.numId !== numId // Use numId for filtering
      );
      setNumerologyData(updatedData);
      fetchData();

      // Show a success toast
      Toast.fire({
        icon: "success",
        title: "Record deleted successfully",
      });
    } catch (err) {
      console.error("Error deleting data:", err);

      // Show an error toast
      Toast.fire({
        icon: "error",
        title: "Failed to delete the record. Please try again.",
      });
    }
  };

  // Filter logic
  const handleFilter = () => {
    let filtered = numerologyData;

    if (topicFilter) {
      filtered = filtered.filter((item) => item.topicNumber === topicFilter);
    }

    if (numerologyFilter) {
      filtered = filtered.filter(
        (item) => item.numerologyNumber === numerologyFilter
      );
    }

    setFilteredData(filtered);
  };

  // Handle the "Filter" div click
  const handleEnableFilters = () => {
    setShowFilterFields(true); // Show the input fields
  };

  // Handle the "Cancel" div click to hide the input fields and reset filters
  const handleCancelFilter = () => {
    setShowFilterFields(false);
    setTopicFilter("");
    setNumerologyFilter("");
    setFilteredData(numerologyData); // Reset to the full data
  };

  return (
    <div className="">
      {/* Header Section */}
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Numerology Management</h1>
        <div className="flex gap-3">
          <div className="flex space-x-4">
            <div
              onClick={handleEnableFilters}
              className="cursor-pointer flex items-center"
            >
              <FiFilter className="mr-2" /> {/* Add the filter icon */}
            </div>
          </div>

          <div
            onClick={handleAddClick}
            className="cursor-pointer bg-orange-500 hover:bg-orange-600 text-white px-4 py-2 rounded-3xl flex items-center"
          >
            <BsSortNumericUpAlt className="mr-2 h-4 w-4 " />
            Add Numerology
          </div>
        </div>
      </div>

      {/* Filter Section */}
      <div className="mb-6">
        {showFilterFields && (
          <div className="mt-4">
            <div className="flex space-x-4 justify-between">
              <div className="flex gap-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Topic Number
                  </label>
                  <input
                    type="text"
                    required
                    value={topicFilter}
                    onChange={(e) => setTopicFilter(e.target.value)}
                    className="mt-1 p-2 border border-gray-300  focus:outline-none focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
                    placeholder="Enter Topic Number"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Numerology Number
                  </label>
                  <input
                    type="text"
                    required
                    value={numerologyFilter}
                    onChange={(e) => setNumerologyFilter(e.target.value)}
                    className="mt-1 p-2 border border-gray-300 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
                    placeholder="Enter Numerology Number"
                  />
                </div>
              </div>

              <div className="flex items-end">
                <div
                  onClick={handleFilter}
                  className="cursor-pointer bg-green-500 text-white p-2 rounded-3xl hover:bg-green-600"
                >
                  Apply
                </div>
                <div
                  onClick={handleCancelFilter}
                  className="cursor-pointer ml-2 bg-red-500 text-white p-2 rounded-3xl hover:bg-red-600"
                >
                  Cancel
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Main Content */}
      {loading ? (
        <CommongPreloader />
      ) : error ? (
        <p className="text-red-500 text-center">{error}</p>
      ) : showAddNumerology ? (
        <AddNumerology
          onClose={() => setShowAddNumerology(false)}
          setNumerologyData={setNumerologyData}
          numerologyData={numerologyData}
          selectedData={selectedData}
          isEditing={isEditing}
        />
      ) : (
        <div className="rounded-lg overflow-hidden">
          {filteredData.length === 0 ? (
            <p className="p-4 text-center text-gray-500">No Data Found</p>
          ) : (
            <table className="w-full bg-white">
              <thead>
                <tr className="border-b border-gray-700">
                  <th className="px-6 py-3 text-left text-xs font-medium text-black uppercase tracking-wider">
                    Topic Number
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-black uppercase tracking-wider">
                    Numerology Number
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-black uppercase tracking-wider">
                    Title
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-black uppercase tracking-wider">
                    Description
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-black uppercase tracking-wider">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-700">
                {filteredData.map((item, index) => (
                  <tr key={index} className="hover:bg-gray-50">
                    <td className="px-6 py-4">{item.topicNumber}</td>
                    <td className="px-6 py-4">{item.numerologyNumber}</td>
                    <td className="px-6 py-4">{item.title}</td>
                    <td className="px-6 py-4">
                      {item.description.length > 200
                        ? HTMLReactParser(
                            item.description.slice(0, 200) + "....."
                          )
                        : HTMLReactParser(item.description)}
                    </td>

                    <td className="px-6 py-4">
                      <div className="flex space-x-3">
                        <div className="text-black hover:text-black">
                          <FaEdit
                            className="text-green-600 cursor-pointer"
                            onClick={() => handleEditClick(item)}
                          />
                        </div>
                        <div className="text-black hover:text-black">
                          <FaTrash
                            className="text-red-600 cursor-pointer"
                            onClick={() => handleDeleteClick(item.numId)}
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      )}
    </div>
  );
};

export default Numerology;
