import {
  calculateJulianDay,
  calculateAyanamsa,
  calculateSunPosition,
  calculateMoonPosition,
  calculatePlanetHeliocentric,
  calculateEarthHeliocentric,
  calculateGeocentricLongitude,
  calculateRahuKetu,
  isPlanetRetrograde,
  getNakshatraInfo,
  RASHI_NAMES as RASHI_NAMES_HINDI,
  NAKSHATRA_NAMES,
  NAKSHATRA_LORDS,
  PLANET_DATA
} from '../../Panchang/AstrologyCalculations';

const RASHI_NAMES = [
  "Aries", "Taurus", "Gemini", "Cancer", "Leo", "Virgo",
  "Libra", "Scorpio", "Sagittarius", "Capricorn", "Aquarius", "Pisces"
];

const RASHI_LORDS = [
  "Mars", "Venus", "Mercury", "Moon", "Sun", "Mercury",
  "Venus", "Mars", "Jupiter", "Saturn", "Saturn", "Jupiter"
];

const calculateAscendant = (jd, latitude, longitude) => {
  const T = (jd - 2451545.0) / 36525.0;
  const D = jd - 2451545.0;
  let theta0 = 280.46061837 + 360.98564736629 * D + 0.000387933 * T * T - (T * T * T) / 38710000;
  theta0 = theta0 % 360;
  if (theta0 < 0) theta0 += 360;
  const LST = (theta0 + longitude) % 360;
  const epsilon = 23.439281 - 0.0130042 * T - 0.00000016 * T * T;
  const tanLat = Math.tan(latitude * Math.PI / 180);
  const sinEpsilon = Math.sin(epsilon * Math.PI / 180);
  const cosEpsilon = Math.cos(epsilon * Math.PI / 180);
  const ascendant = Math.atan2(
    Math.cos(LST * Math.PI / 180),
    -Math.sin(LST * Math.PI / 180) * cosEpsilon - tanLat * sinEpsilon
  ) * (180 / Math.PI);
  let tropicalAscendant = ascendant % 360;
  if (tropicalAscendant < 0) tropicalAscendant += 360;
  const ayanamsa = calculateAyanamsa(jd);
  let siderealAscendant = (tropicalAscendant - ayanamsa) % 360;
  if (siderealAscendant < 0) siderealAscendant += 360;
  const signIndex = Math.floor(siderealAscendant / 30);
  const degreeInSign = siderealAscendant % 30;
  return {
    longitude: siderealAscendant,
    sign: RASHI_NAMES[signIndex],
    signLord: RASHI_LORDS[signIndex],
    degree: degreeInSign.toFixed(2),
  };
};

const calculateHouse = (planetLongitude, ascendantLongitude) => {
  return Math.floor(((planetLongitude - ascendantLongitude + 360) % 360) / 30) + 1;
};

const calculatePanchang = (sunLongitude, moonLongitude) => {
  const lunarDay = (moonLongitude - sunLongitude + 360) % 360;
  const tithiIndex = Math.floor(lunarDay / 12);
  const tithiNames = [
    "Shukla Pratipada", "Shukla Dwitiya", "Shukla Tritiya", "Shukla Chaturthi", "Shukla Panchami",
    "Shukla Shashthi", "Shukla Saptami", "Shukla Ashtami", "Shukla Navami", "Shukla Dashami",
    "Shukla Ekadashi", "Shukla Dwadashi", "Shukla Trayodashi", "Shukla Chaturdashi", "Purnima",
    "Krishna Pratipada", "Krishna Dwitiya", "Krishna Tritiya", "Krishna Chaturthi", "Krishna Panchami",
    "Krishna Shashthi", "Krishna Saptami", "Krishna Ashtami", "Krishna Navami", "Krishna Dashami",
    "Krishna Ekadashi", "Krishna Dwadashi", "Krishna Trayodashi", "Krishna Chaturdashi", "Amavasya",
  ];
  const karanaIndex = Math.floor(lunarDay / 6) % 11;
  const karanaNames = ["Bava", "Balava", "Kaulava", "Taitila", "Gara", "Vanija", "Vishti", "Shakuni", "Chatushpada", "Naga", "Kimstughna"];
  const yogaLongitude = (sunLongitude + moonLongitude) % 360;
  const yogaIndex = Math.floor(yogaLongitude / (360 / 27));
  const yogaNames = [
    "Vishkumbha", "Preeti", "Ayushman", "Saubhagya", "Shobhana", "Atiganda", "Sukarma", "Dhriti", "Shoola",
    "Ganda", "Vriddhi", "Dhruva", "Vyaghata", "Harshana", "Vajra", "Siddhi", "Vyatipata", "Variyana",
    "Parigha", "Shiva", "Siddha", "Sadhya", "Shubha", "Shukla", "Brahma", "Indra", "Vaidhriti",
  ];
  return {
    tithi: tithiNames[tithiIndex],
    karan: karanaNames[karanaIndex],
    yog: yogaNames[yogaIndex],
    nakshatra: getNakshatraInfo(moonLongitude).name,
  };
};

export const calculateKundliData = (birthDateTime, latitude, longitude) => {
  const jd = calculateJulianDay(birthDateTime);
  const ayanamsa = calculateAyanamsa(jd);
  const [λ_e, r_e] = calculateEarthHeliocentric(jd);
  const ascendant = calculateAscendant(jd, latitude, longitude);

  // Constants for Nakshatra and Pada calculations
  const nakshatraSize = 360 / 27; // ≈ 13.333 degrees
  const padaSize = nakshatraSize / 4; // ≈ 3.333 degrees

  // Calculate Nakshatra and Pada for Ascendant
  const ascendantNakshatraInfo = getNakshatraInfo(ascendant.longitude);
  const ascendantPositionWithinNakshatra = ascendant.longitude % nakshatraSize;
  const ascendantPada = Math.floor(ascendantPositionWithinNakshatra / padaSize) + 1;
  ascendant.nakshatra = ascendantNakshatraInfo.name;
  ascendant.nakshatraLord = ascendantNakshatraInfo.lord; // Optional
  ascendant.pada = ascendantPada; // Add Pada to ascendant object

  const planets = [
    { name: "Sun", positionFunc: () => calculateSunPosition(jd), isGeocentric: true },
    { name: "Moon", positionFunc: () => calculateMoonPosition(jd), isGeocentric: true },
    { name: "Mercury", positionFunc: () => calculatePlanetHeliocentric(jd, PLANET_DATA.Mercury), isGeocentric: false },
    { name: "Venus", positionFunc: () => calculatePlanetHeliocentric(jd, PLANET_DATA.Venus), isGeocentric: false },
    { name: "Mars", positionFunc: () => calculatePlanetHeliocentric(jd, PLANET_DATA.Mars), isGeocentric: false },
    { name: "Jupiter", positionFunc: () => calculatePlanetHeliocentric(jd, PLANET_DATA.Jupiter), isGeocentric: false },
    { name: "Saturn", positionFunc: () => calculatePlanetHeliocentric(jd, PLANET_DATA.Saturn), isGeocentric: false },
    { name: "Rahu", positionFunc: () => calculateRahuKetu(jd)[0], isGeocentric: true },
    { name: "Ketu", positionFunc: () => calculateRahuKetu(jd)[1], isGeocentric: true },
  ];

  const planetPositions = planets.map((planet) => {
    let tropicalLong;
    if (planet.isGeocentric) {
      tropicalLong = planet.positionFunc();
    } else {
      const [helio_long, r_p] = planet.positionFunc();
      tropicalLong = calculateGeocentricLongitude([helio_long, r_p], [λ_e, r_e]);
    }
    let siderealLong = (tropicalLong - ayanamsa) % 360;
    if (siderealLong < 0) siderealLong += 360;
    const signIndex = Math.floor(siderealLong / 30) % 12;
    const degreeInSign = siderealLong % 30;
    const nakshatraInfo = getNakshatraInfo(siderealLong);

    // Calculate Pada for Planet
    const positionWithinNakshatra = siderealLong % nakshatraSize;
    const pada = Math.floor(positionWithinNakshatra / padaSize) + 1;

    const motionStatus = planet.name === "Rahu" || planet.name === "Ketu" ? "Vakri" : (isPlanetRetrograde(planet.name, jd) ? "Vakri" : "Margi");
    return {
      name: planet.name,
      sign: RASHI_NAMES[signIndex],
      signLord: RASHI_LORDS[signIndex],
      degree: degreeInSign.toFixed(2),
      nakshatra: nakshatraInfo.name,
      nakshatraLord: nakshatraInfo.lord,
      pada: pada, // Add Pada to planet object
      house: calculateHouse(siderealLong, ascendant.longitude),
      motionStatus,
    };
  });

  const panchang = calculatePanchang(
    planetPositions.find(p => p.name === "Sun").degree * 1 + RASHI_NAMES.indexOf(planetPositions.find(p => p.name === "Sun").sign) * 30,
    planetPositions.find(p => p.name === "Moon").degree * 1 + RASHI_NAMES.indexOf(planetPositions.find(p => p.name === "Moon").sign) * 30
  );

  return {
    ascendant,
    planetPositions,
    panchang,
  };
};

export { RASHI_NAMES, RASHI_LORDS };