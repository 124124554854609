import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Skeleton from "react-loading-skeleton"; // Import Skeleton
import "react-loading-skeleton/dist/skeleton.css";
import HTMLReactParser from "html-react-parser";
const Sub_Category_Pooja_Vidhi = () => {
  const [subcategory, setsubcategory] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { category } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null); // Reset error state on new request
      try {
        const response = await fetch(
          `https://vpandit.caxis.ca/public/api/categories/${category}`
        );
        if (!response.ok) {
          throw new Error("No Any Sub Category Found");
        }
        const data = await response.json();
        if (data && data.subcategories && data.subcategories.length === 0) {
          setsubcategory(null); // No subcategories found
        } else {
          setsubcategory(data.subcategories); // Set the subcategories array
        }
      } catch (err) {
        setError(err.message); // Set error message on failure
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [category]);

  return (
    <div className="container mx-auto px-4 py-8 h-auto">
      <nav className="flex mb-4 text-sm">
        <Link
          to="/"
          className="text-orange-500 hover:text-orange-600 font-bold"
        >
          Home
        </Link>
        <span className="mx-2 text-gray-500">&gt;</span>
        <Link to={"/pooja-vidhi"} className="text-orange-600 font-bold">
          Pooja Vidhi
        </Link>
        <span className="mx-2 text-gray-500">&gt;</span>
        <span className="text-gray-600 font-bold">{category}</span>
      </nav>

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2 w-full">
        {/* Show Skeletons while loading */}
        {loading &&
          Array(8) // Adjust number of skeletons based on layout
            .fill()
            .map((_, index) => (
              <div
                key={index}
                className="flex w-full w-full h-24 bg-white rounded-xl overflow-hidden shadow-lg mt-7 cursor-pointer"
              >
                {/* Skeleton for the icon */}
                <Skeleton width={50} height={50} circle className="m-3" />
                <div className="w-full">
                  {/* Skeleton for title */}
                  <Skeleton width="80%" height={24} className="mt-2" />
                  {/* Skeleton for description */}
                  <Skeleton width="100%" height={16} className="mt-2" />
                </div>
              </div>
            ))}

        {/* Error Handling */}
        {error && <h1>Error: {error}</h1>}

        {/* No Data Found Message */}
        {subcategory === null && !loading && !error && (
          <h1>No subcategories found for the selected category.</h1>
        )}

        {/* Display actual content once data is loaded */}
        {subcategory &&
          subcategory.length > 0 &&
          subcategory.map((subCat, i) => (
            <div
              key={i}
              className="flex w-full == h-auto bg-white rounded-xl overflow-hidden shadow-lg mt-5 cursor-pointer "
            >
              {/* Custom SVG Icon */}
              <svg width="16" height="96" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M 8 0 Q 4 4.8, 8 9.6 T 8 19.2 Q 4 24, 8 28.8 T 8 38.4 Q 4 43.2, 8 48 T 8 57.6 Q 4 62.4, 8 67.2 T 8 76.8 Q 4 81.6, 8 86.4 T 8 96 L 0 96 L 0 0 Z"
                  fill="#ffA500"
                  stroke="#ffA500"
                  strokeWidth="2"
                  strokeLinecap="round"
                ></path>
              </svg>
              <Link
                className="mx-2.5 overflow-hidden w-full"
                to={`/pooja-vidhi/${category}/${subCat.SubCat_Name}?sid=${subCat.SubCat_Id}`}
              >
                <p className="mt-1.5 text-xl font-bold text-orange-500 leading-8 mr-3 overflow-hidden text-ellipsis whitespace-nowrap">
                  {subCat.SubCat_Name}
                </p>
                <p className="overflow-hidden leading-5 break-all text-zinc-400 max-h-10">
                  {HTMLReactParser(subCat.SubCat_Desc)}
                </p>
                {/* <p className="text-orange-500 font-bold mt-2">{subCat.Price}</p> */}
              </Link>
            </div>
          ))}
      </div>
    </div>
  );
};

export default Sub_Category_Pooja_Vidhi;
