import React, { useState, useEffect } from "react";
import mercury from "../../Images/mercury-removebg-preview (2).png";
import venus from "../../Images/venus-removebg-preview (1).png";
import saturn from "../../Images/saturn.jpg";
import jupiter from "../../Images/jupiter-removebg-preview.png";
import mars from "../../Images/mars-removebg-preview (3).png";
import rahu from "../../Images/rahu-removebg-preview.png";
import ketu from "../../Images/ketu-removebg-preview (2).png";
import sun from "../../Images/sun-removebg-preview (1).png";
import moon from "../../Images/moon-removebg-preview (2).png";

const PI = Math.PI;
const RAD = PI / 180;
const DEG = 180 / PI;

const RASHI_NAMES = [
  "मेष", "वृषभ", "मिथुन", "कर्क", "सिंह", "कन्या",
  "तुला", "वृश्चिक", "धनु", "मकर", "कुंभ", "मीन",
];

const NAKSHATRA_NAMES = [
  "Ashwini", "Bharani", "Krittika", "Rohini", "Mrigashira", "Ardra",
  "Punarvasu", "Pushya", "Ashlesha", "Magha", "Purva Phalguni", "Uttara Phalguni",
  "Hasta", "Chitra", "Swati", "Vishakha", "Anuradha", "Jyeshtha",
  "Mula", "Purva Ashadha", "Uttara Ashadha", "Shravana", "Dhanishta", "Shatabhisha",
  "Purva Bhadrapada", "Uttara Bhadrapada", "Revati",
];
const NAKSHATRA_LORDS = [
  "Ketu", "Venus", "Sun", "Moon", "Mars", "Rahu", "Jupiter", "Saturn", "Mercury",
  "Ketu", "Venus", "Sun", "Moon", "Mars", "Rahu", "Jupiter", "Saturn", "Mercury",
  "Ketu", "Venus", "Sun", "Moon", "Mars", "Rahu", "Jupiter", "Saturn", "Mercury",
];

const PLANET_DATA = {
  Mercury: { L0: 252.250906, dL: 149472.6746358, M0: 178.179078, dM: 149474.071, e0: 0.205635, de: 0.0000209, a: 0.38709927 },
  Venus: { L0: 181.979801, dL: 58517.8156760, M0: 48.339618, dM: 58519.213, e0: 0.006792, de: -0.000047, a: 0.723326 },
  Mars: { L0: 355.433000, dL: 19141.6964471, M0: 19.373481, dM: 19140.302, e0: 0.093405, de: 0.000090, a: 1.523668 },
  Jupiter: { L0: 34.351519, dL: 3036.3027748, M0: 19.895036, dM: 3034.906, e0: 0.048498, de: -0.000163, a: 5.202887 },
  Saturn: { L0: 50.077444, dL: 1223.5110686, M0: 316.967, dM: 1222.114, e0: 0.055548, de: -0.000346, a: 9.536675 },
};

const calculateJulianDay = (date) => {
  const year = date.getUTCFullYear();
  const month = date.getUTCMonth() + 1;
  const day = date.getUTCDate();
  const hour = date.getUTCHours();
  const minute = date.getUTCMinutes();
  const second = date.getUTCSeconds();
  const decimalDay = day + (hour + minute / 60 + second / 3600) / 24;

  let y = year;
  let m = month;
  if (month <= 2) {
    y -= 1;
    m += 12;
  }
  const a = Math.floor(y / 100);
  const b = 2 - a + Math.floor(a / 4);
  return Math.floor(365.25 * (y + 4716)) + Math.floor(30.6001 * (m + 1)) + decimalDay + b - 1524.5;
};

const calculateAyanamsa = (jd) => {
  const T = (jd - 2451545.0) / 36525;
  const baseAyanamsa = 23.853;
  const precessionRate = 50.27 / 3600;
  const yearsSinceJ2000 = T * 100;
  return baseAyanamsa + (precessionRate * yearsSinceJ2000);
};

const calculateSunPosition = (jd) => {
  const T = (jd - 2451545.0) / 36525.0;
  let M = 357.5291 + 35999.0503 * T - 0.0001537 * T * T;
  M = M % 360;
  if (M < 0) M += 360;
  let L0 = 280.46646 + 36000.76983 * T + 0.0003032 * T * T;
  L0 = L0 % 360;
  if (L0 < 0) L0 += 360;
  const e = 0.016708634 - 0.000042037 * T;
  const C = (1.914602 - 0.004817 * T) * Math.sin(M * RAD) +
    (0.019993 - 0.000101 * T) * Math.sin(2 * M * RAD);
  const trueL = (L0 + C) % 360;
  return trueL >= 0 ? trueL : trueL + 360;
};

const calculateEarthHeliocentric = (jd) => {
  const T = (jd - 2451545.0) / 36525.0;
  let M = 357.5291 + 35999.0503 * T - 0.0001537 * T * T;
  M = M % 360;
  if (M < 0) M += 360;
  let L0 = 280.46646 + 36000.76983 * T + 0.0003032 * T * T;
  L0 = L0 % 360;
  if (L0 < 0) L0 += 360;
  const e = 0.016708634 - 0.000042037 * T;
  const C = (1.914602 - 0.004817 * T) * Math.sin(M * RAD) +
    (0.019993 - 0.000101 * T) * Math.sin(2 * M * RAD);
  const trueL = (L0 + C) % 360;
  const v = M + C;
  const r_e = (1 - e * e) / (1 + e * Math.cos(v * RAD));
  const λ_e = (trueL + 180) % 360;
  return [λ_e >= 0 ? λ_e : λ_e + 360, r_e];
};

const calculateMoonPosition = (jd) => {
  const T = (jd - 2451545.0) / 36525.0;
  let L = 218.3164591 + 481267.88134236 * T - 0.0013268 * T * T + T * T * T / 538841;
  L = L % 360;
  if (L < 0) L += 360;
  let D = 297.8502042 + 445267.1115168 * T - 0.0016300 * T * T + T * T * T / 545868;
  D = D % 360;
  if (D < 0) D += 360;
  let M = 357.5291092 + 35999.0502909 * T - 0.0001536 * T * T;
  M = M % 360;
  if (M < 0) M += 360;
  let M_moon = 134.9634114 + 477198.8676313 * T + 0.0089970 * T * T + T * T * T / 69699;
  M_moon = M_moon % 360;
  if (M_moon < 0) M_moon += 360;
  let F = 93.2720993 + 483202.0175273 * T - 0.0034029 * T * T - T * T * T / 3526000;
  F = F % 360;
  if (F < 0) F += 360;

  let longitude = L +
    6.288750 * Math.sin(M_moon * RAD) +
    1.274018 * Math.sin((2 * D - M_moon) * RAD) +
    0.658309 * Math.sin(2 * D * RAD) +
    0.213616 * Math.sin(2 * M_moon * RAD) -
    0.185596 * Math.sin(M * RAD);

  longitude = longitude % 360;
  return longitude >= 0 ? longitude : longitude + 360;
};

const calculatePlanetHeliocentric = (jd, planetData) => {
  const T = (jd - 2451545.0) / 36525.0;
  let L = planetData.L0 + planetData.dL * T;
  L = L % 360;
  if (L < 0) L += 360;
  let M = planetData.M0 + planetData.dM * T;
  M = M % 360;
  if (M < 0) M += 360;
  const e = planetData.e0 + planetData.de * T;
  let E = M;
  for (let i = 0; i < 5; i++) {
    E = M + e * DEG * Math.sin(E * RAD);
  }
  const v = 2 * Math.atan2(Math.sqrt((1 + e) / (1 - e)) * Math.sin(E * RAD / 2), Math.cos(E * RAD / 2)) * DEG;
  let helio_long = (L + v - M) % 360;
  const a = planetData.a;
  const r_p = a * (1 - e * e) / (1 + e * Math.cos(v * RAD));
  return [helio_long >= 0 ? helio_long : helio_long + 360, r_p];
};

const calculateGeocentricLongitude = (planetHelio, earthHelio) => {
  const [λ_p, r_p] = planetHelio;
  const [λ_e, r_e] = earthHelio;
  const dx = r_p * Math.cos(λ_p * RAD) - r_e * Math.cos(λ_e * RAD);
  const dy = r_p * Math.sin(λ_p * RAD) - r_e * Math.sin(λ_e * RAD);
  let λ_geo = Math.atan2(dy, dx) * DEG;
  return λ_geo >= 0 ? λ_geo : λ_geo + 360;
};

const calculateRahuKetu = (jd) => {
  const T = (jd - 2451545.0) / 36525.0;
  let omega = 125.04452 - 1934.136261 * T + 0.0020708 * T * T;
  omega = omega % 360;
  if (omega < 0) omega += 360;
  const rahuTropical = omega;
  const ketuTropical = (omega + 180) % 360;
  return [rahuTropical, ketuTropical];
};

const isPlanetRetrograde = (planet, jd) => {
  if (planet === "Sun" || planet === "Moon") return false;
  if (planet === "Rahu" || planet === "Ketu") return true;
  const jd2 = jd + 1;
  const ayanamsa1 = calculateAyanamsa(jd);
  const ayanamsa2 = calculateAyanamsa(jd2);
  const [λ_e1, r_e1] = calculateEarthHeliocentric(jd);
  const [λ_e2, r_e2] = calculateEarthHeliocentric(jd2);
  const planetData = PLANET_DATA[planet];
  const [helio_long1, r_p1] = calculatePlanetHeliocentric(jd, planetData);
  const [helio_long2, r_p2] = calculatePlanetHeliocentric(jd2, planetData);
  const λ_geo1 = calculateGeocentricLongitude([helio_long1, r_p1], [λ_e1, r_e1]);
  const λ_geo2 = calculateGeocentricLongitude([helio_long2, r_p2], [λ_e2, r_e2]);
  let siderealLong1 = (λ_geo1 - ayanamsa1) % 360;
  let siderealLong2 = (λ_geo2 - ayanamsa2) % 360;
  if (siderealLong1 < 0) siderealLong1 += 360;
  if (siderealLong2 < 0) siderealLong2 += 360;
  return siderealLong2 < siderealLong1;
};

const getNakshatraInfo = (longitude) => {
  const nakshatraSpan = 360.0 / 27;
  const nakshatraIndex = Math.floor(longitude / nakshatraSpan);
  const padaSpan = nakshatraSpan / 4;
  const positionInNakshatra = longitude % nakshatraSpan;
  const pada = Math.floor(positionInNakshatra / padaSpan) + 1;
  return {
    name: NAKSHATRA_NAMES[nakshatraIndex] || "Unknown",
    pada: pada,
    lord: NAKSHATRA_LORDS[nakshatraIndex] || "-",
  };
};

const AstrologyCalculations = () => {
  const [planetPositions, setPlanetPositions] = useState([]);
  const [currentDate, setCurrentDate] = useState(new Date());

  useEffect(() => {
    const calculatePositions = () => {
      const date = new Date();
      const jd = calculateJulianDay(date);
      const ayanamsa = calculateAyanamsa(jd);
      const [λ_e, r_e] = calculateEarthHeliocentric(jd);

      const planets = [
        { name: "Sun", image: sun, positionFunc: () => calculateSunPosition(jd), isGeocentric: true, sanskritName: "Surya" },
        { name: "Moon", image: moon, positionFunc: () => calculateMoonPosition(jd), isGeocentric: true, sanskritName: "Chandra" },
        { name: "Mercury", image: mercury, positionFunc: () => calculatePlanetHeliocentric(jd, PLANET_DATA.Mercury), isGeocentric: false, sanskritName: "Budha" },
        { name: "Venus", image: venus, positionFunc: () => calculatePlanetHeliocentric(jd, PLANET_DATA.Venus), isGeocentric: false, sanskritName: "Shukra" },
        { name: "Mars", image: mars, positionFunc: () => calculatePlanetHeliocentric(jd, PLANET_DATA.Mars), isGeocentric: false, sanskritName: "Mangal" },
        { name: "Jupiter", image: jupiter, positionFunc: () => calculatePlanetHeliocentric(jd, PLANET_DATA.Jupiter), isGeocentric: false, sanskritName: "Guru" },
        { name: "Saturn", image: saturn, positionFunc: () => calculatePlanetHeliocentric(jd, PLANET_DATA.Saturn), isGeocentric: false, sanskritName: "Shani" },
        { name: "Rahu", image: rahu, positionFunc: () => calculateRahuKetu(jd)[0], isGeocentric: true },
        { name: "Ketu", image: ketu, positionFunc: () => calculateRahuKetu(jd)[1], isGeocentric: true },
      ];

      const newPositions = planets.map((planet) => {
        let tropicalLong;
        if (planet.isGeocentric) {
          tropicalLong = planet.positionFunc();
        } else {
          const [helio_long, r_p] = planet.positionFunc();
          tropicalLong = calculateGeocentricLongitude([helio_long, r_p], [λ_e, r_e]);
        }
        let siderealLong = (tropicalLong - ayanamsa) % 360;
        if (siderealLong < 0) siderealLong += 360;
        const zodiacIndex = Math.floor(siderealLong / 30) % 12;
        const degreesInSign = siderealLong % 30;
        const nakshatraInfo = getNakshatraInfo(siderealLong);
        const motionStatus = planet.name === "Rahu" || planet.name === "Ketu" ? "Vakri" : (isPlanetRetrograde(planet.name, jd) ? "Vakri" : "Margi");
        return {
          name: planet.sanskritName || planet.name,
          position: `${degreesInSign.toFixed(2)}° ${RASHI_NAMES[zodiacIndex]}`,
          exactDegrees: siderealLong.toFixed(2),
          image: planet.image,
          nakshatra: nakshatraInfo.name,
          lord: nakshatraInfo.lord,
          motionStatus,
        };
      });
      setPlanetPositions(newPositions);
      setCurrentDate(date);
    };

    calculatePositions();
    const interval = setInterval(calculatePositions, 60000);
    return () => clearInterval(interval);
  }, []);

  const formattedDate = currentDate.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
  const formattedTime = currentDate.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', second: '2-digit' });

  return (
    <div className="pt-8 mb-8">
      <div className="flex justify-between items-center mb-8">
        <h1 className="text-xl font-semibold text-start underline underline-offset-8">Planet Positions</h1>
        <p className="text-sm text-gray-500">{formattedDate} at {formattedTime} UTC</p>
      </div>
      <div className="container grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-6">
        {planetPositions.map((planet, index) => (
          <div key={index} className="bg-white rounded-lg shadow-lg p-4 hover:shadow-xl transition-shadow duration-300">
            <div className="text-center">
              <div className="w-32 h-32 mx-auto">
                <img src={planet.image} alt={planet.name} className="w-full h-full object-cover rounded-full" />
              </div>
              <h2 className="text-lg font-semibold text-gray-700 text-start mt-4">{planet.name}</h2>
            </div>
            <div className="mt-2 text-left">
              <p className="text-gray-500 text-sm"><span className="font-medium text-gray-700">Position:</span> {planet.position}</p>
              <p className="text-gray-500 text-sm"><span className="font-medium text-gray-700">Exact:</span> {planet.exactDegrees}°</p>
              <p className="text-gray-500 text-sm"><span className="font-medium text-gray-700">Nakshatra:</span> {planet.nakshatra}</p>
              <p className="text-gray-500 text-sm"><span className="font-medium text-gray-700">Lord:</span> {planet.lord}</p>
              <p className="text-gray-500 text-sm"><span className="font-medium text-gray-700">Motion:</span> {planet.motionStatus}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export {
  calculateJulianDay,
  calculateAyanamsa,
  calculateSunPosition,
  calculateMoonPosition,
  calculatePlanetHeliocentric,
  calculateEarthHeliocentric,
  calculateGeocentricLongitude,
  calculateRahuKetu,
  isPlanetRetrograde,
  getNakshatraInfo,
  RASHI_NAMES,
  NAKSHATRA_NAMES,
  NAKSHATRA_LORDS,
  PLANET_DATA
};

export default AstrologyCalculations;