import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { FaEdit, FaTrash } from "react-icons/fa";
import { UserPlus } from "lucide-react";
import CommongPreloader from "../CommongPreloader/CommongPreloader";
import Add_Health_Numerology from "./Add_Health_Numerology";
import HTMLReactParser from "html-react-parser";
import { BsSortNumericUp } from "react-icons/bs";
const Health_Numerology = () => {
  const [numerologyData, setNumerologyData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showAddNumerology, setShowAddNumerology] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [isEditing, setIsEditing] = useState(false);

  // Fetch Data

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        "https://vpandit.caxis.ca/public/api/health-numerologies"
      );
      if (Array.isArray(response.data)) {
        const formattedData = response.data.map((item) => ({
          numId: item.Health_Id, // Map from Health_Id
          topicNumber: item.topic_no, // Map topic_no
          gender: item.gender, // Map gender
          age: parseInt(item.age, 10), // Ensure age is a number
          numerologyNumber: item.mulank, // Map mulank
          bhagyank: item.bhagyank, // Map bhagyank
          luckyNumber: item.lucky, // Map lucky number
          personalYear: item.per_year, // Separate personal year
          personalMonth: item.per_month, // Separate personal month
          personalDay: item.per_day, // Separate personal day
          title: item.title || "Default Title", // Ensure title is not null
          description: item.description || "Default description.", // Ensure description is not null
        }));
        setNumerologyData(formattedData);
      } else {
        throw new Error("Invalid data format received");
      }
    } catch (err) {
      setError("Failed to fetch data. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    fetchData();
  }, [showAddNumerology]);

  // Handle Add Click
  const handleAddClick = () => {
    setSelectedData(null);
    setIsEditing(false);
    setShowAddNumerology(true);
    fetchData();
  };

  // Handle Edit Click
  const handleEditClick = (data) => {
    console.log(data);

    setSelectedData(data);
    setIsEditing(true);
    setShowAddNumerology(true);
    fetchData();
  };

  // Handle Delete Click
 // Define Toast once for reuse
const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.onmouseenter = Swal.stopTimer;
    toast.onmouseleave = Swal.resumeTimer;
  }
});

// Handle Delete Click
const handleDeleteClick = async (numId) => {
  try {
    await axios.delete(
      `https://vpandit.caxis.ca/public/api/health-numerologies/${numId}`
    );
    setNumerologyData((prevData) =>
      prevData.filter((item) => item.numId !== numId)
    );

    fetchData();
    // Show a success toast
    Toast.fire({
      icon: "success",
      title: "Record deleted successfully",
    });
  } catch (err) {
    // Show an error toast
    Toast.fire({
      icon: "error",
      title: "Failed to delete the record. Please try again.",
    });
  }
};


  return (
    <div>
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Health Numerology </h1>
        <div
          onClick={handleAddClick}
          className="cursor-pointer bg-orange-500 hover:bg-orange-600 text-white px-4 py-2 rounded-3xl flex items-center"
        >
          <BsSortNumericUp className="mr-2 h-4 w-4" /> Add Numerology
        </div>
      </div>

      {loading ? (
        <CommongPreloader />
      ) : error ? (
        <p className="text-red-500 text-center">{error}</p>
      ) : showAddNumerology ? (
        <Add_Health_Numerology
          onClose={() => setShowAddNumerology(false)}
          setNumerologyComboData={setNumerologyData}
          numerologyComboData={numerologyData}
          selectedData={selectedData}
          isEditing={isEditing}
        />
      ) : (
        <div className="rounded-lg overflow-hidden">
          {numerologyData.length === 0 ? (
            <p className="p-4 text-center text-gray-500">No Data Found</p>
          ) : (
            <table className="w-full bg-white">
              <thead>
                <tr className="border-b border-gray-700">
                  <th className="px-6 py-3 text-left text-xs font-medium text-black uppercase tracking-wider">
                    Topic Number
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-black uppercase tracking-wider">
                    Gender
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-black uppercase tracking-wider">
                    Age
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-black uppercase tracking-wider">
                    Mulank
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-black uppercase tracking-wider">
                    Bhagyank
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-black uppercase tracking-wider">
                    Lucky Number
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-black uppercase tracking-wider">
                    Personal Year
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-black uppercase tracking-wider">
                    Personal Month
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-black uppercase tracking-wider">
                    Personal Day
                  </th>

                  <th className="px-6 py-3 text-left text-xs font-medium text-black uppercase tracking-wider">
                    Title
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-black uppercase tracking-wider">
                    Description
                  </th>

                  <th className="px-6 py-3 text-left text-xs font-medium text-black uppercase tracking-wider">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-700">
                {numerologyData.map((item, index) => (
                  <tr key={index} className="hover:bg-gray-50">
                    <td className="px-6 py-4">{item.topicNumber}</td>
                    <td className="px-6 py-4">{item.gender}</td>
                    <td className="px-6 py-4">{item.age}</td>
                    <td className="px-6 py-4">{item.numerologyNumber}</td>
                    <td className="px-6 py-4">{item.bhagyank}</td>
                    <td className="px-6 py-4">{item.luckyNumber}</td>
                    <td className="px-6 py-4">{item.personalYear}</td>
                    <td className="px-6 py-4">{item.personalMonth}</td>
                    <td className="px-6 py-4">{item.personalDay}</td>
                    <td className="px-6 py-4">{item.title}</td>
                    {item.description
                      ? HTMLReactParser(item.description)
                      : "No description available"}
                    <td className="px-6 py-4">
                      <div className="flex space-x-3">
                        <FaEdit
                          className="text-green-600 cursor-pointer"
                          onClick={() => handleEditClick(item)}
                        />
                        <FaTrash
                          className="text-red-600 cursor-pointer"
                          onClick={() => handleDeleteClick(item.numId)}
                        />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      )}
    </div>
  );
};

export default Health_Numerology;
