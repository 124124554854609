import { useState } from "react"
import { Phone, Mail, User } from "lucide-react"
import { Link, useLocation, useNavigate } from "react-router-dom"
import img1 from "../../Images/logo.png"

export default function Navbar() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  // Check if user is authenticated
  const token = localStorage.getItem("token");
  const userID = localStorage.getItem("userID");
  const role = localStorage.getItem("role");

  // Toggle mobile menu
  const toggleMobileMenu = () => setIsMobileMenuOpen(!isMobileMenuOpen);
  const closeSidebar = () => setIsMobileMenuOpen(false);

  // Active link styling logic
  const isActiveLink = (path) =>
    location.pathname === path ? "text-orange-500" : "hover:text-orange-500";

  const handleMenuClick = (e, route) => {
    e.preventDefault();
    if (userID) {
      if (route === "/shop") {
        window.location.href = "https://vpandit.com/vshop/";
      } else {
        navigate(route);
        closeSidebar();
      }
    } else {
      navigate("/login");
      closeSidebar();
    }
  };

  let route = "/profile";
  if (role === "1") {
    route = `/ultimate-admin/authority/${token}`;
  } else if (role === "2") {
    route = `/panditdash/${userID}`;
  } else if (role === "3") {
    route = `/profile/${userID}`;
  } else if (role === "4") {
    route = `/supplier-dashboard/${userID}`;
  }

  return (
    <header className="bg-[#002538] text-white sticky top-0 z-50 border-b border-gray-600 mb-6 md:mb-0 lg:mb-0">
      {/* Main Navigation */}
      <div className="container mx-auto px-4 py-4">
        <div className="flex justify-between items-center">
          {/* Logo */}
          <div className="flex items-center">
            <Link to="/" className="flex items-center space-x-2">
              <img
                className="w-20"
                src={img1 || "/placeholder.svg"}
                alt="Vpandit Logo"
              />
              <span className="text-2xl font-bold text-orange-500 select-none">
                Vpandit
              </span>
            </Link>
          </div>

          {/* Navigation Links - Desktop */}
          <nav className="hidden lg:flex items-center space-x-6">
            <Link
              to="/"
              className={`transition-colors font-bold ${isActiveLink("/")}`}
            >
              Home
            </Link>
            <Link
              to="/numerology"
              onClick={(e) => handleMenuClick(e, "/numerology")}
              className={`transition-colors font-bold ${isActiveLink(
                "/numerology"
              )}`}
            >
              Numerology
            </Link>
            <Link
              to="/panchang"
              className={`transition-colors font-bold ${isActiveLink(
                "/panchang"
              )}`}
            >
              Panchang
            </Link>
            <Link
              to="/pooja-vidhi"
              className={`transition-colors font-bold ${isActiveLink(
                "/pooja-vidhi"
              )}`}
            >
              Pooja
            </Link>
            <Link
              to="/horoscope"
              className={`transition-colors font-bold ${isActiveLink(
                "/horoscope"
              )}`}
            >
              Horoscope
            </Link>
            <Link
              to="/kundli"
              className={`transition-colors font-bold ${isActiveLink(
                "/kundli"
              )}`}
            >
              Kundli
            </Link>
            <Link
              to="/shop"
              onClick={(e) => handleMenuClick(e, "/shop")}
              className={`transition-colors font-bold ${isActiveLink("/shop")}`}
            >
              Products
            </Link>
            <Link
              to="/contact"
              className={`transition-colors font-bold ${isActiveLink(
                "/contact"
              )}`}
            >
              Contact
            </Link>
          </nav>

          {/* Profile/Login - Desktop */}
          <div className="hidden lg:flex items-center">
            {token ? (
              <Link
                to={route}
                className="flex items-center space-x-1 text-sm transition-colors hover:bg-white hover:text-gray-900"
              >
                <User className="h-8 w-8 rounded-full border-2 border-gray-300 hover:border-white p-1 hover:text-gray-900" />
              </Link>
            ) : (
              <Link
                to="/register"
                className="flex items-center space-x-2 text-sm transition-colors"
              >
                <button className="font-bold text-white whitespace-nowrap flex gap-2">
                  <User className="h-5 w-5 text-white" />
                  Log in / Register
                </button>
              </Link>
            )}
          </div>

          {/* Mobile Menu Button */}
          <button className="lg:hidden p-1" onClick={toggleMobileMenu}>
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          </button>
        </div>
      </div>

      {/* Mobile Sidebar */}
      {/* Backdrop */}
      <div
        className={`fixed inset-0 bg-black bg-opacity-50 z-40 lg:hidden transition-opacity ${
          isMobileMenuOpen ? "opacity-100" : "opacity-0 pointer-events-none"
        }`}
        onClick={closeSidebar}
      ></div>

      {/* Sidebar */}
      <div
        className={`fixed top-0 left-0 w-full h-full bg-[#001829] text-white z-50 transform transition-transform duration-300 ease-in-out lg:hidden ${
          isMobileMenuOpen ? "translate-x-0" : "-translate-x-full"
        }`}
      >
        {/* Sidebar Header */}
        <div className="flex items-center justify-between p-4 border-b border-gray-700">
          <Link to="/" className="flex items-center space-x-2">
            <img
              className="w-20"
              src={img1 || "/placeholder.svg"}
              alt="Vpandit Logo"
            />
            <span className="text-2xl font-bold text-orange-500 select-none">
              Vpandit
            </span>
          </Link>
          <div
            onClick={closeSidebar}
            className="text-white text-5xl cursor-pointer"
          >
            &times;
          </div>
        </div>

        {/* Mobile Contact Info */}
        <div className="px-4 py-2">
          <div className="flex items-center space-x-2 mb-2">
            <Phone className="h-4 w-4 text-orange-500" />
            <span className="text-sm">
              <span className="text-orange-500">Talk to our Pandit - </span>
              <a
                href="https://wa.me/919998713312?text=Hello%20V-Pandit%20I%20Need%20Your%20Help.."
                className="font-bold"
                target="_blank"
                rel="noopener noreferrer"
              >
                +91 99987 13312
              </a>
            </span>
          </div>
          <div className="flex items-center space-x-2 mb-2">
            <Mail className="h-4 w-4 text-orange-500" />
            <span className="text-sm">
              <span className="text-orange-500">Talk to our Pandit - </span>
              support@vpandit.com
            </span>
          </div>
        </div>

        {/* Mobile Navigation Links */}
        <nav className="flex flex-col px-4 py-2">
          <Link
            to="/"
            className={`py-2 ${isActiveLink("/")}`}
            onClick={closeSidebar}
          >
            Home
          </Link>
          <Link
            to="/numerology"
            className={`py-2 ${isActiveLink("/numerology")}`}
            onClick={(e) => handleMenuClick(e, "/numerology")}
          >
            Numerology
          </Link>
          <Link
            to="/panchang"
            className={`py-2 ${isActiveLink("/panchang")}`}
            onClick={closeSidebar}
          >
            Panchang
          </Link>
          <Link
            to="/shop"
            className={`py-2 ${isActiveLink("/shop")}`}
            onClick={(e) => handleMenuClick(e, "/shop")}
          >
            Products
          </Link>
          <Link
            to="/pooja-vidhi"
            className={`py-2 ${isActiveLink("/pooja-vidhi")}`}
            onClick={(e) => handleMenuClick(e, "/pooja-vidhi")}
          >
            Pooja
          </Link>
          <Link
            to="/horoscope"
            className={`py-2 ${isActiveLink("/horoscope")}`}
            onClick={closeSidebar}
          >
            Horoscope
          </Link>
          <Link
            to="/kundli"
            className={`py-2 ${isActiveLink("/kundli")}`}
            onClick={closeSidebar}
          >
            Kundli
          </Link>
          <Link
            to="/contact"
            className={`py-2 ${isActiveLink("/contact")}`}
            onClick={closeSidebar}
          >
            Contact
          </Link>
        </nav>

        {/* Mobile Sidebar Profile Icon */}
        <div
          className="flex items-center space-x-4 px-4 py-2"
          onClick={closeSidebar}
        >
          {token ? (
            <Link
              to={`/profile/${userID}`}
              className="flex items-center space-x-2 text-sm transition-colors hover:text-white"
            >
              <User className="h-8 w-8 rounded-full border-2 text-white border-gray-300 hover:border-white p-1" />
              <span className="text-white">My Profile</span>
            </Link>
          ) : (
            <Link
              to="/register"
              className="flex items-center space-x-2 text-sm transition-colors hover:text-white"
            >
              <User className="h-5 w-5 text-white" />
              <span className="font-bold text-white">Log in / Register</span>
            </Link>
          )}
        </div>
      </div>
    </header>
  );
}

