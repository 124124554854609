import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { CiEdit } from "react-icons/ci";
import { FaPlus, FaTrash } from "react-icons/fa";
import { MdKeyboardArrowRight } from "react-icons/md";
import { FiArrowLeft } from "react-icons/fi";
import { Link } from "react-router-dom";
import { MdLibraryBooks } from "react-icons/md";
import { FaCheckSquare, FaSquare } from "react-icons/fa"; // Added icons for checkbox
import Add_Circulam from "./Add_Circulam/Add_Circulam";
import Add_Pooja from "../Add_Pooja";
import Add_Material from "./Add_Material/Add_Material";
import Swal from "sweetalert2";
import axios from "axios";
import HTMLReactParser from "html-react-parser";

const Sub_Category_Pooja_Vidhi_Admin = ({ category, onBack }) => {
  const [subcategory, setsubcategory] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedSubcategory, setSelectedSubcategory] = useState(null);
  const [showAddCirculam, setShowAddCirculam] = useState(false);
  const [circulamData, setCirculamData] = useState(null);
  const [isAddUser, setIsAddUser] = useState(false);
  const [maintitle, setmaintitle] = useState(null);
  const [showAddMaterial, setShowAddMaterial] = useState(false);
  const [selectedMaterialSubCatId, setSelectedMaterialSubCatId] =
    useState(null);
  const [checkedSubcategories, setCheckedSubcategories] = useState({}); // State for subcategory checkboxes
  const [isFilterChecked, setIsFilterChecked] = useState(false); // State for filter checkbox

  const fetchData = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch(
        `https://vpandit.caxis.ca/public/api/categories/${category}`
      );
      if (!response.ok) throw new Error("No Subcategories Found");
      const data = await response.json();

      setsubcategory(data.subcategories || []);
      setmaintitle(data.Cat_Name);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [category, isAddUser]);

  const handleEditSubcategory = (index) => {
    setSelectedSubcategory(subcategory[index]);
  };

  const handleCloseForm = () => {
    setSelectedSubcategory(null);
    setIsAddUser(false);
    fetchData();
  };

  const handleCirculamClick = (sub) => {
    setShowAddCirculam(true);
    setCirculamData(sub);
  };

  const handleCloseCirculam = () => {
    setShowAddCirculam(false);
    setCirculamData(null);
  };

  const handleAddMaterialClick = (subCatId) => {
    setSelectedMaterialSubCatId(subCatId);
    setShowAddMaterial(true);
  };

  const handleCloseMaterial = () => {
    setShowAddMaterial(false);
    setSelectedMaterialSubCatId(null);
  };

  const handleDelete = async (SubCat_Id) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You will not be able to recover this subcategory!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, delete it!",
      });

      if (result.isConfirmed) {
        const response = await axios.delete(
          `https://vpandit.caxis.ca/public/api/subcategories/${SubCat_Id}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        fetchData();
        Swal.fire("Deleted!", "The subcategory has been deleted.", "success");
      }
    } catch (error) {
      console.error("Error deleting subcategory:", error);
    }
  };

  const handleAddPoojaClick = () => {
    setIsAddUser(true);
  };

  // Toggle checkbox for subcategory
  const toggleSubcategoryCheck = (subCatId) => {
    setCheckedSubcategories((prev) => ({
      ...prev,
      [subCatId]: !prev[subCatId],
    }));
  };

  // Toggle filter checkbox
  const toggleFilterCheck = () => {
    setIsFilterChecked((prev) => !prev);
  };

  return (
    <div className="">
      {isAddUser ? (
        <Add_Pooja onClose={() => setIsAddUser(false)} maintitle={maintitle} />
      ) : showAddMaterial ? (
        <Add_Material
          subCatId={selectedMaterialSubCatId}
          onBack={handleCloseMaterial}
        />
      ) : (
        <>
          <div className="flex items-center justify-between ">
            <nav className="flex mb-4 text-sm ml-1">
              <div
                className="text-orange-500 hover:text-orange-600 font-bold cursor-pointer text-2xl"
                onClick={onBack}
              >
                Pooja Management
              </div>
              <MdKeyboardArrowRight size={22} className="mt-2 ml-3" />
              <span className="text-gray-600 font-bold cursor-pointer text-2xl m-1 ml-4">
                {maintitle}
              </span>
            </nav>

            <div className="flex justify-between items-center space-x-4">
              {/* Filter Button with Checkbox */}
              <div
                className="flex items-center bg-gray-100 text-gray-700 px-4 py-2 rounded-full shadow-lg hover:bg-gray-300 cursor-pointer font-bold"
                onClick={toggleFilterCheck}
              >
                {isFilterChecked ? (
                  <FaCheckSquare size={20} className="mr-2 text-orange-500" />
                ) : (
                  <FaSquare size={20} className="mr-2" />
                )}
                Filter
              </div>

              <div
                className="flex items-center bg-orange-500 text-white px-4 py-2 rounded-full shadow-lg hover:bg-orange-600 cursor-pointer font-bold"
                onClick={handleAddPoojaClick}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-5 h-5 mr-2"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M12 5v14M5 12h14" />
                </svg>
                Add New Pooja
              </div>
            </div>
          </div>

          {selectedSubcategory ? (
            <Add_Pooja
              onClose={handleCloseForm}
              selectedUser={selectedSubcategory}
              isEditing={true}
            />
          ) : showAddCirculam ? (
            <Add_Circulam onClose={handleCloseCirculam} data={circulamData} />
          ) : (
            <>
              {loading && (
                <div className="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-4 gap-4">
                  {Array(6)
                    .fill()
                    .map((_, index) => (
                      <div
                        key={index}
                        className="p-4 border rounded shadow-md bg-white"
                      >
                        <Skeleton height={20} className="mb-2" />
                        <Skeleton height={15} />
                      </div>
                    ))}
                </div>
              )}

              {error && <p className="text-red-500">{error}</p>}

              {subcategory && subcategory.length > 0 ? (
                <div className="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-4 gap-4">
                  {subcategory.map((sub, index) => (
                    <div
                      key={index}
                      className="p-4 border rounded shadow-md bg-white hover:shadow-lg transition-shadow relative h-auto"
                    >
                      {/* Checkbox at top-right */}
                      <div
                        className="absolute top-2 right-2 cursor-pointer"
                        onClick={() => toggleSubcategoryCheck(sub.SubCat_Id)}
                      >
                        {checkedSubcategories[sub.SubCat_Id] ? (
                          <FaCheckSquare
                            size={20}
                            className="text-orange-500 bg-white"
                          />
                        ) : (
                          <FaSquare size={20} className="text-gray-400" />
                        )}
                      </div>

                      <h3 className="text-orange-600 font-bold text-lg mb-2">
                        {sub.SubCat_Name.length > 15
                          ? sub.SubCat_Name.slice(0, 15) + "..."
                          : sub.SubCat_Name}
                      </h3>
                      <p className="text-gray-600 text-sm pb-10">
                        {HTMLReactParser(
                          sub.SubCat_Desc.length > 15
                            ? sub.SubCat_Desc.slice(0, 15) + "..."
                            : sub.SubCat_Desc
                        )}
                      </p>

                      <div className="absolute bottom-2 right-2 flex space-x-4">
                        <div
                          onClick={() => handleEditSubcategory(index)}
                          className="text-gray-500 hover:text-orange-600 transition-colors cursor-pointer"
                        >
                          <CiEdit size={22} />
                        </div>

                        <div
                          onClick={() => handleCirculamClick(sub)}
                          className="text-gray-400 hover:text-orange-600 transition-colors cursor-pointer"
                        >
                          <FaPlus size={20} />
                        </div>

                        <div
                          onClick={() => handleDelete(sub.SubCat_Id)}
                          className="text-gray-400 hover:text-red-700 transition-colors cursor-pointer"
                        >
                          <FaTrash size={18} />
                        </div>

                        <div
                          onClick={() => handleAddMaterialClick(sub.SubCat_Id)}
                          className="text-gray-400 hover:text-blue-500 transition-colors cursor-pointer"
                        >
                          <MdLibraryBooks size={20} />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                !loading && (
                  <p className="text-gray-500">No subcategories available.</p>
                )
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Sub_Category_Pooja_Vidhi_Admin;