import React, { useState, useEffect } from "react";
import { FaArrowTurnUp } from "react-icons/fa6";

const ScrollToTop = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [scrollProgress, setScrollProgress] = useState(0);

  const handleScroll = () => {
    const scrollTop = window.scrollY;
    const windowHeight =
      document.documentElement.scrollHeight - window.innerHeight;
    const progress = (scrollTop / windowHeight) * 100;

    setScrollProgress(progress);
    setIsVisible(scrollTop > 200); // Show  div after 200px of scrolling
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div>
      {isVisible && (
        <div
          onClick={scrollToTop}
          className="cursor-pointer fixed bottom-4 right-4 z-50 p-2 w-12 h-12 rounded-full bg-white border-2 border-gray-300 shadow-lg flex items-center justify-center group 
          overflow-hidden"
          aria-label="Scroll to Top"
        >
          {/* Progress Circle */}
          <div
            className="absolute inset-0 rounded-full bg-gradient-to-t from-[#f97316] to-[#002538]"
            style={{
              clipPath: `inset(${100 - scrollProgress}% 0 0 0)`,
            }}
          />
          {/* Border Effect */}
          <div className="absolute inset-0 rounded-full border-4 border-transparent  transition-all duration-300"></div>
          {/* Icon */}
          <span className="relative z-10 text-white group-hover:text-white transition-all duration-300">
            <FaArrowTurnUp size={24} />
          </span>
        </div>
      )}
    </div>
  );
};

export default ScrollToTop;
