"use client";
import { useState, useEffect } from "react";
import { Search, ChevronRight, Sun, Sunrise, Sunset, Moon } from 'lucide-react';
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaRegClock } from "react-icons/fa";
import AstrologyCalculations from "./AstrologyCalculations";
import { useMemo } from "react";
import { FaCalendarAlt } from "react-icons/fa"
import { Link } from "react-router-dom";

const WEATHER_API_KEY = "ca380313c26445359c951955242611";

const Choghdiye = [
  {
    day: "Monday",
    Din: ["Amrit", "Kaal", "Shubh", "Rog", "Udveg", "Chal", "Labh", "Amrit"],
    Raat: ["Chal", "Rog", "Kaal", "Labh", "Udveg", "Shubh", "Amrit", "Chal"],
  },
  {
    day: "Tuesday",
    Din: ["Rog", "Udveg", "Chal", "Labh", "Amrit", "Kaal", "Shubh", "Rog"],
    Raat: ["Kaal", "Labh", "Udveg", "Shubh", "Amrit", "Chal", "Rog", "Kaal"],
  },
  {
    day: "Wednesday",
    Din: ["Labh", "Amrit", "Kaal", "Shubh", "Rog", "Udveg", "Chal", "Labh"],
    Raat: ["Udveg", "Shubh", "Amrit", "Chal", "Rog", "Kaal", "Labh", "Udveg"],
  },
  {
    day: "Thursday",
    Din: ["Shubh", "Rog", "Udveg", "Chal", "Labh", "Amrit", "Kaal", "Shubh"],
    Raat: ["Amrit", "Chal", "Rog", "Kaal", "Labh", "Udveg", "Shubh", "Amrit"],
  },
  {
    day: "Friday",
    Din: ["Chal", "Labh", "Amrit", "Kaal", "Shubh", "Rog", "Udveg", "Chal"],
    Raat: ["Rog", "Kaal", "Labh", "Udveg", "Shubh", "Amrit", "Chal", "Rog"],
  },
  {
    day: "Saturday",
    Din: ["Kaal", "Shubh", "Rog", "Udveg", "Chal", "Labh", "Amrit", "Kaal"],
    Raat: ["Labh", "Udveg", "Shubh", "Amrit", "Chal", "Rog", "Kaal", "Labh"],
  },
  {
    day: "Sunday",
    Din: ["Udveg", "Chal", "Labh", "Labh", "Kaal", "Shubh", "Rog", "Udveg"],
    Raat: ["Shubh", "Amrit", "Chal", "Rog", "Kaal", "Labh", "Udveg", "Shubh"],
  },
];

// Time conversion utilities
const timeStringToMinutes = (timeStr) => {
  const [time, period] = timeStr.split(' ');
  let [hours, minutes] = time.split(':').map(Number);

  if (period === 'PM' && hours !== 12) hours += 12;
  if (period === 'AM' && hours === 12) hours = 0;

  return hours * 60 + minutes;
};

const minutesToTimeString = (minutes) => {
  let hours = Math.floor(minutes / 60) % 24; // Ensure hours wrap around at 24
  const mins = Math.floor(minutes % 60);
  const period = hours >= 12 ? 'PM' : 'AM';

  if (hours > 12) hours -= 12;
  if (hours === 0) hours = 12; // Handle midnight as 12 AM

  return `${hours.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')} ${period}`;
};

// Muhurat calculation logic
const calculateMuhuratTimes = (sunrise, sunset, dayOfWeek) => {
  const sunriseMinutes = timeStringToMinutes(sunrise);
  const sunsetMinutes = timeStringToMinutes(sunset);
  const dayDuration = sunsetMinutes - sunriseMinutes;

  // Calculate Abhijit Muhurat (middle of the day)
  const middayMinutes = sunriseMinutes + dayDuration / 2;
  const abhijitStart = middayMinutes - 24; // Subtract 24 minutes from the middle of the day
  const abhijitEnd = middayMinutes + 24; // Add 24 minutes for the end of Abhijit Muhurat

  // Correct Rahukaal mapping based on the day of the week
  const rahukaalStartMapping = [
    1,  // Monday starts at 1/8 of the day
    2,  // Tuesday starts at 2/8 of the day
    3,  // Wednesday starts at 3/8 of the day
    4,  // Thursday starts at 4/8 of the day
    5,  // Friday starts at 5/8 of the day
    6,  // Saturday starts at 6/8 of the day
    7,  // Sunday starts at 7/8 of the day
  ];

  // Fixed Rahukaal duration (90 minutes)
  const rahukaalDuration = 90;

  // Calculate Rahukaal start based on day mapping
  const rahukaalStart = sunriseMinutes + (dayDuration * (rahukaalStartMapping[dayOfWeek] - 1)) / 8;
  const rahukaalEnd = rahukaalStart + rahukaalDuration; // Add 90 minutes to get the end time

  // Gulikaal calculation (no change)
  const gulikaalDurations = [[6, 7], [5, 6], [4, 5], [3, 4], [2, 3], [1, 2], [7, 8]];
  const [guliStart, guliEnd] = gulikaalDurations[dayOfWeek];
  const gulikaalStart = sunriseMinutes + (dayDuration * guliStart) / 8;
  const gulikaalEnd = sunriseMinutes + (dayDuration * guliEnd) / 8;

  // Yamghant Kaal calculation (similar to Rahukaal)
  const yamghantDurations = [
    [4, 5], [3, 4], [2, 3], [1, 2], [7, 8], [6, 7], [5, 6]
  ];

  const [yamStart, yamEnd] = yamghantDurations[dayOfWeek];
  const yamghantStart = sunriseMinutes + (dayDuration * yamStart) / 8;
  const yamghantEnd = sunriseMinutes + (dayDuration * yamEnd) / 8;

  // Return the results with proper formatting
  return {
    abhijitMuhurat: {
      start: minutesToTimeString(abhijitStart),
      end: minutesToTimeString(abhijitEnd),
    },
    rahukaal: {
      start: minutesToTimeString(rahukaalStart),
      end: minutesToTimeString(rahukaalEnd),
    },
    gulikaal: {
      start: minutesToTimeString(gulikaalStart),
      end: minutesToTimeString(gulikaalEnd),
    },
    yamghantKaal: {
      start: minutesToTimeString(yamghantStart),
      end: minutesToTimeString(yamghantEnd),
    },
  };
};

// Constants for astronomical calculations (adjust these as needed)
const AYANAMSA = 23.4367;  // Sun's Ayanamsa
const OBLIQUITY = 23.4393;  // Earth's axial tilt

// Helper functions for degree/radian conversion
const deg2rad = (deg) => (deg * Math.PI) / 180;
const rad2deg = (rad) => (rad * 180) / Math.PI;

// Function to calculate Julian Day
const julianDay = (date) => {
  const year = date.getUTCFullYear();
  const month = date.getUTCMonth() + 1;
  const day = date.getUTCDate();

  const a = Math.floor((14 - month) / 12);
  const y = year + 4800 - a;
  const m = month + 12 * a - 3;

  const jd =
    day +
    Math.floor((153 * m + 2) / 5) +
    365 * y +
    Math.floor(y / 4) -
    Math.floor(y / 100) +
    Math.floor(y / 400) -
    32045;
  return jd;
};

// Function to calculate Sun's position
const calculateSunPosition = (date) => {
  const jd = julianDay(date);
  const t = (jd - 2451545.0) / 36525.0;

  const L = (280.46646 + 36000.76983 * t + 0.0003032 * Math.pow(t, 2)) % 360;
  const M = 357.52911 + 35999.05029 * t - 0.0001537 * Math.pow(t, 2);
  const e = 0.016708634 - 0.000042037 * t - 0.0000001267 * Math.pow(t, 2);
  const C =
    (1.914602 - 0.004817 * t - 0.000014 * Math.pow(t, 2)) * Math.sin(deg2rad(M)) +
    (0.019993 - 0.000101 * t) * Math.sin(deg2rad(2 * M)) +
    0.000289 * Math.sin(deg2rad(3 * M));

  const O = L + C;
  const v = M + C;
  const R = 1.000001018 * (1 - e * e) / (1 + e * Math.cos(deg2rad(v)));

  const lambda = O - AYANAMSA;
  const beta = 0;

  const alpha = Math.atan2(Math.cos(deg2rad(OBLIQUITY)) * Math.sin(deg2rad(lambda)), Math.cos(deg2rad(lambda)));
  const delta = Math.asin(Math.sin(deg2rad(OBLIQUITY)) * Math.sin(deg2rad(lambda)));

  return [rad2deg(alpha), rad2deg(delta), R];
};

// Function to calculate Moon's position
const calculateMoonPosition = (date) => {
  const jd = julianDay(date);
  const t = (jd - 2451545.0) / 36525.0;

  let L = 218.3164477 + 481267.88123421 * t - 0.0015786 * Math.pow(t, 2) + Math.pow(t, 3) / 538841 -
    Math.pow(t, 4) / 65194000.0;
  const D = 297.8501921 + 445267.1114034 * t - 0.0018819 * Math.pow(t, 2) + Math.pow(t, 3) / 545868 -
    Math.pow(t, 4) / 113065000.0;
  const M = 357.5291092 + 35999.0502909 * t - 0.0001536 * Math.pow(t, 2) + Math.pow(t, 3) / 24490000.0;
  const M_ = 134.9633964 + 477198.8675055 * t + 0.0087414 * Math.pow(t, 2) + Math.pow(t, 3) / 69699.0 -
    Math.pow(t, 4) / 14712000.0;
  const F = 93.2720950 + 483202.0175233 * t - 0.0036539 * Math.pow(t, 2) - Math.pow(t, 3) / 3526000.0 +
    Math.pow(t, 4) / 863310000.0;

  const lambda = L + 6.288774 * Math.sin(deg2rad(M_)) +
    1.274027 * Math.sin(deg2rad(2 * D - M_)) +
    0.658314 * Math.sin(deg2rad(2 * D)) +
    0.213618 * Math.sin(deg2rad(2 * M_)) -
    0.185116 * Math.sin(deg2rad(M)) -
    0.114332 * Math.sin(deg2rad(2 * F));

  const beta = 5.128122 * Math.sin(deg2rad(F)) +
    0.280602 * Math.sin(deg2rad(M_ + F)) +
    0.277693 * Math.sin(deg2rad(M_ - F)) +
    0.173237 * Math.sin(deg2rad(2 * D - F)) +
    0.055413 * Math.sin(deg2rad(2 * D - M_ + F)) +
    0.046271 * Math.sin(deg2rad(2 * D - M_ - F));

  const dist = 385000.56 - 20905.355 * Math.cos(deg2rad(M_)) -
    3699.111 * Math.cos(deg2rad(2 * D - M_)) -
    2955.968 * Math.cos(deg2rad(2 * D)) -
    569.925 * Math.cos(deg2rad(2 * M_));

  const lambdaCorrected = lambda - AYANAMSA;

  const alpha = Math.atan2(Math.cos(deg2rad(OBLIQUITY)) * Math.sin(deg2rad(lambdaCorrected)) -
    Math.sin(deg2rad(OBLIQUITY)) * Math.tan(deg2rad(beta)),
    Math.cos(deg2rad(lambdaCorrected)));
  const delta = Math.asin(Math.sin(deg2rad(OBLIQUITY)) * Math.sin(deg2rad(lambdaCorrected)) *
    Math.cos(deg2rad(beta)) + Math.cos(deg2rad(OBLIQUITY)) * Math.sin(deg2rad(beta)));

  return [rad2deg(alpha), rad2deg(delta), dist];
};

const calculatePaksha = (sunLongitude, moonLongitude) => {
  let difference = (moonLongitude - sunLongitude + 360) % 360;
  if (difference <= 180) {
    return 'Shukla Paksha';
  } else {
    return 'Krishna Paksha';
  }
};

// Function to normalize longitude to the range [0, 360]
const normalizeLongitude = (longitude) => {
  return ((longitude % 360) + 360) % 360;
};

// Update Nakshatra and Pada Calculation to Use Normalized Longitude
const calculateNakshatraAndPada = (moonLongitude) => {
  const normalizedLongitude = normalizeLongitude(moonLongitude);
  const nakshatraSpan = 360.0 / 27; // 27 Nakshatras in a 360-degree circle
  const nakshatraNumber = Math.floor(normalizedLongitude / nakshatraSpan);
  const padaProgress = (normalizedLongitude % nakshatraSpan) / (nakshatraSpan / 4);
  const padaNumber = Math.floor(padaProgress) + 1;

  return [nakshatraNumber, padaNumber];
};

// Function to calculate Tithi
const calculateTithi = (sunLongitude, moonLongitude) => {
  const tithiAngle = (moonLongitude - sunLongitude + 360) % 360;
  return Math.floor(tithiAngle / 12) + 1;
};

// Function to calculate Yoga
const calculateYoga = (sunLongitude, moonLongitude) => {
  const yogaAngle = (sunLongitude + moonLongitude) % 360;
  const positiveYogaAngle = yogaAngle >= 0 ? yogaAngle : yogaAngle + 360;
  const yogaIndex = Math.floor(positiveYogaAngle / (360.0 / 27)) + 1;
  return yogaIndex;
};

// Function to calculate Karana
const calculateKarana = (sunLongitude, moonLongitude) => {
  const tithiAngle = (moonLongitude - sunLongitude + 360) % 360;
  const tithiFraction = tithiAngle / 12.0;
  const karanaIndex = Math.floor(tithiFraction * 2) % 60; // 60 Karanas in total

  // The first Karana of the lunar month is always Kimstughna
  if (karanaIndex === 0) return 1; // Kimstughna

  // The last 7 Karanas of the lunar month are fixed
  if (karanaIndex >= 57) {
    return karanaIndex - 50; // 51 to 57 for the last 7 fixed Karanas
  }

  // For the remaining Karanas, use the 7 rotating Karanas
  return ((karanaIndex - 1) % 7) + 2; // 2 to 8 for the 7 rotating Karanas
};

// Gujarati Month Mapping (Starts from Kartak)
const gujaratiMonths = [
  'Kartak', 'Magshar', 'Paus', 'Magh', 'Phagun', 'Chaitra', 'Vaishakha', 'Jyeshtha',
  'Ashadha', 'Shravana', 'Bhadrapada', 'Ashvin'
];

// Approximate transition dates of Gujarati months (based on Amavasya)
const gujaratiMonthTransitions = [
  { month: 'Kartak', start: '2024-11-02 ' },
  { month: 'Magshar', start: '2024-12-01' },
  { month: 'Paus', start: '2024-12-31' },
  { month: 'Magh', start: '2025-01-30' },
  { month: 'Phagun', start: '2025-02-28' },
  { month: 'Chaitra', start: '2025-03-29' },
  { month: 'Vaishakha', start: '2025-04-28' },
  { month: 'Jyeshtha', start: '2025-05-27' },
  { month: 'Ashadha', start: '2025-06-25' },
  { month: 'Shravana', start: '2025-07-24' },
  { month: 'Bhadrapada', start: '2025-08-23' },
  { month: 'Ashvin', start: '2025-09-22' }
];

const calculateGujaratiMonth = (date) => {
  const currentDate = new Date(date);

  for (let i = gujaratiMonthTransitions.length - 1; i >= 0; i--) {
    const monthStartDate = new Date(gujaratiMonthTransitions[i].start);
    if (currentDate >= monthStartDate) {
      return gujaratiMonthTransitions[i].month;
    }
  }

  return 'Kartak'; // Default to Kartak if no match is found
};


// Add these near your existing name getter functions (getTithiName, getNakshatraName, etc.)
const getZodiacSign = (longitude) => {
  const signs = [
    "Mesh (Aries)", "Vrishabh (Taurus)", "Mithun (Gemini)",
    "Kark (Cancer)", "Sinh (Leo)", "Kanya (Virgo)",
    "Tula (Libra)", "Vrishchik (Scorpio)", "Dhanu (Sagittarius)",
    "Makar (Capricorn)", "Kumbh (Aquarius)", "Meen (Pisces)"
  ];

  const signIndex = Math.floor(longitude / 30);
  return signs[signIndex];
};

// Function to get names based on the index
const getTithiName = (tithi) => {
  const tithiNames = [
    "Pratipada", "Dwitiya", "Tritiya", "Chaturthi", "Panchami", "Shashti", "Saptami", "Ashtami",
    "Navami", "Dashami", "Ekadashi", "Dwadashi", "Trayodashi", "Chaturdashi", "Purnima", "Pratipada",
    "Dwitiya", "Tritiya", "Chaturthi", "Panchami", "Shashti", "Saptami", "Ashtami", "Navami", "Dashami",
    "Ekadashi", "Dwadashi", "Trayodashi", "Chaturdashi", "Amavasya"
  ];
  return tithiNames[tithi - 1];
};

const getNakshatraName = (nakshatra) => {
  const nakshatraNames = [
    "Ashwini", "Bharani", "Krittika", "Rohini", "Mrigashira", "Ardra", "Punarvasu", "Pushya", "Ashlesha",
    "Magha", "Purva Phalguni", "Uttara Phalguni", "Hasta", "Chitra", "Swati", "Vishakha", "Anuradha",
    "Jyeshtha", "Mula", "Purva Ashadha", "Uttara Ashadha", "Shravana", "Dhanishtha", "Shatabhisha",
    "Purva Bhadrapada", "Uttara Bhadrapada", "Revati"
  ];
  return nakshatraNames[nakshatra];
};

const getYogaName = (yoga) => {
  const yogaNames = [
    "Vishkambha", "Priti", "Ayushman", "Saubhagya", "Shobhana", "Atiganda", "Sukarma", "Dhriti", "Shula",
    "Ganda", "Vriddhi", "Dhruva", "Vyaghata", "Harshana", "Vajra", "Siddhi", "Vyatipata", "Variyan", "Parigha",
    "Shiva", "Siddha", "Sadhya", "Shubha", "Shukla", "Brahma", "Indra", "Vaidhriti"
  ];
  return yogaNames[yoga - 1];
};

const getKaranaName = (karana) => {
  const karanaNames = [
    "Kimstughna", "Bava", "Balava", "Kaulava", "Taitila", "Gara", "Vanija", "Vishti",
    "Shakuni", "Chatushpada", "Naga"
  ];
  return karanaNames[karana - 1];
};

const calculatePanchangData = (date) => {
  const sunPosition = calculateSunPosition(date);
  const moonPosition = calculateMoonPosition(date);

  const sunLongitude = normalizeLongitude(sunPosition[0]);
  const moonLongitude = normalizeLongitude(moonPosition[0]);

  const tithi = calculateTithi(sunLongitude, moonLongitude);
  const nakshatraAndPada = calculateNakshatraAndPada(moonLongitude);
  const yoga = calculateYoga(sunLongitude, moonLongitude);
  const karana = calculateKarana(sunLongitude, moonLongitude);
  const paksha = calculatePaksha(sunLongitude, moonLongitude);
  const sunSign = getZodiacSign(sunLongitude);
  const moonSign = getZodiacSign(moonLongitude);


  return {
    tithi: getTithiName(tithi),
    nakshatra: `${getNakshatraName(nakshatraAndPada[0])}`,
    yoga: getYogaName(yoga),
    karana: getKaranaName(karana),
    paksha: paksha,
    sunSign, // Add Sun Sign
    moonSign, // Add Moon Sign
  };
};

// New function to calculate moonrise and moonset
const calculateMoonRiseSet = (date, lat, lon) => {
  const jd = julianDay(date);
  const dayStart = jd - 0.5; // Start at midnight UTC
  const steps = 48; // Check every 30 minutes (24 hours / 0.5)
  let moonrise = null, moonset = null;

  // Simple altitude-based approximation
  for (let i = 0; i < steps; i++) {
    const time = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()) + (i * 30 * 60 * 1000));
    const [ra, dec] = calculateMoonPosition(time); // Right ascension and declination

    // Convert to local hour angle and altitude (simplified)
    const lst = (jd + i / 48 - 2451545.0) * 360 / 365.25 + lon; // Local sidereal time (approx)
    const ha = (lst - ra + 360) % 360; // Hour angle
    const alt = Math.asin(Math.sin(deg2rad(lat)) * Math.sin(deg2rad(dec)) +
      Math.cos(deg2rad(lat)) * Math.cos(deg2rad(dec)) * Math.cos(deg2rad(ha)));

    const prevTime = new Date(time - 30 * 60 * 1000);
    const [prevRa, prevDec] = calculateMoonPosition(prevTime);
    const prevHa = (lst - prevRa + 360) % 360;
    const prevAlt = Math.asin(Math.sin(deg2rad(lat)) * Math.sin(deg2rad(prevDec)) +
      Math.cos(deg2rad(lat)) * Math.cos(deg2rad(prevDec)) * Math.cos(deg2rad(prevHa)));

    // Detect horizon crossing (altitude changes sign)
    if (prevAlt < 0 && alt >= 0 && !moonrise) {
      const minutes = Math.floor((i - 1) * 30 + (30 * -prevAlt) / (alt - prevAlt));
      moonrise = minutesToTimeString(minutes);
    }
    if (prevAlt >= 0 && alt < 0 && !moonset) {
      const minutes = Math.floor((i - 1) * 30 + (30 * prevAlt) / (prevAlt - alt));
      moonset = minutesToTimeString(minutes);
    }
  }

  return { moonrise: moonrise || "N/A", moonset: moonset || "N/A" };
};

export default function PanchangDashboard() {
  const [city, setCity] = useState("Ahmedabad, India");
  const [error, setError] = useState("");
  const [currentTime, setCurrentTime] = useState(new Date());
  const [celestialTimes, setCelestialTimes] = useState({
    sunrise: "",
    sunset: "",
    moonrise: "",
    moonset: "",
    abhijitMuhurat: "",
    rahukaal: "",
    gulikaal: "",
    yamghantKaal: ""
  });
  const [choghadiya, setChoghadiya] = useState({ din: [], raat: [] });
  const [rutu, setRutu] = useState('');
  const [golardh, setGolardh] = useState('');
  const [Ayan, setAyan] = useState('');
  const [day, setDay] = useState('');
  const [vikramSamvat, setVikramSamvat] = useState('');
  const [shakaSamvat, setShakaSamvat] = useState('');
  const { sunrise, sunset } = celestialTimes;
  // Memoize today and tomorrow
  const { today, tomorrow, todayWeek } = useMemo(() => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);

    return {
      today,
      tomorrow,
      todayWeek: today.toLocaleDateString("en-US", { weekday: "long" }),
    };
  }, []);
  const weekFilter = Choghdiye.filter(e => e.day === todayWeek);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [citySuggestions, setCitySuggestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [panchangData, setPanchangData] = useState({
    tithi: "",
    nakshatra: "",
    yoga: "",
    karana: "",
    paksha: "",
    sunSign: "", // Initialize Sun Sign
    moonSign: "", // Initialize Moon Sign
  });
  // const [currentDate, setCurrentDate] = useState(new Date());
  const [gujaratiMonth, setGujaratiMonth] = useState('');
  const [dishaShool, setDishaShool] = useState(''); // New state for Disha Shool
  const updatePanchangData = (date) => {
    setPanchangData(calculatePanchangData(date)); // Use the new function
    setGujaratiMonth(calculateGujaratiMonth(date));
  };
  useEffect(() => {
    const calculateDishaShool = (date) => {
      const weekday = date.toLocaleString("en-US", { weekday: "long" });

      // Corrected Disha Shool directions based on Vedic astrology
      const dishaShoolMap = {
        Sunday: "West",
        Monday: "East",
        Tuesday: "North",
        Wednesday: "North",
        Thursday: "South",
        Friday: "West",
        Saturday: "East",
      };

      // Special case overrides (extend as needed)
      const specialCases = {
        "2025-01-20": "West", // Example override for a specific date
      };

      const selectedDateString = date.toISOString().split("T")[0]; // YYYY-MM-DD
      return specialCases[selectedDateString] || dishaShoolMap[weekday] || "Unknown";
    };

    const dishaShoolDirection = calculateDishaShool(selectedDate);
    setDishaShool(dishaShoolDirection);
  }, [selectedDate]); // Dependency on selectedDate


  // Start a timer to update the current time
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000); // Update every second

    return () => clearInterval(timer); // Cleanup the interval on unmount
  }, []);
  // useEffect(() => {
  //   updatePanchangData(currentDate);
  // }, [currentDate]);

  // this is for a ayan golarth and ayan 
  useEffect(() => {
    // Get the current date
    const currentDate = new Date();
    const currentDay = currentDate.getDate();
    const currentMonth = currentDate.getMonth() + 1; // 0-based index, so adding 1
    setDay(new Date().toLocaleString('en-US', { weekday: 'long' })); // this gives a current day

    // Helper function to check if the current date is within a range (adjusted for cross-year ranges)
    const isDateInRange = (month, day, startMonth, startDay, endMonth, endDay) => {
      if (startMonth < endMonth || (startMonth === endMonth && startDay <= endDay)) {
        // Regular range (within the same year)
        return (
          (month > startMonth || (month === startMonth && day >= startDay)) &&
          (month < endMonth || (month === endMonth && day <= endDay))
        );
      } else {
        // Wraps around the year (e.g., September 24th to March 20th)
        return (
          (month > startMonth || (month === startMonth && day >= startDay)) ||
          (month < endMonth || (month === endMonth && day <= endDay))
        );
      }
    };

    // Function to calculate Rutu based on the exact date                         
    const calculateRutu = (month, day) => {
      if (isDateInRange(month, day, 12, 22, 2, 19)) return 'Shishir Rutu(Winter)';
      if (isDateInRange(month, day, 2, 19, 4, 21)) return 'Vasant Rutu(Spring)';
      if (isDateInRange(month, day, 4, 21, 6, 21)) return 'Grishm Rutu(Summer)';
      if (isDateInRange(month, day, 6, 21, 8, 21)) return 'Varsha Rutu(Monsoon)';
      if (isDateInRange(month, day, 8, 21, 10, 22)) return 'Sharad Rutu(Autumn)';
      if (isDateInRange(month, day, 10, 22, 12, 21)) return 'Hemant Rutu'; // Pre-winter                  
      return 'Invalid date';
    };

    // Modified Golardh calculation based on date ranges
    const calculateGolardh = (month, day) => {
      // North Gol: March 21 to September 23
      if (isDateInRange(month, day, 3, 21, 9, 23)) return 'North Gol';
      // South Gol: September 24 to March 20
      if (isDateInRange(month, day, 9, 24, 3, 20)) return 'South Gol';
      return 'Invalid date';
    };

    // Modified Ayan calculation based on the new date ranges
    const calculateAyan = (month, day) => {
      // Uttarayan: 23rd Dec to 20th June
      // Dakshinayan: 21st June to 22nd Dec
      if (isDateInRange(month, day, 6, 21, 12, 22)) return "Dakshinayana";  // Dakshinayan (Southward movement)
      if (isDateInRange(month, day, 12, 23, 6, 20)) return "Uttarayana";   // Uttarayan (Northward movement)
      return 'Invalid date';
    };

    const calculatedRutu = calculateRutu(currentMonth, currentDay);
    setRutu(calculatedRutu);
    setGolardh(calculateGolardh(currentMonth, currentDay)); // Updated Golardh calculation
    setAyan(calculateAyan(currentMonth, currentDay));

    // Get the current year (Gregorian)
    const currentYear = new Date().getFullYear();

    // Vikram Samvat is approximately 57/56 years ahead of the Gregorian calendar
    const vikramSamvatYear = currentYear + 56;

    // Shaka Samvat is approximately 78/79 years ahead of the Gregorian calendar
    const shakaSamvatYear = currentYear - 79;

    // Set the state for Vikram Samvat and Shaka Samvat
    setVikramSamvat(vikramSamvatYear);
    setShakaSamvat(shakaSamvatYear);
  }, []);

  // Function to get city suggestions from the OpenCage API
  const getCitySuggestions = async (input) => {
    const apiKey = '8a8bf13b0b694e5c99c719d998a9ff6c';

    // other api keyfor backup 
    // const apikey ='443bfa6adb754281a21554287a684799';

    if (input.length < 3) { // Show suggestions only after 3 characters
      setCitySuggestions([]);
      return;
    }

    setLoading(true);
    const url = `https://api.opencagedata.com/geocode/v1/json?q=${input}&key=${apiKey}&language=en&pretty=1`;

    try {
      const response = await fetch(url);
      const data = await response.json();
      console.log("dataaaaaaa", data);

      if (data.results) {
        const suggestions = data.results.map((result) => result.formatted);
        setCitySuggestions(suggestions);
      }
    } catch (error) {
      console.error("Error fetching city suggestions:", error);
    }

    setLoading(false);
  };

  // Event handler for input changes
  const handleCityChange = (e) => {
    const value = e.target.value;
    setCity(value);
    getCitySuggestions(value);
  };

  // Event handler for selecting a suggestion
  const handleCitySelect = (city) => {
    setCity(city);
    setCitySuggestions([]); // Clear suggestions after selecting
  };


  // Update useEffect to use selectedDate

  // Trigger updates when the selected date changes
  useEffect(() => {
    if (city && selectedDate) {
      updatePanchangData(selectedDate); // Update Panchang data
      fetchAstronomyData(selectedDate); // Fetch astronomical data
    }
  }, [selectedDate, city]);


  const fetchAstronomyData = async (date) => {
    try {
      const selectedDay = date.toLocaleDateString("en-US", { weekday: "long" }); // Calculate selectedDay here
      const response = await axios.get("https://api.weatherapi.com/v1/astronomy.json", {
        params: {
          key: WEATHER_API_KEY,
          q: city,
          dt: date.toISOString().split('T')[0], // Send the selected date in YYYY-MM-DD format
        }
      });
      const { astronomy } = response.data;
      const { sunrise, sunset, moonrise, moonset } = astronomy.astro;
      setCelestialTimes(prev => ({
        ...prev,
        sunrise,
        sunset,
        moonrise,
        moonset,
        abhijitMuhurat: "",
        gulikaal: "",
        rahukaal: "",
        yamghantKaal: "",
        ...calculateMuhuratTimes(sunrise, sunset, date.getDay())
      }));

      calculateChoghadiya(sunrise, sunset, selectedDay);
      setError("");
    } catch (err) {
      setError("Failed to fetch astronomical data. Please check the city name.");
      setCelestialTimes({
        sunrise: "",
        sunset: "",
        moonrise: "",
        moonset: "",
        abhijitMuhurat: "",
        gulikaal: "",
        rahukaal: "",
        yamghantKaal: ""
      });
    }
  };

  const calculateChoghadiya = (sunrise, sunset, selectedDay) => {
    const sunriseMinutes = timeStringToMinutes(sunrise);
    const sunsetMinutes = timeStringToMinutes(sunset);

    const dayDuration = (sunsetMinutes - sunriseMinutes) / 8;
    const nightDuration = (24 * 60 - (sunsetMinutes - sunriseMinutes)) / 8;

    // Calculate time slots for Din Choghadiya
    const dinChoghadiya = Array.from({ length: 8 }, (_, i) => ({
      start: minutesToTimeString(sunriseMinutes + dayDuration * i),
      end: minutesToTimeString(sunriseMinutes + dayDuration * (i + 1)),
    }));

    // Calculate time slots for Raat Choghadiya
    const raatChoghadiya = Array.from({ length: 8 }, (_, i) => {
      const startMinutes = sunsetMinutes + nightDuration * i;
      const endMinutes = sunsetMinutes + nightDuration * (i + 1);

      return {
        start: minutesToTimeString(startMinutes),
        end: minutesToTimeString(endMinutes),
      };
    });

    // Get Choghadiya names for the selected day
    const filteredChoghadiya = Choghdiye.find((item) => item.day === selectedDay);

    // Attach names to time slots
    const dinWithNames = dinChoghadiya.map((slot, i) => ({
      ...slot,
      name: filteredChoghadiya?.Din[i] || "N/A",
    }));

    const raatWithNames = raatChoghadiya.map((slot, i) => ({
      ...slot,
      name: filteredChoghadiya?.Raat[i] || "N/A",
    }));

    setChoghadiya({ din: dinWithNames, raat: raatWithNames });
  };

  // Now you can safely check if sunrise and sunset are defined before using them
  const isDayTime = (sunrise, sunset) => {
    if (!sunrise || !sunset) return false; // Ensure both sunrise and sunset are available

    const now = new Date();
    const currentMinutes = now.getHours() * 60 + now.getMinutes();
    const sunriseMinutes = timeStringToMinutes(sunrise);
    const sunsetMinutes = timeStringToMinutes(sunset);

    if (sunsetMinutes < sunriseMinutes) {
      return currentMinutes >= sunriseMinutes || currentMinutes < sunsetMinutes;
    } else {
      return currentMinutes >= sunriseMinutes && currentMinutes < sunsetMinutes;
    }
  };



  const handleSetToday = () => {
    setSelectedDate(today); // Update selected date to today
  };

  const handleSetTomorrow = () => {
    setSelectedDate(tomorrow); // Update selected date to tomorrow
  };

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     // Trigger a state update to refresh the component
  //     setCurrentDate(new Date());
  //   }, 60000);

  //   return () => clearInterval(interval); // Cleanup interval on unmount
  // }, []);

  return (
    <div className=" p-6 container mx-auto px-4 py-8">
      {/* Navigation */}
      <nav className="flex mb-4 text-sm">
        <Link to="/" className="text-orange-500 hover:text-orange-600 font-bold">
          Home
        </Link>
        <span className="mx-2 text-gray-500">&gt;</span>
        <span className="text-gray-600 font-bold">Panchang</span>
      </nav>

      {/* Location and Date Selection */}
      <div className="container mb-10">
        <h1 className="text-3xl font-bold text-gray-900 mb-4">
          Panchang: The Wisdom of Time and Space
        </h1>
        <p className="text-gray-600">
          Panchang is an important aspect of Hindu astrology that provides a
          detailed view of the time and its influence on various activities. It
          includes key details such as Tithi, Nakshatra, Yog, Karan, and the
          times of sunrise, sunset, moonrise, and moonset. Each of these
          elements plays an essential role in understanding the most auspicious
          times for performing tasks and rituals.
        </p>
      </div>

      {/* City Input */}
      <div className="flex flex-col sm:flex-row gap-4 mb-6">
        <div className="relative flex-1">
          <CustomInput
            type="text"
            placeholder="Enter City"
            className="pl-10"
            value={city}
            onChange={handleCityChange}
          />
          <Search className="absolute  top-3 left-3 h-4 text-black" />
          {/* Suggestions */}
          {citySuggestions.length > 0 && (
            <ul className="absolute mt-1 w-full bg-white border border-gray-300 rounded-md shadow-md z-10">
              {citySuggestions.map((suggestion, index) => (
                <li
                  key={index}
                  onClick={() => handleCitySelect(suggestion)}
                  className="p-2 cursor-pointer hover:bg-gray-100"
                >
                  {suggestion}
                </li>
              ))}
            </ul>
          )}
        </div>

        <div className="flex flex-wrap justify-center gap-2 sm:gap-4">
          <DatePicker
            selected={selectedDate}
            onChange={(date) => setSelectedDate(date)}
            dateFormat="dd-MM-yyyy"
            showMonthDropdown
            showYearDropdown
            dropdownMode="select" // Dropdown को Enable करता है
            // Hide the native input
            customInput={
              <div className="flex items-center p-2 border-2 border-orange-500 rounded-lg cursor-pointer">
                <FaCalendarAlt className="text-orange-500 mr-2" />
                <span className="text-gray-700">
                  {selectedDate
                    ? selectedDate.toLocaleDateString("en-GB") // 'en-GB' outputs dd-MM-yyyy
                    : "Select a Date"}
                </span>
              </div>
            }
          />
          {/* <CustomButton className="cursor-pointer" variant="outline">
            Day:
            <span className="text-gray-700 ml-1">{day}</span>
          </CustomButton> */}

          <CustomButton
            className="cursor-pointer"
            variant="outline"
            onClick={handleSetTomorrow}
          >
            Tomorrow:{" "}
            <span className="text-gray-700 ml-1"> {tomorrow.toLocaleDateString("en-GB")} {/* Format as dd-MM-yyyy */}</span>
          </CustomButton>

        </div>
      </div>

      {error && <p className="text-red-500">{error}</p>}

      {/* Sun Moon Times */}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4 mb-8 mt-5">
        <TimeCard
          icon={<Sunrise className="w-6 h-6 text-orange-500" />}
          title="Sunrise"
          time={celestialTimes.sunrise}
        />
        <TimeCard
          icon={<Sunset className="w-6 h-6 text-orange-500" />}
          title="Sunset"
          time={celestialTimes.sunset}
        />
        <TimeCard
          icon={<Moon className="w-6 h-6 text-blue-500" />}
          title="Moonrise"
          time={celestialTimes.moonrise}
        />
        <TimeCard
          icon={<Moon className="w-6 h-6 text-blue-500" />}
          title="Moonset"
          time={celestialTimes.moonset}
        />
      </div>

      <div className="container mx-auto space-y-6">
        {/* Choghadiya and Panchang Side by Side */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 md:grid-cols-1 gap-6">
          {/* Panchang */}
          <div>
            <h2 className="text-xl font-semibold mb-4 underline underline-offset-8">
              Panchang
            </h2>
            <CustomCard>
              <CardContent className="border-t-2 border-gray-200">
                <div className="space-y-4">
                  {/* Tithi Row */}
                  <div className="flex flex-wrap justify-between gap-6 border p-4 rounded-lg border-gray-300 text-center">
                    <DetailRow label="Day" value={day} />
                    <DetailRow label="Gujarati Month" value={gujaratiMonth} />
                    <DetailRow label="Paksha" value={panchangData.paksha} />
                    <DetailRow label="Tithi" value={panchangData.tithi} />
                  </div>
                  {/* Nakshatra, Yog, Karan */}
                  <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
                    <div className="border p-4 rounded-lg border-gray-300">
                      <DetailRow label="Nakshatra" value={panchangData.nakshatra} />
                    </div>
                    <div className="border p-4 rounded-lg border-gray-300">
                      <DetailRow label="Yog" value={panchangData.yoga} />
                    </div>
                    <div className="border p-4 rounded-lg border-gray-300">
                      <DetailRow label="Karan" value={panchangData.karana} />
                    </div>
                  </div>
                </div>
              </CardContent>
            </CustomCard>

            {/* Hindu Calendar */}
            <CustomCard className="border-gray-300 rounded-lg mt-4">
              <CardContent className="border-t-2 border-gray-200">
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
                  <DetailRow label="Vikram Samvat" value={vikramSamvat} />
                  <DetailRow label="Shaka Samvat" value={shakaSamvat} />
                  <DetailRow label="SunSign" value={panchangData.sunSign} /> {/* Updated */}
                  <DetailRow label="MoonSign" value={panchangData.moonSign} />
                  <DetailRow label="Ayana" value={Ayan} />
                  <DetailRow label="Rutu" value={rutu} />
                  <DetailRow label="Gol" value={golardh} />
                  <DetailRow label="Disha Shool" value={dishaShool} />
                </div>
              </CardContent>
            </CustomCard>
          </div>

          {/* Choghadiya */}
          <div>
            <h2 className="text-xl font-semibold mb-4 underline underline-offset-8">
              Choghadiya
            </h2>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
              {/* Day Choghadiya */}
              <div className="rounded-lg shadow-md border border-gray-200">
                <div className="bg-[#F97316] py-3 px-4 rounded-t-lg flex justify-center items-center gap-2">
                  <Sun className="w-5 h-5 text-white" />
                  <h2 className="font-semibold text-lg text-white">Day Choghadiya</h2>
                </div>
                <div className="p-4">
                  <ul className="space-y-2">
                    {choghadiya.din.map((e, i) => {
                      const currentMinutes =
                        currentTime.getHours() * 60 + currentTime.getMinutes();
                      const startMinutes = timeStringToMinutes(e.start);
                      const endMinutes = timeStringToMinutes(e.end);
                      const isCurrentChoghadiya =
                        currentMinutes >= startMinutes && currentMinutes < endMinutes;

                      const textColor = ["Labh", "Shubh", "Amrit"].includes(e.name)
                        ? "#4CAF50"
                        : e.name === "Chal"
                          ? "#2196F3"
                          : "#F44336";

                      return (
                        <li
                          key={i}
                          className={`
                  py-2 border-b border-gray-300
                  ${isCurrentChoghadiya ? 'bg-orange-50' : ''}
                `}
                        >
                          <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between gap-2">
                            <div className="flex items-center gap-2 min-w-[140px]">
                              <span style={{ color: textColor, fontWeight: 500 }}>
                                {e.start} To {e.end}
                              </span>
                              {isCurrentChoghadiya && (
                                <FaRegClock className="text-lg text-gray-600" />
                              )}
                            </div>
                            <span
                              className="font-medium"
                              style={{ color: textColor }}
                            >
                              {e.name}
                            </span>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>

              {/* Night Choghadiya */}
              <div className="rounded-lg shadow-md border border-gray-200">
                <div className="bg-[#002538] py-3 px-4 rounded-t-lg flex justify-center items-center gap-2">
                  <Moon className="w-5 h-5 text-white" />
                  <h2 className="font-semibold text-lg text-white">Night Choghadiya</h2>
                </div>
                <div className="p-4">
                  <ul className="space-y-2">
                    {choghadiya.raat.map((e, i) => {
                      const currentMinutes =
                        currentTime.getHours() * 60 + currentTime.getMinutes();
                      const startMinutes = timeStringToMinutes(e.start);
                      const endMinutes = timeStringToMinutes(e.end);
                      const isCurrentChoghadiya =
                        currentMinutes >= startMinutes && currentMinutes < endMinutes;

                      const textColor = ["Labh", "Shubh", "Amrit"].includes(e.name)
                        ? "#4CAF50"
                        : e.name === "Chal"
                          ? "#2196F3"
                          : "#F44336";

                      return (
                        <li
                          key={i}
                          className={`
                  py-2 border-b border-gray-300
                  ${isCurrentChoghadiya ? 'bg-blue-50' : ''}
                `}
                        >
                          <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between gap-2">
                            <div className="flex items-center gap-2 min-w-[140px]">
                              <span style={{ color: textColor, fontWeight: 500 }}>
                                {e.start} To {e.end}
                              </span>
                              {isCurrentChoghadiya && (
                                <FaRegClock className="text-lg text-gray-600" />
                              )}
                            </div>
                            <span
                              className="font-medium"
                              style={{ color: textColor }}
                            >
                              {e.name}
                            </span>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>

        </div>

        {/* Auspicious/ Inauspicious Time */}
        <div>
          <h2 className="text-xl font-semibold mb-4 underline underline-offset-8">Auspicious/ Inauspicious Time</h2>
          <div className="grid sm:grid-cols-2 md:grid-cols-4 gap-4">
            <AuspiciousCard
              title="Abhijeet Muhurat"
              startTime={celestialTimes.abhijitMuhurat.start}
              endTime={celestialTimes.abhijitMuhurat.end}
              className="bg-blue-100"
            />
            <AuspiciousCard
              title="GuliKaal"
              startTime={celestialTimes.gulikaal.start}
              endTime={celestialTimes.gulikaal.end}
              className="bg-orange-100"
            />
            <AuspiciousCard
              title="Rahukaal"
              startTime={celestialTimes.rahukaal.start}
              endTime={celestialTimes.rahukaal.end}
              className="bg-red-100"
            />
            <AuspiciousCard
              title="Yamghant Kaal"
              startTime={celestialTimes.yamghantKaal.start}
              endTime={celestialTimes.yamghantKaal.end}
              className="bg-purple-100"
            />
          </div>
          <AstrologyCalculations />
        </div>
      </div>
    </div>
  );

}

// TimeCard: Card for displaying sun-moon times
function TimeCard({ icon, title, time }) {
  return (
    <CustomCard>
      <CardContent className="p-4 flex items-center gap-3">
        {icon}
        <div>
          <h3 className="font-medium">{title}</h3>
          <p className="text-sm text-gray-500">{time}</p>
        </div>
      </CardContent>
    </CustomCard>
  );
}

// AuspiciousCard: Card for displaying auspicious times

function AuspiciousCard({
  title,
  startTime,
  endTime,
  className
}) {
  return (
    <CustomCard className={className}>
      <CardContent className="p-4 border-t-2">
        <h3 className="font-medium mb-1">{title}</h3>
        <p className="text-sm  text-gray-600">
          {startTime} - {endTime}
        </p>
      </CardContent>
    </CustomCard>
  );
}

// DetailRow: Display a label-value pair with an optional sub-value
function DetailRow({ label, value, subValue }) {
  return (
    <div className="space-y-1 text-start">
      <div className="text-sm text-gray-600 font-medium">{label}</div>
      <div>
        <span className="">{value}</span>
        {subValue && (
          <span className="text-sm text-gray-500 ml-2">at {subValue}</span>
        )}
      </div>
    </div>
  );
}

// CustomInput: Styled input component using Tailwind
function CustomInput({ type, placeholder, className, value, onChange }) {
  return (
    <input
      type={type}
      placeholder={placeholder}
      className={`w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500 ${className}`}
      value={value}
      onChange={onChange}
    />
  );
}

// CustomButton: Button component with outline and filled variants
function CustomButton({ children, variant, className, onClick }) {
  const baseStyle = 'py-2 px-4 rounded-md text-sm font-medium focus:outline-none focus:ring-2';
  const variantStyle = variant === 'outline'
    ? 'border-2 border-orange-500 text-orange-600 bg-transparent hover:bg-orange-100'
    : 'bg-orange-500 text-white hover:bg-orange-600';

  const handleClick = () => {
    if (onClick) onClick();  // Make sure onClick is triggered
  };

  return (
    <span className={`${baseStyle} ${variantStyle} ${className}`} onClick={handleClick}>
      {children}
    </span>
  );
}

// CustomCard: Card component for wrapping content
function CustomCard({ children, className }) {
  return (
    <div className={`shadow-md rounded-lg overflow-hidden ${className || "bg-white"}`}>
      {children}
    </div>
  );
}


// CardContent: Content wrapper for CustomCard
function CardContent({ children, className }) {
  return <div className={`p-4 ${className}`}>{children}</div>;
}

export { calculatePanchangData, calculateGujaratiMonth };