import {
  FaHome,
  FaStar,
  FaRegComments,
  FaPhoneAlt,
  FaRegChartBar,
} from "react-icons/fa";
import { AiOutlineArrowRight, AiOutlineUser } from "react-icons/ai";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { FaDownload } from "react-icons/fa";
import jsPDF from "jspdf";
import "jspdf-autotable";
import How_To_Chat from "./How_To_Chat/How_To_Chat";
import { useEffect, useState } from "react";
import axios from "axios";
import SlotPicker from "../SlotPicker/SlotPicker";
import Swal from "sweetalert2";

export default function Pandit_Profilea() {
  const { id } = useParams();
  const queryParams = new URLSearchParams(window.location.search);

  // Extract specific query parameters
  const U_Id = queryParams.get("P_id");
  const price = queryParams.get("price");
  const subcatid = queryParams.get("pjid");

  // State hooks for storing data
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [subcategoryData, setSubcategoryData] = useState(null);
  const [sidebarVisible, setSidebarVisible] = useState(false); // State for sidebar visibility
  const [PanditData, setPanditData] = useState(null);

  // Fetch user data on U_Id change
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `https://vpandit.caxis.ca/public/api/users/${U_Id}`
        );
        setUserData(response.data); // Update state with the fetched data
        console.log(response.data); // For debugging
      } catch (err) {
        setError("Failed to fetch user data. Please try again.");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    if (U_Id) {
      fetchUserData();
    } else {
      setError("Invalid or missing U_Id in query parameters.");
      setLoading(false);
    }
  }, [U_Id]);


  const [panditid, setpandit] = useState(null);
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        setLoading(true);

        // Step 1: Fetch data from the API
        const response = await axios.get(
          "https://vpandit.caxis.ca/public/api/pandit-masters"
        );

        if (response.data && Array.isArray(response.data.pandits)) {
          // Step 2: Filter response where U_Id matches
          const filteredPandit = response.data.pandits.find(
            (pandit) => String(pandit.U_Id) === String(U_Id)
          );

          if (filteredPandit) {
            setPanditData(filteredPandit); // Store only the matched pandit
            console.log("User Data:", filteredPandit.Pandit_Id); // Debugging
            setpandit(filteredPandit.Pandit_Id);
          } else {
            setError("No user found with the given U_Id.");
          }
        } else {
          setError("Invalid response structure.");
        }
      } catch (err) {
        setError("Failed to fetch user data. Please try again.");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    if (U_Id) {
      fetchUserData();
    } else {
      setError("Invalid or missing U_Id in query parameters.");
      setLoading(false);
    }
  }, [U_Id]);

  // Fetch subcategory data on subcatid change
  useEffect(() => {
    const fetchSubcategoryData = async () => {
      try {
        setLoading(true);
        setError(null); // Reset error state

        // Make the GET request for subcategory
        const response = await axios.get(
          `https://vpandit.caxis.ca/public/api/subcategories/${subcatid}`
        );

        // Store the response data in state
        setSubcategoryData(response.data);
        console.log(response.data); // For debugging
      } catch (err) {
        console.error("Error fetching subcategory data:", err);
        setError("Failed to fetch subcategory details. Please try again.");
      } finally {
        setLoading(false); // Set loading to false after request completes
      }
    };

    if (subcatid) {
      fetchSubcategoryData();
    }
  }, [subcatid]);

  // Toggle sidebar visibility
  const handleBookPoojaClick = () => {
    setSidebarVisible(!sidebarVisible);
  };

  const navigate = useNavigate();

  return (
    <div className="container mx-auto px-4 py-8 min-h-screen">
      {/* Breadcrumb */}
      <nav className="flex mb-8 text-sm">
        <Link
          to="/"
          className="text-orange-500 hover:text-orange-600 font-bold"
        >
          Home
        </Link>
        <span className="mx-2 text-gray-500">&gt;</span>
        <Link to={"/pooja-vidhi"} className="text-gray-600 font-bold">
          Pooja Vidhi
        </Link>
        <span className="mx-2 text-gray-500">&gt;</span>
        <span className="text-gray-600 font-bold">{id}</span>
      </nav>

      <div className="grid md:grid-cols-[300px,1fr] gap-6">
        {/* Profile Card */}
        <div className="bg-gray-100 p-6 rounded-lg border">
          <div className="flex flex-col items-center">
            <div className="w-48 h-48 rounded-full border-4 border-white overflow-hidden mb-4">
              <img
                src="https://www.tech101.in/wp-content/uploads/2018/07/blank-profile-picture.png"
                alt="Profile"
                className="w-full h-full object-cover"
              />
            </div>
            <div className="w-full py-2 px-4 border-2 border-orange-500 text-orange-500 rounded-md hover:bg-orange-50 transition-colors flex items-center justify-center gap-1">
              <AiOutlineUser /> FOLLOW
            </div>
          </div>
        </div>

        {/* Main Content */}
        <div className="space-y-6">
          {/* Header */}
          <div className="space-y-4">
            <div className="flex items-center justify-between gap-2">
              <div className="flex gap-2 items-center justify-between">
                <h1 className="text-2xl font-bold ">
                  {userData?.U_Name || "User Name Not Available"}
                </h1>
              </div>
            </div>

            {/* Personal Info */}
            <div className="space-y-2">
              <p className="text-gray-700">
                <span className="font-semibold">Experience:</span>{" "}
                {PanditData?.Experience || "Experience Not Available"} Year
              </p>
            </div>
          </div>

          {/* Consultation Info */}
          <div className="grid grid-cols-1 border rounded-lg overflow-hidden">
            <div className="p-4 border-r">
              <p className="mb-3 text-gray-900 text-2xl font-bold">
                Pooja Charge For {subcategoryData?.SubCat_Name || "Loading"}
              </p>
              <p className="font-semibold">₹ {price}</p>

              {/* Small Button for "Book Pooja" */}
              <div
                onClick={() => {
                  const token = localStorage.getItem("token");
                  const role = localStorage.getItem("role"); // Fetch the role from localStorage

                  if (token) {
                    // Check the role and perform actions accordingly
                    if (role === "1") {
                      Swal.fire({
                        icon: "info",
                        title: "Admin Access",
                        text: "You are Admin",
                      });
                    } else if (role === "2") {
                      Swal.fire({
                        icon: "info",
                        title: "Pandit Access",
                        text: "You are already Pandit",
                      });
                    } else if (role === "3") {
                      handleBookPoojaClick(); // Call the function for role 3
                    } else {
                      Swal.fire({
                        icon: "warning",
                        title: "Unknown Role",
                        text: "Role is not recognized",
                      });
                    }
                  } else {
                    navigate("/login");
                  }
                }}
                className="cursor-pointer mt-4 px-2 py-1 md:w-[10%] w-full text-sm text-white bg-orange-600 rounded-md hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-opacity-50"
              >
                Book Pooja
              </div>
            </div>
          </div>
          <div className="space-y-4">
            <h2 className="text-xl font-bold">About Astrologer</h2>

            <div className="grid md:grid-cols-4 grid-cols-2  gap-4">
              <div className="space-y-2 border border-gray-300 p-4 rounded-lg">
                <p className="text-gray-700">
                  <span className="font-semibold">City:</span>{" "}
                  {PanditData?.City || "Not Available"}
                </p>
                <p className="text-gray-700">
                  <span className="font-semibold">State:</span>{" "}
                  {PanditData?.State || "Not Available"}
                </p>
                <p className="text-gray-700">
                  <span className="font-semibold">Zipcode:</span>{" "}
                  {PanditData?.Zipcode || "Not Available"}
                </p>
                <p className="text-gray-700">
                  <span className="font-semibold">Country:</span>{" "}
                  {PanditData?.Country || "Not Available"}
                </p>
              </div>

              {/* 2 */}
              <div className="space-y-2 border border-gray-300 p-4 rounded-lg">
                <p className="text-gray-700">
                  <span className="font-semibold">Religion:</span>{" "}
                  {PanditData?.Religion || "Not Available"}
                </p>
                <p className="text-gray-700">
                  <span className="font-semibold">Gotra:</span>{" "}
                  {PanditData?.Gotra || "Not Available"}
                </p>
                <p className="text-gray-700">
                  <span className="font-semibold">Pravar:</span>{" "}
                  {PanditData?.Pravar || "Not Available"}
                </p>
                <p className="text-gray-700">
                  <span className="font-semibold">Shakha:</span>{" "}
                  {PanditData?.Shakha || "Not Available"}
                </p>
              </div>

              {/* 3 */}
              <div className="space-y-2 border border-gray-300 p-4 rounded-lg">
                <p className="text-gray-700">
                  <span className="font-semibold">Ved:</span>{" "}
                  {PanditData?.Ved || "Not Available"}
                </p>
                <p className="text-gray-700">
                  <span className="font-semibold">UpVed:</span>{" "}
                  {PanditData?.UpVed || "Not Available"}
                </p>
                <p className="text-gray-700">
                  <span className="font-semibold">Mukh:</span>{" "}
                  {PanditData?.Mukh || "Not Available"}
                </p>
                <p className="text-gray-700">
                  <span className="font-semibold">Charan:</span>{" "}
                  {PanditData?.Charan || "Not Available"}
                </p>
              </div>

              {/* 4 */}
              <div className="space-y-2 border border-gray-300 p-4 rounded-lg">
                <p className="text-gray-700">
                  <span className="font-semibold">Kuldevta:</span>{" "}
                  {PanditData?.Kuldevta || "Not Available"}
                </p>
                <p className="text-gray-700">
                  <span className="font-semibold">Kuldevi:</span>{" "}
                  {PanditData?.Kuldevi || "Not Available"}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Sidebar (SlotPicker) */}
      {sidebarVisible && (
        <div className="fixed inset-0 bg-gray-700 bg-opacity-50 z-50">
          <div
            className={`w-96 bg-white p-6 rounded-lg shadow-lg absolute top-0 right-0 h-full transform transition-all duration-500 ease-in-out ${
              sidebarVisible ? "translate-x-0" : "translate-x-full"
            }`}
          >
            <SlotPicker
              handleBookPoojaClick={handleBookPoojaClick}
              subcatid={subcatid}
              price={price}
              U_Id={U_Id}
              panditid={panditid}
            />
          </div>
        </div>
      )}

      {/* <How_To_Chat /> */}
    </div>
  );
}
