"use client"

import { useState, useEffect } from "react"
import Aries from '../../Assets/Aries.jpg'
import Taurus from '../../Assets/Taurus.jpg'
import Gemini from "../../Assets/gemini.jpg";
import Cancer from '../../Assets/cancer.webp'
import Leo from '../../Assets/Leo.jpg'
import Virgo from '../../Assets/Virgo.avif'
import Libra from "../../Assets/Libra.jpg";
import Scorpio from '../../Assets/Scorpio.jpg'
import Sagittarius from "../../Assets/Sagittarius.webp";
import Capricorn from '../../Assets/Capricorn.jpg'
import Aquarius from '../../Assets/Aquarius.avif'
import Ophiuchus from '../../Assets/Ophiuchus.avif'
import Draco from '../../Assets/Draco.jpg'
import Pisces from '../../Assets/Pisces.jpg'
import Pegasus from '../../Assets/Pisces.jpg'


// Placeholder for your actual image imports
// In a Next.js app, you would typically store these in the public folder
const zodiacImages = {
  Aries: Aries,
  Taurus: Taurus,
  Gemini: Gemini,
  Cancer: Cancer,
  Leo: Leo,
  Virgo: Virgo,
  Libra: Libra,
  Scorpio: Scorpio,
  Sagittarius: Sagittarius,
  Capricorn: Capricorn,
  Aquarius: Aquarius,
  Ophiuchus: Ophiuchus,
};

export default function Horoscope() {
  const [activeTimeTab, setActiveTimeTab] = useState("Today");
  const [activeCategory, setActiveCategory] = useState("Love");
  const [activeSign, setActiveSign] = useState("Aries");
  const [horoscopeData, setHoroscopeData] = useState(null);
  const [loading, setLoading] = useState(true);

  const zodiacSigns = [
    { name: "Aries", dates: "Mar 21 - Apr 19" },
    { name: "Taurus", dates: "Apr 20 - May 20" },
    { name: "Gemini", dates: "May 21 - Jun 20" },
    { name: "Cancer", dates: "Jun 21 - Jul 22" },
    { name: "Leo", dates: "Jul 23 - Aug 22" },
    { name: "Virgo", dates: "Aug 23 - Sep 22" },
    { name: "Libra", dates: "Sep 23 - Oct 22" },
    { name: "Scorpio", dates: "Oct 23 - Nov 21" },
    { name: "Sagittarius", dates: "Nov 22 - Dec 21" },
    { name: "Capricorn", dates: "Dec 22 - Jan 19" },
    { name: "Aquarius", dates: "Jan 20 - Feb 18" },
  ];

  const timeTabs = [
    "Yesterday",
    "Today",
    "Tomorrow",
    "Weekly",
    "Monthly",
    "Yearly",
  ];

  const categories = [
    { name: "Love", color: "text-red-500" },
    { name: "Health", color: "text-blue-500" },
    { name: "Career", color: "text-orange-500" },
    { name: "Emotions", color: "text-yellow-500" },
    { name: "Travel", color: "text-green-500" },
  ];

  // Fetch horoscope data when activeSign, activeTimeTab, or activeCategory changes
  useEffect(() => {
    const fetchHoroscopeData = async () => {
      setLoading(true);
      try {
        const data = await fetchMockHoroscopeData(
          activeSign,
          activeTimeTab,
          activeCategory
        );
        setHoroscopeData(data);
      } catch (error) {
        console.error("Error fetching horoscope data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchHoroscopeData();
  }, [activeSign, activeTimeTab, activeCategory]);

  // Mock function to simulate API call
  const fetchMockHoroscopeData = async (sign, timeFrame, category) => {
    // Simulate network delay
    await new Promise((resolve) => setTimeout(resolve, 500));

    // Generate dynamic lucky items based on sign and time frame
    const luckyColors = getLuckyColors(sign, timeFrame);
    const luckyNumbers = getLuckyNumbers(sign, timeFrame);
    const luckyAlphabets = getLuckyAlphabets(sign, timeFrame);

    // Generate dynamic content based on sign, time frame, and category
    const content = generateHoroscopeContent(sign, timeFrame, category);

    // Generate tips based on sign and time frame
    const tips = {
      cosmic: getCosmicTip(sign, timeFrame),
      singles: getSinglesTip(sign, timeFrame),
      couples: getCouplesTip(sign, timeFrame),
    };

    return {
      sign,
      timeFrame,
      category,
      luckyItems: {
        colors: luckyColors,
        numbers: luckyNumbers,
        alphabets: luckyAlphabets,
      },
      content,
      tips,
    };
  };

  // Helper functions to generate dynamic content
  const getLuckyColors = (sign, timeFrame) => {
    const colorMap = {
      Aries: ["red", "white"],
      Taurus: ["green", "pink"],
      Gemini: ["yellow", "blue"],
      Cancer: ["silver", "white"],
      Leo: ["gold", "orange"],
      Virgo: ["green", "brown"],
      Libra: ["pink", "blue"],
      Scorpio: ["red", "black"],
      Sagittarius: ["purple", "blue"],
      Capricorn: ["brown", "gray"],
      Aquarius: ["blue", "turquoise"],
      Ophiuchus: ["purple", "red"],
    };

    // Add variation based on time frame
    if (timeFrame === "Today" || timeFrame === "Tomorrow") {
      return colorMap[sign][0];
    } else {
      return colorMap[sign].join(", ");
    }
  };

  const getLuckyNumbers = (sign, timeFrame) => {
    const numberMap = {
      Aries: [1, 9],
      Taurus: [2, 6],
      Gemini: [3, 5],
      Cancer: [4, 7],
      Leo: [1, 5],
      Virgo: [5, 8],
      Libra: [6, 9],
      Scorpio: [8, 9],
      Sagittarius: [3, 9],
      Capricorn: [4, 8],
      Aquarius: [7, 11],
      Ophiuchus: [12, 7],
    };

    // Add variation based on time frame
    if (timeFrame === "Weekly" || timeFrame === "Monthly") {
      return [...numberMap[sign], Math.floor(Math.random() * 10) + 1].join(
        ", "
      );
    } else if (timeFrame === "Yearly") {
      return [
        ...numberMap[sign],
        Math.floor(Math.random() * 10) + 1,
        Math.floor(Math.random() * 10) + 1,
      ].join(", ");
    } else {
      return numberMap[sign].join(", ");
    }
  };

  const getLuckyAlphabets = (sign, timeFrame) => {
    const alphabetMap = {
      Aries: ["A", "L"],
      Taurus: ["B", "V"],
      Gemini: ["C", "F"],
      Cancer: ["D", "G"],
      Leo: ["E", "O"],
      Virgo: ["N", "P"],
      Libra: ["L", "R"],
      Scorpio: ["M", "S"],
      Sagittarius: ["J", "Z"],
      Capricorn: ["K", "Y"],
      Aquarius: ["U", "W"],
      Ophiuchus: ["O", "H"],
    };

    return alphabetMap[sign].join(", ");
  };

  const generateHoroscopeContent = (sign, timeFrame, category) => {
    // Base content templates for each category
    const templates = {
      Love: [
        "Your personal relationships may require some extra attention today. The Moon's energy encourages open communication with loved ones. Minor misunderstandings could arise, but addressing them calmly will strengthen your bonds.",
        "Listen attentively and share your feelings honestly. This phase is perfect for rekindling connections and creating harmony in friendships. Remember, genuine conversations can overcome any barriers and bring warmth to your relationships.",
        "A new romantic opportunity may present itself unexpectedly. Stay open to possibilities and trust your intuition when meeting new people.",
      ],
      Health: [
        "Pay attention to your physical well-being today. Your energy levels might fluctuate, so balance activity with adequate rest. Stay hydrated and consider incorporating more fresh fruits and vegetables into your diet.",
        "Mental health is equally important - take time for meditation or mindfulness practices. A short walk in nature could significantly improve your mood and overall wellness.",
        "Listen to what your body needs today. If you're feeling tired, don't push yourself too hard. Gentle stretching exercises might be particularly beneficial.",
      ],
      Career: [
        "Professional opportunities are on the horizon. Your creative thinking will be appreciated by colleagues and superiors. Don't hesitate to share your innovative ideas during meetings.",
        "Financial matters require careful attention. Review your budget and consider long-term investments. A methodical approach to money management will serve you well in the coming months.",
        "Networking could lead to unexpected opportunities. Reach out to former colleagues or industry contacts - a casual conversation might open new doors.",
      ],
      Emotions: [
        "Your emotional landscape is rich and complex today. Allow yourself to feel without judgment, acknowledging that all emotions provide valuable insights into your inner world.",
        "If you've been suppressing feelings, they might surface unexpectedly. Creating space for emotional expression through journaling or talking with a trusted friend can be healing.",
        "Emotional balance comes through acceptance. Rather than fighting against difficult feelings, try to observe them with compassion and curiosity.",
      ],
      Travel: [
        "Travel plans may require flexibility. Unexpected changes could actually lead to more enjoyable experiences if you remain open-minded. Consider local destinations you haven't explored.",
        "If planning a trip, pay attention to details and double-check reservations. Having contingency plans will give you peace of mind and allow for a more relaxed journey.",
        "A spontaneous day trip could provide the refreshment you need. Sometimes the most memorable adventures happen close to home.",
      ],
    };

    // Select random paragraphs based on sign, time frame, and category
    const seed = sign.charCodeAt(0) + timeFrame.length + category.length;
    const paragraphCount =
      timeFrame === "Weekly" ||
      timeFrame === "Monthly" ||
      timeFrame === "Yearly"
        ? 3
        : 2;

    const selectedParagraphs = [];
    for (let i = 0; i < paragraphCount; i++) {
      const index = (seed + i) % templates[category].length;
      selectedParagraphs.push(templates[category][index]);
    }

    return selectedParagraphs.join(" ");
  };

  const getCosmicTip = (sign, timeFrame) => {
    const tips = [
      "Trust the process; patience brings rewards unexpectedly.",
      "Embrace change as it opens doors to new possibilities.",
      "Your intuition is especially strong now - listen to it.",
      "Balance work and play for optimal harmony.",
      "Small steps consistently taken lead to significant progress.",
      "The universe supports your authentic self-expression.",
      "Connections made now may have lasting importance.",
      "Release what no longer serves you to make space for new blessings.",
    ];

    const index = (sign.length + timeFrame.length) % tips.length;
    return tips[index];
  };

  const getSinglesTip = (sign, timeFrame) => {
    const tips = [
      "Engage in social events; a new connection awaits discovery.",
      "Self-love attracts the right kind of partner into your life.",
      "Be clear about your values and boundaries in relationships.",
      "A friendship might evolve into something deeper if you're open to it.",
      "Focus on personal growth now; love often arrives when you're not searching for it.",
      "Try new activities to expand your social circle.",
      "Online connections may lead to meaningful relationships.",
      "Your authentic self is your most attractive quality.",
    ];

    const index = (sign.charCodeAt(0) + timeFrame.charCodeAt(0)) % tips.length;
    return tips[index];
  };

  const getCouplesTip = (sign, timeFrame) => {
    const tips = [
      "Nurture trust with honesty, enhancing shared emotional bonds.",
      "Small gestures of appreciation strengthen your connection.",
      "Create new memories through shared experiences.",
      "Listen not just to respond, but to understand your partner's perspective.",
      "Respect differences as they add richness to your relationship.",
      "Prioritize quality time together without distractions.",
      "Express gratitude for the ways your partner enriches your life.",
      "Remember to maintain individual interests alongside shared ones.",
    ];

    const index = (sign.length * timeFrame.length) % tips.length;
    return tips[index];
  };

  // Function to get color for lucky color display
  const getLuckyColorDisplay = () => {
    if (!horoscopeData) return "bg-cyan-400";

    const colorMap = {
      red: "bg-red-500",
      white: "bg-white",
      green: "bg-green-500",
      pink: "bg-pink-500",
      yellow: "bg-yellow-500",
      blue: "bg-blue-500",
      silver: "bg-gray-300",
      gold: "bg-yellow-600",
      brown: "bg-amber-800",
      black: "bg-black",
      purple: "bg-purple-500",
      gray: "bg-gray-500",
      turquoise: "bg-teal-400",
      orange: "bg-orange-500",
    };

    const color = horoscopeData.luckyItems.colors
      .split(",")[0]
      .trim()
      .toLowerCase();
    return colorMap[color] || "bg-cyan-400";
  };

  return (
    <div className="container mx-auto px-4 py-8 space-y-6">
      {/* Breadcrumb */}
      <nav className="flex mb-4 text-sm">
        <a href="/" className="text-orange-500 hover:text-orange-600 font-bold">
          Home
        </a>
        <span className="mx-2 text-gray-500">&gt;</span>
        <span className="text-gray-600 font-bold">Horoscope</span>
      </nav>

      {/* Zodiac Signs */}
      <div className="overflow-x-auto">
        <div className="container">
          <h1 className="text-3xl font-bold text-gray-900 mb-4">
            Astrology: The Power of the Stars
          </h1>
          <p className="text-gray-600">
            Astrology reveals the influence of the stars and planets on our
            lives. The zodiac signs, each associated with a specific range of
            dates, offer profound insights into an individual's personality,
            traits, and destiny. Discover your zodiac sign and explore what the
            stars have to say about your path. Use our astrology calculator
            based on your date of birth to unveil your horoscope.
          </p>
        </div>

        <div className="flex gap-8 min-w-max mt-8 mb-5">
          {zodiacSigns.map((sign) => (
            <div
              key={sign.name}
              onClick={() => setActiveSign(sign.name)}
              className={`flex flex-col items-center gap-2 p-2 cursor-pointer
                ${
                  sign.name === activeSign
                    ? "text-orange-500"
                    : "text-gray-600 hover:text-orange-500"
                }`}
            >
              <div
                className={`w-20 h-20 rounded-full border-2 flex items-center justify-center overflow-hidden
                  ${
                    sign.name === activeSign
                      ? "border-orange-500"
                      : "border-gray-300"
                  }`}
              >
                <img
                  src={zodiacImages[sign.name] || "/placeholder.svg"}
                  alt={sign.name}
                  className="w-full h-full object-cover"
                />
              </div>
              <span className="text-sm font-medium">{sign.name}</span>
              <span className="text-xs text-gray-500">{sign.dates}</span>
            </div>
          ))}
        </div>
      </div>

      {/* Time Period Tabs */}
      <div className="flex flex-wrap justify-between border-b">
        {timeTabs.map((tab) => (
          <div
            key={tab}
            onClick={() => setActiveTimeTab(tab)}
            className={`px-4 py-2 relative cursor-pointer font-bold text-center w-full sm:w-auto ${
              activeTimeTab === tab
                ? "text-orange-500 border-b-2 border-orange-500"
                : "text-gray-600 hover:text-orange-500"
            }`}
          >
            {tab}
          </div>
        ))}
      </div>

      {/* Lucky Items */}
      <section className="">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
          <div className="bg-yellow-50 cursor-pointer p-6 rounded-lg shadow-lg hover:shadow-xl transform hover:scale-105 transition-all duration-300 text-center">
            <h3 className="text-lg font-semibold text-gray-700 mb-4">
              Colours
            </h3>

            {/* Circle Loader or Color */}
            <div
              className={`w-8 h-8 rounded-full mx-auto shadow-md ${
                loading ? "bg-gray-300 animate-pulse" : getLuckyColorDisplay()
              }`}
            ></div>

            {/* Text Loader or Color Name */}
            <div className="mt-2">
              {loading ? (
                <div className="h-4 bg-gray-300 rounded w-24 mx-auto animate-pulse"></div>
              ) : (
                horoscopeData && (
                  <p className="text-gray-800">
                    {horoscopeData.luckyItems.colors}
                  </p>
                )
              )}
            </div>
          </div>

          <div className="bg-red-50 p-6 cursor-pointer rounded-lg shadow-lg hover:shadow-xl transform hover:scale-105 transition-all duration-300 text-center">
            <h3 className="text-lg font-semibold text-gray-700 mb-4">
              Numbers
            </h3>

            {/* Number Skeleton Loader or Actual Number */}
            {loading ? (
              <div className="h-6 bg-gray-300 rounded w-16 mx-auto animate-pulse"></div>
            ) : (
              <p className="text-gray-800 text-xl font-medium">
                {horoscopeData?.luckyItems.numbers}
              </p>
            )}
          </div>

          <div className="bg-blue-50 p-6 cursor-pointer rounded-lg shadow-lg hover:shadow-xl transform hover:scale-105 transition-all duration-300 text-center">
            <h3 className="text-lg font-semibold text-gray-700 mb-4">
              Alphabets
            </h3>

            {/* Alphabet Skeleton Loader or Actual Alphabet */}
            {loading ? (
              <div className="h-6 bg-gray-300 rounded w-16 mx-auto animate-pulse"></div>
            ) : (
              <p className="text-gray-800 text-xl font-medium">
                {horoscopeData?.luckyItems.alphabets}
              </p>
            )}
          </div>
        </div>
      </section>

      {/* Category Tabs */}
      <div className="grid grid-cols-2 md:grid-cols-5 gap-4">
        {categories.map((category) => (
          <div
            key={category.name}
            onClick={() => setActiveCategory(category.name)}
            className={`flex items-center justify-center gap-2 py-2 px-4 rounded-full border cursor-pointer
              ${
                activeCategory === category.name
                  ? "border-current font-bold " + category.color
                  : "border-gray-300 text-gray-600 hover:border-current hover:" +
                    category.color
              }`}
          >
            {category.name}
          </div>
        ))}
      </div>

      {/* Content */}
      <div className="border rounded-lg p-6 relative">
        <h2 className="text-xl font-semibold mb-4">{activeCategory}</h2>
        {loading ? (
          <div className="animate-pulse space-y-2">
            <div className="h-4 bg-gray-200 rounded w-3/4"></div>
            <div className="h-4 bg-gray-200 rounded"></div>
            <div className="h-4 bg-gray-200 rounded w-5/6"></div>
          </div>
        ) : (
          <p className="text-gray-600 leading-relaxed">
            {horoscopeData?.content}
          </p>
        )}
      </div>

      {/* Tips Section */}
      <section>
        <h2 className="text-xl font-semibold mb-4">Tips for you</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div className="border rounded-lg p-4">
            <h3 className="font-semibold">Cosmic Tip</h3>
            <p className="text-gray-600 text-sm">
              {loading ? "Loading..." : horoscopeData?.tips.cosmic}
            </p>
          </div>
          <div className="border rounded-lg p-4">
            <h3 className="font-semibold">Tips for Singles</h3>
            <p className="text-gray-600 text-sm">
              {loading ? "Loading..." : horoscopeData?.tips.singles}
            </p>
          </div>
          <div className="border rounded-lg p-4">
            <h3 className="font-semibold">Tips for Couples</h3>
            <p className="text-gray-600 text-sm">
              {loading ? "Loading..." : horoscopeData?.tips.couples}
            </p>
          </div>
        </div>
      </section>

      {/* Description Sections */}
      <section className="space-y-6">
        <div>
          <h2 className="text-xl font-semibold mb-2">
            {activeSign} {activeTimeTab} Horoscope
          </h2>
          <p className="text-gray-600 leading-relaxed">
            Wouldn't it be so fortunate to know what side you will be on for the
            next day: surprises or challenges? Introducing the {activeSign}{" "}
            {activeTimeTab} Horoscope for the people born between{" "}
            {zodiacSigns.find((sign) => sign.name === activeSign)?.dates}.
            {activeSign} horoscope predicts whether the{" "}
            {activeTimeTab.toLowerCase()} horoscope is filled with joys or
            hurdles that must be overcome.
          </p>
          <p className="text-gray-600 leading-relaxed mt-4">
            By reading these helpful insights, one can make better life
            decisions and be prepared for the upcoming twists and turns. The
            best part is that one can cover important aspects of life, from love
            to emotional aspects, health, and much more. So, dive in and see
            what will {activeTimeTab.toLowerCase()} bring for {activeSign}{" "}
            zodiac.
          </p>
        </div>

        <div>
          <h2 className="text-xl font-semibold mb-2">
            {activeSign} {activeTimeTab} {activeCategory} Horoscope
          </h2>
          <p className="text-gray-600 leading-relaxed">
            Aren't you curious to know what will {activeTimeTab.toLowerCase()}{" "}
            bring for {activeSign} {activeCategory.toLowerCase()} life? Will
            there be {activeCategory.toLowerCase()} challenges, or will new
            surprises await you? Meet a one-time solution for all your confusion
            about your
            {activeSign} {activeCategory} Horoscope {activeTimeTab}.
          </p>
        </div>
      </section>
    </div>
  );
}

