import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import JoditEditor from "jodit-react";
import DOMPurify from "dompurify"; 
import HTMLReactParser from "html-react-parser/lib/index";
const Add_Numerology_Combo = ({
  onClose,
  setNumerologyComboData,
  numerologyComboData,
  selectedData,
  isEditing,
}) => {
  const editor = useRef(null);
  const [formData, setFormData] = useState({
    Value1: "",
    Value2: "",
    desc_title: "",
    desc_details: "",
  });

  const [loading, setLoading] = useState(false);

  // Populate form data if editing
  useEffect(() => {
    if (isEditing && selectedData) {
      setFormData({
        Value1: selectedData.numerologyNumber1 || "",
        Value2: selectedData.numerologyNumber2 || "",
        desc_title: selectedData.title || "",
        desc_details: selectedData.description || "",
      });
    }
  }, [isEditing, selectedData]);

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Show Swal notification
  const showNotification = (type, message) => {
    Swal.fire({
      toast: true,
      position: "top-right",
      icon: type,
      title: message,
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
    });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const payload = {
      Value1: formData.Value1,
      Value2: formData.Value2,
      desc_title: formData.desc_title,
      desc_details: formData.desc_details,
    };

    try {
      let response;

      if (isEditing) {
        // Update existing data
        response = await axios.put(
          `https://vpandit.caxis.ca/public/api/numcom/${selectedData.numId}`,
          payload
        );
        showNotification(
          "success",
          "Numerology Combination updated successfully!"
        );

        // Update the local state
        const updatedData = numerologyComboData.map((item) =>
          item.numId === selectedData.numId ? { ...item, ...formData } : item
        );
        setNumerologyComboData(updatedData);
      } else {
        // Add new data
        response = await axios.post(
          "https://vpandit.caxis.ca/public/api/numcom",
          payload
        );
        showNotification(
          "success",
          "Numerology Combination added successfully!"
        );

        // Add the new record to local state
        const newRecord = {
          ...formData,
          numId: response.data.Num_Id,
        };
        setNumerologyComboData([...numerologyComboData, newRecord]);
      }

      // Reset form
      setFormData({
        Value1: "",
        Value2: "",
        desc_title: "",
        desc_details: "",
      });
      onClose();
    } catch (err) {
      showNotification(
        "error",
        err.response?.data?.message || "An error occurred. Please try again."
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="p-4 bg-white rounded-lg shadow-lg mt-6">
      <div className="flex mb-6">
        <h1 className="text-2xl font-bold text-orange-500">
          {isEditing
            ? "Edit Numerology Combination"
            : "Add Numerology Combination"}
        </h1>
      </div>

      <form onSubmit={handleSubmit}>
        {/* Value1 Field */}
        <div className="mt-4">
          <label
            htmlFor="Value1"
            className="block text-sm font-medium text-gray-700"
          >
            Value 1
          </label>
          <input
            type="text"
            id="Value1"
            name="Value1"
            value={formData.Value1}
            onChange={handleInputChange}
            required
            disabled={loading}
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-orange-500 focus:border-orange-500 sm:text-sm"
          />
        </div>

        {/* Value2 Field */}
        <div className="mt-4">
          <label
            htmlFor="Value2"
            className="block text-sm font-medium text-gray-700"
          >
            Value 2
          </label>
          <input
            type="text"
            id="Value2"
            name="Value2"
            value={formData.Value2}
            onChange={handleInputChange}
            required
            disabled={loading}
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-orange-500 focus:border-orange-500 sm:text-sm"
          />
        </div>

        {/* Title Field */}
        <div className="mt-4">
          <label
            htmlFor="desc_title"
            className="block text-sm font-medium text-gray-700"
          >
            Title
          </label>
          <input
            type="text"
            id="desc_title"
            name="desc_title"
            value={formData.desc_title}
            onChange={handleInputChange}
            required
            disabled={loading}
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-orange-500 focus:border-orange-500 sm:text-sm"
          />
        </div>

        {/* Description Field */}
        <div className="mt-4">
          <label
            htmlFor="desc_details"
            className="block text-sm font-medium text-gray-700"
          >
            Description
          </label>
          <JoditEditor
            ref={editor}
            value={formData.desc_details}
            tabIndex={1}
            onBlur={(content) =>
              setFormData({
                ...formData,
                desc_details: content,
              })
            }
            onChange={() => {}}
          />
        </div>

        {/* Submit and Cancel Buttons */}
        <div className="mt-6 flex gap-4">
          <button
            type="submit"
            className="bg-orange-500 text-white px-4 py-2 rounded-full"
            disabled={loading}
          >
            {loading ? "Saving..." : isEditing ? "Update" : "Add"}
          </button>
          <button
            type="button"
            onClick={onClose}
            className="bg-gray-500 text-white px-4 py-2 rounded-full"
            disabled={loading}
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default Add_Numerology_Combo;
