import React, { useEffect, useState } from "react";
import axios from "axios";
import CommongPreloader from "../CommongPreloader/CommongPreloader";

const List_Of_Inquiry = () => {
  const [inquiries, setInquiries] = useState([]); // State to store API response
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch data from API
    axios
      .get("https://vpandit.caxis.ca/public/api/contact-form")
      .then((response) => {
        setInquiries(response.data);
        setLoading(false); // Turn off loading after data is fetched
      })
      .catch((error) => {
        console.error("Error fetching inquiries:", error);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <CommongPreloader />; // Show loading state
  }

  return (
    <div className="py-2">
      <div className="flex space-x-4 mb-3">
        <h1 className="text-2xl font-bold">List of Inquiries</h1>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3">
        {inquiries.map((inquiry) => (
          <div
            key={inquiry.Inq_Seq}
            className="bg-white shadow-md rounded-lg p-3 border border-gray-200 flex flex-col"
          >
            <h5 className="text-lg font-semibold text-gray-800 mb-2">
              {inquiry.Name}
            </h5>
            <p className="text-sm text-gray-600">
              <strong>Email:</strong> {inquiry.Email}
            </p>
            <p className="text-sm text-gray-600">
              <strong>Mobile:</strong> {inquiry.Mobile}
            </p>
            <p className="text-sm text-gray-600">
              <strong>Subject:</strong> {inquiry.Subject}
            </p>
            <p className="text-sm text-gray-600 mb-4">
              <strong>Message:</strong> {inquiry.Message}
            </p>
            <p className="text-xs text-gray-500 mt-auto">
              {new Date(inquiry.created_at).toLocaleString()}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default List_Of_Inquiry;
