import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";

const Add_Mobile_Number = ({ onClose, selectedData, fetchData, isEditing }) => {
  const [Value, setValue] = useState("");
  const [YN, setYN] = useState(false);
  const [Pros, setPros] = useState([]);
  const [Cons, setCons] = useState([]);
  const [Remedies, setRemedies] = useState([]); // New state for Remedies

  useEffect(() => {
    if (isEditing && selectedData) {
      setValue(selectedData.Value);
      setYN(selectedData.YN);
      setPros(selectedData.Pros);
      setCons(selectedData.Cons);
      setRemedies(selectedData.Remedies || []); // Initialize Remedies if available
    }
  }, [isEditing, selectedData]);

  const handleSave = async () => {
    const newNumerology = { Value, YN, Pros, Cons, Remedies }; // Include Remedies

    try {
      if (isEditing) {
        await axios.put(
          `https://vpandit.caxis.ca/public/api/mobile-numerologies/${selectedData.Mn_Num}`,
          newNumerology
        );
        Swal.fire("Success", "Numerology updated successfully", "success");
      } else {
        await axios.post(
          "https://vpandit.caxis.ca/public/api/mobile-numerologies",
          newNumerology
        );
        Swal.fire("Success", "Numerology added successfully", "success");
      }

      fetchData();
      onClose();
    } catch (err) {
      Swal.fire("Error", "Something went wrong. Please try again.", "error");
    }
  };

  return (
    <div className="p-6 bg-white shadow-md rounded-lg">
      <h2 className="text-xl font-bold mb-4">
        {isEditing ? "Edit Numerology" : "Add Numerology"}
      </h2>

      <div className="mb-4">
        <label className="block mb-2">Value</label>
        <input
          type="text"
          className="w-full px-4 py-2 border border-gray-300 rounded-md"
          value={Value}
          onChange={(e) => setValue(e.target.value)}
        />
      </div>
      <div className="mb-4">
        <label className="block mb-2">YN</label>
        <input
          type="checkbox"
          checked={YN}
          onChange={(e) => setYN(e.target.checked)}
        />
      </div>
      <div className="mb-4">
        <label className="block mb-2">Pros</label>
        <textarea
          className="w-full px-4 py-2 border border-gray-300 rounded-md"
          value={Pros.join("\n")}
          rows={3}
          onChange={(e) => setPros(e.target.value.split("\n"))}
        />
      </div>
      <div className="mb-4">
        <label className="block mb-2">Cons</label>
        <textarea
          className="w-full px-4 py-2 border border-gray-300 rounded-md"
          value={Cons.join("\n")}
          rows={3}
          onChange={(e) => setCons(e.target.value.split("\n"))}
        />
      </div>
      <div className="mb-4">
        <label className="block mb-2">Remedies</label>
        <textarea
          className="w-full px-4 py-2 border border-gray-300 rounded-md"
          value={Remedies.join("\n")}
          rows={3}
          onChange={(e) => setRemedies(e.target.value.split("\n"))}
        />
      </div>

      <div className="flex gap-3">
        <button
          onClick={handleSave}
          className="px-4 py-2 bg-orange-500 text-white rounded-3xl"
        >
          Save
        </button>
        <button
          onClick={onClose}
          className="px-4 py-2 bg-gray-500 text-white rounded-3xl"
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default Add_Mobile_Number;
