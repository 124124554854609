import React, { useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa";
import Add_Product from "./Add_Product/Add_Product";

const Manage_Product = ({ id }) => {
  const [data, setData] = useState([]);
  const [vendorId, setVendorId] = useState(null); // State to store vendor ID
  const [showAddProduct, setShowAddProduct] = useState(false);

  const fetchData = async () => {
    try {
      const response = await fetch(
        "http://127.0.0.1:8000/api/prod-vendor-price-list"
      );
      const result = await response.json();

      console.log("Raw API Response:", result);
      console.log("Provided id:", id);

      const filteredData = result
        .filter((item) => Number(item.vendor.U_ID) === Number(id))
        .map((item) => {
          setVendorId(item.vendor.Vendor_Id);
          return {
            name: item.product.Prd_Desc,
            price: item.Prd_Price,
            quantity: item.Prd_Qty,
            total: item.Prd_Price * item.Prd_Qty,
          };
        });

      console.log("Filtered Data:", filteredData);
      console.log("Vendor ID:", vendorId);
      setData(filteredData);
    } catch (error) {
      console.error("Error fetching or processing data:", error.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  const handleCloseAddProduct = () => {
    setShowAddProduct(false);
    fetchData();
  };

  return (
    <div className="p-5 bg-gray-100 min-h-screen">
      {showAddProduct ? (
        <Add_Product id={id} data={data} vendorId={vendorId} onClose={handleCloseAddProduct} />
      ) : (
        <div className="rounded-lg overflow-hidden">
          <div className="flex justify-end pb-5">
            <div
              className="bg-[#002538] text-white px-4 py-2 rounded hover:bg-gray-600 cursor-pointer flex items-center gap-2"
              onClick={() => setShowAddProduct(true)}
            >
              <FaPlus />
              <p>Add Product</p>
            </div>
          </div>
          <div className="overflow-x-auto">
            <table className="table-auto w-full text-left">
              <thead className="bg-[#002538] text-white text-sm uppercase">
                <tr>
                  <th className="px-4 py-4">Order ID</th>
                  <th className="px-4 py-4">Product Name</th>
                  <th className="px-4 py-4">Product Price</th>
                  <th className="px-4 py-4">Product Quantity</th>
                  <th className="px-4 py-4">Total Price</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => (
                  <tr
                    key={index}
                    className={`${
                      index % 2 === 0 ? "bg-gray-100" : "bg-white"
                    } text-sm`}
                  >
                    <td className="px-4 py-4">#{index + 1}</td>
                    <td className="px-4 py-4">{item.name}</td>
                    <td className="px-4 py-4">{item.price}</td>
                    <td className="px-4 py-4">{item.quantity}</td>
                    <td className="px-4 py-4">{item.total}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default Manage_Product;
