import { UserPlus } from "lucide-react";
import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import CommongPreloader from "../../../CommongPreloader/CommongPreloader";
import { FaEdit, FaTrash } from "react-icons/fa";
import Add_Manage_Category from "./Add_Manage_Category";
import { IoDuplicateSharp } from "react-icons/io5";

const Manage_Category = () => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showAddCategory, setShowAddCategory] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [isEditing, setIsEditing] = useState(false);

  // Fetch Data
  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await axios.get("https://vpandit.caxis.ca/public/api/prod-categories");
      if (Array.isArray(response.data)) {
        const formattedData = response.data.map((item) => ({
          id: item.PRCat_ID,
          name: item.PrCat_Name,
        }));
        setCategories(formattedData);
      } else {
        throw new Error("Invalid data format received");
      }
    } catch (err) {
      setError("Failed to fetch data. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Handle Add Button Click
  const handleAddClick = () => {
    setSelectedData(null);
    setIsEditing(false);
    setShowAddCategory(true);
  };

  // Handle Edit Button Click
  const handleEditClick = (data) => {
    setSelectedData(data);
    setIsEditing(true);
    setShowAddCategory(true);
  };

  // Handle Delete Button Click
  const handleDeleteClick = async (id) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 1000,
      timerProgressBar: true,
    });

    try {
      await axios.delete(`https://vpandit.caxis.ca/publicapi/prod-categories/${id}`);
      setCategories(categories.filter((item) => item.id !== id));

      // Show success toast
      Toast.fire({
        icon: "success",
        title: "Category deleted successfully",
      });
    } catch (err) {
      console.error("Error deleting category:", err);

      // Show error toast
      Toast.fire({
        icon: "error",
        title: "Failed to delete category. Please try again.",
      });
    }
  };

  return (
    <div className="">
      {/* Header Section */}
      <div className="flex justify-between items-center mb-3">
        <h1 className="text-2xl font-bold">Categories Management</h1>
        <div
          onClick={handleAddClick}
          className="cursor-pointer bg-orange-500 hover:bg-orange-600 text-white px-4 py-2 rounded-3xl flex items-center"
        >
          <IoDuplicateSharp className="mr-2 h-4 w-4" />
          Add Category
        </div>
      </div>

      {/* Main Content */}
      {loading ? (
        <CommongPreloader />
      ) : error ? (
        <p className="text-red-500 text-center">{error}</p>
      ) : showAddCategory ? (
        <Add_Manage_Category
          onClose={() => setShowAddCategory(false)}
          selectedData={selectedData}
          fetchData={fetchData}
          isEditing={isEditing}
        />
      ) : (
        <div className="rounded-lg overflow-hidden">
          {categories.length === 0 ? (
            <p className="p-4 text-center text-gray-500">No Data Found</p>
          ) : (
            <table className="w-full bg-white">
              <thead>
                <tr className="border-b border-gray-700">
                  <th className="px-6 py-3 text-left text-xs font-medium text-black uppercase tracking-wider">
                    Category Name
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-black uppercase tracking-wider">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-700">
                {categories.map((item) => (
                  <tr key={item.id} className="hover:bg-gray-50">
                    <td className="px-6 py-4">{item.name}</td>
                    <td className="px-6 py-4">
                      <div className="flex space-x-3">
                        <div className="text-black hover:text-black">
                          <FaEdit
                            className="text-green-600 cursor-pointer"
                            onClick={() => handleEditClick(item)}
                          />
                        </div>
                        <div className="text-black hover:text-black">
                          <FaTrash
                            className="text-red-600 cursor-pointer"
                            onClick={() => handleDeleteClick(item.id)}
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      )}
    </div>
  );
};

export default Manage_Category;
