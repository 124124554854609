'use client';

import { useState, useEffect } from 'react';
import CommongPreloader from "../../AdminPanel/CommongPreloader/CommongPreloader";
export default function Profile({ id }) {
  const [profileData, setProfileData] = useState({});
  const [loading, setLoading] = useState(true);
  const [isEditMode, setIsEditMode] = useState(false);  // Track edit mode

  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const response = await fetch(
          `https://vpandit.caxis.ca/public/api/users/${id}`
        );
        const data = await response.json();
        setProfileData(data);
      } catch (error) {
        console.error("Error fetching profile data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProfileData();
  }, []);

  const handleInputChange = (field, value) => {
    setProfileData({ ...profileData, [field]: value });
  };


  
  const handleUpdateProfile = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(`https://vpandit.caxis.ca/public/api/users/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(profileData),
      });

      const result = await response.json();
      if (response.ok) {
        alert('Profile updated successfully!');
        setIsEditMode(false); // Automatically disable edit mode after successful update
      } else {
        alert('Failed to update profile');
      }
    } catch (error) {
      console.error('Error updating profile:', error);
      alert('Error updating profile');
    }
  };

  if (loading) {
    return (
      <div>
        <CommongPreloader />
      </div>
    );
  }

  const renderValue = (value) => (value ? value : "Not Provided");

  return (
    <div className="container  rounded-lg shadow-sm">
      {/* Account Information Header */}
      <div className="mb-8">
        <h2 className="text-2xl font-bold ">Account Information</h2>
      </div>

      {/* Edit Icon */}

      {/* Profile Picture Section */}
      <div className="mb-4 flex justify-between items-center">
        <div>
          <h3 className="text-md font-medium text-gray-700 mb-3">
            Profile picture change
          </h3>
          <div className="flex items-center gap-4">
            <img
              src={
                profileData.U_Image ||
                "https://image.shutterstock.com/image-photo/indian-male-pandit-smiling-age-260nw-2513079627.jpg"
              }
              alt="Profile"
              className="w-16 h-16 rounded-full object-cover border border-gray-200"
            />
            <div className="flex gap-2">
              <button className="px-4 py-1.5 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50">
                Change
              </button>
            </div>
          </div>
        </div>
        <div className=" text-right">
          <button
            onClick={() => setIsEditMode(!isEditMode)}
            className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
          >
            {isEditMode ? "Cancel" : "Edit"}
          </button>
        </div>
      </div>

      {/* Personal Information Form */}
      <form className="space-y-6 p-1" onSubmit={handleUpdateProfile}>
        {/* Name Fields */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label className="block text-sm font-medium text-gray-700">
              First Name
            </label>
            <input
              type="text"
              value={
                isEditMode
                  ? profileData.U_Name
                  : renderValue(profileData.U_Name)
              }
              onChange={(e) => handleInputChange("U_Name", e.target.value)}
              disabled={!isEditMode}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none  focus:ring-orange-500 focus:border-orange-500"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Last Name
            </label>
            <input
              type="text"
              value={
                isEditMode
                  ? profileData.U_LastName
                  : renderValue(profileData.U_LastName)
              }
              onChange={(e) => handleInputChange("U_LastName", e.target.value)}
              disabled={!isEditMode}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-orange-500 focus:border-orange-500"
            />
          </div>
        </div>

        {/* Gender and Date of Birth */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Gender
            </label>
            <div className="flex gap-6">
              <label className="flex items-center">
                <input
                  type="radio"
                  name="gender"
                  value="M"
                  checked={profileData.U_Gender === "M"}
                  onChange={() => handleInputChange("U_Gender", "M")}
                  disabled={!isEditMode}
                  className="h-4 w-4 text-orange-600 focus:ring-orange-500 border-gray-300"
                />
                <span className="ml-2 text-sm text-gray-700">Male</span>
              </label>
              <label className="flex items-center">
                <input
                  type="radio"
                  name="gender"
                  value="F"
                  checked={profileData.U_Gender === "F"}
                  onChange={() => handleInputChange("U_Gender", "F")}
                  disabled={!isEditMode}
                  className="h-4 w-4 text-orange-600 focus:ring-orange-500 border-gray-300"
                />
                <span className="ml-2 text-sm text-gray-700">Female</span>
              </label>
            </div>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Date of Birth
            </label>
            <input
              type="date"
              value={
                isEditMode ? profileData.U_DOB : renderValue(profileData.U_DOB)
              }
              onChange={(e) => handleInputChange("U_DOB", e.target.value)}
              disabled={!isEditMode}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-orange-500 focus:border-orange-500"
            />
          </div>
        </div>

        {/* Mobile Number */}
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Mobile Number
          </label>
          <input
            type="tel"
            value={
              isEditMode
                ? profileData.U_Mobile
                : renderValue(profileData.U_Mobile)
            }
            onChange={(e) => handleInputChange("U_Mobile", e.target.value)}
            disabled={!isEditMode}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-orange-500 focus:border-orange-500"
          />
        </div>

        {/* Submit Section */}
        {isEditMode && (
          <div className="flex justify-end">
            <button
              type="submit"
              className="px-4 py-2 bg-orange-600 text-white text-sm font-medium rounded-md hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
            >
              Update Profile
            </button>
          </div>
        )}
      </form>
    </div>
  );
}
