"use client";

import { useState } from "react";
import { Mail, MapPin, MessageCircle, Phone } from "lucide-react";

export default function ContactUs() {
  const [formState, setFormState] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormState((prevState) => ({ ...prevState, [id]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    // Prepare the payload
    const payload = {
      Name: formState.firstName + " " + formState.lastName,
      Email: formState.email,
      Mobile: formState.phone || "Not provided",
      Subject: "Astrological Inquiry",
      Message: formState.message,
    };

    try {
      const response = await fetch(
        "https://https://vpandit.caxis.ca/public/api/contact-form",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      if (response.ok) {
        const data = await response.json();
        console.log("Success:", data);
        alert("Your message has been sent successfully!");
      } else {
        const error = await response.json();
        console.error("Error:", error);
        alert("Failed to send your message. Please try again.");
      }
    } catch (error) {
      console.error("Network error:", error);
      alert(
        "An error occurred while sending your message. Please check your network connection and try again."
      );
    } finally {
      setIsSubmitting(false);

      // Reset form
      setFormState({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        message: "",
      });
    }
  };

  return (
    <div className="container mx-auto px-4 py-8 md:py-12">
      <div className="grid gap-8 lg:grid-cols-2">
        <div className="space-y-6">
          <div>
            <h1 className="text-4xl font-bold sm:text-5xl md:text-4xl">
              Have a question about your astrological chart?
            </h1>
          </div>
          <div className="max-w-md space-y-4 text-gray-600">
            <p>
              We're here to guide you through your astrological journey! Fill
              out the form below or reach out to us via email or phone. Our team
              is ready to assist with birth chart interpretations, transit
              questions, compatibility readings, and more.
            </p>
            <p>
              Expect a personalized response from our astrologers within 24
              hours during business hours (M-F, 9 AM - 5 PM PT).
            </p>
          </div>
          <div className="space-y-4">
            {/* <a
              href="mailto:support@vpandit.com"
              className="flex items-center gap-2 hover:underline"
            >
              <Mail className="h-5 w-5" />
              support@vpandit.com
            </a>
            <a
              href="tel:+91 99987 13312"
              className="flex items-center gap-2 hover:underline"
            >
              <Phone className="h-5 w-5" />
              +91 99987 13312
            </a> */}
            <div className="flex items-center gap-2">
              {/* <MapPin className="h-5 w-5" />
              <a
                href="https://www.google.com/maps/search/?api=1&query=5th,+Maradia+Plaza,+A+Wing,+Chimanlal+Girdharlal+Rd,+Ellisbridge,+Ahmedabad,+Gujarat+380009"
                target="_blank"
                rel="noopener noreferrer"
                className="hover:underline text-gray-700"
              >
                5th, Maradia Plaza, A Wing, Chimanlal Girdharlal Rd, <br />
                Ellisbridge, Ahmedabad, Gujarat 380009
              </a> */}
            </div>
            {/* <button className="flex items-center gap-2 hover:text-white">
              <MessageCircle className="h-5 w-5" />
              Chat with us
            </button> */}
          </div>
        </div>

        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="grid gap-4 sm:grid-cols-2">
            <div className="space-y-2">
              <label
                htmlFor="firstName"
                className="block text-sm font-medium text-gray-700"
              >
                First Name *
              </label>
              <input
                id="firstName"
                type="text"
                value={formState.firstName}
                onChange={handleChange}
                required
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-orange-500"
              />
            </div>
            <div className="space-y-2">
              <label
                htmlFor="lastName"
                className="block text-sm font-medium text-gray-700"
              >
                Last Name *
              </label>
              <input
                id="lastName"
                type="text"
                value={formState.lastName}
                onChange={handleChange}
                required
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-orange-500"
              />
            </div>
          </div>
          <div className="space-y-2">
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700"
            >
              Email *
            </label>
            <input
              id="email"
              type="email"
              value={formState.email}
              onChange={handleChange}
              required
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-orange-500"
            />
          </div>
          <div className="space-y-2">
            <label
              htmlFor="phone"
              className="block text-sm font-medium text-gray-700"
            >
              Phone Number (optional)
            </label>
            <input
              id="phone"
              type="tel"
              value={formState.phone}
              onChange={handleChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-orange-500"
            />
          </div>
          <div className="space-y-2">
            <label
              htmlFor="message"
              className="block text-sm font-medium text-gray-700"
            >
              Message *
            </label>
            <textarea
              id="message"
              value={formState.message}
              onChange={handleChange}
              required
              className="w-full min-h-[150px] px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-orange-500"
              placeholder="Please provide details about your astrological question or request"
            />
          </div>
          <button
            type="submit"
            className="w-full bg-orange-600 text-white hover:bg-orange-800 py-2 px-4 rounded-md focus:outline-none"
            disabled={isSubmitting}
          >
            {isSubmitting ? "Submitting..." : "Submit"}
          </button>
        </form>
      </div>
    </div>
  );
}
