import React from "react";
import About from "../../Pages/About/About";
import Services from "../../Pages/Services/Services";
import Choosesign from "../../Pages/Choosesign/Choosesign";
import Testemonial from "../../Pages/Testemonial/Testemonial";
import WhyChooseUs from "../../Pages/WhyChooseUs/WhyChooseUs";
import Blog from "../../Pages/Blog/Blog";
import Hero from "../../Pages/Hero/Hero";
import AppDownload from "../../Pages/AppDownload/AppDownload";
import Our_Astrologer from "../../Pages/Our_Astrologer/Our_Astrologer";
import Best_Astro from "../../Pages/Best_Astro/Best_Astro";
import Pop_Up_Model from "../../Pages/Pop_Up_Model/Pop_Up_Model";
// import Content from '../../Pages/Content/Content';

const Home = () => {
  return (
    <div>
      {/* <Hero /> */}

      <About />
      <Services />
      {/* <Choosesign /> */}
      {/* <WhyChooseUs /> */}
      {/* <Testemonial /> */}
      {/* <Our_Astrologer /> */}
      {/* <Best_Astro /> */}
      {/* <AppDownload /> */}
      {/* <Blog /> */}
    </div>
  );
};

export default Home;
