"use client";

import React, { useState, useEffect } from "react";
import { FaEdit, FaTrash } from "react-icons/fa";
import Swal from "sweetalert2";
import axios from "axios";
import Add_Inventory_Management from "./Add_Inventory_Management";

const Inventory_Management = () => {
  const [viewType, setViewType] = useState("table");
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isEditing, setIsEditing] = useState(false);

  // Fetch data from the API
  const fetchData = async () => {
    try {
      const response = await axios.get(
        "https://vpandit.caxis.ca/public/api/product-masters"
      );
      setProducts(response.data); // Assuming response is an array of products
    } catch (error) {
      console.error("Error fetching products:", error);
      Swal.fire({
        icon: "error",
        title: "Error fetching products",
      });
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleAddProductClick = () => {
    setIsEditing(false);
    setViewType("addProduct");
    setSelectedProduct(null);
  };

  const handleEditProduct = (product) => {
    console.log(product);

    setIsEditing(true);
    setSelectedProduct(product);
    setViewType("addProduct");
  };

  const handleDeleteProduct = async (productId) => {
    console.log(productId);

    try {
      await axios.delete(
        `https://vpandit.caxis.ca/public/api/product-masters/${productId}`
      );
      setProducts(products.filter((product) => product.Prd_Id !== productId));
      Swal.fire({
        toast: true,
        position: "top-end",
        icon: "success",
        title: "Product deleted successfully",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.onmouseenter = Swal.stopTimer;
          toast.onmouseleave = Swal.resumeTimer;
        },
      });
    } catch (error) {
      console.error("Error deleting product", error);
      Swal.fire({
        toast: true,
        position: "top-end",
        icon: "error",
        title: "Error deleting the product",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.onmouseenter = Swal.stopTimer;
          toast.onmouseleave = Swal.resumeTimer;
        },
      });
    }
  };

  const handleCloseForm = () => {
    setViewType("table"); // Close the AddProduct form and return to table view
    fetchData();
  };

  return (
    <div className="min-h-screen">
      {/* Header */}
      <div className="flex items-center justify-between mb-6">
        <h1 className="text-2xl font-bold pt-2">Inventory Management</h1>
        <button
          onClick={handleAddProductClick}
          className="flex items-center bg-orange-500 text-white px-4 py-2 rounded-full shadow-lg hover:bg-orange-600 cursor-pointer font-bold"
        >
          Add Product
        </button>
      </div>

      {/* Conditional rendering */}
      {viewType === "table" ? (
        // Table View
        <div className="rounded-lg overflow-hidden">
          <table className="w-full bg-white">
            <thead>
              <tr className="border-b border-gray-700">
                <th className="px-6 py-3 text-left text-xs font-medium text-black uppercase tracking-wider">
                  Product Description
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-black uppercase tracking-wider">
                  Category Name
                </th>
                <th className="px-6 py-3 text-right text-xs font-medium text-black uppercase tracking-wider">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-700">
              {products.length === 0 ? (
                <tr>
                  <td
                    colSpan="3"
                    className="px-6 py-4 text-center text-gray-500"
                  >
                    No data available
                  </td>
                </tr>
              ) : (
                products.map((product) => (
                  <tr key={product.Prd_Id} className="hover:bg-gray-50">
                    <td className="px-6 py-4">{product.Prd_Desc}</td>
                    <td className="px-6 py-4">
                      {product.category
                        ? product.category.PrCat_Name
                        : "No Category"}
                    </td>
                    <td className="px-6 py-4 text-right">
                      <div className="flex justify-end space-x-3">
                        <FaEdit
                          className="text-green-600 cursor-pointer"
                          onClick={() => handleEditProduct(product)}
                        />
                        <FaTrash
                          className="text-red-600 cursor-pointer"
                          onClick={() => handleDeleteProduct(product.Prd_Id)}
                        />
                      </div>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      ) : (
        <Add_Inventory_Management
          handleCloseForm={handleCloseForm}
          setItems={setProducts}
          items={products}
          selectedProduct={selectedProduct}
          isEditing={isEditing}
        />
      )}
    </div>
  );
};

export default Inventory_Management;
