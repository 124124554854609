import React, { useState, useEffect, useRef } from "react";
import Swal from "sweetalert2";
import JoditEditor from "jodit-react";
import {
  FaUser,
  FaFileAlt,
  FaTools,
  FaDollarSign,
  FaCheckCircle,
  FaPhotoVideo,
  FaSearch,
  FaBox,
  FaPlus,
} from "react-icons/fa";

const Add_Pooja = ({ onClose, setUsers, users, selectedUser, isEditing }) => {
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    category: "",
    image: "",
    price: "",
    process: "",
    benefits: "",
    specialPooja: "",
    faqQuestion: "",
    faqAnswer: "",
    requirements: "",
    outcome: "",
    hasDiscount: false,
    discountPrice: "",
    overviewProvider: "",
    metaKeywords: "",
    metaDescription: "",
  });

  const [loading, setLoading] = useState(true);
  const editor = useRef(null);
  const processEditor = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 4000);
  }, []);

  // MultiStepForm logic
  const [currentStep, setCurrentStep] = useState(1);

  const steps = [
    { id: 1, title: "Information", icon: <FaUser className="w-6 h-6" /> },
    { id: 2, title: "Pricing", icon: <FaDollarSign className="w-6 h-6" /> },
    { id: 3, title: "Media", icon: <FaPhotoVideo className="w-6 h-6" /> },
    { id: 4, title: "Finish", icon: <FaCheckCircle className="w-6 h-6" /> },
  ];

  const handleNext = () => {
    if (currentStep < steps.length) {
      setCurrentStep(currentStep + 1);
    } else if (currentStep === steps.length) {
      handleSubmit();
    }
  };

  const handleBack = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  // Handle sidebar tab click
  const handleStepClick = (stepId) => {
    setCurrentStep(stepId);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormData({
        ...formData,
        image: file,
      });
    }
  };

  const tryParseJSON = (json) => {
    try {
      return JSON.parse(json);
    } catch (e) {
      return json;
    }
  };

  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch(
          "https://vpandit.caxis.ca/public/api/categories"
        );
        const data = await response.json();
        if (response.ok) {
          setCategories(data);
        } else {
          console.error("Failed to fetch categories");
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);

  useEffect(() => {
    if (isEditing && selectedUser) {
      setFormData({
        title: selectedUser.SubCat_Name || "",
        description: selectedUser.SubCat_Desc || "",
        category: selectedUser.Cat_Id || "",
        image: selectedUser.SubCat_Image || "",
        price: selectedUser.Price || "",
        process: Array.isArray(selectedUser.process_of_pooja)
          ? selectedUser.process_of_pooja
          : tryParseJSON(selectedUser.process_of_pooja) || "",
        benefits: Array.isArray(selectedUser.benefits_of_pooja)
          ? selectedUser.benefits_of_pooja
          : tryParseJSON(selectedUser.benefits_of_pooja) || [],
        specialPooja: Array.isArray(selectedUser.about_special_pooja_for)
          ? selectedUser.about_special_pooja_for
          : tryParseJSON(selectedUser.about_special_pooja_for) || [],
        faqQuestion:
          Array.isArray(selectedUser.faq_and_answer) &&
          selectedUser.faq_and_answer.length > 0
            ? selectedUser.faq_and_answer[0].question
            : "",
        faqAnswer:
          Array.isArray(selectedUser.faq_and_answer) &&
          selectedUser.faq_and_answer.length > 0
            ? selectedUser.faq_and_answer[0].answer
            : "",
        requirements: Array.isArray(selectedUser.requirement)
          ? selectedUser.requirement
          : tryParseJSON(selectedUser.requirement) || [],
        outcome: Array.isArray(selectedUser.outcome)
          ? selectedUser.outcome
          : tryParseJSON(selectedUser.outcome) || [],
        hasDiscount: Boolean(selectedUser.discount),
        discountPrice: selectedUser.discount || "",
        overviewProvider: selectedUser.pooja_overview_provider || "",
        metaKeywords: selectedUser.meta_keyword || "",
        metaDescription: selectedUser.meta_description || "",
      });
    }
  }, [isEditing, selectedUser]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (formData.category) {
      if (isEditing && selectedUser) {
        const payload = {
          Cat_Id: formData.category,
          SubCat_Name: formData.title,
          SubCat_Desc: formData.description,
          SubCat_Image: formData.image || "",
          Price: formData.price,
          process_of_pooja: JSON.stringify(formData.process || ""),
          benefits_of_pooja: JSON.stringify(formData.benefits || []),
          about_special_pooja_for: JSON.stringify(formData.specialPooja || []),
          faq_and_answer: [
            {
              question: formData.faqQuestion || "",
              answer: formData.faqAnswer || "",
            },
          ],
          requirement: Array.isArray(formData.requirements)
            ? formData.requirements
            : [],
          outcome: Array.isArray(formData.outcome) ? formData.outcome : [],
          discount: formData.hasDiscount ? formData.discountPrice : "",
          pooja_overview_provider: formData.overviewProvider || "",
          meta_keyword: formData.metaKeywords || "",
          meta_description: formData.metaDescription || "",
        };

        const url = `https://vpandit.caxis.ca/public/api/subcategories/${selectedUser.SubCat_Id}`;

        try {
          const response = await fetch(url, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
          });

          const result = await response.json();
          if (response.ok && result?.SubCat_Id) {
            Swal.fire({
              position: "top-right",
              icon: "success",
              title: "Successfully updated!",
              showConfirmButton: false,
              timer: 1100,
              timerProgressBar: true,
            });
            onClose();
          } else {
            onClose();
          }
        } catch (error) {
          onClose();
        }
      } else {
        const url = "https://vpandit.caxis.ca/public/api/subcategories";

        const newSubcategory = {
          Cat_Id: formData.category,
          SubCat_Name: formData.title,
          SubCat_Desc: formData.description,
          SubCat_Image: formData.image || null,
          Price: formData.price || null,
          process_of_pooja: JSON.stringify(formData.process || ""),
          benefits_of_pooja: JSON.stringify(formData.benefits || []),
          about_special_pooja_for: JSON.stringify(formData.specialPooja || []),
          faq_and_answer: [
            {
              question: formData.faqQuestion || null,
              answer: formData.faqAnswer || null,
            },
          ],
          requirement: Array.isArray(formData.requirements)
            ? formData.requirements
            : [],
          outcome: Array.isArray(formData.outcome) ? formData.outcome : [],
          discount: formData.hasDiscount ? formData.discountPrice : null,
          pooja_overview_provider: formData.overviewProvider || null,
          meta_keyword: formData.metaKeywords || null,
          meta_description: formData.metaDescription || null,
        };

        try {
          const response = await fetch(url, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(newSubcategory),
          });

          const result = await response.json();
          if (response.ok && result?.SubCat_Id) {
            Swal.fire({
              title: "Success!",
              text: "Subcategory added successfully.",
              icon: "success",
              confirmButtonText: "Okay",
            });

            const updatedSubcategory = {
              SubCat_Id: result.SubCat_Id,
              Cat_Id: result.Cat_Id,
              SubCat_Name: result.SubCat_Name,
              SubCat_Desc: result.SubCat_Desc,
              SubCat_Image: result.SubCat_Image,
              Price: result.Price,
              process_of_pooja: JSON.parse(result.process_of_pooja),
              benefits_of_pooja: JSON.parse(result.benefits_of_pooja),
              about_special_pooja_for: JSON.parse(
                result.about_special_pooja_for
              ),
              faq_and_answer: result.faq_and_answer,
              requirement: result.requirement,
              outcome: result.outcome,
              discount: result.discount,
              pooja_overview_provider: result.pooja_overview_provider,
              meta_keyword: result.meta_keyword,
              meta_description: result.meta_description,
              created_at: result.created_at,
              updated_at: result.updated_at,
            };

            setUsers((prevUsers) => [...prevUsers, updatedSubcategory]);
            onClose();
          } else {
            console.error("API Error:", result);
            Swal.fire({
              title: "Error",
              text:
                result?.message ||
                "Failed to add subcategory. Please try again.",
              icon: "error",
              confirmButtonText: "Okay",
            });
          }
        } catch (error) {
          onClose();
        }
      }
    } else {
      const categoryUrl = "https://vpandit.caxis.ca/public/api/categories";

      const newCategory = {
        Cat_Name: formData.title,
        Cat_Desc: formData.description,
      };

      try {
        const categoryResponse = await fetch(categoryUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(newCategory),
        });

        const categoryResult = await categoryResponse.json();
        onClose();

        if (categoryResponse.ok && categoryResult?.Cat_Id) {
          Swal.fire({
            title: "Success!",
            text: "Category added successfully.",
            icon: "success",
            confirmButtonText: "Okay",
          });

          setCategories([
            ...categories,
            { ...newCategory, Cat_Id: categoryResult.Cat_Id || Date.now() },
          ]);
        }
      } catch (error) {
        console.error("Category Error:", error);
        onClose();
      }
    }
  };

  return (
    <div className="p-4 bg-white rounded-lg shadow-lg mt-2">
      <div className="flex mb-6">
        <h1 className="text-2xl font-bold text-orange-500">
          {isEditing ? "Update Item" : "Add New Item"}
        </h1>
      </div>

      {/* Title Field */}
      <div className="mt-4">
        <label
          htmlFor="title"
          className="block text-sm font-medium text-gray-700"
        >
          Title
        </label>
        <input
          type="text"
          id="title"
          name="title"
          value={formData.title}
          onChange={handleInputChange}
          required
          className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-orange-500 focus:border-orange-500 sm:text-sm"
        />
      </div>

      {/* Description Field */}
      <div className="mt-4">
        <label
          htmlFor="description"
          className="block text-sm font-medium text-gray-700"
        >
          Description
        </label>
        <JoditEditor
          ref={editor}
          value={typeof formData.description === 'string' ? formData.description : ''} // Ensure value is a string
          tabIndex={1}
          onBlur={(newContent) =>
            setFormData({ ...formData, description: newContent })
          }
          onChange={() => {}}
        />
      </div>

      <div className="mt-4">
        <label
          htmlFor="category"
          className="block text-sm font-medium text-gray-700"
        >
          Category
        </label>
        <select
          id="category"
          name="category"
          value={formData.category}
          onChange={handleInputChange}
          className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-orange-500 focus:border-orange-500 sm:text-sm"
        >
          <option value="">Select Category</option>
          {categories.map((category) => (
            <option key={category.Cat_Id} value={category.Cat_Id}>
              {category.Cat_Name}
            </option>
          ))}
        </select>
      </div>

      {formData.category && (
        <div className="h-auto bg-white">
          <div className="container mx-auto p-3 flex flex-col lg:flex-row gap-1">
            {/* Progress Indicator (Sidebar) */}
            <div className="relative pt-8 flex-shrink-0 lg:w-1/4">
              <div className="absolute left-1/2 h-full w-px bg-gray-200 -translate-x-1/2 hidden lg:block ml-24" />
              <div className="relative space-y-8">
                {steps.map((step) => (
                  <div
                    key={step.id}
                    className="flex items-center gap-4 cursor-pointer"
                    onClick={() => handleStepClick(step.id)}
                  >
                    <div
                      className={`relative z-10 w-12 h-12 rounded-full flex items-center justify-center ${
                        step.id === currentStep
                          ? "bg-orange-600 text-white"
                          : step.id < currentStep
                          ? "bg-orange-600/20 text-orange-600"
                          : "bg-gray-100 text-gray-400"
                      }`}
                    >
                      {step.icon}
                    </div>
                    <div className="flex flex-col">
                      <span
                        className={`text-sm font-bold ${
                          step.id === currentStep
                            ? "text-orange-600"
                            : "text-gray-500"
                        }`}
                      >
                        {step.title}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {/* Content Area */}
            <div className="flex-1 pt-8 lg:w-3/4">
              <div className="mb-4">
                <div className="text-sm text-gray-500">
                  Step {currentStep}/{steps.length}
                </div>
                <h1 className="text-2xl font-semibold mt-2">
                  {steps[currentStep - 1].title}
                </h1>
                <p className="text-gray-500 mt-2">
                  Please fill in the required information for the step.
                </p>
              </div>

              {/* Step-specific content */}
              <div>
                {currentStep === 1 && (
                  <div>
                    <label
                      htmlFor="process"
                      className="block text-sm font-medium text-gray-700"
                    >
                      About Of Pooja *
                    </label>
                    <JoditEditor
                      ref={processEditor}
                      value={typeof formData.process === 'string' ? formData.process : ''} // Ensure value is a string
                      tabIndex={1}
                      onBlur={(newContent) =>
                        setFormData({ ...formData, process: newContent })
                      }
                      onChange={() => {}}
                      className="mt-2"
                    />
                  </div>
                )}

                {currentStep === 2 && (
                  <div>
                    <label
                      htmlFor="price"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Pooja Price ($)
                    </label>
                    <input
                      type="number"
                      id="price"
                      name="price"
                      value={formData.price}
                      onChange={handleInputChange}
                      className="mt-2 p-2 w-full border border-gray-300 rounded"
                      placeholder="Enter Pooja price"
                    />

                    <div className="mt-4">
                      <input
                        type="checkbox"
                        id="hasDiscount"
                        name="hasDiscount"
                        checked={formData.hasDiscount || false}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            hasDiscount: e.target.checked,
                          })
                        }
                      />
                      <label
                        htmlFor="hasDiscount"
                        className="ml-2 text-sm text-gray-700"
                      >
                        Check if this Pooja has discount
                      </label>
                    </div>

                    {formData.hasDiscount && (
                      <div className="mt-4">
                        <label
                          htmlFor="discountPrice"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Discounted Price ($)
                        </label>
                        <input
                          type="number"
                          id="discountPrice"
                          name="discountPrice"
                          value={formData.discountPrice || ""}
                          onChange={handleInputChange}
                          className="mt-2 p-2 w-full border border-gray-300 rounded"
                          placeholder="Enter discounted price"
                        />
                      </div>
                    )}
                  </div>
                )}

                {currentStep === 3 && (
                  <div>
                    <label
                      htmlFor="overviewProvider"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Pooja Overview Provider
                    </label>
                    <input
                      type="url"
                      id="overviewProvider"
                      name="overviewProvider"
                      value={formData.overviewProvider || ""}
                      onChange={handleInputChange}
                      className="mt-2 p-2 w-full border border-gray-300 rounded"
                      placeholder="E.g: https://www.youtube.com/watch?v=oBtf8Yglw2w"
                    />
                  </div>
                )}

                {currentStep === 4 && (
                  <div className="">
                    <h3 className="text-xl font-semibold">Form Submitted!</h3>
                    <p className="text-gray-500">
                      Thank you for completing the form. We will get back to you
                      shortly.
                    </p>
                  </div>
                )}
              </div>

              {/* Navigation */}
              <div className="flex justify-between mt-8 space-x-4">
                {currentStep !== 1 ? (
                  <button
                    onClick={handleBack}
                    className="px-6 py-2 bg-orange-600 text-white rounded-full hover:bg-orange-700 transition-colors"
                  >
                    Back
                  </button>
                ) : (
                  <div onClick={handleBack} className=""></div>
                )}

                <button
                  onClick={(e) =>
                    currentStep === steps.length
                      ? handleSubmit(e)
                      : handleNext()
                  }
                  className="px-6 py-2 bg-orange-600 text-white rounded-full hover:bg-orange-700 transition-colors"
                >
                  {currentStep === steps.length ? "Submit" : "Next Step"}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Submit Button */}
      <div className="flex items-center justify-end gap-5 mt-5 mr-2">
        <div className="mt-6 flex justify-between">
          <button
            onClick={(e) => handleSubmit(e)}
            className="px-6 py-2 bg-orange-600 text-white rounded-full hover:bg-orange-700 transition-colors"
          >
            {isEditing ? "Update" : "Add Item"}
          </button>
        </div>
        <div className="mt-6 flex justify-between">
          <button
            onClick={onClose}
            type="submit"
            className="px-6 py-2 bg-gray-600 text-white rounded-full hover:bg-orange-700 transition-colors"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default Add_Pooja;