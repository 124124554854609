import React, { useEffect, useState } from "react";
import {
  FaUser,
  FaFileAlt,
  FaTools,
  FaDollarSign,
  FaCheckCircle,
  FaPhone,
  FaEnvelope,
  FaAddressCard,
  FaChevronDown,
  FaChevronRight,
  FaFlagCheckered,
} from "react-icons/fa";
import Preloader from "./Preloader";
import axios from "axios";
import { h1 } from "framer-motion/client";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

const PanditRegister = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 700);
  }, []);

  const [currentStep, setCurrentStep] = useState(1);
  const [panditInfo, setPanditInfo] = useState({
    name: "",
    dateOfBirth: "",
    gender: "",
    contact: "",
    email: "",
    experience: "",
    currentAddress: "",
    taluka: "",
    Zipcode: "",
    state: "",
    country: "",
    languages: [],
    Address: "",
    photograph: "",
    socialLinks: [],
    consent: false,
    gotra: "",
    religion: "",
    kuldevi: "",
    charan: "",
    mukh: "",
    upved: "",
    ved: "",
    pravar: "",
    ocuupation: "",
  });

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));

    if (user) {
      setPanditInfo((prevState) => ({
        ...prevState,
        name: user.U_Name || "",
        dateOfBirth: user.U_DOB || "",
        gender:
          user.U_Gender === "M"
            ? "Male"
            : user.U_Gender === "F"
            ? "Female"
            : "Male",
        contact: user.U_Mobile || "",
        email: user.U_Email || "",
        // Add more mappings as needed for other fields
      }));
    }
  }, []);

  const steps = [
    { id: 1, title: "Personal Details", icon: <FaUser className="w-6 h-6" /> },
    {
      id: 2,
      title: "Professional Details",
      icon: <FaFileAlt className="w-6 h-6" />,
    },
    {
      id: 3,
      title: "Address Details",
      icon: <FaAddressCard className="w-6 h-6" />,
    },
    {
      id: 4,
      title: "Payment and Charges",
      icon: <FaDollarSign className="w-6 h-6" />,
    },

    {
      id: 5,
      title: "Complete Process",
      icon: <FaFlagCheckered className="w-6 h-6" />,
    },
  ];

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scrolling
    });
  };
  const handleNext = () => {
    if (currentStep < steps.length) {
      setCurrentStep(currentStep + 1);
      scrollToTop();
    }
  };

  const handleBack = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
      scrollToTop();
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPanditInfo({
      ...panditInfo,
      [name]: value,
    });
  };

  const [availableLanguages, setAvailableLanguages] = useState([]);

  useEffect(() => {
    // Fetch languages from the API
    const fetchLanguages = async () => {
      try {
        const response = await fetch(
          "https://vpandit.caxis.ca/public/api/languages"
        );
        if (!response.ok) {
          throw new Error("Failed to fetch languages");
        }
        const data = await response.json();
        if (Array.isArray(data)) {
          setAvailableLanguages(data); // Ensure data is an array
        } else {
          console.error("API response is not an array:", data);
        }
      } catch (error) {
        console.error("Error fetching languages:", error);
      }
    };

    fetchLanguages();
  }, []);

  const handleLanguageSelection = (languageId) => {
    const selectedLanguage = availableLanguages.find(
      (lang) => lang.Lang_ID === parseInt(languageId, 10)
    );

    if (
      selectedLanguage &&
      !panditInfo.languages.some(
        (lang) => lang.Lang_ID === selectedLanguage.Lang_ID
      )
    ) {
      setPanditInfo((prev) => ({
        ...prev,
        languages: [
          ...prev.languages,
          {
            Lang_ID: selectedLanguage.Lang_ID,
            Lang_Name: selectedLanguage.Lang_Name,
          },
        ],
      }));
    }
  };

  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [error, setError] = useState("");
  const [selectedCategory, setSelectedCategory] = useState(null);

  useEffect(() => {
    const fetchCategories = async () => {
      setLoading(true);
      setError("");
      try { 
        const response = await axios.get(
          "https://vpandit.caxis.ca/public/api/categories"
        );
        setCategories(response.data);
      } catch (err) {
        setError("Failed to fetch categories.");
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();
  }, []);

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    setSubcategories(category.subcategories || []);
  };

  const handleRemoveLanguage = (index) => {
    setPanditInfo((prev) => ({
      ...prev,
      languages: prev.languages.filter((_, i) => i !== index),
    }));
  };

  const [checkedSubcategories, setCheckedSubcategories] = useState({});
  const [currentSubcategory, setCurrentSubcategory] = useState(null);
  const [showModal, setShowModal] = useState(false);

  // Function to log the SubCat_Id and Price
  const [subCategoryData, setSubCategoryData] = useState([]);

  const handleCheckboxChange = (subcategory) => {
    if (!checkedSubcategories[subcategory.SubCat_Id]) {
      setCurrentSubcategory(subcategory);
      setShowModal(true);
    } else {
      // Uncheck the box and remove the subcategory from the state
      setCheckedSubcategories((prev) => {
        const updated = { ...prev };
        delete updated[subcategory.SubCat_Id];
        return updated;
      });

      setSubCategoryData((prevData) =>
        prevData.filter((item) => item.subCatId !== subcategory.SubCat_Id)
      );
    }
  };

  // Handle modal submission
  const handleModalSubmit = (enteredPrice) => {
    if (enteredPrice && !isNaN(enteredPrice)) {
      const subCatId = currentSubcategory.SubCat_Id;
      const price = parseFloat(enteredPrice);

      // Update checked subcategories and subcategory data
      setCheckedSubcategories((prev) => ({
        ...prev,
        [subCatId]: price,
      }));

      setSubCategoryData((prevData) => [
        ...prevData.filter((item) => item.subCatId !== subCatId),
        { subCatId, price },
      ]);
    }
    setShowModal(false);
    setCurrentSubcategory(null);
  };

  const logSubcategoryPrice = (subCatId, price) => {
    console.log(`SubCat_Id: ${subCatId}, Price: ₹${price}`);

    // Update the state with the new subcategory and price
    setSubCategoryData((prevData) => [
      ...prevData.filter((item) => item.subCatId !== subCatId),
      { subCatId, price },
    ]);
  };

  // Function to close the modal
  const closeModal = () => {
    setShowModal(false);
    setCurrentSubcategory(null);
  };

  const [userid, setuserid] = useState(null);

  useEffect(() => {
    const userId = localStorage.getItem("userID");
    console.log(userId);

    setuserid(userId);
  }, []);

  const [Pid, setPid] = useState(null);

  const navigate = useNavigate();
  const handleFormSubmit = async () => {
    const payload = {
      U_Id: userid,
      taluka: panditInfo.taluka,
      Zipcode: panditInfo.Zipcode,
      State: panditInfo.state,
      Country: panditInfo.country,
      Religion: panditInfo.religion,
      Gotra: panditInfo.gotra,
      Pravar: panditInfo.pravar,
      Ved: panditInfo.ved,
      UpVed: panditInfo.upved,
      Mukh: panditInfo.mukh,
      Charan: panditInfo.charan,
      Kuldevi: panditInfo.kuldevi,
      Experience: panditInfo.experience,
      Lang_ID: panditInfo.languages.map((lang) => lang.Lang_ID) || [],
      Address: panditInfo.Address,
      City: panditInfo.taluka,
      Occupation: panditInfo.ocuupation,
      Pan_Approve: "2",
    };

    console.log(payload);
    console.log(selectedCategory);

    try {
      // First API Call to create/update Pandit details
      const updateResponse = await axios.post(
        `https://vpandit.caxis.ca/public/api/pandit-masters`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Update successful:", updateResponse.data.pandit);

      const panditId = updateResponse.data.pandit.Pandit_Id;
      console.log(panditId);

      setPid(panditId); // Update the state with the Pandit_Id

      if (panditId) {
        const subCategoryDetails = subCategoryData.map((item) => ({ 
          SubCat_ID: item.subCatId,
          Price: item.price,
        }));

        const payload2 = {
          Pandit_Id: panditId,
          Skills: subCategoryDetails,
        };

        console.log(payload2);
        console.log(subCategoryDetails);

        try {
          const response = await axios.post(
            "https://vpandit.caxis.ca/public/api/pandit-skills",
            payload2,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );

          console.log("POST Response:", response.data);

          // Show success popup using SweetAlert2
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Your Pandit Request is Sent Successfully!",
            position: "top-end", // Show the popup at the top-right corner
            toast: true, // Use toast-style notification
            timer: 3000, // Auto-dismiss after 3 seconds
            showConfirmButton: false, // No confirm button
          });
          navigate("/");
        } catch (error) {
          console.log(error);

          // Show error popup using SweetAlert2
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Failed to submit the data. Please try again.",
            position: "top-end",
            toast: true,
            timer: 3000,
            showConfirmButton: false,
          });
        }
      }
    } catch (error) {
      console.error("Error updating data:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to update data. Please try again.",
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        toast: true,
      });
      return;
    }
  };

  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <div className="min-h-screen bg-white pt-5">
          <div className="container mx-auto p-3 flex flex-col lg:flex-row gap-1">
            <div className="relative pt-8 flex-shrink-0 lg:w-1/4">
              <div className="relative space-y-8">
                {steps.map((step, index) => (
                  <div
                    key={step.id}
                    className="flex items-center gap-4 relative"
                  >
                    {/* Vertical Line */}
                    {index !== steps.length - 1 && (
                      <div
                        className={`absolute left-6 top-full w-px ${
                          step.id < currentStep
                            ? "bg-orange-600"
                            : "bg-gray-300"
                        }`}
                        style={{ height: "calc(100% + 1rem)" }} // Adjust the height dynamically
                      ></div>
                    )}
                    <div
                      className={`relative z-10 w-12 h-12 rounded-full flex items-center justify-center ${
                        step.id === currentStep
                          ? "bg-orange-600 text-white"
                          : step.id < currentStep
                          ? "bg-orange-600/20 text-orange-600"
                          : "bg-gray-100 text-gray-400"
                      }`}
                    >
                      {step.icon}
                    </div>
                    <div className="flex flex-col">
                      <span
                        className={`text-sm font-bold ${
                          step.id === currentStep
                            ? "text-orange-600"
                            : "text-gray-500"
                        }`}
                      >
                        {step.title}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="flex-1 pt-8 lg:w-3/4 lg:-ml-16 ml-0">
              <div className="mb-8">
                <div className="text-sm text-gray-500">
                  Step {currentStep}/ {steps.length}
                </div>
                <h1 className="text-2xl font-semibold mt-2">
                  {steps[currentStep - 1].title}
                </h1>
                <p className="text-gray-500 mt-2">
                  Please fill in the required information for the step.
                </p>
              </div>

              <div>
                {currentStep === 1 && (
                  <div>
                    <label
                      htmlFor="name"
                      className="block text-sm text-gray-500"
                    >
                      Full Name
                    </label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      value={panditInfo.name}
                      onChange={handleInputChange}
                      className="mt-2 p-2 w-full border border-gray-300 rounded"
                      placeholder="Enter your full name"
                    />

                    <label
                      htmlFor="dateOfBirth"
                      className="block text-sm text-gray-500 mt-4"
                    >
                      Date of Birth
                    </label>
                    <input
                      type="date"
                      id="dateOfBirth"
                      name="dateOfBirth"
                      value={panditInfo.dateOfBirth}
                      onChange={handleInputChange}
                      className="mt-2 p-2 w-full border border-gray-300 rounded"
                    />

                    <label
                      htmlFor="gender"
                      className="block text-sm text-gray-500 mt-4"
                    >
                      Gender
                    </label>
                    <input
                      id="gender"
                      name="gender"
                      value={panditInfo.gender}
                      onChange={handleInputChange}
                      className="mt-2 p-2 w-full border border-gray-300 rounded"
                      disabled
                    />

                    <label
                      htmlFor="contact"
                      className="block text-sm text-gray-500 mt-4"
                    >
                      Contact Number
                    </label>
                    <input
                      type="text"
                      id="contact"
                      name="contact"
                      value={panditInfo.contact}
                      onChange={handleInputChange}
                      className="mt-2 p-2 w-full border border-gray-300 rounded"
                      placeholder="Enter your contact number"
                    />

                    <label
                      htmlFor="email"
                      className="block text-sm text-gray-500 mt-4"
                    >
                      Email Address
                    </label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      value={panditInfo.email}
                      onChange={handleInputChange}
                      className="mt-2 p-2 w-full border border-gray-300 rounded"
                      placeholder="Enter your email"
                    />
                  </div>
                )}

                {currentStep === 3 && (
                  <div>
                    <div className="mt-4 grid grid-cols-2 gap-4">
                      {/* Taluka */}
                      <div>
                        <label
                          htmlFor="taluka"
                          className="block text-sm text-gray-500"
                        >
                          Address
                        </label>
                        <input
                          type="text"
                          id="Address"
                          name="Address"
                          value={panditInfo.Address}
                          onChange={handleInputChange}
                          className="mt-2 p-2 w-full border border-gray-300 rounded"
                          placeholder="Enter your Address"
                        />
                      </div>
                      <div>
                        <label
                          htmlFor="taluka"
                          className="block text-sm text-gray-500"
                        >
                          Taluka
                        </label>
                        <input
                          type="text"
                          id="taluka"
                          name="taluka"
                          value={panditInfo.taluka}
                          onChange={handleInputChange}
                          className="mt-2 p-2 w-full border border-gray-300 rounded"
                          placeholder="Enter your taluka"
                        />
                      </div>

                      {/* Zipcode */}
                      <div>
                        <label
                          htmlFor="Zipcode"
                          className="block text-sm text-gray-500"
                        >
                          Zipcode
                        </label>
                        <input
                          type="text"
                          id="Zipcode"
                          name="Zipcode"
                          value={panditInfo.Zipcode}
                          onChange={handleInputChange}
                          className="mt-2 p-2 w-full border border-gray-300 rounded"
                          placeholder="Enter your Zipcode"
                        />
                      </div>

                      <div>
                        <label
                          htmlFor="ocuupation"
                          className="block text-sm text-gray-500"
                        >
                          Ocuupation
                        </label>
                        <input
                          type="text"
                          id="ocuupation"
                          name="ocuupation"
                          value={panditInfo.ocuupation}
                          onChange={handleInputChange}
                          className="mt-2 p-2 w-full border border-gray-300 rounded"
                          placeholder="Enter your years of ocuupation"
                        />
                      </div>
                      {/* State */}
                      <div>
                        <label
                          htmlFor="state"
                          className="block text-sm text-gray-500"
                        >
                          State
                        </label>
                        <input
                          type="text"
                          id="state"
                          name="state"
                          value={panditInfo.state}
                          onChange={handleInputChange}
                          className="mt-2 p-2 w-full border border-gray-300 rounded"
                          placeholder="Enter your state"
                        />
                      </div>

                      {/* Country */}
                      <div>
                        <label
                          htmlFor="country"
                          className="block text-sm text-gray-500"
                        >
                          Country
                        </label>
                        <input
                          type="text"
                          id="country"
                          name="country"
                          value={panditInfo.country}
                          onChange={handleInputChange}
                          className="mt-2 p-2 w-full border border-gray-300 rounded"
                          placeholder="Enter your country"
                        />
                      </div>
                    </div>
                  </div>
                )}

                {currentStep === 2 && (
                  <div>
                    <label
                      htmlFor="experience"
                      className="block text-sm text-gray-500"
                    >
                      Experience (in years)
                    </label>
                    <input
                      type="text"
                      id="experience"
                      name="experience"
                      value={panditInfo.experience}
                      onChange={handleInputChange}
                      className="mt-2 p-2 w-full border border-gray-300 rounded"
                      placeholder="Enter your years of experience"
                    />

                    <div>
                      <label
                        htmlFor="languages"
                        className="block text-sm text-gray-500 mt-4"
                      >
                        Languages Known
                      </label>
                      <select
                        id="languages"
                        className="mt-2 p-2 w-full border border-gray-300 rounded"
                        onChange={(e) =>
                          handleLanguageSelection(e.target.value)
                        }
                      >
                        <option value="" disabled selected>
                          Select a language
                        </option>
                        {availableLanguages.map((language) => (
                          <option
                            key={language.Lang_ID}
                            value={language.Lang_ID}
                          >
                            {language.Lang_Name || "Unnamed Language"}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="mt-4">
                      <span className="block text-sm text-gray-500">
                        Selected Languages:
                      </span>
                      <div className="mt-2 flex flex-wrap gap-2">
                        {panditInfo.languages.map((lang, index) => (
                          <span
                            key={index}
                            className="inline-flex items-center px-3 py-1 bg-gray-200 text-sm rounded-full"
                          >
                            {lang.Lang_Name}
                            <div
                              className="ml-2 text-red-500 cursor-pointer"
                              onClick={() => handleRemoveLanguage(index)}
                            >
                              &times;
                            </div>
                          </span>
                        ))}
                      </div>
                    </div>

                    {/* New Fields in 4 Columns */}
                    <div className="mt-6 grid md:grid-cols-3 lg:grid-cols-4 gap-4">
                      {[
                        "Gotra",
                        "Religion",
                        "Kuldevi",
                        "Charan",
                        "Mukh",
                        "Upved",
                        "Shakha",
                        "Ved",
                        "Pravar",
                      ].map((field) => (
                        <div key={field}>
                          <label
                            htmlFor={field.toLowerCase()}
                            className="block text-sm text-gray-500"
                          >
                            {field}
                          </label>
                          <input
                            type="text"
                            id={field.toLowerCase()}
                            name={field.toLowerCase()}
                            value={panditInfo[field.toLowerCase()] || ""}
                            onChange={handleInputChange}
                            className="mt-2 p-2 w-full border border-gray-300 rounded"
                            placeholder={`Enter ${field}`}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                )}

                {currentStep === 4 && (
                  <>
                    <div className="mt-6 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                      {categories.map((category) => (
                        <div
                          key={category.Cat_Id}
                          onClick={() => handleCategoryClick(category)}
                          className="border border-gray-300 rounded-lg p-4 shadow-md bg-white hover:shadow-lg transition-shadow cursor-pointer"
                        >
                          <h3 className="text-lg font-semibold">
                            {category.Cat_Name}
                          </h3>
                        </div>
                      ))}
                    </div>

                    {selectedCategory && (
                      <div className="mt-8 cursor-pointer">
                        <h2 className="text-xl font-semibold mb-4">
                          Subcategories for {selectedCategory.Cat_Name}
                        </h2>
                        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                          {subcategories.length > 0 ? (
                            subcategories.map((subcategory) => (
                              <div
                                key={subcategory.SubCat_Id}
                                className="relative border border-gray-300 rounded-lg p-4 shadow-md bg-white hover:shadow-lg transition-shadow"
                              >
                                {/** Checkbox at the top-right */}
                                <input
                                  type="checkbox"
                                  className="absolute top-2 right-2 w-4 h-4 cursor-pointer"
                                  checked={
                                    !!checkedSubcategories[
                                      subcategory.SubCat_Id
                                    ]
                                  }
                                  onChange={() =>
                                    handleCheckboxChange(subcategory)
                                  }
                                />

                                {/** Subcategory Name */}
                                <h3 className="text-lg font-semibold">
                                  {subcategory.SubCat_Name}
                                </h3>

                                {/** Display Entered Price */}
                                {checkedSubcategories[
                                  subcategory.SubCat_Id
                                ] && (
                                  <p className="text-green-500 text-sm mt-2">
                                    Entered Price: ₹
                                    {
                                      checkedSubcategories[
                                        subcategory.SubCat_Id
                                      ]
                                    }
                                  </p>
                                )}
                              </div>
                            ))
                          ) : (
                            <p className="text-gray-500">
                              No subcategories available.
                            </p>
                          )}
                        </div>
                      </div>
                    )}

                    {showModal && currentSubcategory && (
                      <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                        <div className="bg-white p-6 rounded-lg shadow-lg w-96">
                          <h2 className="text-lg font-semibold mb-4">
                            Enter Price for {currentSubcategory.SubCat_Name}
                          </h2>
                          <input
                            type="number"
                            placeholder="Enter price"
                            className="w-full border border-gray-300 p-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-500"
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                handleModalSubmit(e.target.value);
                              }
                            }}
                          />
                          <div className="flex justify-end space-x-4 mt-4">
                            <div
                              className="bg-gray-300 px-4 py-2 rounded-lg hover:bg-gray-400 cursor-pointer"
                              onClick={closeModal}
                            >
                              Cancel
                            </div>
                            <div
                              className="bg-orange-500 text-white px-4 py-2 rounded-lg hover:bg-orange-600 cursor-pointer"
                              onClick={() =>
                                handleModalSubmit(
                                  document.querySelector("input[type=number]")
                                    .value
                                )
                              }
                            >
                              Submit
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                )}

                {currentStep === 5 && (
                  <div className="">
                    <p className="mt-2 text-gray-600">
                      Thank you for providing all the necessary information.
                      Please review your details and submit the form.
                    </p>
                  </div>
                )}
              </div>

              <div className="ralative">
                <div className=" ">
                  <div className="flex justify-between mt-6 gap-5">
                    <button
                      type="button"
                      onClick={handleBack}
                      disabled={currentStep === 1}
                      className="bg-gray-300 text-gray-700 py-2 px-4 rounded"
                    >
                      Back
                    </button>

                    {currentStep === 5 ? (
                      <button
                        type="button"
                        onClick={handleFormSubmit}
                        className="bg-orange-600 text-white py-2 px-4 rounded"
                      >
                        Submit
                      </button>
                    ) : (
                      <button
                        type="button"
                        onClick={handleNext}
                        className="bg-orange-600 text-white py-2 px-4 rounded"
                      >
                        Next
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PanditRegister;
