import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { FaEdit, FaTrash } from "react-icons/fa";
import { BsSortNumericUp } from "react-icons/bs";
import HTMLReactParser from "html-react-parser";
import AddLuShodGrid from "./AddLuShodGrid";
import CommongPreloader from "../CommongPreloader/CommongPreloader";
import { Filter } from "lucide-react";
import { FiFilter } from "react-icons/fi";

const LuShodGrid = () => {
  const [numerologyData, setNumerologyData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showAddNumerology, setShowAddNumerology] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [isEditing, setIsEditing] = useState(false);

  // Filter states
  const [topicFilter, setTopicFilter] = useState("");
  const [numerologyFilter, setNumerologyFilter] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [showFilterFields, setShowFilterFields] = useState(false);

  // Fetch Data
  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        "https://vpandit.caxis.ca/public/api/loshu-grids"
      );
      if (Array.isArray(response.data)) {
        const formattedData = response.data.map((item) => ({
          numId: item.Loshugrid_Id,
          topicNumber: item.topic_no,
          numerologyNumber: item.Value1,
          Count: item.Count,
          Count1: item.Count1, // ✅ Added Count1 field
          title: item.desc_title,
          description: item.desc_details,
        }));
        setNumerologyData(formattedData);
        console.log(formattedData);
        setFilteredData(formattedData); // Set initial data for filteredData
      } else {
        throw new Error("Invalid data format received");
      }
    } catch (err) {
      setError("Failed to fetch data. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Filter function
  const handleFilter = () => {
    let filtered = numerologyData;

    if (topicFilter) {
      filtered = filtered.filter((item) => item.topicNumber === topicFilter);
    }

    if (numerologyFilter) {
      filtered = filtered.filter(
        (item) => item.numerologyNumber === numerologyFilter
      );
    }

    setFilteredData(filtered);
  };

  // Handle Add Click
  const handleAddClick = () => {
    setSelectedData(null);
    setIsEditing(false);
    setShowAddNumerology(true);
    fetchData();
  };

  // Handle Edit Click
  const handleEditClick = (data) => {
    setSelectedData(data);
    setIsEditing(true);
    setShowAddNumerology(true);
    fetchData();
  };

  // Handle Delete Click
  const handleDeleteClick = async (numId) => {
    try {
      await axios.delete(
        `https://vpandit.caxis.ca/public/api/loshu-grids/${numId}`
      );
      setNumerologyData((prevData) =>
        prevData.filter((item) => item.numId !== numId)
      );

      fetchData();

      // Show a success toast
      Toast.fire({
        icon: "success",
        title: "Record deleted successfully",
      });
    } catch (err) {
      // Show an error toast
      Toast.fire({
        icon: "error",
        title: "Failed to delete the record. Please try again.",
      });
    }
  };

  // Define Toast once for reuse
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    },
  });

  // Handle Enable Filters
  const handleEnableFilters = () => {
    setShowFilterFields(true);
  };

  // Handle Cancel Filters
  const handleCancelFilter = () => {
    setShowFilterFields(false);
    setTopicFilter("");
    setNumerologyFilter("");
    setFilteredData(numerologyData); // Reset to the full data
  };

  return (
    <div>
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Lu Shod Grid </h1>
        <div className="flex gap-3">
          <div className="flex space-x-4">
            <div
              onClick={handleEnableFilters}
              className="cursor-pointer flex items-center"
            >
              <FiFilter className="mr-2" /> {/* Add the filter icon */}
            </div>
          </div>

          <div
            onClick={handleAddClick}
            className="cursor-pointer bg-orange-500 hover:bg-orange-600 text-white px-4 py-2 rounded-3xl flex items-center"
          >
            <BsSortNumericUp className="mr-2 h-4 w-4" /> Add Numerology
          </div>
        </div>
      </div>

      {showFilterFields && (
        <div className="mt-4 mb-6">
          <div className="flex space-x-4 justify-between">
            <div className="flex gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Topic Number
                </label>
                <input
                  type="text"
                  value={topicFilter}
                  onChange={(e) => setTopicFilter(e.target.value)}
                  className="mt-1 p-2 border border-gray-300  focus:outline-none focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
                  placeholder="Enter Topic Number"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Numerology Number
                </label>
                <input
                  type="text"
                  value={numerologyFilter}
                  onChange={(e) => setNumerologyFilter(e.target.value)}
                  className="mt-1 p-2 border border-gray-300 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
                  placeholder="Enter Numerology Number"
                />
              </div>
            </div>

            <div className="flex items-end">
              <div
                onClick={handleFilter}
                className="cursor-pointer bg-green-500 text-white p-2 rounded-3xl hover:bg-green-600"
              >
                Apply
              </div>
              <div
                onClick={handleCancelFilter}
                className="cursor-pointer ml-2 bg-red-500 text-white p-2 rounded-3xl hover:bg-red-600"
              >
                Cancel
              </div>
            </div>
          </div>
        </div>
      )}

      {loading ? (
        <CommongPreloader />
      ) : error ? (
        <p className="text-red-500 text-center">{error}</p>
      ) : showAddNumerology ? (
        <AddLuShodGrid
          onClose={() => setShowAddNumerology(false)}
          setNumerologyComboData={setNumerologyData}
          numerologyComboData={numerologyData}
          selectedData={selectedData}
          isEditing={isEditing}
          fetchData={fetchData}
        />
      ) : (
        <div className="rounded-lg overflow-hidden">
          {filteredData.length === 0 ? (
            <p className="p-4 text-center text-gray-500">No Data Found</p>
          ) : (
            <table className="w-full bg-white">
              <thead>
                <tr className="border-b border-gray-700">
                  <th className="px-6 py-3 text-left text-xs font-medium text-black uppercase tracking-wider">
                    Topic Number
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-black uppercase tracking-wider">
                    Numerology Number
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-black uppercase tracking-wider">
                    Count
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-black uppercase tracking-wider">
                    Count 2
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-black uppercase tracking-wider">
                    Title
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-black uppercase tracking-wider">
                    Description
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-black uppercase tracking-wider">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-700">
                {filteredData.map((item, index) => (
                  <tr key={index} className="hover:bg-gray-50">
                    <td className="px-6 py-4">{item.topicNumber}</td>
                    <td className="px-6 py-4">{item.numerologyNumber}</td>
                    <td className="px-6 py-4">
                      {item.Count ? item.Count : "Not Provided"}
                    </td>
                    <td className="px-6 py-4">
                      {item.Count1 ? item.Count1 : "Not Provided"}
                    </td>
                    <td className="px-6 py-4">{item.title}</td>
                    <td className="px-6 py-4">
                      {item.description
                        ? HTMLReactParser(item.description)
                        : "No description available"}
                    </td>

                    <td className="px-6 py-4">
                      <div className="flex space-x-3">
                        <FaEdit
                          className="text-green-600 cursor-pointer"
                          onClick={() => handleEditClick(item)}
                        />
                        <FaTrash
                          className="text-red-600 cursor-pointer"
                          onClick={() => handleDeleteClick(item.numId)}
                        />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      )}
    </div>
  );
};

export default LuShodGrid;
