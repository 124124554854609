import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton"; // Import the Skeleton component
import "react-loading-skeleton/dist/skeleton.css";
import Pooja_Vidhi_Description from "./Pooja_Vidhi_Description/Pooja_Vidhi_Description";
import HTMLReactParser from "html-react-parser";
const Pooja_Vidhi = () => {
  const [poojas, setPoojas] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state
  const [editorContent, setEditorContent] = useState(''); // Editor content state

  // Fetch categories from the API
  useEffect(() => {
    const fetchPoojas = async () => {
      try {
        const response = await fetch(
          "https://vpandit.caxis.ca/public/api/categories"
        );
        const data = await response.json();
        setPoojas(data); // Wrap the single category in an array
        setLoading(false); // Set loading to false once data is fetched
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false); // In case of error, stop loading
      }
    };

    fetchPoojas();
  }, []);

  const handleChange = (value) => {
    setEditorContent(value); // Update content state when content changes
  };

  const modules = {
    toolbar: [
      [{ font: [] }, { size: ["small", "medium", "large", "huge"] }],
      [{ header: "1" }, { header: "2" }, { font: [] }],
      ["bold", "italic", "underline", "strike"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ align: [] }],
      ["link"],
      ["image"],
    ],
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <nav className="flex mb-4 text-sm">
        <Link
          to="/"
          className="text-orange-500 hover:text-orange-600 font-bold"
        >
          Home
        </Link>
        <span className="mx-2 text-gray-500">&gt;</span>
        <span className="text-gray-600 font-bold cursor-pointer">
          Pooja Vidhi
        </span>
      </nav>

      <div className="container mb-8">
        <h1 className="text-3xl font-bold text-gray-900 mb-4">
          Pooja : The Sacred Rituals of Life
        </h1>
        <p className="text-gray-600">
          Pooja is often called the sacred ritual of life. It includes offerings
          to the divine, performed with devotion and reverence. Each ritual has
          a significant spiritual value. It is believed to reveal much about the
          individual’s inner self, from their virtues to their deeper spiritual
          essence. If you wish to connect with the divine and understand your
          life's purpose, you can begin your Pooja through the sacred practices
          tied to your birth and spiritual energy.
        </p>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-6">
        {loading
          ? Array(10)
              .fill()
              .map((_, index) => (
                <div
                  key={index}
                  className="h-[300px] w-full bg-white shadow-[0px_0px_15px_rgba(0,0,0,0.09)] p-9 space-y-3 relative overflow-hidden"
                >
                  <div className="w-24 h-24 bg-orange-500 rounded-full absolute -right-5 -top-7"></div>
                  <Skeleton circle height={50} width={50} className="mt-5" />
                  <Skeleton height={34} className="mt-5" />
                  <Skeleton height={20} className="mt-5" />
                </div>
              ))
          : poojas.map((pooja, key) => (
              <Link
                to={`/pooja-vidhi/${pooja.Cat_Name}`}
                key={pooja.Cat_Id}
                className="w-full bg-white shadow-[0px_0px_15px_rgba(0,0,0,0.09)] p-9 space-y-3 relative overflow-hidden cursor-pointer"
              >
                <div className="w-24 h-24 bg-orange-500 rounded-full absolute -right-5 -top-7">
                  <p className="absolute bottom-6 left-7 text-white text-2xl font-semibold">
                    {pooja?.subcategories?.length || 0}
                  </p>
                </div>

                <div className="fill-orange-500 w-12">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    id="Layer_1"
                    data-name="Layer 1"
                    viewBox="0 0 24 24"
                  >
                    <path d="m24,6.928v13.072h-11.5v3h5v1H6.5v-1h5v-3H0V4.5c0-1.379,1.122-2.5,2.5-2.5h12.98c-.253.295-.54.631-.856,1H2.5c-.827,0-1.5.673-1.5,1.5v14.5h22v-10.993l1-1.079Zm-12.749,3.094C19.058.891,19.093.855,19.11.838c1.118-1.115,2.936-1.113,4.052.002,1.114,1.117,1.114,2.936,0,4.052l-8.185,8.828c-.116,1.826-1.623,3.281-3.478,3.281h-5.59l.097-.582c.043-.257,1.086-6.16,5.244-6.396Zm2.749,3.478c0-1.379-1.122-2.5-2.5-2.5-2.834,0-4.018,3.569-4.378,5h4.378c1.378,0,2.5-1.121,2.5-2.5Zm.814-1.073l2.066-2.229c-.332-1.186-1.371-2.057-2.606-2.172-.641.749-1.261,1.475-1.817,2.125,1.117.321,1.998,1.176,2.357,2.277Zm.208-5.276c1.162.313,2.125,1.134,2.617,2.229l4.803-5.18c.737-.741.737-1.925.012-2.653-.724-.725-1.908-.727-2.637,0-.069.08-2.435,2.846-4.795,5.606Z"></path>
                  </svg>
                </div>
                <h1 className="font-bold text-xl">{pooja.Cat_Name}</h1>
              </Link>
            ))}
      </div>
      {/* <Pooja_Vidhi_Description /> */}
    </div>
  );
};

export default Pooja_Vidhi;