import { Link, useNavigate } from "react-router-dom";
import { FaStar, FaCalendarAlt, FaPrayingHands, FaRegSun } from "react-icons/fa"; // Import icons
import underline from "../../Assets/underline.png";
import about from "../../Assets/About.png";

const services = [
  {
    title: "Numerology",
    description:
      "Numerology is the mystical study of numbers and their influence on human life.",
    link: "/numerology",
    requiresLogin: true,
    icon: <FaStar />, // Icon for Numerology
    image: about, // Using the imported about image for all services
  },
  {
    title: "Astrology",
    description:
      "Astrology is the ancient study of celestial bodies and their influence on human lives. ",
    link: "/kundli",
    requiresLogin: false,
    icon: <FaCalendarAlt />,
    image: about, // Using the imported about image for all services
  },
  {
    title: "Pooja",
    description:
      "Pooja is a spiritual ritual in Hinduism performed to honor and worship deities.",
    link: "/pooja-vidhi",
    requiresLogin: false,
    icon: <FaPrayingHands />, // Icon for Pooja
    image: about, // Using the imported about image for all services
  },
  {
    title: "Horoscope",
    description:
      "A horoscope is a detailed chart based on an individual's birth date, time, and place.",
    link: "/horoscope",
    requiresLogin: false,
    icon: <FaRegSun />, // Icon for Horoscope
    image: about, // Using the imported about image for all services
  },
];

const pandits = [
  {
    image: "https://img.freepik.com/premium-photo/photo-elderly-wise-man-meditating-serene-thoughtful-pose_1110958-76765.jpg?semt=ais_hybrid",
    name: " Rajesh Sharma",
    skills: "Vedic Astrology, Kundli Matching, Havan Specialist",
  },
  {
    image: "https://img.freepik.com/free-photo/indian-man-city-male-traditional-turban-hinduist-summer-city_1157-41018.jpg",
    name: " Suresh Tripathi",
    skills: "Numerology, Palmistry, Vastu Consultant",
  },
  {
    image: "https://t3.ftcdn.net/jpg/06/81/63/04/360_F_681630427_yh616OP6hW5QgaYVHPcoWpcHmCxgJzOZ.jpg",
    name: " Rohit Tripathi",
    skills: "Numerology, Palmistry, Vastu Consultant",
  },
  {
    image: "https://t3.ftcdn.net/jpg/06/81/63/00/360_F_681630040_C2eolGEtUHmPAfKtLdwX6fJnvBT37M2G.jpg",
    name: " Mohit Tripathi",
    skills: "Numerology, Palmistry, Vastu Consultant",
  },
  // Add more pandits here
];

const products = [
  {
    image: "https://5.imimg.com/data5/SELLER/Default/2023/12/368832962/IX/MB/HD/193295183/screenshot-2023-12-08-080332-500x500.png",
    name: "Astrology Book",
    price: "₹599",
    rating: 4.5,
  },
  {
    image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT6g3gq-VaGBK8wlEEC-2IruZy8xoqqLIF4Kw&s",
    name: "Yantra",
    price: "₹799",
    rating: 4.8,
  },
  {
    image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQZkZ-vlipTHZYT0F-MVIVr-DuCZWL41LapYg&s",
    name: "Rudraksha Mala",
    price: "₹999",
    rating: 4.6,
  },
  {
    image: "https://m.media-amazon.com/images/I/61GXbnYuapL._AC_UY300_.jpg",
    name: "Gemstone Ring",
    price: "₹2999",
    rating: 4.7,
  },
];

const pooja = [
  {
    name: "Ganesh Pooja",
    image: "https://imvoyager.com/wp-content/uploads/2022/08/Ganesh-Chaturthi-Quotes-SM.jpg",
    description: "Pooja for Lord Ganesha, the remover of obstacles.",
    panditDetails: {
      name: "Pandit Ravi Shastri",
      experience: 15,
      charges: 1500,
      image: "https://img.freepik.com/premium-photo/photo-elderly-wise-man-meditating-serene-thoughtful-pose_1110958-76765.jpg?semt=ais_hybrid"
    },
  },
  {
    name: "Navagraha Pooja",
    image: "https://shreeayodhyajidham.com/wp-content/uploads/2022/03/Navagrah-Shanti-Pooja.jpg",
    description: "Pooja for the nine planets in Hindu astrology.",
    panditDetails: {
      name: "Pandit Suraj Mishra",
      experience: 12,
      charges: 2500,
      image: "https://img.freepik.com/free-photo/indian-man-city-male-traditional-turban-hinduist-summer-city_1157-41018.jpg"
    },
  },
  {
    name: "Homam",
    image: "https://static.vecteezy.com/system/resources/previews/038/361/160/non_2x/ai-generated-vibrant-hindu-ceremony-fire-amidst-floral-decor-free-photo.jpg",
    description: "A fire ritual performed for various purposes like blessings.",
    panditDetails: {
      name: "Pandit Arvind Kumar",
      experience: 20,
      charges: 3000,
      image: "https://t3.ftcdn.net/jpg/06/81/63/04/360_F_681630427_yh616OP6hW5QgaYVHPcoWpcHmCxgJzOZ.jpg"
    },
  },
  {
    name: "Satyanarayana Pooja",
    image: "https://onlinetemple.com/wp-content/uploads/2024/01/Comprehensive-Guide-to-Satyanarayan-Pooja-Rituals-Stories-and-Significance.jpg",
    description: "Pooja dedicated to Lord Satyanarayana, a form of Vishnu.",
    panditDetails: {
      name: "Pandit Anil Sharma",
      experience: 18,
      charges: 1800,
      image: "https://t3.ftcdn.net/jpg/06/81/63/00/360_F_681630040_C2eolGEtUHmPAfKtLdwX6fJnvBT37M2G.jpg"
    },
  },
];

export default function Services() {
  const navigate = useNavigate();

  const userID = localStorage.getItem("userID");

  const handleServiceClick = (route, requiresLogin) => {
    if (requiresLogin && !userID) {
      navigate("/login");
    } else {
      navigate(route);
    }
  };

  return (
    <section className=" md:px-4 px-0 mb-20">
      <div className="container mx-auto px-4">
        {/* Header */}
        <div className="text-center mb-8">
          {/* <h2 className="text-4xl font-bold mb-4 text-gray-900 cursor-pointer">
            Our <span className="text-orange-500">Services</span>
            <div className="h-auto w-full mx-auto mt-4 flex justify-center">
              <img src={underline} alt="" className="object-contain" />
            </div>
          </h2> */}
          {/* <p className="text-gray-900 max-w-2xl mx-auto">
          Unlock the secrets of your destiny with our expertly curated services
          </p> */}
        </div>
        {/* <div className="flex justify-end -mt-5 mb-2 text-sm">
          <button>view all</button>
        </div> */}

        {/* Services Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          {services.map((service, index) => (
            <div
              key={index}
              className="border border-orange-300 p-12 rounded-lg group transition-colors duration-500 flex flex-col justify-between h-full relative overflow-hidden hover:bg-orange-500 hover:text-white cursor-pointer"
              onClick={() => handleServiceClick(service.link, service.requiresLogin)}
            >
              <div
                className="absolute inset-0 opacity-0 transform scale-125 group-hover:opacity-25 group-hover:scale-105 transition-all duration-700 ease-out group-hover:animate-spin-slow"
                style={{
                  backgroundImage: `url(${service.image})`,
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                }}
              ></div>
              <div className="relative z-10 space-y-7">
                <h3 className="text-2xl font-semibold text-center group-hover:text-white">
                  {service.title}
                </h3>
                <div className="text-orange-500 text-5xl flex justify-center group-hover:text-white">
                  {service.icon}
                </div>
                <p className="mb-6 group-hover:text-white text-base text-center">
                  {service.description}
                </p>
              </div>
            </div>
          ))}
        </div>


        {/* Our Pooja Services Section */}
        <div className="container text-center mt-24">
          {/* <h2 className="text-4xl font-bold mb-4 text-gray-900 cursor-pointer">
            Our <span className="text-orange-500">Pooja Services</span>
            <div className="h-auto w-full mx-auto mt-4 flex justify-center">
              <img src={underline} alt="" className="object-contain" />
            </div>
          </h2> */}
          {/* <p className="text-gray-900 max-w-2xl mx-auto">
            Explore our wide range of spiritual pooja services.
          </p> */}
          <Link to={"/pooja-vidhi "}>
            <div className="flex justify-end mb-2 text-sm">
              <button>view all</button>
            </div>
          </Link>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
            {pooja.map((poojaItem, index) => (
              <div key={index} className="shadow-lg rounded-lg overflow-hidden hover:shadow-2xl transition-shadow duration-300 cursor-pointer">
                <img
                  src={poojaItem.image}
                  alt={poojaItem.name}
                  className="w-full aspect-[16/9] object-cover"
                />
                <div className="p-4 md:p-6">
                  <h3 className="text-lg md:text-xl font-semibold text-center text-gray-900">
                    {poojaItem.name}
                  </h3>
                  <p className="text-gray-600 text-center mt-2 text-sm md:text-base h-16 overflow-hidden text-ellipsis">
                    {poojaItem.description}
                  </p>

                  {/* Pandit Details Card */}
                  <div className="flex items-center bg-white rounded-lg overflow-hidden shadow-md mt-5 p-3">
                    {/* SVG Icon */}
                    <svg width="14" height="90" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M 8 0 Q 4 4.8, 8 9.6 T 8 19.2 Q 4 24, 8 28.8 T 8 38.4 Q 4 43.2, 8 48 T 8 57.6 Q 4 62.4, 8 67.2 T 8 76.8 Q 4 81.6, 8 86.4 T 8 96 L 0 96 L 0 0 Z"
                        fill="#F97316"
                        stroke="#F97316"
                        strokeWidth="2"
                        strokeLinecap="round"
                      />
                    </svg>

                    {/* Pandit Image */}
                    <div className="w-14 h-14 flex-shrink-0 ml-2">
                      <img
                        src={poojaItem.panditDetails.image || '/placeholder-profile.jpg'}
                        alt={poojaItem.panditDetails.name}
                        className="w-full h-full rounded-full object-cover border-2 border-orange-500"
                      />
                    </div>

                    {/* Pandit Info */}
                    <div className="ml-3 overflow-hidden">
                      <p className="text-orange-600 font-bold text-md md:text-lg overflow-hidden text-ellipsis whitespace-nowrap">
                        {poojaItem.panditDetails.name}
                      </p>
                      <p className="text-sm text-gray-500">Experience: {poojaItem.panditDetails.experience} years</p>
                      <p className="text-sm text-gray-500">Charges: ₹{poojaItem.panditDetails.charges}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>


        {/* Our Latest Products Section */}
        <div className="container text-center mt-24">
          {/* <h2 className="text-4xl font-bold mb-4 text-gray-900 cursor-pointer">
            Our <span className="text-orange-500">Latest Products</span>
            <div className="h-auto w-full mx-auto mt-4 flex justify-center">
              <img src={underline} alt="" className="object-contain" />
            </div>
          </h2> */}
          {/* <p className="text-gray-900 max-w-2xl mx-auto">
            Explore our wide range of spiritual products crafted to meet your
            needs.
          </p> */}
        </div>
        <Link to="https://vpandit.com/vshop/">
          <div className="flex justify-end mb-2">
            <button>view all</button>
          </div>
        </Link>

        {/* Products Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 ">
          {products.map((product, index) => (
            <div
              key={index}
              className="shadow-xl rounded-lg overflow-hidden hover:shadow-2xl transition-shadow duration-300 cursor-pointer"
            >
              <img
                src={product.image}
                alt={product.name}
                className="w-full h-48 object-cover"
              />
              <div className="p-6">
                <h3 className="text-xl font-semibold text-center text-gray-900">
                  {product.name}
                </h3>
                <p className="text-gray-600 text-center mt-2">{product.price}</p>
                <div className="flex justify-center mt-2 text-orange-500">
                  {Array.from({ length: Math.floor(product.rating) }, (_, i) => (
                    <FaStar key={i} />
                  ))}
                  {product.rating % 1 !== 0 && <FaStar className="half-star" />}
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Our Expert Pandits Section */}
        <div className="container text-center mt-24 mb-16">
          {/* <h2 className="text-4xl font-bold mb-4 text-gray-900 cursor-pointer">
            Our <span className="text-orange-500">Expert Pandits</span>
            <div className="h-auto w-full mx-auto mt-4 flex justify-center">
              <img src={underline} alt="" className="object-contain" />
            </div>
          </h2> */}
          {/* <p className="text-gray-900 max-w-2xl mx-auto">
            Meet our experienced and skilled pandits, ready to assist you with
            your spiritual needs.
          </p> */}
        </div>
        <div className="flex justify-end -mt-5 mb-2">
          <button>view all</button>
        </div>

        {/* Pandits Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          {pandits.map((pandit, index) => (
            <div
              key={index}
              className="shadow-xl rounded-lg overflow-hidden hover:shadow-2xl transition-shadow duration-300 cursor-pointer"
            >
              <img
                src={pandit.image}
                alt={pandit.name}
                className="w-full h-48 object-cover"
              />
              <div className="p-6">
                <h3 className="text-xl font-semibold text-center text-gray-900">
                  {pandit.name}
                </h3>
                <p className="text-gray-600 text-center mt-2">{pandit.skills}</p>
              </div>
            </div>
          ))}
        </div>

      </div>
    </section>
  );

}
