import React from "react";
import {
  Routes,
  Route,
  Navigate,
  useLocation,
  useParams,
} from "react-router-dom";
import "./App.css";
import "@fontsource/poppins";
import Navbar from "./Component/Navbar/Navbar";
import Footer from "./Component/Footer/Footer";
import Home from "./Component/Home/Home";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Numerology from "./Pages/Numerology/Numerology";
import AdminPanel from "./Pages/AdminPanel/AdminPanel";
import Register from "./Pages/Register/Register";
import Numerology_InnerPage from "./Pages/Numerology-InnerPage/Numerology_InnerPage";
import Login from "./Pages/Login/Login";
import User_Profile from "./Pages/User_Profile/User_Profile";
import Panchang from "./Pages/Panchang/Panchang";
import ScrollToTopAll from "./Pages/ScrollToTopAll";
import Pooja_Vidhi from "./Pages/Pooja-Vidhi/Pooja_Vidhi";
import Pandit_register from "./Pages/Pandit_register/Pandit_register";
import Sub_Category_Pooja_Vidhi from "./Pages/Pooja-Vidhi/Sub_Pooja_Vidhi/Sub_Category_Pooja_Vidhi";
import Inner_Sub_Pooja_Vidhi2 from "./Pages/Pooja-Vidhi/Sub_Pooja_Vidhi/Inner_Sub_Pooja_Vidhi/Inner_Sub_Pooja_Vidhi2";
import ContactUs from "./Pages/ContactUs/ContactUs";
import BLog_Details from "./Pages/BLog_Details/BLog_Details";
import Horoscope from "./Pages/Horoscope/Horoscope";
import Pandit_Profilea from "./Pages/Pandit_Profilea/Pandit_Profilea";
import ScrollToTop from "./Pages/ScrollToTop/ScrollToTop";
import Forgot_Password from "./Pages/Forgot Password/Forgot_Password";
import Talk_With_Astrologer from "./Pages/Talk_With_Astrologer/Talk_With_Astrologer";
import Kundli from "./Pages/Kundli/Kundli";
import Kundli_Details from "./Pages/Kundli/Kundli_Details/Kundli_Details";
import Not_Found from "./Pages/Not_Found/Not_Found";
import Pandit_Crm from "./Pages/Pandit_Crm/Pandit_Crm";
import Successfully_Pooja_Booked from "./Pages/Successfully_Pooja_Booked/Successfully_Pooja_Booked";
import Supplier_Dashboard from "./Pages/Supplier_Dashboard/Supplier_Dashboard"; 
import Supplier_register from "./Pages/Supplier_register/Supplier_register";
import TawkToChat from "./Pages/TawkToChat/TawkToChat";


// ✅ Protected Route for General Role-Based Access
const ProtectedRoute = ({ children, allowedRole }) => {
  const token = localStorage.getItem("token");
  const role = localStorage.getItem("role");

  if (!token || role !== allowedRole) {
    return <Navigate to="/login" />;
  }
  return children;
};

// ✅ Ultimate Admin Route - Ensures ID & Token Match
const UltimateAdminRoute = ({ children }) => {
  const { id } = useParams(); // Extract ID from the URL
  const storedToken = localStorage.getItem("token"); // Token from localStorage
  const role = localStorage.getItem("role"); // Get user role

  // 🔹 Redirect to login if:
  // - No token exists
  // - Role is NOT "1" (Admin)
  // - Token in URL doesn't match stored token
  if (!storedToken || role !== "1" || id !== storedToken) {
    return <Navigate to="/login" />;
  }

  return children;
};

const App = () => {
  const location = useLocation();

  // Define routes where the Navbar and Footer should be hidden
  const noHeaderFooterRoutes = [
    "/*",
    "/panditdash",
    "/bookedsuccessfully",
    "/profile",
    "/supplier-dashboard",
    "/ultimate-admin/authority",
  ];

  const hideHeaderFooter = noHeaderFooterRoutes.some((route) =>
    location.pathname.startsWith(route)
  );

  return (
    <>
      {!hideHeaderFooter && <Navbar />}
      <ScrollToTopAll />
      <ScrollToTop />

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/numerology" element={<Numerology />} />
        <Route path="/numerology/:value" element={<Numerology_InnerPage />} />
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />
        <Route path="/profile/:id" element={<User_Profile />} />
        <Route path="/panchang" element={<Panchang />} />
        <Route path="/horoscope" element={<Horoscope />} />
        <Route path="/kundli" element={<Kundli />} />
        <Route path="/kundli/:name" element={<Kundli_Details />} />
        <Route path="/pooja-vidhi" element={<Pooja_Vidhi />} />

        <Route
          path="/pooja-vidhi/:category"
          element={<Sub_Category_Pooja_Vidhi />}
        />
        <Route
          path="/pooja-vidhi/:category/:details"
          element={<Inner_Sub_Pooja_Vidhi2 />}
        />
        <Route path="/supplier_register" element={<Supplier_register />} />
        <Route path="/pandit_register" element={<Pandit_register />} />
        <Route path="/pandit/:id" element={<Pandit_Profilea />} />
        <Route path="/talkastrologer" element={<Talk_With_Astrologer />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/forgot-password" element={<Forgot_Password />} />

        {/* 🔒 Protected Routes */}
        <Route
          path="/panditdash/:id"
          element={
            <ProtectedRoute allowedRole="2">
              <Pandit_Crm />
            </ProtectedRoute>
          }
        />
        <Route
          path="/bookedsuccessfully"
          element={<Successfully_Pooja_Booked />}
        />

        {/* 🔒 Ultimate Admin Protected Route */}
        <Route
          path="/ultimate-admin/authority/:id"
          element={
            <UltimateAdminRoute>
              <AdminPanel />
            </UltimateAdminRoute>
          }
        />

        {/* 🔒 Supplier Dashboard Protected Route */}
        <Route
          path="/supplier-dashboard/:id"
          element={
            <ProtectedRoute allowedRole="4">
              <Supplier_Dashboard />
            </ProtectedRoute>
          }
        />
        <Route path="/blog/:details" element={<BLog_Details />} />
        <Route path="/*" element={<Not_Found />} />
      </Routes>

      {!hideHeaderFooter && <Footer />}
    </>
  );
};

export default App;
