import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import JoditEditor from "jodit-react";

const Add_Personal_Month_Numerology = ({
  onClose,
  setNumerologyComboData,
  numerologyComboData,
  selectedData,
  isEditing,
}) => {
  const editor = useRef(null);
  const [formData, setFormData] = useState({
    Value1: "",
    Value2: "",
    Value3: "",
    Value4: "",
    Value5: "",
    Value6: "",
    desc_title: "",
    desc_details: "",
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isEditing && selectedData) {
      setFormData({
        Value1: selectedData.numerologyNumber1 || "",
        Value2: selectedData.numerologyNumber2 || "",
        Value3: selectedData.numerologyNumber3 || "",
        Value4: selectedData.numerologyNumber4 || "",
        Value5: selectedData.numerologyNumber5 || "",
        Value6: selectedData.numerologyNumber6 || "",
        desc_title: selectedData.title || "",
        desc_details: selectedData.description || "",
      });
    }
  }, [isEditing, selectedData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const payload = {
      Value1: formData.Value1,
      Value2: formData.Value2,
      Value3: formData.Value3,
      Value4: formData.Value4,
      Value5: formData.Value5,
      Value6: formData.Value6,
      desc_title: formData.desc_title,
      desc_details: formData.desc_details,
    };

    try {
      if (isEditing) {
        // PUT Request
        await axios.put(
          `https://vpandit.caxis.ca/public/api/personalnumcom/${selectedData.pNumComId}`,
          payload
        );
        setNumerologyComboData((prevData) =>
          prevData.map((item) =>
            item.pNumComId === selectedData.pNumComId
              ? { ...item, ...formData }
              : item
          )
        );
        Swal.fire({
          toast: true,
          position: "top-end",
          icon: "success",
          title: "Record updated successfully",
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        // POST Request
        const response = await axios.post(
          "https://vpandit.caxis.ca/public/api/personalnumcom",
          payload
        );
        setNumerologyComboData([...numerologyComboData, response.data]);
        Swal.fire({
          toast: true,
          position: "top-end",
          icon: "success",
          title: "Record added successfully",
          showConfirmButton: false,
          timer: 1500,
        });
      }

      setFormData({
        Value1: "",
        Value2: "",
        Value3: "",
        Value4: "",
        Value5: "",
        Value6: "",
        desc_title: "",
        desc_details: "",
      });
      onClose();
    } catch (err) {
      Swal.fire({
        toast: true,
        position: "top-end",
        icon: "error",
        title: "Failed to save the record. Please try again.",
        showConfirmButton: false,
        timer: 1500,
      });
      console.error("Error saving data:", err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="p-6 bg-white rounded-lg shadow-md mt-6">
      <h2 className="text-xl font-bold text-orange-500 mb-4">
        {isEditing ? "Edit Numerology Data" : "Add Numerology Data"}
      </h2>

      <form onSubmit={handleSubmit}>
        {/* Grid for Values */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {["Value1", "Value2", "Value3", "Value4", "Value5", "Value6"].map(
            (field, index) => (
              <div key={index} className="col-span-1">
                <label
                  htmlFor={field}
                  className="block text-sm font-medium text-gray-700 capitalize"
                >
                  {field.replace("Value", "Value ")}
                </label>
                <input
                  type="text"
                  id={field}
                  name={field}
                  value={formData[field]}
                  onChange={handleInputChange}
                  required
                  disabled={loading}
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-orange-500 focus:border-orange-500 sm:text-sm"
                />
              </div>
            )
          )}
        </div>

        {/* Title Field */}
        <div className="mt-4">
          <label
            htmlFor="desc_title"
            className="block text-sm font-medium text-gray-700"
          >
            Title
          </label>
          <input
            type="text"
            id="desc_title"
            name="desc_title"
            value={formData.desc_title}
            onChange={handleInputChange}
            required
            disabled={loading}
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-orange-500 focus:border-orange-500 sm:text-sm"
          />
        </div>

        {/* Description Field */}
        <div className="mt-4">
          <label
            htmlFor="desc_details"
            className="block text-sm font-medium text-gray-700"
          >
            Description
          </label>
          <JoditEditor
            ref={editor}
            value={formData.desc_details}
            tabIndex={1}
            onBlur={(content) =>
              setFormData({ ...formData, desc_details: content })
            }
            onChange={() => {}}
          />
        </div>

        {/* Buttons */}
        <div className="mt-6 flex justify-start gap-4">
          <button
            type="submit"
            className="bg-orange-500 text-white px-4 py-2 rounded-3xl"
            disabled={loading}
          >
            {loading ? "Saving..." : isEditing ? "Update" : "Add"}
          </button>

          <button
            type="button"
            onClick={onClose}
            className="bg-gray-400 text-white px-4 py-2 rounded-3xl"
            disabled={loading}
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default Add_Personal_Month_Numerology;
