import React, { useEffect, useState } from "react";
import axios from "axios";
import { MapPin, User, Phone, Mail, Briefcase } from "lucide-react";
import CommongPreloader from "../../AdminPanel/CommongPreloader/CommongPreloader";

const Upcoming_Pooja = ({ id }) => {
  const [poojaData, setPoojaData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [tab, setTab] = useState("In Progress");
  const [search, setSearch] = useState("");
  const [selectedPooja, setSelectedPooja] = useState(null); // Stores selected Pooja

  useEffect(() => {
    const fetchPoojaData = async () => {
      try {
        const response = await axios.get(
          "https://vpandit.caxis.ca/public/api/inquiries"
        );
        const filteredData = response.data.filter(
          (item) => item.pandit?.U_Id == id
        );
        setPoojaData(filteredData);
      } catch (err) {
        setError("Failed to fetch data");
      } finally {
        setLoading(false);
      }
    };

    fetchPoojaData();
  }, [id]);

  if (loading) return <CommongPreloader />;
  if (error) return <div>{error}</div>;
  if (poojaData.length === 0) return <div>No upcoming poojas found.</div>;

  // **If a pooja is selected, show Pooja Details instead of the list**
  if (selectedPooja) {
    return (
      <div className="min-h-screen">
        <h1 className="text-2xl font-bold">
          Pooja Details for{" "}
          <span className="underline">
            {selectedPooja.sub_category?.SubCat_Name}
          </span>{" "}
        </h1>
        <div className="bg-white rounded-2xl p-3 mt-4 border">
          <h3 className="text-xl font-bold">
            {selectedPooja.sub_category?.SubCat_Name}
          </h3>
          <p className="text-gray-700 flex items-center gap-2">
            <MapPin size={18} /> <strong>Location:</strong>{" "}
            {selectedPooja.Pooja_Address}, {selectedPooja.Pooja_Location}
          </p>
          <p className="text-gray-700 flex items-center gap-2">
            <User size={18} /> <strong>User:</strong>{" "}
            {selectedPooja.user?.U_Name}
          </p>
          <p className="text-gray-700 flex items-center gap-2">
            <Phone size={18} /> <strong>Phone:</strong>{" "}
            {selectedPooja.user?.U_Mobile}
          </p>
          <p className="text-gray-700 flex items-center gap-2">
            <Mail size={18} /> <strong>Email:</strong>{" "}
            {selectedPooja.user?.U_Email}
          </p>

          <p className="font-medium text-gray-900 mt-4">
            <strong>Total Price:</strong> ₹ {selectedPooja.sub_category?.Price}
          </p>

          {/* Buttons row */}
          <div className="flex gap-4 mt-6">
            {/* Back Button */}
            <div
              onClick={() => setSelectedPooja(null)}
              className="cursor-pointer px-4 py-2 text-sm font-medium text-white bg-gray-600 rounded-lg hover:bg-gray-700 transition-colors"
            >
              Back to Pooja List
            </div>

            {/* Accept Pooja Button */}
            <div
              // onClick={() => handleAcceptPooja(selectedPooja)}
              className="cursor-pointer px-4 py-2 text-sm font-medium text-white bg-orange-500 rounded-lg hover:bg-orange-700 transition-colors"
            >
              Accept Pooja
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen">
      {/* Header Section */}
      <div className="mb-6">
        <h1 className="text-2xl font-bold">Upcoming Pooja Management</h1>

        {/* Tabs & Search */}
        <div className="mt-4 flex items-center justify-between bg-white p-2 rounded-xl border">
          <div className="flex space-x-4">
            {["In Progress", "Ready"].map((status) => (
              <div
                key={status}
                onClick={() => setTab(status)}
                className={`px-4 py-2 text-sm font-medium cursor-pointer ${
                  tab === status
                    ? "text-gray-900 border-b-2 border-gray-900"
                    : "text-gray-500"
                }`}
              >
                {status}
              </div>
            ))}
          </div>

          {/* Search Bar */}
          <div className="relative">
            <input
              type="text"
              placeholder="Search a name, order or ID..."
              className="w-64 px-4 py-2 text-sm border rounded-lg focus:outline-none focus:ring-1 focus:ring-gray-400"
              onChange={(e) => setSearch(e.target.value.toLowerCase())}
            />
          </div>
        </div>
      </div>

      {/* Pooja Cards Grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {poojaData
          .filter((pooja) =>
            pooja.sub_category?.SubCat_Name.toLowerCase().includes(search)
          )
          .map((pooja) => (
            <div
              key={pooja.Booking_Id}
              className="bg-white rounded-lg shadow-sm p-4 border"
            >
              {/* Pooja Title & Status */}
              <div className="flex items-center justify-between mb-4">
                <h3 className="text-xl font-bold">
                  {pooja.sub_category?.SubCat_Name}
                </h3>
                <div
                  className={`px-3 py-1 rounded-full text-sm ${
                    pooja.status === "confirmed"
                      ? "bg-green-600 text-white"
                      : "bg-red-600 text-white"
                  }`}
                >
                  {pooja.status || "Pending"}
                </div>
              </div>

              {/* Date & Time */}
              {/* <div className="flex items-center space-x-2 text-sm text-gray-500 mb-4">
                <span>{pooja.start_date || "N/A"}</span>
                <span>•</span>
                <span>{pooja.start_hour || "N/A"}</span>
              </div> */}

              {/* Location */}
              <p className="flex items-center gap-2 text-gray-700">
                <MapPin size={18} /> <strong>Location:</strong>{" "}
                {pooja.Pooja_Address}, {pooja.Pooja_Location}
              </p>

              {/* Action divs */}
              <div className="flex space-x-2">
                {/* "See Details" div updates the selected Pooja state */}
                <div
                  onClick={() => setSelectedPooja(pooja)}
                  className="cursor-pointer mt-6 flex-1 px-4 py-2 text-sm font-medium text-white bg-gray-500 rounded-lg hover:bg-gray-600 transition-colors"
                >
                  See Details
                </div>
                <div className="cursor-pointer mt-6 flex-1 px-4 py-2 text-sm font-medium text-white bg-orange-500 rounded-lg hover:bg-orange-600 transition-colors">
                  Accept Pooja
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default Upcoming_Pooja;
