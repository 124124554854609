import React, { useEffect, useState } from "react";
import axios from "axios";
import { FaArrowLeft } from "react-icons/fa";
import { MdCategory, MdDateRange, MdAttachMoney, MdLocationOn } from "react-icons/md";

const BookingPoojaDetails = ({ BookingId, HandleClose }) => {
  const [bookingDetails, setBookingDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!BookingId) return;
    const fetchDetails = async () => {
      try {
        const response = await axios.get(
          `https://vpandit.caxis.ca/public/api/inquiries/${BookingId}`
        );
        setBookingDetails(response.data);
      } catch (err) {
        setError("Failed to fetch booking details.");
      } finally {
        setLoading(false);
      }
    };
    fetchDetails();
  }, [BookingId]);

  if (loading) return <p className="text-center text-gray-600">Loading...</p>;
  if (error) return <p className="text-center text-red-500">{error}</p>;

  return (
    <div className=" min-h-screen flex justify-center items-center ">
      <div className="bg-white p-6 rounded-lg  w-[5000px] max-w-lg border border-gray-200 ">
        {/* Back div */}
        <div
          onClick={HandleClose}
          className="mb-4 px-4 py-2 bg-primary text-orange-600 shadow hover:bg-primary-dark cursor-pointer rounded-2xl border border-orange-600 flex items-center space-x-2 w-fit"
        >
          <FaArrowLeft className="text-lg" />
          <span className="font-semibold">Back to Bookings</span>
        </div>

        <h2 className="text-xl font-bold text-gray-800 mb-4 text-left">
          Booking Details
        </h2>

        {/* General Information */}
        <InfoSection title="General Information" icon={<MdCategory />}>
          <InfoRow label="Booking ID" value={bookingDetails.Booking_Id} />
          <InfoRow label="Status" value="Pending" />
          <InfoRow
            label="Subcategory"
            value={bookingDetails.sub_category?.SubCat_Name}
          />
        </InfoSection>

        {/* Timing Information */}
        <InfoSection title="Timing" icon={<MdDateRange />}>
          <InfoRow label="Start Date" value="Not Confirmed" />
          <InfoRow label="Start Hour" value="Not Confirmed" />
          <InfoRow label="End Date" value="Not Confirmed" />
          <InfoRow label="End Hour" value="Not Confirmed" />
        </InfoSection>

        {/* Pricing Information */}
        <InfoSection title="Pricing" icon={<MdAttachMoney />}>
          <InfoRow
            label="Price"
            value={`₹${bookingDetails.sub_category?.Price}`}
          />
        </InfoSection>

        {/* Address Information */}
        <InfoSection title="Address" icon={<MdLocationOn />}>
          <InfoRow label="Address" value={bookingDetails.Pooja_Address} />
          <InfoRow label="Location" value={bookingDetails.Pooja_Location} />
        </InfoSection>

        {/* User Details */}
        <InfoSection title="User Details">
          <InfoRow label="Name" value={bookingDetails.user?.U_Name} />
          <InfoRow label="Mobile" value={bookingDetails.user?.U_Mobile} />
        </InfoSection>

        {/* Pooja Details */}
        <InfoSection title="Pooja Details">
          <InfoRow
            label="Name"
            value={bookingDetails.sub_category?.SubCat_Name}
          />
          <InfoRow
            label="Description"
            value={bookingDetails.sub_category?.SubCat_Desc}
          />
        </InfoSection>

        {/* Close div */}
        <div
          onClick={HandleClose}
          className="mt-4 w-full py-2 bg-[#ea580c] text-white rounded-lg hover:bg-orange-600 text-center cursor-pointer"
        >
          Close
        </div>
      </div>
    </div>
  );
};

// Reusable Section Component
const InfoSection = ({ title, icon, children }) => (
  <div className="mb-6">
    <h3 className="text-md font-semibold text-gray-700 mb-2 flex items-center space-x-2">
      {icon && <span className="text-gray-600">{icon}</span>}
      <span>{title}</span>
    </h3>
    <div className="space-y-3">{children}</div>
  </div>
);

// Reusable Row Component
const InfoRow = ({ label, value }) => (
  <p className="flex justify-between">
    <span className="font-medium text-gray-600">{label}:</span>
    <span className="text-gray-800">{value}</span>
  </p>
);

export default BookingPoojaDetails;
