import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

export default function Register() {
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    password: "",
    dob: "",
    gender: "Male", // New field for gender
    terms: false,
  });

  const [errors, setErrors] = useState({
    name: "",
    phone: "",
    password: "",
    dob: "",
    gender: "", // Adding error handling for gender
    terms: "",
  });

  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = {};

    if (!formData.name) {
      newErrors.name = "Name is required.";
      valid = false;
    }

    const phoneRegex = /^[0-9]{10}$/; // Ensures exactly 10 digits
    if (!formData.phone || !phoneRegex.test(formData.phone)) {
      newErrors.phone = "Phone number must be exactly 10 digits.";
      valid = false;
    }

    if (!formData.password || formData.password.length < 8) {
      newErrors.password = "Password must be at least 8 characters long.";
      valid = false;
    }

    if (!formData.dob) {
      newErrors.dob = "Date of birth is required.";
      valid = false;
    }

    if (!formData.gender) {
      newErrors.gender = "Gender is required.";
      valid = false;
    }

    if (!formData.terms) {
      newErrors.terms = "You must accept the terms and conditions.";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      // Set gender based on the value of formData.gender
      const gender =
        formData.gender === "Male"
          ? "M"
          : formData.gender === "Female"
          ? "F"
          : "";

      // Format the date to d/m/Y (Day/Month/Year)
      const formatDate = (dob) => {
        const date = new Date(dob);
        const day = date.getDate().toString().padStart(2, "0"); // Ensure two digits
        const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-indexed
        const year = date.getFullYear();
        return `${day}-${month}-${year}`; // Use "-" instead of "/"
      };

      const formattedDob = formatDate(formData.dob);

      const payload = {
        U_Name: formData.name, // map formData.name to U_Name
        U_Password: formData.password, // map formData.password to U_Password
        U_Gender: gender, // Set the correct gender value (M or F)
        U_Mobile: formData.phone, // map formData.phone to U_Mobile
        U_DOB: formattedDob,
      };

      // Show the SweetAlert preloader
      const swalInstance = Swal.fire({
        title: "Processing...",
        text: "Please wait while we register your details.",
        icon: "info",
        showConfirmButton: false,
        willOpen: () => {
          Swal.showLoading(); // Show the loading spinner
        },
      });

      try {
        const response = await fetch(
          `https://vpandit.caxis.ca/public/api/register`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
          }
        );

        if (response.ok) {
          const data = await response.json();
          swalInstance.close(); // Close the preloader
          Swal.fire({
            icon: "success",
            title: "Registration Successful",
            text: "Welcome to the Astrology World!",
            confirmButtonText: "OK",
          }).then(() => {
            navigate("/login");
          });
        } else {
          const errorData = await response.json();
          swalInstance.close(); // Close the preloader
          if (
            errorData.message &&
            errorData.message.includes("email already exists")
          ) {
            Swal.fire({
              icon: "error",
              title: "Email Already Registered",
              text: "The email you entered is already associated with an account.",
              confirmButtonText: "Login",
            }).then(() => {
              window.location.href = "/login";
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Registration Failed",
              text:
                errorData.message || "An error occurred during registration.",
              confirmButtonText: "Try Again",
            });
          }
        }
      } catch (error) {
        swalInstance.close(); // Close the preloader
        Swal.fire({
          icon: "error",
          title: "Network Error",
          text: "Please check your internet connection and try again.",
          confirmButtonText: "Close",
        });
      }
    }
  };

  return (
    <div className="h-auto container mx-auto my-16 px-4">
      {/* Left Section */}
      <div className="flex flex-col lg:flex-row w-full rounded-lg">
        {/* Left Content */}
        <div className="flex flex-col bg-gradient-to-r w-full lg:w-1/2">
          <h2 className="text-3xl font-bold mb-6">
            Welcome to the{" "}
            <span className="text-orange-600">Astrology World</span>
          </h2>
          <p className="text-lg mb-6  text-left">
            Discover profound insights into your life path, love compatibility,
            career forecast, and much more. By joining our community, you gain
            access to personalized horoscopes, tarot readings, astrological
            reports, and spiritual guidance that can help you navigate your
            journey and make informed decisions.
          </p>
          <div className="text-left mb-6">
            <p className="text-sm">
              Whether you're a beginner or an experienced seeker, our platform
              offers something for everyone. Explore the mysteries of the stars,
              planets, and cosmic energies to unlock the secrets of your
              destiny.
            </p>
          </div>

          {/* New Section: Benefits of Joining */}
          <div className="text-left mb-6">
            <h3 className="text-xl font-semibold mb-4">Why Join Us?</h3>
            <ul className="list-disc pl-5 space-y-2 text-sm">
              <li>Personalized daily horoscopes to guide your decisions</li>
              <li>In-depth astrology reports tailored to your birth chart</li>
              <li>Guidance on love, career, health, and life path</li>
              <li>
                Exclusive access to tarot readings and astrological forecasts
              </li>
              <li>
                Join a vibrant community of spiritual seekers and learners
              </li>
            </ul>
          </div>

          {/* New Section: Call to Action */}
          <div className="text-left">
            <p className="text-sm mb-6">
              Ready to start your spiritual journey? By signing up today, you
              will be able to access our comprehensive resources and begin your
              exploration.
            </p>
            <a
              href="/register"
              className="bg-orange-600 hover:bg-orange-700 text-white rounded-full py-2 px-6 text-sm font-semibold transition"
            >
              Join the Journey
            </a>
          </div>
        </div>

        {/* Right Content - Registration Form */}
        <div className="flex items-center w-full lg:w-1/2  md:pl-24 md:pt-0 pt-10">
          <div className="w-full max-w-md space-y-8">
            <h2 className="text-2xl font-semibold mb-2">Register</h2>
            <form className="space-y-6" onSubmit={handleSubmit}>
              {/* Name */}
              <div>
                <label className="text-xs uppercase text-gray-500 mb-1 block">
                  Name
                </label>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  className="w-full border-b-2 border-gray-300 focus:border-orange-600 focus:outline-none px-0 py-2 rounded-sm text-gray-800 placeholder-gray-400 focus:ring-0"
                  placeholder="Enter your name"
                />
                {errors.name && (
                  <p className="text-red-500 text-xs">{errors.name}</p>
                )}
              </div>

              {/* Email */}

              {/* Phone */}
              <div>
                <label className="text-xs uppercase text-gray-500 mb-1 block">
                  Phone No
                </label>
                <input
                  type="tel"
                  name="phone"
                  value={formData.phone}
                  onChange={(e) => {
                    const { value } = e.target;
                    if (value.length <= 10 && /^[0-9]*$/.test(value)) {
                      handleInputChange(e);
                    }
                  }}
                  maxLength={10} // Prevents entering more than 10 digits
                  className="w-full border-b-2 border-gray-300 focus:border-orange-600 focus:outline-none px-0 py-2 rounded-sm text-gray-800 placeholder-gray-400 focus:ring-0"
                  placeholder="Enter your phone number"
                />
                {errors.phone && (
                  <p className="text-red-500 text-xs">{errors.phone}</p>
                )}
              </div>

              {/* Password */}
              <div>
                <label className="text-xs uppercase text-gray-500 mb-1 block">
                  Password
                </label>
                <input
                  type="password"
                  name="password"
                  value={formData.password}
                  onChange={handleInputChange}
                  className="w-full border-b-2 border-gray-300 focus:border-orange-600 focus:outline-none px-0 py-2 rounded-sm text-gray-800 placeholder-gray-400 focus:ring-0"
                  placeholder="Enter your password"
                />
                {errors.password && (
                  <p className="text-red-500 text-xs">{errors.password}</p>
                )}
              </div>

              {/* DOB and Gender */}
              <div className="flex flex-col lg:flex-row lg:space-x-4">
                <div className="w-full lg:w-1/2">
                  <label className="text-xs uppercase text-gray-500 mb-1 block">
                    Date of Birth
                  </label>
                  <input
                    type="date"
                    name="dob"
                    value={formData.dob}
                    onChange={handleInputChange}
                    className="w-full border-b-2 border-gray-300 focus:border-orange-600 focus:outline-none px-0 py-2 rounded-sm text-gray-800 placeholder-gray-400 focus:ring-0"
                  />
                  {errors.dob && (
                    <p className="text-red-500 text-xs">{errors.dob}</p>
                  )}
                </div>

                <div className="w-full lg:w-1/2">
                  <label className="text-xs uppercase text-gray-500 mb-1 block">
                    Gender
                  </label>
                  <select
                    name="gender"
                    value={formData.gender}
                    onChange={handleInputChange}
                    className="w-full border-b-2 border-gray-300 focus:border-orange-600 focus:outline-none px-0 py-2 rounded-sm text-gray-800 focus:ring-0"
                  >
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="O">Other</option>
                  </select>
                  {errors.gender && (
                    <p className="text-red-500 text-xs">{errors.gender}</p>
                  )}
                </div>
              </div>

              {/* Terms and Conditions */}
              <div className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  id="terms"
                  name="terms"
                  checked={formData.terms}
                  onChange={handleInputChange}
                  className="h-4 w-4 text-orange-600 rounded focus:ring-0 border-gray-300"
                />
                <label
                  htmlFor="terms"
                  className="text-sm text-gray-500 leading-none"
                >
                  I accept terms and conditions & privacy policy
                </label>
              </div>
              {errors.terms && (
                <p className="text-red-500 text-xs">{errors.terms}</p>
              )}

              {/* Submit Button */}
              <button
                type="submit"
                className="w-full bg-orange-600 hover:bg-orange-700 text-white rounded-full py-2"
              >
                REGISTER
              </button>

              {/* Login link */}
              <div className="text-center mt-4">
                <p className="text-sm text-gray-600">
                  Already have an account?{" "}
                  <Link to="/login" className="text-orange-600 hover:underline">
                    Login here
                  </Link>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
