import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { FaEdit, FaTrash } from "react-icons/fa";
import { UserPlus } from "lucide-react";
import Add_Numerology_Combo from "./Add_Numerology_Combo";
import CommongPreloader from "../CommongPreloader/CommongPreloader";
import HTMLReactParser from "html-react-parser/lib/index";
import { BsSortNumericUp } from "react-icons/bs";
import { BsSortNumericUpAlt } from "react-icons/bs";
import { FiFilter } from "react-icons/fi";
const Numerology_Combo = () => {
  const [numerologyData, setNumerologyData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showAddNumerology, setShowAddNumerology] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [isEditing, setIsEditing] = useState(false);

  // Filters state
  const [topicFilter, setTopicFilter] = useState("");
  const [numerologyFilter, setNumerologyFilter] = useState("");
  const [filtersEnabled, setFiltersEnabled] = useState(false); // For enabling/disabling inputs
  const [showFilterFields, setShowFilterFields] = useState(false); // For toggling filter fields visibility
  const [filteredData, setFilteredData] = useState([]);

  // Fetch Data
  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        "https://vpandit.caxis.ca/public/api/numcom"
      );
      if (Array.isArray(response.data)) {
        const formattedData = response.data.map((item) => ({
          numId: item.NumCom_Id,
          numerologyNumber1: item.Value1,
          numerologyNumber2: item.Value2,
          title: item.desc_title,
          description: item.desc_details,
        }));
        setNumerologyData(formattedData);
        setFilteredData(formattedData); // Set initial filtered data as all data
      } else {
        throw new Error("Invalid data format received");
      }
    } catch (err) {
      setError("Failed to fetch data. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    fetchData();
  }, [showAddNumerology]);

  // Handle Add Click
  const handleAddClick = () => {
    setSelectedData(null);
    setIsEditing(false);
    setShowAddNumerology(true);
  };

  // Handle Edit Click
  const handleEditClick = (data) => {
    setSelectedData(data);
    setIsEditing(true);
    setShowAddNumerology(true);
  };

  // Handle Delete Click
  const handleDeleteClick = async (numId) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 1000,
      timerProgressBar: true,
    });

    try {
      await axios.delete(`https://vpandit.caxis.ca/public/api/numcom/${numId}`);
      setNumerologyData((prevData) =>
        prevData.filter((item) => item.numId !== numId)
      );

      fetchData();
      // Show a success toast
      Toast.fire({
        icon: "success",
        title: "Record deleted successfully",
      });
    } catch (err) {
      console.error("Error deleting data:", err);

      // Show an error toast
      Toast.fire({
        icon: "error",
        title: "Failed to delete the record. Please try again.",
      });
    }
  };

  // Filter Logic
  const handleFilter = () => {
    let filtered = numerologyData;

    if (topicFilter) {
      filtered = filtered.filter(
        (item) =>
          item.numerologyNumber1 === topicFilter ||
          item.numerologyNumber2 === topicFilter
      );
    }

    if (numerologyFilter) {
      filtered = filtered.filter(
        (item) =>
          item.numerologyNumber1 === numerologyFilter ||
          item.numerologyNumber2 === numerologyFilter
      );
    }

    setFilteredData(filtered);
  };

  // Handle the "Filter" div click
  const handleEnableFilters = () => {
    setShowFilterFields(!showFilterFields); // Toggle filter visibility
  };

  // Handle the "Cancel" div click to hide the input fields and reset filters
  const handleCancelFilter = () => {
    setShowFilterFields(false);
    setTopicFilter("");
    setNumerologyFilter("");
    setFilteredData(numerologyData); // Reset to the full data
  };

  return (
    <div>
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Numerology Combination</h1>
        <div className="flex gap-3">
          <div className="flex space-x-4">
            <div
              onClick={handleEnableFilters}
              className="cursor-pointer  flex items-center"
            >
              <FiFilter className="mr-2" /> {/* Add the filter icon */}
            </div>
          </div>

          <div
            onClick={handleAddClick}
            className="cursor-pointer bg-orange-500 hover:bg-orange-600 text-white px-4 py-2 rounded-3xl flex items-center"
          >
            <BsSortNumericUpAlt className="mr-2 h-4 w-4 " />
            Add Numerology
          </div>
        </div>
      </div>

      {showFilterFields && (
        <div className="mt-4 mb-8">
          <div className="flex space-x-4 justify-between">
            <div className="flex gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Numerology Number 1
                </label>
                <input
                  type="text"
                  required
                  value={topicFilter}
                  onChange={(e) => setTopicFilter(e.target.value)}
                  className="mt-1 p-2 border border-gray-300  focus:outline-none focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
                  placeholder="Enter Numerology Number 1"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Numerology Number 2
                </label>
                <input
                  type="text"
                  required
                  value={numerologyFilter}
                  onChange={(e) => setNumerologyFilter(e.target.value)}
                  className="mt-1 p-2 border border-gray-300  focus:outline-none focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
                  placeholder="Enter Numerology Number 2"
                />
              </div>
            </div>

            <div className="flex items-end">
              <div
                onClick={handleFilter}
                className="cursor-pointer bg-green-500 text-white p-2 rounded-3xl hover:bg-green-600"
              >
                Apply
              </div>
              <div
                onClick={handleCancelFilter}
                className="cursor-pointer ml-2 bg-red-500 text-white p-2 rounded-3xl hover:bg-red-600"
              >
                Cancel
              </div>
            </div>
          </div>
        </div>
      )}

      {loading ? (
        <CommongPreloader />
      ) : error ? (
        <p className="text-red-500 text-center">{error}</p>
      ) : showAddNumerology ? (
        <Add_Numerology_Combo
          onClose={() => setShowAddNumerology(false)}
          setNumerologyComboData={setNumerologyData}
          numerologyComboData={numerologyData}
          selectedData={selectedData}
          isEditing={isEditing}
        />
      ) : (
        <div className="rounded-lg overflow-hidden">
          {numerologyData.length === 0 ? (
            <p className="p-4 text-center text-gray-500">No Data Found</p>
          ) : (
            <table className="w-full bg-white">
              <thead>
                <tr className="border-b border-gray-700">
                  <th className="px-6 py-3 text-left text-xs font-medium text-black uppercase tracking-wider">
                    Numerology Number 1
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-black uppercase tracking-wider">
                    Numerology Number 2
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-black uppercase tracking-wider">
                    Title
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-black uppercase tracking-wider">
                    Description
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-black uppercase tracking-wider">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-700">
                {filteredData.map((item, index) => (
                  <tr key={index} className="hover:bg-gray-50">
                    <td className="px-6 py-4">{item.numerologyNumber1}</td>
                    <td className="px-6 py-4">{item.numerologyNumber2}</td>
                    <td className="px-6 py-4">{item.title}</td>
                    <td className="px-6 py-4">
                      {item.description
                        ? HTMLReactParser(item.description)
                        : "No description available"}
                    </td>

                    <td className="px-6 py-4">
                      <div className="flex space-x-3">
                        <FaEdit
                          className="text-green-600 cursor-pointer"
                          onClick={() => handleEditClick(item)}
                        />
                        <FaTrash
                          className="text-red-600 cursor-pointer"
                          onClick={() => handleDeleteClick(item.numId)}
                        />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      )}
    </div>
  );
};

export default Numerology_Combo;
