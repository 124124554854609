import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";

export default function AddUser({ onClose, setUsers, users, selectedUser, isEditing }) {
  const [formData, setFormData] = useState({
    U_Name: "",
    U_Email: "",
    Role_Id: "",
    U_Gender: "",
    U_Mobile: "",
    U_DOB: "",
    U_Password: "", // U_Password field for new user creation
  });

  useEffect(() => {
    if (isEditing && selectedUser) {
      setFormData({
        U_Name: selectedUser.U_Name || "",
        U_Email: selectedUser.U_Email || "",
        Role_Id: selectedUser.Role_Id || "",
        U_Gender: selectedUser.U_Gender || "",
        U_Mobile: selectedUser.U_Mobile || "",
        U_DOB: formatToInputDate(selectedUser.U_DOB || ""),
        U_Password: "", // Ensure U_Password is not pre-filled during edit
      });
    }
  }, [isEditing, selectedUser]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const formattedDOB = formatDateToDMY(formData.U_DOB);

    const userPayload = {
      U_Name: formData.U_Name,
      U_Email: formData.U_Email,
      Role_Id: formData.Role_Id,
      U_Gender: formData.U_Gender,
      U_Mobile: formData.U_Mobile,
      U_DOB: formattedDOB,
    };

    // Include U_Password only in POST request (new user creation)
    if (!isEditing) {
      userPayload.U_Password = formData.U_Password;
    }

    if (isEditing && selectedUser) {
      axios
        .put(
          `https://vpandit.caxis.ca/public/api/users/${selectedUser.U_Id}`,
          userPayload
        )
        .then((response) => {
          console.log(userPayload);

          Swal.fire({
            icon: "success",
            title: "User Updated!",
            text: "User updated successfully!",
          });

          setUsers(
            users.map((user) =>
              user.id === selectedUser.U_Id ? response.data.data.user : user
            )
          );

          onClose();
        })
        .catch((error) => handleError(error));
    } else {
      axios
        .post("https://vpandit.caxis.ca/public/api/users", userPayload)
        .then((response) => {
          Swal.fire({
            icon: "success",
            title: "User Registered!",
            text: "User added successfully!",
          });

          setUsers([...users, response.data.data.user]);
          console.log(userPayload);

          onClose();
        });
    }
  };

  const handleError = (error) => {
    if (error.response && error.response.data && error.response.data.errors) {
      const errorMessages = Object.values(error.response.data.errors)
        .flat()
        .join("<br>");

      Swal.fire({
        icon: "error",
        title: "Validation Error!",
        html: errorMessages,
      });
    } 
  };

  const formatDateToDMY = (date) => {
    const [year, month, day] = date.split("-");
    return `${day}/${month}/${year}`;
  };

  const formatToInputDate = (date) => {
    if (!date) return "";
    const [day, month, year] = date.split("/");
    return `${year}-${month}-${day}`;
  };

  return (
    <div className="p-6 bg-white rounded-lg shadow-lg">
      <div className="flex mb-6">
        <h1 className="text-2xl font-bold text-orange-500">
          {isEditing ? "Update User" : "Add New User"}
        </h1>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="grid grid-cols-1 gap-4">
          <div>
            <label
              htmlFor="U_Name"
              className="block text-sm font-medium text-gray-700"
            >
              Full Name
            </label>
            <input
              type="text"
              id="U_Name"
              name="U_Name"
              value={formData.U_Name}
              onChange={handleChange}
              required
              className="mt-1 block p-2 border border-gray-300 rounded-md shadow-sm focus:ring-red-500 focus:border-red-500 sm:text-sm w-full"
            />
          </div>
        </div>

        <div className="mt-4">
          <label
            htmlFor="U_Email"
            className="block text-sm font-medium text-gray-700"
          >
            Email
          </label>
          <input
            type="email"
            id="U_Email"
            name="U_Email"
            value={formData.U_Email}
            onChange={handleChange}
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-red-500 focus:border-red-500 sm:text-sm"
          />
        </div>

        <div className="mt-4">
          <label
            htmlFor="Role_Id"
            className="block text-sm font-medium text-gray-700"
          >
            Role
          </label>
          <select
            id="Role_Id"
            name="Role_Id"
            value={formData.Role_Id}
            onChange={handleChange}
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-red-500 focus:border-red-500 sm:text-sm"
          >
            <option value="" disabled>
              Select a Role
            </option>
            <option value="1">Admin</option>
            <option value="2">Pandit</option>
            <option value="3">User</option>
          </select>
        </div>

        <div className="mt-4">
          <label
            htmlFor="U_DOB"
            className="block text-sm font-medium text-gray-700"
          >
            Date of Birth
          </label>
          <input
            type="date"
            id="U_DOB"
            name="U_DOB"
            value={formData.U_DOB}
            onChange={handleChange}
            required
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-red-500 focus:border-red-500 sm:text-sm"
          />
        </div>

        <div className="mt-4">
          <label
            htmlFor="U_Gender"
            className="block text-sm font-medium text-gray-700"
          >
            Gender
          </label>
          <select
            id="U_Gender"
            name="U_Gender"
            value={formData.U_Gender}
            onChange={handleChange}
            required
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-red-500 focus:border-red-500 sm:text-sm"
          >
            <option value="" disabled>
              Select Gender
            </option>
            <option value="M">Male</option>
            <option value="F">Female</option>
          </select>
        </div>

        <div className="mt-4">
          <label
            htmlFor="U_Mobile"
            className="block text-sm font-medium text-gray-700"
          >
            Mobile
          </label>
          <input
            type="text"
            id="U_Mobile"
            name="U_Mobile"
            value={formData.U_Mobile}
            onChange={handleChange}
            required
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-red-500 focus:border-red-500 sm:text-sm"
          />
        </div>

        {!isEditing && (
          <div className="mt-4">
            <label
              htmlFor="U_Password"
              className="block text-sm font-medium text-gray-700"
            >
              Password
            </label>
            <input
              type="password"
              id="U_Password"
              name="U_Password"
              value={formData.U_Password}
              onChange={handleChange}
              required
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-red-500 focus:border-red-500 sm:text-sm"
            />
          </div>
        )}
        <div className="mt-4 flex gap-4 w-full">
          <button
            type="submit"
            className="bg-orange-500 text-white p-2 rounded-full"
          >
            {isEditing ? "Update User" : "Add User"}
          </button>
          <button
            type="button"
            onClick={onClose}
            className="bg-gray-500 text-white p-2 rounded-full"
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
}
