import React, { useEffect, useState } from "react";
import {
  FaCog,
  FaMoon,
  FaExpand,
  FaBell,
  FaUser,
  FaHome,
} from "react-icons/fa";
import { IoAdd, IoList, IoFolderOpen, IoBag } from "react-icons/io5";

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import {
  Bell,
  Maximize,
  Moon,
  Settings,
  Home,
  Grid,
  FileText,
  Users,
  File,
} from "lucide-react";

// import { User } from "lucide-react"; // Replace with your actual icon imports
import { IoPerson, IoLockClosed } from "react-icons/io5";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io"; // Import arrow icons

import { Link, useNavigate } from "react-router-dom";
import { FaCalculator } from "react-icons/fa"; // Add this import for a numerology-related icon
import { FiUser, FiLock, FiLogOut } from "react-icons/fi";
import logo from "../../Assets/logo.png";
import UserMaster from "./UserMaster/UserMaster";
import Add_New_Pooja from "./Add_New_Pooja/Add_New_Pooja";
import Numerology from "./Numerology/Numerology";
import Admin_Profile from "./Admin_Profile/Admin_Profile";
import Numerology_Combo from "./Numerology_Combo/Numerology_Combo";
import LuShodGrid from "./LuShodGrid/LuShodGrid";
import Health_Numerology from "./Health_Numerology/Health_Numerology";
import Pandit_Master from "./Pandit_Master/Pandit_Master";
import Inventory_Management from "./Add_New_Pooja/Inventory_Management/Inventory_Management";
import Personal_Month_Numerology from "./Personal_Month_Numerology/Personal_Month_Numerology";
import Supplier_Master from "./Supplier_Master/Supplier_Master";
import Manage_Category from "./Add_New_Pooja/Inventory_Management/Manage_Category/Manage_Category";
import List_Of_Inquiry from "./List_Of_Inquiry/List_Of_Inquiry";
import Mobile_Number from "./Mobile_Number/Mobile_Number";
const data = [
  {
    date: "Sep 1",
    ParkingRequests: 100,
    ActiveSlots: 150,
    TotalEarnings: 2500,
  },
  {
    date: "Sep 2",
    ParkingRequests: 120,
    ActiveSlots: 140,
    TotalEarnings: 2700,
  },
  {
    date: "Sep 3",
    ParkingRequests: 110,
    ActiveSlots: 145,
    TotalEarnings: 2600,
  },
  { date: "Sep 4", ParkingRequests: 90, ActiveSlots: 160, TotalEarnings: 2800 },
  {
    date: "Sep 5",
    ParkingRequests: 130,
    ActiveSlots: 155,
    TotalEarnings: 3000,
  },
  {
    date: "Sep 6",
    ParkingRequests: 140,
    ActiveSlots: 150,
    TotalEarnings: 3200,
  },
];

export default function Super_admin() {
  const [activeLink, setActiveLink] = useState("Home"); // Default active link
  const [dropdownOpen, setDropdownOpen] = useState(false); // State to manage dropdown visibility
  const [poojaDropdownOpen, setPoojaDropdownOpen] = useState(false); // Pooja dropdown
  const [numerologyDropdownOpen, setNumerologyDropdownOpen] = useState(false); // New numerology dropdown
  const [inventoryDropdownOpen, setInventoryDropdownOpen] = useState(false); // New numerology dropdown
  const [InquiryDropdownOpen, setInquiryDropdownOpen] = useState(false); // New numerology dropdown

  const handleClick = (link) => {
    if (link === "User Management") {
      setDropdownOpen(!dropdownOpen);
    } else if (link === "Pooja Management") {
      setPoojaDropdownOpen(!poojaDropdownOpen);
    } else if (link === "Numerology Management") {
      setNumerologyDropdownOpen(!numerologyDropdownOpen);
    } else if (link === "Inventory Management") {
      setInventoryDropdownOpen(!inventoryDropdownOpen);
    } 
    else if (link === "Inquiry Mater") {
      setInquiryDropdownOpen(!InquiryDropdownOpen);
    } 
    else {
      setActiveLink(link);
    }
  };

  const [userDataLength, setUserDataLength] = useState(0);

  useEffect(() => {
    const fetchUser = async () => {
      const response = await fetch("https://vpandit.caxis.ca/public/api/users");

      const data = await response.json();
      setUserDataLength(data.length); // Store the length of the response
    };

    fetchUser();
  }, []);

  const navigate = useNavigate();

  const handleLogout = () => {
    console.log("User logged out");

    localStorage.removeItem("userID");
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    localStorage.removeItem("role");
    navigate("/"); // Navigate to home page
  };

  useEffect(() => {
    console.log(activeLink);
  }, [activeLink]);

  const [isProfileMenuVisible, setIsProfileMenuVisible] = useState(false);

  const toggleProfileMenu = () => setIsProfileMenuVisible((prev) => !prev);

  const handleProfileMenuClose = () => {
    setIsProfileMenuVisible(false);
    setActiveLink("My_Profile");
  };

  const [isNotificationListOpen, setIsNotificationListOpen] = useState(false);
  const notificationCount = 3;

  const notifications = [
    "New horoscope request from User",
    "User registered as an Astrologer",
    "Session feedback issue reported by User",
  ];

  const toggleNotificationList = () => {
    setIsNotificationListOpen(!isNotificationListOpen);
  };

  function handleSetting() {
    setActiveLink("General Settings");
  }

  function hadleScreenshot() {}

  return (
    <div className="flex h-screen bg-gray-100">
      {/* Sidebar */}
      <aside className="pt-5 bg-[#002538] p-4 hidden md:block max-h-auto">
        <div className="flex items-center mb-6  rounded">
          {/* Image */}
          <img
            src={logo} // Adjust the path based on your project structure
            alt="V Pandit Logo"
            className="w-16 h-16 object-contain" // Adjust size as needed
          />

          {/* Text */}
          <span className="text-[#f97316] text-3xl font-bold ml-4">
            V Pandit
          </span>
        </div>

        <nav className="space-y-4 max-h-auto bg-[#002538] w-72">
          <a
            href="#"
            className={`flex items-center py-2 px-4 text-gray-100 hover:bg-gray-500 font-bold rounded ${
              activeLink === "Home" ? "bg-[#f97316] text-white" : ""
            }`}
            onClick={() => handleClick("Home")}
          >
            <Home className="w-5 h-5 mr-2" /> Dashboard
          </a>

          {/* User Management Dropdown */}
          <div>
            <a
              href="#"
              className={`flex items-center py-2 px-4 text-gray-100 hover:bg-gray-500 rounded font-bold ${
                activeLink === "User Management"
                  ? "bg-[#f97316] text-white"
                  : ""
              }`}
              onClick={() => handleClick("User Management")}
            >
              <Grid className="w-5 h-5 mr-2" /> User Management
              <span
                className={`ml-auto transition-transform duration-300 ${
                  dropdownOpen ? "rotate-180" : ""
                }`}
              >
                <IoIosArrowDown className="w-5 h-5" />
              </span>
            </a>
            <div
              className={`ml-6 mt-2 space-y-2 overflow-hidden transition-max-height duration-300 ${
                dropdownOpen ? "max-h-auto" : "max-h-0"
              }`}
            >
              <a
                href="#"
                className={`flex items-center py-2 px-4 text-gray-100 hover:bg-gray-500 rounded ${
                  activeLink === "User Master" ? "bg-[#f97316] text-white" : ""
                }`}
                onClick={() => handleClick("User Master")}
              >
                <IoPerson className="w-5 h-5 mr-2" /> User Master
              </a>
              <a
                href="#"
                className={`flex items-center py-2 px-4 text-gray-100 hover:bg-gray-500 rounded ${
                  activeLink === "Pandit Master"
                    ? "bg-[#f97316] text-white"
                    : ""
                }`}
                onClick={() => handleClick("Pandit Master")}
              >
                <IoPerson className="w-5 h-5 mr-2" /> Pandit Master
              </a>
              {/* New Field for Supplier Master */}
              <a
                href="#"
                className={`flex items-center py-2 px-4 text-gray-100 hover:bg-gray-500 rounded ${
                  activeLink === "Supplier Master"
                    ? "bg-[#f97316] text-white"
                    : ""
                }`}
                onClick={() => handleClick("Supplier Master")}
              >
                <IoPerson className="w-5 h-5 mr-2" /> Supplier Master
              </a>
            </div>
          </div>

          {/* Pooja Management Dropdown */}
          <div>
            <a
              href="#"
              className={`flex items-center py-2 px-4 text-gray-100 hover:bg-gray-500 rounded font-bold ${
                activeLink === "Pooja Management"
                  ? "bg-[#f97316] text-white"
                  : ""
              }`}
              onClick={() => handleClick("Pooja Management")}
            >
              <FileText className="w-5 h-5 mr-2" /> Pooja Management
              <span
                className={`ml-auto transition-transform duration-300 ${
                  poojaDropdownOpen ? "rotate-180" : ""
                }`}
              >
                <IoIosArrowDown className="w-5 h-5" />
              </span>
            </a>

            {/* Submenu */}
            <div
              className={`ml-6 mt-2 space-y-2 overflow-hidden transition-max-height duration-300 ${
                poojaDropdownOpen ? "max-h-auto" : "max-h-0"
              }`}
            >
              {/* Pooja Master */}
              <a
                href="#"
                className={`flex items-center py-2 px-4 text-gray-100 hover:bg-gray-500 rounded ${
                  activeLink === "Pooja Master" ? "bg-[#f97316] text-white" : ""
                }`}
                onClick={() => handleClick("Pooja Master")}
              >
                <IoFolderOpen className="w-5 h-5 mr-2" /> Pooja Master
              </a>

              {/* Inventory Management */}
            </div>
          </div>

          {/* Inventory Management Dropdown */}
          <div>
            <a
              href="#"
              className={`flex items-center py-2 px-4 text-gray-100 hover:bg-gray-500 rounded font-bold ${
                activeLink === "Inventory Management"
                  ? "bg-[#f97316] text-white"
                  : ""
              }`}
              onClick={() => handleClick("Inventory Management")}
            >
              <IoBag className="w-5 h-5 mr-2" /> Inventory Management
              <span
                className={`ml-auto transition-transform duration-300 ${
                  inventoryDropdownOpen ? "rotate-180" : ""
                }`}
              >
                <IoIosArrowDown className="w-5 h-5" />
              </span>
            </a>
            <div
              className={`ml-6 mt-2 space-y-2 overflow-hidden transition-max-height duration-300 ${
                inventoryDropdownOpen ? "max-h-auto" : "max-h-0"
              }`}
            >
              {/* Manage Category */}
              <a
                href="#"
                className={`flex items-center py-2 px-4 text-gray-100 hover:bg-gray-500 rounded ${
                  activeLink === "Manage Category"
                    ? "bg-[#f97316] text-white"
                    : ""
                }`}
                onClick={() => handleClick("Manage Category")}
              >
                <IoFolderOpen className="w-5 h-5 mr-2" /> Manage Category
              </a>
              {/* Product Management */}
              <a
                href="#"
                className={`flex items-center py-2 px-4 text-gray-100 hover:bg-gray-500 rounded ${
                  activeLink === "Product Management"
                    ? "bg-[#f97316] text-white"
                    : ""
                }`}
                onClick={() => handleClick("Product Management")}
              >
                <IoFolderOpen className="w-5 h-5 mr-2" /> Product Management
              </a>
            </div>
          </div>

          {/* Numerology Management Dropdown (New) */}
          {/* Numerology Management Dropdown (Updated) */}
          <div>
            <a
              href="#"
              className={`flex items-center py-2 px-4 text-gray-100 hover:bg-gray-500 rounded font-bold ${
                activeLink === "Numerology Management"
                  ? "bg-[#f97316] text-white"
                  : ""
              }`}
              onClick={() => handleClick("Numerology Management")}
            >
              <FaCalculator className="w-5 h-5 mr-2" /> Numerology
              <span
                className={`ml-auto transition-transform duration-300 ${
                  numerologyDropdownOpen ? "rotate-180" : ""
                }`}
              >
                <IoIosArrowDown className="w-5 h-5" />
              </span>
            </a>
            <div
              className={`ml-6 mt-2 space-y-2 overflow-hidden transition-max-height duration-300 ${
                numerologyDropdownOpen ? "max-h-auto" : "max-h-0"
              }`}
            >
              {/* Submenu: Add Numerology */}
              <a
                href="#"
                className={`flex items-center py-2 px-4 text-gray-100 hover:bg-gray-500 rounded ${
                  activeLink === "Add Numerology"
                    ? "bg-[#f97316] text-white"
                    : ""
                }`}
                onClick={() => handleClick("Add Numerology")}
              >
                <IoFolderOpen className="w-5 h-5 mr-2" /> Add Numerology
              </a>
              {/* Submenu: Numerology Combo */}
              <a
                href="#"
                className={`flex items-center py-2 px-4 text-gray-100 hover:bg-gray-500 rounded ${
                  activeLink === "Numerology Combo"
                    ? "bg-[#f97316] text-white"
                    : ""
                }`}
                onClick={() => handleClick("Numerology Combo")}
              >
                <IoFolderOpen className="w-5 h-5 mr-2" /> Numerology Combo
              </a>
              {/* Submenu: Lu Shod Grid */}
              <a
                href="#"
                className={`flex items-center py-2 px-4 text-gray-100 hover:bg-gray-500 rounded ${
                  activeLink === "Lu Shod Grid" ? "bg-[#f97316] text-white" : ""
                }`}
                onClick={() => handleClick("Lu Shod Grid")}
              >
                <IoFolderOpen className="w-5 h-5 mr-2" /> Lu Shod Grid
              </a>
              {/* Submenu: Health Numerology */}
              <a
                href="#"
                className={`flex items-center py-2 px-4 text-gray-100 hover:bg-gray-500 rounded ${
                  activeLink === "Health Numerology"
                    ? "bg-[#f97316] text-white"
                    : ""
                }`}
                onClick={() => handleClick("Health Numerology")}
              >
                <IoFolderOpen className="w-5 h-5 mr-2" /> Health Numerology
              </a>

              <a
                href="#"
                className={`flex items-center py-2 px-4 text-gray-100 hover:bg-gray-500 rounded ${
                  activeLink === "Predictions"
                    ? "bg-[#f97316] text-white"
                    : ""
                }`}
                onClick={() => handleClick("Predictions")}
              >
                <IoFolderOpen className="w-5 h-5 mr-2" /> Predictions
              </a>

              <a
                href="#"
                className={`flex items-center py-2 px-4 text-gray-100 hover:bg-gray-500 rounded ${
                  activeLink === "Mobile Number"
                    ? "bg-[#f97316] text-white"
                    : ""
                }`}
                onClick={() => handleClick("Mobile Number")}
              >
                <IoFolderOpen className="w-5 h-5 mr-2" /> Mobile Number
              </a>
            </div>
          </div>

          {/* Inquiry Mater */}
          <div>
            <a
              href="#"
              className={`flex items-center py-2 px-4 text-gray-100 hover:bg-gray-500 rounded font-bold ${
                activeLink === "Inquiry Mater" ? "bg-[#f97316] text-white" : ""
              }`}
              onClick={() => handleClick("Inquiry Mater")}
            >
              <FileText className="w-5 h-5 mr-2" /> Inquiry Mater
              <span
                className={`ml-auto transition-transform duration-300 ${
                  InquiryDropdownOpen ? "rotate-180" : ""
                }`}
              >
                <IoIosArrowDown className="w-5 h-5" />
              </span>
            </a>

            {/* Submenu */}
            <div
              className={`ml-6 mt-2 space-y-2 overflow-hidden transition-max-height duration-300 ${
                InquiryDropdownOpen ? "max-h-auto" : "max-h-0"
              }`}
            >
              {/* List Of Inquiry List Of Inquiry */}
              <a
                href="#"
                className={`flex items-center py-2 px-4 text-gray-100 hover:bg-gray-500 rounded ${
                  activeLink === "List Of Inquiry"
                    ? "bg-[#f97316] text-white"
                    : ""
                }`}
                onClick={() => handleClick("List Of Inquiry")}
              >
                <IoFolderOpen className="w-5 h-5 mr-2" /> List Of Inquiry
              </a>
            </div>
          </div>
        </nav>
      </aside>
      <main className="flex-1 p-4 overflow-auto">
        {/* Header */}
        {activeLink === "Home" && (
          <>
            <header className="flex justify-between items-center mb-8 bg-gray-100 ">
              <div>
                <h1 className="text-2xl font-bold">
                  Welcome, Astrology Service Admin!
                </h1>
              </div>
              <div className="flex items-center space-x-4">
                {/* <div className="p-2" onClick={handleSetting}>
                  <FaCog className="w-6 h-6 text-gray-700 hover:text-[#002538]" />
                </div> */}

                <div className="p-2" onClick={hadleScreenshot}>
                  <FaExpand className="w-6 h-6 text-gray-700 hover:text-[#002538]" />
                </div>
                <div className="relative cursor-pointer">
                  <div className="p-2" onClick={toggleNotificationList}>
                    <FaBell className="w-6 h-6 text-gray-700 hover:text-[#002538]" />
                    {notificationCount > 0 && (
                      <span className="absolute top-0 right-0  w-4 h-4 bg-[#f97316] text-white text-xs font-semibold rounded-full flex items-center justify-center">
                        {notificationCount}
                      </span>
                    )}
                  </div>

                  {/* Notification list dropdown */}
                  {isNotificationListOpen && (
                    <div className="absolute z-50 right-0 mt-2 w-64 bg-white border border-gray-300 shadow-lg rounded-md">
                      <ul>
                        {notifications.length > 0 ? (
                          notifications.map((notification, index) => (
                            <li
                              key={index}
                              className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                            >
                              {notification}
                            </li>
                          ))
                        ) : (
                          <li className="px-4 py-2 text-gray-500">
                            No new notifications
                          </li>
                        )}
                      </ul>
                    </div>
                  )}
                </div>
                <div className="relative">
                  <div
                    onClick={toggleProfileMenu}
                    className="p-2 rounded-full bg-gray-200 hover:bg-gray-300"
                  >
                    <FaUser className="w-6 h-6 text-gray-700" />
                  </div>
                  {isProfileMenuVisible && (
                    <div className="absolute right-0 mt-2 w-48 bg-white border border-gray-200 rounded-md shadow-lg cursor-pointer">
                      <Link
                        to={"/"}
                        className="flex items-center px-4 py-2 text-gray-700 hover:bg-gray-100 w-full text-left"
                      >
                        <FaHome className="mr-2" /> Home
                      </Link>
                      <div
                        onClick={handleProfileMenuClose}
                        className="flex items-center px-4 py-2 text-gray-700 hover:bg-gray-100 w-full text-left"
                      >
                        <FiUser className="mr-2" /> My Profile
                      </div>

                      <div
                        onClick={handleLogout}
                        className="flex items-center px-4 py-2 text-gray-700 hover:bg-gray-100 w-full text-left"
                      >
                        <FiLogOut className="mr-2" /> Logout
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </header>

            {/* Stats */}
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8 -mt-6">
              <StatCard
                title="Total Users"
                value={userDataLength}
                percent={5.4}
                increase
              />
              <StatCard
                title="Active Consultations"
                value="320"
                percent={2.5}
              />
              <StatCard
                title="Total Astrology Sessions"
                value="2,000"
                percent={3.2}
              />
              <StatCard
                title="Total Earnings"
                value="$12,000"
                percent={4.5}
                increase
              />
            </div>

            {/* Chart */}
            <div className="bg-white p-6 rounded-lg shadow mb-8">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-xl font-semibold">
                  Astrology Service Activity
                </h2>
                <select className="border rounded p-2">
                  <option>Daily Data</option>
                  <option>Weekly Data</option>
                </select>
              </div>
              <ResponsiveContainer width="100%" height={300}>
                <BarChart data={data}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="date" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="Consultations" fill="#3b82f6" />
                  <Bar dataKey="ActiveSessions" fill="#f59e0b" />
                  <Bar dataKey="TotalEarnings" fill="#10b981" />
                </BarChart>
              </ResponsiveContainer>
            </div>

            {/* Bottom Stats */}
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              <BottomStatCard
                title="Available Astrology Advisors"
                total={1800}
                items={[
                  { label: "Active", value: 1200, color: "bg-blue-500" },
                  { label: "Inactive", value: 600, color: "bg-[#f97316]" },
                ]}
              />
              <BottomStatCard
                title="Consultation Overview"
                count={320}
                items={[
                  { label: "Completed", value: 280 },
                  { label: "Pending", value: 40 },
                ]}
              />
              <BottomStatCard
                title="Astrology Advisor Activity"
                count={150}
                items={[
                  { label: "Active Advisors", value: 120 },
                  { label: "Inactive Advisors", value: 30 },
                ]}
              />
            </div>
          </>
        )}

        {activeLink === "User Master" && <UserMaster />}
        {activeLink === "Pandit Master" && <Pandit_Master />}
        {activeLink === "Supplier Master" && <Supplier_Master />}
        {activeLink === "Pooja Master" && <Add_New_Pooja />}
        {activeLink === "Product Management" && <Inventory_Management />}
        {activeLink === "Manage Category" && <Manage_Category />}

        {activeLink === "Add Numerology" && <Numerology />}
        {activeLink === "Numerology Combo" && <Numerology_Combo />}
        {activeLink === "Lu Shod Grid" && <LuShodGrid />}
        {activeLink === "Health Numerology" && <Health_Numerology />}
        {activeLink === "Predictions" && <Personal_Month_Numerology />}
        {activeLink === "Mobile Number" && <Mobile_Number />}
        {activeLink === "My_Profile" && <Admin_Profile />}

        {activeLink === "List Of Inquiry" && <List_Of_Inquiry />}
      </main>
    </div>
  );
}

function StatCard({ title, value, percent, increase = false }) {
  return (
    <div className="bg-white p-6 rounded-lg shadow">
      <h3 className="text-lg font-semibold mb-2">{title}</h3>
      <div className="text-3xl font-bold mb-2">{value}</div>
      <div
        className={`flex items-center ${
          increase ? "text-green-500" : "text-[#f97316]"
        }`}
      >
        <span className="text-sm font-semibold">{percent}%</span>
        <svg className="w-4 h-4 ml-1" viewBox="0 0 20 20" fill="currentColor">
          <path
            fillRule="evenodd"
            d="M5.293 7.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 5.414V17a1 1 0 11-2 0V5.414L6.707 7.707a1 1 0 01-1.414 0z"
            clipRule="evenodd"
          />
        </svg>
      </div>
    </div>
  );
}

function BottomStatCard({ title, total, count, items }) {
  return (
    <div className="bg-white p-6 rounded-lg shadow">
      <h3 className="text-lg font-semibold mb-4">{title}</h3>
      {total && <div className="text-3xl font-bold mb-2">{total}</div>}
      {count && <div className="text-3xl font-bold mb-2">{count}</div>}
      {items.map((item, index) => (
        <div key={index} className="flex justify-between items-center mb-2">
          <span className={`text-sm ${item.color}`}>{item.label}</span>
          <span className="text-sm font-semibold">{item.value}</span>
        </div>
      ))}
    </div>
  );
}
