import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FaHome } from "react-icons/fa";
import CommongPreloader from "../AdminPanel/CommongPreloader/CommongPreloader";

const Successfully_Pooja_Booked = () => {
  const location = useLocation();
  const navigate = useNavigate();

  // Parse the query parameters
  const queryParams = new URLSearchParams(location.search);

  // Access specific query parameters
  const PoojaId = queryParams.get("pid");
  const PoojaTime = queryParams.get("date");

  const [poojaName, setPoojaName] = useState(null);
  const [userDetails, setUserdetails] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Show preloader for 5 seconds
    const timer = setTimeout(() => {
      setLoading(false);
    }, 5000);

    return () => clearTimeout(timer); // Cleanup timer on component unmount
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch(
        `https://vpandit.caxis.ca/public/api/subcategories/${PoojaId}`
      );
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setPoojaName(data);
    } catch (error) {
      console.error("Error fetching subcategories:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [PoojaId]);

  useEffect(() => {
    const userId = localStorage.getItem("userID");

    const fetchUserDetails = async () => {
      try {
        const response = await fetch(
          `https://vpandit.caxis.ca/public/api/users/${userId}`
        );
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setUserdetails(data);
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    };

    fetchUserDetails();
  }, []);

  // Render the preloader if loading
  if (loading) {
    return <CommongPreloader />;
  }

  // Render the main content
  return (
    <div className="min-h-screen flex items-center justify-center bg-white p-4">
      <div className="max-w-3xl w-full text-center">
        {/* Success Icon */}
        <div className="relative w-24 h-24 mx-auto mb-8">
          <div className="absolute inset-0 bg-gray-100 rounded-full">
            <div className="absolute inset-0 flex items-center justify-center">
              <div className="w-16 h-16 bg-orange-400 rounded-full flex items-center justify-center">
                <svg
                  className="w-8 h-8 text-white"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={3}
                    d="M5 13l4 4L19 7"
                  />
                </svg>
              </div>
            </div>
          </div>
          {/* Decorative dots */}
          <div className="absolute -top-1 right-0 w-2 h-2 bg-yellow-300 rounded-full"></div>
          <div className="absolute top-1/4 -right-2 w-2 h-2 bg-emerald-300 rounded-full"></div>
          <div className="absolute bottom-0 -right-1 w-2 h-2 bg-orange-300 rounded-full"></div>
          <div className="absolute top-1/3 -left-2 w-2 h-2 bg-red-300 rounded-full"></div>
        </div>

        {/* Heading */}
        <h1 className="text-3xl md:text-4xl font-bold text-gray-800 mb-4">
          Your Pooja Booking Request Sent Successfully!
        </h1>

        {/* Subheading */}
        <p className="text-gray-500 text-lg mb-12">
          We have sent your booking information to your email address.
        </p>

        {/* Booking Details */}
        <div className="grid md:grid-cols-3 gap-6 text-left">
          {/* Pooja */}
          <div className="px-6">
            <h2 className="text-gray-500 text-lg mb-2">Pooja Name:</h2>
            <p className="text-gray-600 text-xl">
              {poojaName?.SubCat_Name || ""}
            </p>
          </div>

          {/* Date & Time */}
          <div className="px-6 md:border-x border-gray-200">
            <h2 className="text-gray-500 text-lg mb-2">Date & Time:</h2>
            <p className="text-gray-600 text-xl">
              {new Date(PoojaTime).toDateString()}{" "}
              {new Date(PoojaTime).toLocaleTimeString("en-IN", {
                hour: "2-digit",
                minute: "2-digit",
              })}
            </p>
          </div>

          {/* Customer Name */}
          <div className="px-6">
            <h2 className="text-gray-500 text-lg mb-2">Customer Name:</h2>
            <p className="text-gray-600 text-xl">{userDetails?.U_Name || ""}</p>
          </div>
        </div>

        {/* div to Navigate Home */}
        <div className="mt-12 flex justify-center items-center">
          <div
            onClick={() => navigate("/")}
            className="flex items-center gap-2 px-6 py-3 bg-orange-500 text-white text-lg font-semibold rounded shadow hover:bg-orange-700 cursor-pointer"
          >
            <FaHome className="text-2xl" />
            Go to Home
          </div>
        </div>
      </div>
    </div>
  );
};

export default Successfully_Pooja_Booked;
