import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import axios from "axios";
import HTMLReactParser from 'html-react-parser'
import "../Numerology/Numerology.css";

export default function Numerology_InnerPage() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [searchParams] = useSearchParams();
  const myValue = searchParams.get("value");
  const [sixValueComparison, setSixValueComparison] = useState([]);
  const [personalYearComparison, setPersonalYearComparison] = useState([]);
  const [personalMonthComparison, setPersonalMonthComparison] = useState([]);
  const [personalDayComparison, setPersonalDayComparison] = useState([]);
  const [pyramidNumber, setPyramidNumber] = useState(null);
  const [pyramidValues, setPyramidValues] = useState([]);
  const [masterNumberData, setMasterNumberData] = useState(null);

  const [isSixValueLoading, setIsSixValueLoading] = useState(false);
  const topicno = searchParams.get("topc_no");
  const topc_no = parseInt(queryParams.get("topc_no"));
  const gridParam = searchParams.get("grid");
  const [numcomComparisons, setNumcomComparisons] = useState([]);
  const [isComparisonLoading, setIsComparisonLoading] = useState(false);
  const [numerologyData, setNumerologyData] = useState(null);
  const [numcomData, setNumcomData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pyramidData, setPyramidData] = useState([]);
  const [personalDay, setPersonalDay] = useState(null);
  const [personalMonth, setPersonalMonth] = useState(null);
  const [personalYear, setPersonalYear] = useState(null);
  const [houseNumberResult, setHouseNumberResult] = useState(null);
  const [vehicleNumberResult, setVehicleNumberResult] = useState(null);
  const [mobileNumber, setMobileNumber] = useState("");
  const [modifiedNumber, setModifiedNumber] = useState("");
  const [totalSum, setTotalSum] = useState("");
  const [pairs, setPairs] = useState([]);
  const [selectedPair, setSelectedPair] = useState(null);
  const [totalWeightage, setTotalWeightage] = useState("");
  const [date, setDate] = useState("");
  const [balancedNumbers, setBalancedNumbers] = useState([]);
  const [unbalancedNumbers, setUnbalancedNumbers] = useState([]);
  const [missingNumbers, setMissingNumbers] = useState([]);
  const [mulank, setMulank] = useState(null);
  const [bhagyank, setBhagyank] = useState(null);
  const [luckyNumber, setLuckyNumber] = useState(null);
  const [karmicNumbers, setKarmicNumbers] = useState([]);
  const [masterNumbers, setMasterNumbers] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Loading state
  const [grid, setGrid] = useState([]);
  const [mobile, setMobile] = useState("");
  const [MobileNumerologyData, setMobileNumerologyData] = useState([]);
  const [matchingData, setMatchingData] = useState(null); // Store matching data
  const [apiData, setApiData] = useState({
    balanced: [],
    unbalanced: [],
    missing: [],
  });
  const [frequencies, setFrequencies] = useState(new Map());

  useEffect(() => {
    if (date) {
      const [year, month, day] = date.split("-").map(Number);
      const reducedMonth = calculateNumber(month);
      const reducedDay = calculateNumber(day);
      const reducedYear = calculateNumber(year);

      const pyramidSteps = [[reducedMonth, reducedDay, reducedYear]];

      while (pyramidSteps[pyramidSteps.length - 1].length > 1) {
        const prevRow = pyramidSteps[pyramidSteps.length - 1];
        const newRow = prevRow.slice(0, -1).map((num, i) => Math.abs(prevRow[i + 1] - num));
        pyramidSteps.push(newRow);
      }
      setPyramidValues(pyramidSteps.flat()); // Flatten the array to get all six values
    }
  }, [date]);



  // Call API when pyramidValues update
  useEffect(() => {
    if (pyramidValues.length === 6) { // Ensure there are exactly 6 values
      const fetchPyramidData = async () => {
        try {
          setIsLoading(true);

          // Construct the URL with pyramid values dynamically
          const apiUrl = `https://vpandit.caxis.ca/public/api/personalnumcom/${pyramidValues[0]}/${pyramidValues[1]}/${pyramidValues[2]}/${pyramidValues[3]}/${pyramidValues[4]}/${pyramidValues[5]}`;

          const response = await fetch(apiUrl);
          const data = await response.json();

          // Find matching data based on the pyramid values
          const matchedData = data.find(
            (item) =>
              item.Value1 === String(pyramidValues[0]) &&
              item.Value2 === String(pyramidValues[1]) &&
              item.Value3 === String(pyramidValues[2]) &&
              item.Value4 === String(pyramidValues[3]) &&
              item.Value5 === String(pyramidValues[4]) &&
              item.Value6 === String(pyramidValues[5])
          );

          if (matchedData) {
            setPyramidData([matchedData]); // Save the matching data
          }
        } catch (error) {
          console.error("Error fetching Pyramid Data:", error);
        } finally {
          setIsLoading(false);
        }
      };

      if (value === "Numbers Pyramid") {
        fetchPyramidData();
      }
    }
  }, [pyramidValues]);

  useEffect(() => {
    const fetchNumerologyData = async () => {
      try {
        const response = await fetch("https://vpandit.caxis.ca/public/api/mobile-numerologies");
        const data = await response.json();
        setMobileNumerologyData(data); // Store API data
      } catch (error) {
        console.error("Error fetching numerology data:", error);
      }
    };

    if (value === "Mobile Number") {

      fetchNumerologyData();
    }
  }, []);

useEffect(() => {
  const fetchHealthData = async () => {
    const storedUserData = JSON.parse(localStorage.getItem("numerologyData"));
    if (storedUserData && value === "Health Number") {
      setIsLoading(true); // Start loading
      try {
        const { mulank, bhagyank, luckyNumber, personalYear, personalMonth, U_DOB } = storedUserData;

        const response = await fetch("https://vpandit.caxis.ca/public/api/health-numerologies");
        const data = await response.json();
        setApiData(data); // Store the API data

        const matchingItem = data.find(item =>
          item.mulank === mulank.toString() &&
          item.bhagyank === bhagyank.toString() &&
          item.lucky === luckyNumber.toString() &&
          item.per_year === personalYear.toString() &&
          item.per_month === personalMonth.toString()
        );

        setMatchingData(matchingItem || null); // Set matching data or null if no match
      } catch (error) {
        console.error('Error fetching API data:', error);
        setMatchingData(null); // Reset matchingData on error
      } finally {
        setIsLoading(false); // Stop loading regardless of outcome
      }
    }
  };

  fetchHealthData();
}, []); 


  useEffect(() => {
    const userdetails = JSON.parse(localStorage.getItem("user"));
    setDate(userdetails.U_DOB);
    setPyramidNumber(calculatePyramidNumber(userdetails.U_DOB));
  }, []);

  useEffect(() => {
    if (date && mulank && bhagyank && luckyNumber) {
      calculateGrid();
    }
  }, [date, mulank, bhagyank, luckyNumber]);

  const calculateNumber = (input) => {
    let sum = input
      .toString()
      .split("")
      .map(Number)
      .reduce((acc, digit) => acc + digit, 0);
    while (sum > 9) {
      sum = sum
        .toString()
        .split("")
        .map(Number)
        .reduce((acc, digit) => acc + digit, 0);
    }
    return sum;
  };

  const calculatePyramidNumber = (dob) => {
    if (!dob) return null;
    const [year, month, day] = dob.split("-").map(Number);
    const reducedMonth = calculateNumber(month);
    const reducedDay = calculateNumber(day);
    const reducedYear = calculateNumber(year);

    const result1 = Math.abs(reducedDay - reducedMonth);  // 🔹 Modified order
    const result2 = Math.abs(reducedYear - reducedMonth);

    return Math.abs(result1 - result2);
  };

  const renderPyramid = (number) => {
    if (!number) return null;
    let rows = [];

    for (let i = 0; i <= number; i++) {
      let row = [];
      for (let j = 0; j <= i; j++) {
        row.push(<span key={j} className="pyramid-number">{number}</span>);
      }
      rows.push(
        <div key={i} className="pyramid-row">
          {row}
        </div>
      );
    }

    return <div className="pyramid-container">{rows}</div>;
  };


  //calculation of  grid ********
  const calculateGrid = () => {
    const digits = date.replace(/[^0-9]/g, "");
    const frequency = new Map();

    // Frequency count for each digit in DOB
    for (const digit of digits) {
      frequency.set(digit, (frequency.get(digit) || 0) + 1);
    }

    // Reduce day to single digit if > 9 and add to grid
    const dobParts = date.split("-");
    if (dobParts.length === 3) {
      let day = parseInt(dobParts[2], 10);
      if (day > 9) {
        while (day > 9) {
          day = [...day.toString()].reduce((sum, num) => sum + Number(num), 0);
        }
        frequency.set(day.toString(), (frequency.get(day.toString()) || 0) + 1);
      }
    }

    // Add Bhagyank & Lucky Number
    if (bhagyank) frequency.set(bhagyank.toString(), (frequency.get(bhagyank.toString()) || 0) + 1);
    if (luckyNumber) frequency.set(luckyNumber.toString(), (frequency.get(luckyNumber.toString()) || 0) + 1);

    setFrequencies(frequency);

    const gridTemplate = Array.from({ length: 3 }, () => Array(3).fill(""));
    const gridMapping = {
      4: [0, 0], 9: [0, 1], 2: [0, 2],
      3: [1, 0], 5: [1, 1], 7: [1, 2],
      8: [2, 0], 1: [2, 1], 6: [2, 2],
    };

    Object.keys(gridMapping).forEach((digit) => {
      const [row, col] = gridMapping[digit];
      const count = frequency.get(digit) || 0;
      if (count > 0) {
        gridTemplate[row][col] = (
          <sub>{digit}<sup>{count}</sup></sub>
        );
      }
    });

    setGrid(gridTemplate);
  };

  const fetchApiData = async () => {
    try {
      const loshuCombinations = {
        101: [4, 9, 2], 102: [3, 5, 7], 103: [8, 1, 6],
        104: [4, 3, 8], 105: [9, 5, 1], 106: [2, 7, 6],
        107: [4, 5, 6], 108: [2, 5, 8],
        109: [1, 3], 110: [3, 9], 111: [7, 9],
        112: [1, 7], 113: [5, 6],
        // New combinations for 200 and 300
        200: [2, 9, 5, 6], // First value will be 2's frequency + 200
        300: [5, 8, 1, 6]  // First value will be 5's frequency + 300
      };

      let allRequests = new Map();

      // First API call - Keep this unchanged
      Object.values(loshuCombinations).flat().forEach((number) => {
        const count = frequencies.get(number.toString()) || 0;
        const apiUrl = `https://vpandit.caxis.ca/public/api/loshu-grids/1/${number}/${count}/0`;
        if (!allRequests.has(apiUrl)) {
          allRequests.set(apiUrl, axios.get(apiUrl).then(res => res.data.data[0]).catch(() => null));
        }
      });

      // Second API call - Including new combinations for 200 and 300
      Object.entries(loshuCombinations).forEach(([key, numbers]) => {
        if (key === '200') {
          // For combination 200: [2, 9, 5, 6]
          const freq2 = frequencies.get('2') || 0;
          const freq9 = frequencies.get('9') || 0;
          const freq5 = frequencies.get('5') || 0;
          const freq6 = frequencies.get('6') || 0;
          const baseValue = 200;

          const url = `https://vpandit.caxis.ca/public/api/loshu-grids/${baseValue + freq2}/${freq9}/${freq5}/${freq6}`;
          if (!allRequests.has(url)) {
            allRequests.set(url, axios.get(url).then(res => res.data.data[0]).catch(() => null));
          }
        } else if (key === '300') {
          // For combination 300: [5, 8, 1, 6]
          const freq5 = frequencies.get('5') || 0;
          const freq8 = frequencies.get('8') || 0;
          const freq1 = frequencies.get('1') || 0;
          const freq6 = frequencies.get('6') || 0;
          const baseValue = 300;

          const url = `https://vpandit.caxis.ca/public/api/loshu-grids/${baseValue + freq5}/${freq8}/${freq1}/${freq6}`;
          if (!allRequests.has(url)) {
            allRequests.set(url, axios.get(url).then(res => res.data.data[0]).catch(() => null));
          }
        } else if (numbers.length === 3) {
          const freq1 = frequencies.get(numbers[0].toString()) || 0;
          const freq2 = frequencies.get(numbers[1].toString()) || 0;
          const freq3 = frequencies.get(numbers[2].toString()) || 0;

          const threeNumberUrl = `https://vpandit.caxis.ca/public/api/loshu-grids/${key}/${freq1}/${freq2}/${freq3}`;
          if (!allRequests.has(threeNumberUrl)) {
            allRequests.set(threeNumberUrl, axios.get(threeNumberUrl).then(res => res.data.data[0]).catch(() => null));
          }

          // Add two-value combinations for existing 101-108
          const twoNumberUrl1 = `https://vpandit.caxis.ca/public/api/loshu-grids/${key}/${freq1}/${freq2}/0`;
          const twoNumberUrl2 = `https://vpandit.caxis.ca/public/api/loshu-grids/${key}/${freq1}/${freq3}/0`;
          const twoNumberUrl3 = `https://vpandit.caxis.ca/public/api/loshu-grids/${key}/${freq2}/${freq3}/0`;

          if (!allRequests.has(twoNumberUrl1)) {
            allRequests.set(twoNumberUrl1, axios.get(twoNumberUrl1).then(res => res.data.data[0]).catch(() => null));
          }
          if (!allRequests.has(twoNumberUrl2)) {
            allRequests.set(twoNumberUrl2, axios.get(twoNumberUrl2).then(res => res.data.data[0]).catch(() => null));
          }
          if (!allRequests.has(twoNumberUrl3)) {
            allRequests.set(twoNumberUrl3, axios.get(twoNumberUrl3).then(res => res.data.data[0]).catch(() => null));
          }
        } else if (numbers.length === 2) {
          const freq1 = frequencies.get(numbers[0].toString()) || 0;
          const freq2 = frequencies.get(numbers[1].toString()) || 0;

          const twoNumberUrl = `https://vpandit.caxis.ca/public/api/loshu-grids/${key}/${freq1}/${freq2}/0`;
          if (!allRequests.has(twoNumberUrl)) {
            allRequests.set(twoNumberUrl, axios.get(twoNumberUrl).then(res => res.data.data[0]).catch(() => null));
          }
        }
      });

      const responses = await Promise.all([...allRequests.values()]);
      const validResponses = responses.filter(item => item !== null);

      const categorizedData = {
        balanced: validResponses.filter(item => (frequencies.get(item.Value1?.toString()) || 0) > 0 && (frequencies.get(item.Value1?.toString()) || 0) <= 2),
        unbalanced: validResponses.filter(item => (frequencies.get(item.Value1?.toString()) || 0) > 2),
        missing: validResponses.filter(item => (frequencies.get(item.Value1?.toString()) || 0) === 0),
      };

      setApiData(categorizedData);
    } catch (error) {
      console.error("Error fetching API data:", error);
    }
  };

  useEffect(() => {
    if (value === "Lo Shu Grid" && frequencies.size > 0) {
      setIsLoading(true);
      fetchApiData().finally(() => setIsLoading(false));
    }
  }, [frequencies]);


  //Mobile Number*****************
  const replaceZerosWithPreviousDigit = (number) => {
    let modifiedNumber = "";
    let previousDigit = "0";

    for (const digit of number) {
      if (digit === "0") {
        modifiedNumber += previousDigit;
      } else {
        modifiedNumber += digit;
        previousDigit = digit;
      }
    }
    return modifiedNumber;
  };

  const sumDigits = (number) => {
    let sum = 0;
    for (const digit of number) {
      sum += parseInt(digit);
    }

    while (sum >= 10 && sum !== 11 && sum !== 22 && sum !== 33) {
      sum = sumDigits(sum.toString());
    }
    return sum;
  };

  const createPairs = (number) => {
    const pairs = [];
    for (let i = 0; i < number.length - 1; i++) {
      const pair = number.substring(i, i + 2);
      pairs.push(pair);
      if (pairs.length === 9) break; // Ensure exactly 9 pairs
    }
    return pairs;
  };


  const calculateWeightage = (pairs) => {
    if (pairs.length === 0 || !MobileNumerologyData.length) return 0;

    // Filter pairs where YN === true
    const validPairs = pairs.filter((pair) => {
      const matchedData = MobileNumerologyData.find(
        (item) => item.Value.toString() === pair
      );
      return matchedData && matchedData.YN === true;
    });

    // Calculate percentage
    const weightagePercentage = Math.floor((validPairs.length * 100) / pairs.length);
    return weightagePercentage;
  };
  useEffect(() => {
    const fetchDataAndCalculate = async () => {
      const userdetails = JSON.parse(localStorage.getItem("numerologyData"));
      const userMobile = userdetails?.U_Mobile || "";

      if (userMobile.length === 10) {
        setMobileNumber(userMobile);

        const modified = replaceZerosWithPreviousDigit(userMobile);
        setModifiedNumber(modified);
        setTotalSum(sumDigits(modified).toString());

        const numberPairs = createPairs(modified);
        setPairs(numberPairs);

        // Calculate weightage only if MobileNumerologyData is available
        if (MobileNumerologyData.length > 0) {
          const weightagePercentage = calculateWeightage(numberPairs);
          setTotalWeightage(`${weightagePercentage}%`);
        } else {
          console.log("Waiting for MobileNumerologyData...");
        }
      }
    };

    fetchDataAndCalculate();
  }, [MobileNumerologyData]);  // Dependency added

  useEffect(() => {
    const storedUserData = JSON.parse(localStorage.getItem("numerologyData"));
    if (storedUserData) {
      setMulank(storedUserData.mulank);
      setBhagyank(storedUserData.bhagyank);
      setLuckyNumber(storedUserData.luckyNumber);
      setMobile(storedUserData.U_MobileNumber || ""); // Retrieve Mobile Number
      setDate(storedUserData.U_DOB);
      setGrid(storedUserData.loShuGrid);
      setKarmicNumbers(storedUserData.karmicNumbers || []);
      setMasterNumbers(storedUserData.masterNumbers || []);
      setPersonalDay(storedUserData.personalDay); // Assuming this is stored
      setPersonalMonth(storedUserData.personalMonth); // Assuming this is stored
      setPersonalYear(storedUserData.personalYear); // Assuming this is stored
      setHouseNumberResult(storedUserData.houseNumberResult || null); // Added
      setVehicleNumberResult(storedUserData.vehicleNumberResult || null); // Added
    }
  }, []);

  useEffect(() => {
    const handleStorageChange = () => {
      const storedUserData = JSON.parse(localStorage.getItem("numerologyData"));
      if (storedUserData) {
        setMulank(storedUserData.mulank);
        setBhagyank(storedUserData.bhagyank);
        setLuckyNumber(storedUserData.luckyNumber);
        setMobile(storedUserData.U_MobileNumber || ""); // Retrieve Mobile Number
        setDate(storedUserData.U_DOB);
        setGrid(storedUserData.loShuGrid); // Update the grid
        setKarmicNumbers(storedUserData.karmicNumbers || []);
        setMasterNumbers(storedUserData.masterNumbers || []);
        setPersonalDay(storedUserData.personalDay); // Update personalDay
        setPersonalMonth(storedUserData.personalMonth); // Update personalMonth
        setPersonalYear(storedUserData.personalYear); // Update personalYear
        setHouseNumberResult(storedUserData.houseNumberResult || null); // Added
        setVehicleNumberResult(storedUserData.vehicleNumberResult || null); // Added
      }
    };

    window.addEventListener("storage", handleStorageChange);
    return () => window.removeEventListener("storage", handleStorageChange);
  }, []);

  // Fetch Numerology Data
  const fetchNumerologyData = async () => {
    try {
      setLoading(true); // Start loading
      const response = await fetch(
        `https://vpandit.caxis.ca/public/api/numerologies/${topc_no}/${myValue}`
      );

      if (response.ok) {
        const data = await response.json();
        console.log("Numerology Data:", data);
        setNumerologyData(data);
      } else {
        console.warn("Numerology API returned 404 or another error");
        setNumerologyData([]); // Set empty if no data
      }
    } catch (error) {
      console.error("Error fetching numerology data:", error);
      setNumerologyData([]); // Set empty on error
    } finally {
      setLoading(false); // Stop loading
    }
  };


  // Fetch Master Number Data (same topic_no, but myValue = 0)
  const fetchMasterNumberData = async () => {
    try {
      const response = await fetch(
        `https://vpandit.caxis.ca/public/api/numerologies/${topc_no}/0`
      );

      if (response.ok) {
        const data = await response.json();
        console.log("Master Number Data:", data);
        setMasterNumberData(data);
      } else {
        console.warn("Master Number API returned 404 or another error");
        setMasterNumberData([]);
      }
    } catch (error) {
      console.error("Error fetching master number data:", error);
      setMasterNumberData([]);
    }
  };


  // Fetch Numerology Data for Comparisons
  const fetchNumcomDataForComparisons = async () => {
    setIsComparisonLoading(true);

    // Define a simplified mapping for comparisons based on the selected 'value'
    let comparisonsToFetch = [];

    if (value === "Mulank") {
      comparisonsToFetch = [
        { value1: mulank, value2: bhagyank, },
        { value1: mulank, value2: luckyNumber, },

      ];
    } else if (value === "Bhagyank") {
      comparisonsToFetch = [
        { value1: bhagyank, value2: mulank, },
        { value1: bhagyank, value2: luckyNumber, },

      ];
    } else if (value === "Lucky Number") {
      comparisonsToFetch = [
        { value1: luckyNumber, value2: mulank, },
        { value1: luckyNumber, value2: bhagyank, },

      ];
    } else if (value === "Personal Day") {
      comparisonsToFetch = [
        { value1: personalDay, value2: mulank, },
        { value1: personalDay, value2: bhagyank, },
        { value1: personalDay, value2: luckyNumber, },

      ];
    } else if (value === "Personal Month Number") {
      comparisonsToFetch = [
        { value1: personalMonth, value2: mulank, },
        { value1: personalMonth, value2: bhagyank, },
        { value1: personalMonth, value2: luckyNumber, },
      ];
    } else if (value === "Personal Year Number") {
      comparisonsToFetch = [
        { value1: personalYear, value2: mulank, },
        { value1: personalYear, value2: bhagyank, },
        { value1: personalYear, value2: luckyNumber, },
      ];
    } else if (value === "House Number") {
      comparisonsToFetch = [
        { value1: houseNumberResult, value2: mulank, },
        { value1: houseNumberResult, value2: bhagyank, },
        { value1: houseNumberResult, value2: luckyNumber, },
      ];
    } else if (value === "Vehicle Number") {
      comparisonsToFetch = [
        { value1: vehicleNumberResult, value2: mulank, },
        { value1: vehicleNumberResult, value2: bhagyank, },
        { value1: vehicleNumberResult, value2: luckyNumber, },
      ];
    } else if (value === "Mobile Number") {
      comparisonsToFetch = [
        { value1: mobile, value2: mulank, },
        { value1: mobile, value2: bhagyank, },
        { value1: mobile, value2: luckyNumber, },
      ];
    }

    let allComparisonData = [];

    try {
      // Loop through each comparison and fetch the data
      for (const { value1, value2, label } of comparisonsToFetch) {
        if (value1 && value2) {
          const response = await fetch(
            `https://vpandit.caxis.ca/public/api/numcom/${value1}/${value2}`
          );
          const data = await response.json();
          if (data && data.length > 0) {
            allComparisonData.push({ data: data });
          }
        }
      }
      setNumcomData(allComparisonData);
    } catch (error) {
      console.error("Error fetching comparison data:", error);
    } finally {
      setIsComparisonLoading(false);
    }
  };

  useEffect(() => {
    fetchNumerologyData();
  }, []);

  // Call the fetch functions on changes to topicno and myValue
  useEffect(() => {
    if (topc_no && myValue && value) {

      fetchNumcomDataForComparisons();
      if (value === "Master Numbers") {
        fetchMasterNumberData();
      }
    }
  }, [topc_no, myValue, mulank, bhagyank, luckyNumber, personalDay, personalMonth, personalYear, mobile]);

  const fetchComparisonData = async (inputValue, valueType, setComparisonState) => {
    setIsSixValueLoading(true); // Set loading state for all comparisons

    const currentYear = new Date().getFullYear();
    const currentYearSum = String(currentYear)
      .split("")
      .reduce((sum, num) => sum + parseInt(num), 0);

    let value1 = mulank;
    let value2 = bhagyank;
    let value3 = currentYearSum;
    let value4 = 0;
    let value5 = 0;
    let value6 = 0;

    if (valueType === "Personal Year") {
      value4 = personalYear;
    } else if (valueType === "Personal Month") {
      value4 = personalYear;
      value5 = personalMonth;
    } else if (valueType === "Personal Day") {
      value4 = personalYear;
      value5 = personalMonth;
      value6 = personalDay;
    }

    // Six value comparison API calling....
    try {
      const url = `https://vpandit.caxis.ca/public/api/personalnumcom/${value1}/${value2}/${value3}/${value4}/${value5}/${value6}`;
      const response = await fetch(url);
      const data = await response.json();

      if (data && data.length > 0) {
        setComparisonState(data); // Update the corresponding state
      } else {
        console.warn(`No ${valueType} comparison data found.`);
        setComparisonState([]); // Ensure empty state for no data
      }
    } catch (error) {
      console.error(`Error fetching ${valueType} comparison data:`, error);
    } finally {
      setIsSixValueLoading(false); // End loading state
    }
  };

  // Call the function in useEffect when values change
  useEffect(() => {
    if (value === "Personal Year Number" || value === "Personal Month Number" || value === "Personal Day") {
      if (personalYear && mulank && bhagyank) {
        fetchComparisonData(personalYear, "Personal Year", setPersonalYearComparison);
      }
      if (personalMonth && mulank && bhagyank) {
        fetchComparisonData(personalMonth, "Personal Month", setPersonalMonthComparison);
      }
      if (personalDay && mulank && bhagyank) {
        fetchComparisonData(personalDay, "Personal Day", setPersonalDayComparison);
      }
    }
  }, [personalYear, personalMonth, personalDay, mulank, bhagyank]);

  const { value } = useParams();

  const renderDescDetails = (descDetails) => {
    try {
      if (descDetails) {
        // Remove extra spaces, line breaks, and keep only one space
        const cleanedDetails = descDetails.replace(/\s+/g, ' ').trim();

        return (
          <p
            className="text-gray-700 leading-relaxed mb-6 overflow-hidden break-words"
            style={{ wordBreak: "break-word", whiteSpace: "normal", overflowWrap: "break-word" }}
          >
            {HTMLReactParser(cleanedDetails)}
          </p>
        );
      }
    } catch (error) {
      console.error("Error rendering desc_details:", error);
      return (
        <p
          className="text-gray-700 leading-relaxed mb-6 overflow-hidden break-words"
          style={{ wordBreak: "break-word", whiteSpace: "normal", overflowWrap: "break-word" }}
        >
          {descDetails.replace(/\s+/g, ' ').trim()}
        </p>
      );
    }
    return null;
  };

  return (
    <>
      <div className="container mx-auto px-4 py-8">
        <nav className="flex items-center gap-2 text-sm mb-6">
          <Link
            to="/"
            className="text-orange-500 hover:text-orange-600 font-bold"
          >
            Home
          </Link>
          <span className="text-gray-400">{">"}</span>
          <Link
            to="/numerology"
            className="text-orange-500 hover:text-orange-600 font-bold"
          >
            Numerology
          </Link>
          <span className="text-gray-400">{">"}</span>
          <span className="text-gray-600 font-bold">{value}</span>
        </nav>

        {value === "Lo Shu Grid" ? (
          <div style={{ padding: "20px", fontFamily: "Arial, sans-serif", textAlign: "center" }}>
            <h2 className="text-3xl font-bold mt-[-20px] text-start">Lo Shu Grid Calculator:</h2>

            {/* Check if grid is a valid 2D array */}
            {Array.isArray(grid) && grid.length > 0 && grid.every(row => Array.isArray(row)) ? (
              <div style={{ marginTop: "20px", display: "inline-block" }}>
                <table
                  style={{
                    borderCollapse: "collapse",
                    margin: "auto",
                  }}
                >
                  <tbody>
                    {grid.map((row, rowIndex) => (
                      <tr key={rowIndex}>
                        {row.map((cell, colIndex) => {
                          // Define background colors for each cell position
                          const backgroundColors = {
                            "0,0": "purple",
                            "0,1": "red",
                            "0,2": "pink",
                            "1,0": "green",
                            "1,1": "orange",
                            "1,2": "gray",
                            "2,0": "white",
                            "2,1": "black",
                            "2,2": "blue",
                          };

                          const cellPosition = `${rowIndex},${colIndex}`;
                          const cellBackgroundColor = backgroundColors[cellPosition] || "transparent";

                          return (
                            <td
                              key={colIndex}
                              style={{
                                border: "2px solid #ccc",
                                width: "100px", // Increased width
                                height: "100px", // Increased height
                                backgroundColor: cellBackgroundColor, // Apply background color
                                color: cellBackgroundColor === "black" ? "white" : cellBackgroundColor === "white" ? "black" : "white", // Adjust text color
                                fontSize: "54px", // Further increased font size
                                fontWeight: 800, // Bold numbers
                                textAlign: "center",
                                verticalAlign: "middle", // Center text vertically
                              }}
                            >
                              {cell}
                            </td>
                          );
                        })}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <p style={{ marginTop: "20px", color: "red" }}>
                Grid data is invalid or not available.
              </p>
            )}

            {/* Balanced Numbers */}
            <div className="p-6 bg-gray-50 rounded-lg shadow-lg text-start">
              {/* Render Loading State */}
              {isLoading ? (
                <div className="flex justify-center items-center">
                  <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 border-t-4 border-orange-600 rounded-md" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              ) : (
                <>
                  {/* Render Balanced Numbers */}
                  <section className="mb-8">
                    {/* <h3 className="text-2xl font-semibold text-gray-800 mb-4">Balanced Numbers</h3> */}
                    {apiData.balanced && apiData.balanced.length > 0 ? (
                      apiData.balanced.map((item, index) => (
                        <div key={index} className="bg-white p-4 mb-4 rounded-lg shadow-sm">
                          {/* <h4 className="text-xl font-medium text-orange-600">Number: {item.Value1}</h4> */}
                          <p className="text-gray-800 font-bold mb-3">{item.desc_title}</p>
                          <p className="text-gray-800">{HTMLReactParser(item.desc_details)}</p>
                        </div>
                      ))
                    ) : (
                      <p className="text-gray-500 italic">
                        {/* No balanced numbers data available */}
                      </p>
                    )}
                  </section>

                  {/* Render Unbalanced Numbers */}
                  <section className="mb-8">
                    {/* <h3 className="text-2xl font-semibold text-gray-800 mb-4">Unbalanced Numbers</h3> */}
                    {apiData.unbalanced && apiData.unbalanced.length > 0 ? (
                      apiData.unbalanced.map((item, index) => (
                        <div key={index} className="bg-white p-4 mb-4 rounded-lg shadow-sm">
                          {/* <h4 className="text-xl font-medium text-orange-600">Number: {item.Value1}</h4> */}
                          <p className="text-gray-800 font-bold mb-3"> {item.desc_title}</p>
                          <p className="text-gray-800">{HTMLReactParser(item.desc_details)}</p>
                        </div>
                      ))
                    ) : (
                      <p className="text-gray-500 italic">
                        {/* No unbalanced numbers data available */}
                      </p>
                    )}
                  </section>

                  {/* Render Missing Numbers */}
                  <section>
                    {/* <h3 className="text-2xl font-semibold text-gray-800 mb-4">Missing Numbers</h3> */}
                    {apiData.missing && apiData.missing.length > 0 ? (
                      apiData.missing.map((item, index) => (
                        <div key={index} className="bg-white p-4 mb-4 rounded-lg shadow-sm">
                          {/* <h4 className="text-xl font-medium text-orange-600">Number: {item.Value1}</h4> */}
                          <p className="text-gray-800 font-bold mb-3"> {item.desc_title}</p>
                          <p className="text-gray-800">{HTMLReactParser(item.desc_details)}</p>
                        </div>
                      ))
                    ) : (
                      <p className="text-gray-500 italic">
                        {/* No missing numbers data available */}
                      </p>
                    )}
                  </section>
                </>
              )}
            </div>

          </div>
        ) : value === "Master Numbers" ? (
          <div>
            {/* Loading Spinner */}
            {loading ? (
              <div className="flex justify-center items-center">
                <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 border-t-4 border-orange-500 rounded-md" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              <>

                {/* Show Master Number Data */}
                {masterNumberData && masterNumberData.length > 0 && masterNumberData.map((item, key) => (
                  <div key={key} className="mb-10 text-start">
                    <h2 className="text-2xl font-bold text-red-600 mb-4">{renderDescDetails(item.desc_title)}</h2>
                    <p className="text-gray-700 leading-relaxed">{renderDescDetails(item.desc_details)}</p>
                  </div>
                ))}
                {/* Show Normal Numerology Data */}
                {numerologyData && numerologyData.length > 0 && numerologyData.map((item, key) => (
                  <div key={key} className="mb-10 text-start">
                    <h2 className="text-2xl font-bold text-gray-900 mb-4">{renderDescDetails(item.desc_title)}</h2>
                    <p className="text-gray-700 leading-relaxed">{renderDescDetails(item.desc_details)}</p>
                  </div>
                ))}


              </>
            )}
          </div>
        ) : value === "Karmic Numbers" ? (
          <div className="mt-10">

            {loading ? (
              <div className="flex justify-center items-center">
                <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 border-t-4 border-orange-500 rounded-md" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : numerologyData && numerologyData.length > 0 ? (
              <>
                {/* Render Master Numbers Data */}
                {numerologyData.map((item, key) => (
                  <div key={key} className="mb-10 text-start">
                    <h2 className="text-2xl font-bold text-gray-900 mb-4">{renderDescDetails(item.desc_title)}</h2>
                    <p className="text-gray-700 leading-relaxed">{renderDescDetails(item.desc_details)}</p>
                  </div>
                ))}
                {/*  <hr className="my-6 border-gray-300" />*/}
              </>
            ) : (
              <p className="text-start text-gray-800">
                {/* No numerology data available */}
              </p>
            )}

          </div>
        ) : value === "Mobile Number" ? (
          <div className="container mx-auto min-h-screen">
            <div className="mx-auto px-4 sm:px-6 lg:px-8 mt-[-20px]">
              <div className="bg-white rounded-2xl p-8">
                <div className="max-w-full mx-auto text-center mb-12">
                  <h1 className="text-start text-3xl sm:text-4xl font-bold text-gray-900 mb-4">
                    Mobile Number Numerology
                  </h1>
                </div>

                {mobileNumber.length === 10 ? (
                  <>
                    <div className="rounded-xl p-6 mx-auto mt-[-37px]">
                      <div className="max-w-3xl mx-auto">
                        <h2 className="text-center text-xl sm:text-2xl font-semibold text-gray-900">
                          Mobile Number: {mobileNumber}
                        </h2>
                      </div>
                      <div className="grid grid-cols-1 sm:grid-cols-2 gap-8 mb-4 mt-12 mx-auto">
                        <div className="bg-gradient-to-br from-orange-400 to-orange-500 rounded-2xl p-8 text-white">
                          <h2 className="text-xl font-semibold text-center">Total Sum</h2>
                          <p className="text-4xl font-bold text-center">{totalSum}</p>
                        </div>
                        <div className="bg-gradient-to-br from-gray-500 to-gray-700 rounded-2xl p-8 text-white">
                          <h2 className="text-xl font-semibold text-center">Total Weightage</h2>
                          <p className="text-4xl font-bold text-center">{totalWeightage}</p>
                        </div>
                      </div>
                    </div>

                    <div className="space-y-8">
                      <h2 className="text-2xl sm:text-3xl font-bold text-gray-900 text-center">
                        Number Pairs Analysis
                      </h2>

                      <div className="flex flex-wrap justify-center gap-4 mb-2">
                        {pairs.map((pair, index) => (
                          <div
                            key={index}
                            onClick={() => setSelectedPair(pair)}
                            className={`px-6 py-3 rounded-lg cursor-pointer transition-all duration-300 ${selectedPair === pair
                              ? "bg-orange-500 text-white shadow-lg"
                              : "bg-orange-300 text-orange-700 hover:bg-orange-100"
                              }`}
                          >
                            {pair}
                          </div>
                        ))}
                      </div>

                      {selectedPair ? (
                        <div className="max-w-3xl mx-auto">
                          {(() => {
                            const info = MobileNumerologyData.find((item) => item.Value.toString() === selectedPair);
                            if (!info) {
                              return (
                                <p className="text-xl text-gray-800 text-center">
                                  No data available for {selectedPair}
                                </p>
                              );
                            }

                            return (
                              <div className="bg-white rounded-2xl shadow-lg p-8 transition-all duration-300">
                                <h3 className="text-2xl font-bold text-gray-900 text-center">
                                  Pair: {selectedPair}
                                </h3>
                                {info.Pros && info.Pros.length > 0 && info.Pros.some((pro) => pro !== null) && (
                                  <div className="mb-6 bg-green-100 rounded-xl p-6">
                                    <h4 className="text-xl font-semibold text-green-800">Pros:</h4>
                                    <ul className="text-green-700 text-lg list-disc pl-4">
                                      {info.Pros.filter((pro) => pro !== null).map((pro, i) => (
                                        <li className="list-none" key={i}>
                                          {pro}
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                )}
                                {info.Cons && info.Cons.length > 0 && info.Cons.some((con) => con !== null) && (
                                  <div className="bg-red-50 rounded-xl p-6">
                                    <h4 className="text-xl font-semibold text-red-800 mb-2">Cons:</h4>
                                    <ul className="text-red-700 text-lg list-disc pl-4">
                                      {info.Cons.filter((con) => con !== null).map((con, i) => (
                                        <li className="list-none" key={i}>
                                          {con}
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                )}
                                {info.Remedies && info.Remedies.length > 0 && (
                                  <div className="bg-blue-50 rounded-xl p-6 mt-4">
                                    <h4 className="text-xl font-semibold text-blue-800 mb-2">Remedies:</h4>
                                    <ul className="text-blue-700 text-lg list-disc pl-4">
                                      {info.Remedies.map((remedy, i) => (
                                        <li className="list-none" key={i}>
                                          {remedy}
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                )}
                              </div>
                            );
                          })()}
                        </div>
                      ) : (
                        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
                          {pairs.map((pair, index) => {
                            const matchedData = MobileNumerologyData.find(
                              (item) => item.Value.toString() === pair
                            );

                            if (!matchedData) return null;

                            return (
                              <div
                                key={index}
                                className="h-auto bg-white rounded-2xl shadow-lg p-6 hover:shadow-xl transition-all duration-300"
                              >
                                <div className="bg-orange-200 rounded-full w-12 h-12 flex items-center justify-center mb-4 mx-auto">
                                  <span className="text-orange-600 font-semibold">{pair}</span>
                                </div>

                                {matchedData.Pros && matchedData.Pros.length > 0 && matchedData.Pros.some((pro) => pro !== null) && (
                                  <div className="mb-4 mt-2">
                                    <h4 className="text-xl font-semibold text-green-900">Pros:</h4>
                                    <ul className="text-green-800 text-lg list-disc pl-4">
                                      {matchedData.Pros.filter((pro) => pro !== null).map((pro, i) => (
                                        <li className="list-none" key={i}>
                                          {pro}
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                )}

                                {matchedData.Cons && matchedData.Cons.length > 0 && matchedData.Cons.some((con) => con !== null) && (
                                  <div>
                                    <h4 className="text-xl font-semibold text-red-800 mb-2 mt-2">Cons:</h4>
                                    <ul className="text-red-700 text-lg list-disc pl-4">
                                      {matchedData.Cons.filter((con) => con !== null).map((con, i) => (
                                        <li className="list-none" key={i}>
                                          {con}
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                )}

                                {matchedData.Remedies && matchedData.Remedies.length > 0 && (
                                  <div className="mt-2">
                                    <h4 className="text-xl font-semibold text-blue-800 mb-2">Remedies:</h4>
                                    <ul className="text-blue-700 text-lg list-disc pl-4">
                                      {matchedData.Remedies.map((remedy, i) => (
                                        <li className="list-none" key={i}>
                                          {remedy}
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                )}
                              </div>
                            );
                          })}
                        </div>
                      )}

                      {isComparisonLoading ? (
                        <div className="flex justify-center items-center">
                          <div
                            className="spinner-border animate-spin inline-block w-8 h-8 border-4 border-t-4 border-orange-500 rounded-md"
                            role="status"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        </div>
                      ) : numcomData && numcomData.length > 0 ? (
                        <>
                          <h2 className="text-3xl font-bold text-gray-900 mb-6 text-center"></h2>
                          {numcomData.map((item, key) => (
                            <div key={key} className="mb-10 text-start">
                              <p className="text-gray-700 leading-relaxed mb-6">
                                {HTMLReactParser(item.data[0]?.desc_details)}
                              </p>
                            </div>
                          ))}
                        </>
                      ) : (
                        <p className="text-start text-xl text-gray-800">
                          {/* No comparison data available */}
                        </p>
                      )}
                    </div>
                  </>
                ) : (
                  <p className="text-center text-gray-800">Please provide a valid 10-digit mobile number.</p>
                )}
              </div>
            </div>
          </div>
        ) : value === "Health Number" ? (
          <div>
            {/* Check if value is "Health Number" */}
            {value === "Health Number" && (
              <div>
                {/* Check if data is loading */}
                {isLoading ? (
                  <div className="flex justify-center items-center mt-4">
                    <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 border-t-4 border-orange-500 rounded-md" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                ) : (
                  // Check if matching data is found
                  matchingData ? (
                    <div style={{ padding: "20px", fontFamily: "Arial, sans-serif", textAlign: "center" }}>
                      <h2 className="text-3xl font-bold mt-[-20px] text-start">{matchingData.title}</h2>
                      <p>{HTMLReactParser(matchingData.description)}</p>
                      <div>
                        {/* <p><strong>Health Numerology Insights for Mulank {matchingData.mulank}</strong></p> */}
                        {/* Add other fields you want to display */}
                      </div>
                      {numerologyData && numerologyData.length > 0 ? (
                        <>
                          {numerologyData.map((item, key) => (
                            <div key={key} className="mb-10">
                              <h2 className="text-2xl font-bold text-start text-gray-900 mb-4">{renderDescDetails(item.desc_title)}</h2>
                              <p className="text-gray-700 text-start leading-relaxed">{renderDescDetails(item.desc_details)}</p>
                            </div>
                          ))}
                          {/*  <hr className="my-6 border-gray-300" />*/}
                        </>
                      ) : (
                        <p className="text-start text-gray-800">
                          {/* No numerology data available */}
                        </p>
                      )}
                    </div>
                  ) : (
                    <p className="text-center text-gray-800">
                      {/* No matching health numerology data found for your numerology profile. */}
                    </p>
                  )
                )}
              </div>

            )}
          </div>
        ) : value === "Numbers Pyramid" ? (
          <div className="pyramid-wrapper">
            {date ? (
              (() => {
                const [year, month, day] = date.split("-").map(Number);
                const reducedMonth = calculateNumber(month);
                const reducedDay = calculateNumber(day);
                const reducedYear = calculateNumber(year);

                const pyramidSteps = [];
                pyramidSteps.push([reducedMonth, reducedDay, reducedYear]); // 🔹 Month first!

                while (pyramidSteps[pyramidSteps.length - 1].length > 1) {
                  const prevRow = pyramidSteps[pyramidSteps.length - 1];
                  const newRow = [];
                  for (let i = 0; i < prevRow.length - 1; i++) {
                    newRow.push(Math.abs(prevRow[i + 1] - prevRow[i]));
                  }
                  pyramidSteps.push(newRow);
                }

                return (
                  <div className="pyramid-container">
                    {pyramidSteps.map((row, rowIndex) => ( // 🔹 Reverse to form correct pyramid
                      <div key={rowIndex} className="pyramid-row">
                        {row.map((num, colIndex) => (
                          <span key={colIndex} className="pyramid-number">{num}</span>
                        ))}
                      </div>
                    ))}
                  </div>
                );
              })()
            ) : (
              <p className="text-center text-gray-700"></p>
            )}

            {/* API Matching Data Display */}

            <div className="bg-white rounded-lg mt-6">
              {isLoading ? (
                <p className="spinner-border animate-spin inline-block w-8 h-8 border-4 border-t-4 border-orange-500 rounded-md" role="status"></p>
              ) : pyramidData.length > 0 ? (
                pyramidData.map((item, index) => (
                  <div key={index}>
                    <h3>{item.desc_title}</h3> {/* Display title */}
                    <p>{item.desc_details}</p> {/* Display details */}
                  </div>
                ))
              ) : (
                <p className="text-center text-gray-500"></p>
              )}
            </div>
          </div>

        ) : value === "Mulank" ? (
          <div>
            {/* Show Spinner for Either Loading State */}
            {(loading || isComparisonLoading) ? (
              <div className="flex justify-center items-center mt-4">
                <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 border-t-4 border-orange-500 rounded-md" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              <>
                {/* Numerology Data */}
                {numerologyData && numerologyData.length > 0 ? (
                  <>
                    {numerologyData.map((item, key) => (
                      <div key={key} className="mb-10">
                        <h2 className="text-2xl font-bold text-gray-900 mb-4">{renderDescDetails(item.desc_title)}</h2>
                        <p className="text-gray-700 leading-relaxed">{renderDescDetails(item.desc_details)}</p>
                      </div>
                    ))}
                    {/*  <hr className="my-6 border-gray-300" />*/}
                  </>
                ) : (
                  <p className="text-start text-gray-800">
                    {/* No numerology data available */}
                  </p>
                )}

                {numcomData && numcomData.length > 0 ? (
                  <>
                    <h2 className="text-3xl font-bold text-gray-900 mb-6 text-center">

                    </h2>
                    {numcomData.map((item, key) => (
                      <div key={key} className="mb-10">
                        {/* <h3 className="text-2xl font-bold text-gray-900 mb-4">{item.label}</h3> */}
                        <p>{item.data[0]?.desc_title}</p>
                        <p className="text-gray-700 leading-relaxed mb-6">{HTMLReactParser(item.data[0]?.desc_details)}</p>
                        {/*<hr className="mt-4 border-gray-300" /> */}
                      </div>
                    ))}
                  </>
                ) : (
                  <p className="text-start text-gray-800">
                    {/* No comparison data available */}
                  </p>
                )}
              </>
            )}
          </div>

        ) : value === "Bhagyank" ? (
          <div>
            {/* Show Spinner for Either Loading State */}
            {(loading || isComparisonLoading) ? (
              <div className="flex justify-center items-center mt-4">
                <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 border-t-4 border-orange-500 rounded-md" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              <>
                {/* Numerology Data */}
                {numerologyData && numerologyData.length > 0 ? (
                  <>
                    {numerologyData.map((item, key) => (
                      <div key={key} className="mb-10">
                        <h2 className="text-2xl font-bold text-gray-900 mb-4">{renderDescDetails(item.desc_title)}</h2>
                        <p className="text-gray-700 leading-relaxed">{renderDescDetails(item.desc_details)}</p>
                      </div>
                    ))}
                    {/*  <hr className="my-6 border-gray-300" />*/}
                  </>
                ) : (
                  <p className="text-start text-gray-800">
                    {/* No numerology data available */}
                  </p>
                )}


                {numcomData && numcomData.length > 0 ? (
                  <>
                    <h2 className="text-3xl font-bold text-gray-900 mb-6 text-center">

                    </h2>
                    {numcomData.map((item, key) => (
                      <div key={key} className="mb-10">
                        {/* <h3 className="text-2xl font-bold text-gray-900 mb-4">{item.label}</h3> */}
                        <p>{item.data[0]?.desc_title}</p>
                        <p className="text-gray-700 leading-relaxed mb-6">{HTMLReactParser(item.data[0]?.desc_details)}</p>
                        {/*<hr className="mt-4 border-gray-300" /> */}
                      </div>
                    ))}
                  </>
                ) : (
                  <p className="text-start text-gray-800">
                    {/* No comparison data available */}
                  </p>
                )}
              </>
            )}
          </div>

        ) : value === "Lucky Number" ? (
          <div>
            {/* Show Spinner for Either Loading State */}
            {(loading || isComparisonLoading) ? (
              <div className="flex justify-center items-center mt-4">
                <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 border-t-4 border-orange-500 rounded-md" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              <>
                {/* Numerology Data */}
                {numerologyData && numerologyData.length > 0 ? (
                  <>
                    {numerologyData.map((item, key) => (
                      <div key={key} className="mb-10">
                        <h2 className="text-2xl font-bold text-gray-900 mb-4">{renderDescDetails(item.desc_title)}</h2>
                        <p className="text-gray-700 leading-relaxed">{renderDescDetails(item.desc_details)}</p>
                      </div>
                    ))}
                    {/*  <hr className="my-6 border-gray-300" />*/}
                  </>
                ) : (
                  <p className="text-start text-gray-800">
                    {/* No numerology data available */}
                  </p>
                )}


                {numcomData && numcomData.length > 0 ? (
                  <>
                    <h2 className="text-3xl font-bold text-gray-900 mb-6 text-center">

                    </h2>
                    {numcomData.map((item, key) => (
                      <div key={key} className="mb-10">
                        {/* <h3 className="text-2xl font-bold text-gray-900 mb-4">{item.label}</h3> */}
                        <p>{item.data[0]?.desc_title}</p>
                        <p className="text-gray-700 leading-relaxed mb-6">{HTMLReactParser(item.data[0]?.desc_details)}</p>
                        {/*<hr className="mt-4 border-gray-300" /> */}
                      </div>
                    ))}
                  </>
                ) : (
                  <p className="text-start text-gray-800">
                    {/* No comparison data available */}
                  </p>
                )}
              </>
            )}
          </div>

        ) : value === "Personal Day" ? (
          <div>
            {/* Show Single Spinner for Any Loading State */}
            {(loading || isComparisonLoading || isSixValueLoading) ? (
              <div className="flex justify-center items-center mt-4">
                <div
                  className="spinner-border animate-spin inline-block w-8 h-8 border-4 border-t-4 border-orange-500 rounded-md"
                  role="status"
                >
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              <>
                {/* Numerology Data */}
                {numerologyData && numerologyData.length > 0 ? (
                  <>
                    {numerologyData.map((item, key) => (
                      <div key={key} className="mb-10">
                        <h2 className="text-2xl font-bold text-gray-900 mb-4">
                          {renderDescDetails(item.desc_title)}
                        </h2>
                        <p className="text-gray-700 leading-relaxed">
                          {renderDescDetails(item.desc_details)}
                        </p>
                      </div>
                    ))}
                    {/*  <hr className="my-6 border-gray-300" />*/}
                  </>
                ) : (
                  <p className="text-start text-gray-800">
                    {/* No numerology data available */}
                  </p>
                )}

                {/* Comparison Data */}
                {numcomData && numcomData.length > 0 ? (
                  <>
                    <h2 className="text-3xl font-bold text-gray-900 mb-6 text-center"></h2>
                    {numcomData.map((item, key) => (
                      <div key={key} className="mb-10">
                        <p>{item.data[0]?.desc_title}</p>
                        <p className="text-gray-700 leading-relaxed mb-6">
                          {HTMLReactParser(item.data[0]?.desc_details)}
                        </p>
                        {/*<hr className="mt-4 border-gray-300" /> */}
                      </div>
                    ))}
                  </>
                ) : (
                  <p className="text-start text-gray-800">
                    {/* No comparison data available */}
                  </p>
                )}

                {/* Six-Value Comparison Data */}
                {personalDayComparison && personalDayComparison.length > 0 ? (
                  <ul>
                    {personalDayComparison.map((item, key) => (
                      <li key={key}>
                        <h3>{item.desc_title}</h3>
                        <p>{item.desc_details}</p>
                        {/* <p>
                          <strong>Values:</strong> {item.Value1}, {item.Value2},{" "}
                          {item.Value3}, {item.Value4}, {item.Value5}, {item.Value6}
                        </p> */}
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p className="text-start text-gray-800">
                    {/* No six-value comparison available. */}
                  </p>
                )}
              </>
            )}
          </div>

        ) : value === "Personal Month Number" ? (
          <div>
            {/* Show Single Spinner for Any Loading State */}
            {(loading || isComparisonLoading || isSixValueLoading) ? (
              <div className="flex justify-center items-center mt-4">
                <div
                  className="spinner-border animate-spin inline-block w-8 h-8 border-4 border-t-4 border-orange-500 rounded-md"
                  role="status"
                >
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              <>
                {/* Numerology Data */}
                {numerologyData && numerologyData.length > 0 ? (
                  <>
                    {numerologyData.map((item, key) => (
                      <div key={key} className="mb-10">
                        <h2 className="text-2xl font-bold text-gray-900 mb-4">
                          {renderDescDetails(item.desc_title)}
                        </h2>
                        <p className="text-gray-700 leading-relaxed">
                          {renderDescDetails(item.desc_details)}
                        </p>
                      </div>
                    ))}
                    {/*  <hr className="my-6 border-gray-300" />*/}
                  </>
                ) : (
                  <p className="text-start text-gray-800">
                    {/* No numerology data available */}
                  </p>
                )}

                {/* Comparison Data */}
                {numcomData && numcomData.length > 0 ? (
                  <>
                    <h2 className="text-3xl font-bold text-gray-900 mb-6 text-center"></h2>
                    {numcomData.map((item, key) => (
                      <div key={key} className="mb-10">
                        <p>{item.data[0]?.desc_title}</p>
                        <p className="text-gray-700 leading-relaxed mb-6">
                          {HTMLReactParser(item.data[0]?.desc_details)}
                        </p>
                        {/*<hr className="mt-4 border-gray-300" /> */}
                      </div>
                    ))}
                  </>
                ) : (
                  <p className="text-start text-gray-800">
                    {/* No comparison data available */}
                  </p>
                )}

                {/* Six-Value Comparison Data */}
                {personalMonthComparison && personalMonthComparison.length > 0 ? (
                  <ul>
                    {personalMonthComparison.map((item, key) => (
                      <li key={key}>
                        <h3>{item.desc_title}</h3>
                        <p>{item.desc_details}</p>
                        {/* <p>
                        <strong>Values:</strong> {item.Value1}, {item.Value2},{" "}
                        {item.Value3}, {item.Value4}, {item.Value5}, {item.Value6}
                      </p> */}
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p className="text-start text-gray-800">
                    {/* No six-value comparison available. */}
                  </p>
                )}
              </>
            )}
          </div>

        ) : value === "Personal Year Number" ? (
          <div>
            {/* Show Single Spinner for Any Loading State */}
            {(loading || isComparisonLoading || isSixValueLoading) ? (
              <div className="flex justify-center items-center mt-4">
                <div
                  className="spinner-border animate-spin inline-block w-8 h-8 border-4 border-t-4 border-orange-500 rounded-md"
                  role="status"
                >
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              <>
                {/* Numerology Data */}
                {numerologyData && numerologyData.length > 0 ? (
                  <>
                    {numerologyData.map((item, key) => (
                      <div key={key} className="mb-10">
                        <h2 className="text-2xl font-bold text-gray-900 mb-4">
                          {renderDescDetails(item.desc_title)}
                        </h2>
                        <p className="text-gray-700 leading-relaxed">
                          {renderDescDetails(item.desc_details)}
                        </p>
                      </div>
                    ))}
                    {/*  <hr className="my-6 border-gray-300" />*/}
                  </>
                ) : (
                  <p className="text-start text-gray-800">
                    {/* No numerology data available */}
                  </p>
                )}

                {/* Comparison Data */}
                {numcomData && numcomData.length > 0 ? (
                  <>
                    <h2 className="text-3xl font-bold text-gray-900 mb-6 text-center"></h2>
                    {numcomData.map((item, key) => (
                      <div key={key} className="mb-10">
                        <p>{item.data[0]?.desc_title}</p>
                        <p className="text-gray-700 leading-relaxed mb-6">
                          {HTMLReactParser(item.data[0]?.desc_details)}
                        </p>
                        {/*<hr className="mt-4 border-gray-300" /> */}
                      </div>
                    ))}
                  </>
                ) : (
                  <p className="text-start text-gray-800">
                    {/* No comparison data available */}
                  </p>
                )}

                {/* Six-Value Comparison Data */}
                {personalYearComparison && personalYearComparison.length > 0 ? (
                  <ul>
                    {personalYearComparison.map((item, key) => (
                      <li key={key}>
                        <h3>{item.desc_title}</h3>
                        <p>{item.desc_details}</p>
                        {/* <p>
                          <strong>Values:</strong> {item.Value1}, {item.Value2},{" "}
                          {item.Value3}, {item.Value4}, {item.Value5}, {item.Value6}
                        </p> */}
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p className="text-start text-gray-800">
                    {/* No six-value comparison available. */}
                  </p>
                )}
              </>
            )}
          </div>

        ) : value === "House Number" ? (
          <div>
            {/* Show Spinner for Either Loading State */}
            {(loading || isComparisonLoading) ? (
              <div className="flex justify-center items-center mt-4">
                <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 border-t-4 border-orange-500 rounded-md" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              <>
                {/* Numerology Data */}
                {numerologyData && numerologyData.length > 0 ? (
                  <>
                    {numerologyData.map((item, key) => (
                      <div key={key} className="mb-10">
                        <h2 className="text-2xl font-bold text-gray-900 mb-4">{renderDescDetails(item.desc_title)}</h2>
                        <p className="text-gray-700 leading-relaxed">{renderDescDetails(item.desc_details)}</p>
                      </div>
                    ))}
                    {/*  <hr className="my-6 border-gray-300" />*/}
                  </>
                ) : (
                  <p className="text-start text-gray-800">
                    {/* No numerology data available */}
                  </p>
                )}


                {numcomData && numcomData.length > 0 ? (
                  <>
                    <h2 className="text-3xl font-bold text-gray-900 mb-6 text-center">

                    </h2>
                    {numcomData.map((item, key) => (
                      <div key={key} className="mb-10">
                        {/* <h3 className="text-2xl font-bold text-gray-900 mb-4">{item.label}</h3> */}
                        <p>{item.data[0]?.desc_title}</p>
                        <p className="text-gray-700 leading-relaxed mb-6">{HTMLReactParser(item.data[0]?.desc_details)}</p>
                        {/*<hr className="mt-4 border-gray-300" /> */}
                      </div>
                    ))}
                  </>
                ) : (
                  <p className="text-start text-gray-800">
                    {/* No comparison data available */}
                  </p>
                )}
              </>
            )}
          </div>

        ) : value === "Vehicle Number" ? (
          <div>
            {/* Show Spinner for Either Loading State */}
            {(loading || isComparisonLoading) ? (
              <div className="flex justify-center items-center mt-4">
                <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 border-t-4 border-orange-500 rounded-md" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              <>
                {/* Numerology Data */}
                {numerologyData && numerologyData.length > 0 ? (
                  <>
                    {numerologyData.map((item, key) => (
                      <div key={key} className="mb-10">
                        <h2 className="text-2xl font-bold text-gray-900 mb-4">{renderDescDetails(item.desc_title)}</h2>
                        <p className="text-gray-700 leading-relaxed">{renderDescDetails(item.desc_details)}</p>
                      </div>
                    ))}
                    {/*  <hr className="my-6 border-gray-300" />*/}
                  </>
                ) : (
                  <p className="text-start text-gray-800">
                    {/* No numerology data available */}
                  </p>
                )}


                {numcomData && numcomData.length > 0 ? (
                  <>
                    <h2 className="text-3xl font-bold text-gray-900 mb-6 text-center">

                    </h2>
                    {numcomData.map((item, key) => (
                      <div key={key} className="mb-10">
                        {/* <h3 className="text-2xl font-bold text-gray-900 mb-4">{item.label}</h3> */}
                        <p>{item.data[0]?.desc_title}</p>
                        <p className="text-gray-700 leading-relaxed mb-6">{HTMLReactParser(item.data[0]?.desc_details)}</p>
                        {/*<hr className="mt-4 border-gray-300" /> */}
                      </div>
                    ))}
                  </>
                ) : (
                  <p className="text-start text-gray-800">
                    {/* No comparison data available */}
                  </p>
                )}
              </>
            )}
          </div>

        ) : (

          // this section not check any condition it is else part so take for name number 
          <section className="space-y-12">
            {/* Numerology Data Section */}
            {loading ? (
              <p className="text-center  spinner-border animate-spin inline-block w-8 h-8 border-4 border-t-4 border-orange-600 rounded-md"></p>
            ) : numerologyData && numerologyData.length > 0 ? (
              <>
                {numerologyData.map((item, key) => (
                  <div key={key} className="mb-10">
                    <h2 className="text-2xl font-bold text-gray-900 mb-4">{renderDescDetails(item.desc_title)}</h2>
                    <p className="text-gray-700 leading-relaxed">{renderDescDetails(item.desc_details)}</p>
                  </div>
                ))}
                {/*  <hr className="my-6 border-gray-300" />*/}
              </>
            ) : (
              <p className="text-start text-gray-800">
                {/* No numerology data available */}
              </p>
            )}

            {/* Numcom Data Section */}
            {isComparisonLoading ? (
              <p className="text-center spinner-border animate-spin inline-block w-8 h-8 border-4 border-t-4 border-orange-600 rounded-md">Loading comparison data...</p>
            ) : numcomData && numcomData.length > 0 ? (
              <>
                <h2 className="text-3xl font-bold text-gray-900 mb-6 text-center">

                </h2>
                {numcomData.map((item, key) => (
                  <div key={key} className="mb-10">
                    {/* <h3 className="text-2xl font-bold text-gray-900 mb-4">{item.label}</h3> */}
                    <p className="text-gray-700 leading-relaxed mb-6">{HTMLReactParser(item.data[0]?.desc_details)}</p> {/* Data for the comparison */}
                    {/*<hr className="mt-4 border-gray-300" /> */}
                  </div>
                ))}
              </>
            ) : (
              <p className="text-start text-gray-500">
                {/* No comparison data available */}
              </p>
            )
            }
          </section>

        )}
      </div>
    </>
  );
}

