const How_To_Chat = () => {
    return (
      <div className="container  py-12">
        <div className="mx-auto">
          {/* Header Section */}
          <div className=" mb-8">
            <h1 className="text-2xl font-bold text-gray-800 mb-2">
              How Chatting with an astrologer can help you?
            </h1>
            <p className="text-gray-600">Best online astrology consultation</p>
          </div>

          {/* Main Content */}
          <div className="space-y-6 text-gray-700">
            <p className="leading-relaxed">
              There's literally no point in overthinking about your concerns
              when the solution to them is just a call away. VPandit brings to
              you a pool of over 500+ astrologers with whom you can contact on
              chat and share with them your problems across all aspects of life
              including love, finance, Vastu, career, luck, marriage and so on.
              Simply put, life is a cocktail of highs and lows, and while we
              enjoy the good times, the bad times, though they teach us a lot,
              can also leave us worried, depressed and sad. Such times can also
              hamper the relationship we share with other people in our lives,
              and we are sure that you wouldn't want that to happen for
              yourself.
            </p>

            <p className="leading-relaxed">
              VPandit, with its exceptional astrology consultant services,
              caters to you the solutions to problems that you might be facing
              in the aforementioned aspects of life. Some problems in life are
              simply the consequence of astronomical factors that make it to our
              fate since the time we are born. To specify, there are some
              predefined dashas that each one of us has to go through in life.
              These dashas, like Shani dasha, Rahu dasha, Ketu Dasha, bring a
              spark of problems in your life. These problems can lead to lots of
              disturbance, wealth, relationship and other such woes. Thus it is
              in the best interest to take necessary actions through which such
              problems can be averted. And astrologers have such solutions for
              you.
            </p>

            <p className="leading-relaxed">
              At VPandit, we have a pool of learned astrologers with vast
              experience across various astrology domains like Vedic astrology,
              Numerology, Tarot reading, Reiki healing, Nandi astrology, Vastu
              and more. You can connect with these astrologers and chat with
              them and ask for solutions to your problems. Astrologers use their
              experience and astrological knowledge to guide you and help you
              with the best solutions.
            </p>

            <p className="leading-relaxed">
              Meanwhile, astrology is not only about solving problems. It also
              works in many other areas, like if you are planning a wedding and
              want to find the shubh muhurat for marriage, simply, chat with an
              astrologer and you will have your answer. Wondering what to name
              your baby or when to host the mundan ceremony for him/her? Expert
              astrologers consultation can help here too. Or simply if you are
              planning to buy a gemstone and wondering what gemstone is made for
              your rashi? In such circumstances too, chatting with the
              astrologer can help you find what gemstone would suit your current
              situation. VPandit provides you with the facility to chat with
              astrologer online free and paid in both ways. To find us online,
              you just need to search online chat with astrologer or free astro
              chat or chat with astrologer online free, talk to astrologer
              online, and chat with astrologer near me. The aim of our services
              is to give 100% satisfaction to the users. We offer you many free
              services as well like free kundali and match making.
            </p>
          </div>

          {/* FAQ Section */}
          <div className="mt-12">
            <h2 className="text-2xl font-bold text-gray-800 mb-8 ">
              Chat With Astrologer - FAQs
            </h2>

            <div className="space-y-6">
              <div>
                <h3 className="font-semibold text-gray-800 mb-2">
                  How can I connect with an astrologer on chat?
                </h3>
                <p className="text-gray-700">
                  To chat with astrologer online, you simply need to recharge
                  your wallet and find the 'Chat with Astrologer' section on our
                  app or website. Once you click on it, you can choose from
                  hundreds of astrologers you can chat with at your convenience.
                </p>
              </div>

              <div>
                <h3 className="font-semibold text-gray-800 mb-2">
                  Why do all the astrologers have such good ratings?
                </h3>
                <p className="text-gray-700">
                  All the astrologers who make it to the VPandit platform are
                  categorised across 3 stages. Stage 1 includes astrologers that
                  are highly rated by our customers because of their excellent
                  work. The Stage 2 astrologers are the ones whose ratings fall
                  below fixed criteria. And when that happens, we intentionally
                  remove their profile from the platform so that our customers
                  only get to consult from the top-rated astrologers (Stage 1
                  astrologers). These Stage 2 astrologers are then trained by
                  our expert astrologers, and if they do well during the
                  training, they are brought back on the platform, and if not,
                  they are moved to Stage 3 i.e. they are unrecruited.
                </p>
              </div>

              <div>
                <h3 className="font-semibold text-gray-800 mb-2">
                  Can I chat with the same astrologers I had connected with
                  earlier?
                </h3>
                <p className="text-gray-700">
                  Yes, you can chat with the same astrologers multiple times on
                  our platform. If you wish to chat to the same astrologer,
                  simply find the 'Order History' section on the app or the
                  website. Here you will find the list of all the purchases you
                  have made on VPandit including your previous chats.
                </p>
              </div>

              <div>
                <h3 className="font-semibold text-gray-800 mb-2">
                  Will my phone number be kept confidential?
                </h3>
                <p className="text-gray-700">
                  You really don't need to use your phone number to chat with
                  astrologer. Also, VPandit respects your privacy and as a
                  firm is obliged to keep your information well guarded so no
                  one could ever misuse it whatsoever. You can count on us.
                </p>
              </div>

              <div>
                <h3 className="font-semibold text-gray-800 mb-2">
                  How can I choose the best astrologer to get astrology
                  consultation?
                </h3>
                <p className="text-gray-700">
                  To get astrology consultation through the best astrologer,
                  simply watch out for the rating the other customers may have
                  given to the astrologers. Though we scrutinize all the
                  astrologers ourself and bring onboard only the best ones for
                  you, but as they say, the customer is always right.
                </p>
              </div>

              <div>
                <h3 className="font-semibold text-gray-800 mb-2">
                  Why some astrologers on the app are so expensive?
                </h3>
                <p className="text-gray-700">
                  VPandit promises the best services to its customers and thus
                  recruits well-experienced astrologers after thorough scrutiny.
                  Most of the astrologers we have onboard entertain an
                  experience of over 10+ years. This is to ensure the
                  predictions or any other information they share with you is
                  based on their broad knowledge, so you always receive accurate
                  information. Also, VPandit saves your precious time by
                  helping you consult expert astrologers from the comfort of
                  your home, thus saving your time and money. We maintain strict
                  standards and offer optimum quality. So in a nutshell, our
                  service is qualitative and not expensive.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  
  export default How_To_Chat
  
  