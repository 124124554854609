import React, { useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa";
import { AiOutlineArrowLeft } from "react-icons/ai";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css"; // Skeleton styling

const Add_Product = ({ id, data, onClose, vendorId }) => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedRow, setSelectedRow] = useState(null);
  const [inputValues, setInputValues] = useState({
    brand: "",
    quantity: "",
    price: "",
  });
  const [selectedProducts, setSelectedProducts] = useState([]); // To store selected products for PUT request

  const fetchProducts = async () => {
    try {
      const response = await fetch("http://127.0.0.1:8000/api/product-masters/");
      const result = await response.json();

      const mappedProducts = result.map((item) => ({
        Prd_Id: item.Prd_Id,   // Ensure Prd_Id is included
        description: item.Prd_Desc,
      }));

      setProducts(mappedProducts);
    } catch (error) {
      console.error("Error fetching products:", error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProducts();
    console.log(data);
  }, []);

  const handleAddClick = (index) => {
    setSelectedRow(index);
    setInputValues({
      brand: "",
      quantity: "",
      price: "",
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSave = () => {
    const productPayload = {
      Prd_Id: products[selectedRow].Prd_Id,
      Vendor_Id: vendorId,
      Prd_Brand: inputValues.brand,
      Prd_Qty: inputValues.quantity,
      Prd_Price: inputValues.price,
      Prd_Alt: inputValues.alt_qty || null, // Optional field
    };

    setSelectedProducts((prev) => [...prev, productPayload]);

    console.log("Product Payload:", productPayload);
    
    putRequest(productPayload);

    setSelectedRow(null);
    setInputValues({ brand: "", quantity: "", price: "" });
  };

  const putRequest = async (productPayload) => {
    try {
      const response = await fetch(
        "http://127.0.0.1:8000/api/prod-vendor-price-list",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(productPayload),
        }
      );
      const result = await response.json();

      if (response.ok) {
        console.log("Product updated successfully:", result);
      } else {
        console.error("Error updating product:", result);
      }
    } catch (error) {
      console.error("Error making PUT request:", error);
    }
  };

  return (
    <div className="bg-gray-100 min-h-screen">
      {/* Back Button */}
      <div
        className="bg-[#002538] text-white px-3 py-2 rounded hover:bg-gray-600 flex items-center gap-2 cursor-pointer w-auto mb-5"
        onClick={onClose}
      >
        <AiOutlineArrowLeft />
        <span>Back - {vendorId}</span>
      </div>

      <div className="bg-white shadow-lg rounded-lg p-5">
        {loading ? (
          <Skeleton count={5} />
        ) : (
          <div className="space-y-4">
            {products.map((product, index) => (
              <div
                key={index}
                className="p-2 border border-gray-200 rounded-lg"
              >
                <div className="flex justify-between items-center">
                  <p className="text-gray-700 font-medium">
                    {product.description}
                  </p>
                  <div
                    className="bg-[#002538] text-white px-3 py-2 rounded hover:bg-gray-600 flex items-center gap-2 cursor-pointer"
                    onClick={() => handleAddClick(index)}
                  >
                    <FaPlus />
                    <span>Add</span>
                  </div>
                </div>
                {selectedRow === index && (
                  <div className="mt-4 space-y-2">
                    <input
                      type="text"
                      name="brand"
                      value={inputValues.brand}
                      onChange={handleInputChange}
                      placeholder="Product Brand Name"
                      className="w-full border rounded p-2"
                    />
                    <input
                      type="number"
                      name="quantity"
                      value={inputValues.quantity}
                      onChange={handleInputChange}
                      placeholder="Product Quantity"
                      className="w-full border rounded p-2"
                    />
                    <input
                      type="number"
                      name="price"
                      value={inputValues.price}
                      onChange={handleInputChange}
                      placeholder="Product Price"
                      className="w-full border rounded p-2"
                    />
                    <button
                      className="bg-green-500 text-white px-3 py-2 rounded hover:bg-green-600"
                      onClick={handleSave}
                    >
                      Save
                    </button>
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Add_Product;
