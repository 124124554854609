import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import JoditEditor from "jodit-react"; // Jodit Editor import
import HTMLReactParser from "html-react-parser";
import DOMPurify from "dompurify"; // Import DOMPurify for sanitizing HTML

const AddNumerology = ({
  onClose,
  setNumerologyData,
  numerologyData,
  selectedData,
  isEditing,
}) => {
  const [formData, setFormData] = useState({
    topicNumber: "",
    numerologyNumber: "",
    title: "",
    description: "",
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const editor = useRef(null);
  const [content, setContent] = useState("");

  // Jodit Editor configuration
  const config = {
    readonly: false,
    uploader: {
      url: "https://xdsoft.net/jodit/finder/?action=fileUpload",
    },
    filebrowser: {
      ajax: {
        url: "https://xdsoft.net/jodit/finder/",
      },
    },
  };

  // Populate form data if editing
  useEffect(() => {
    if (isEditing && selectedData) {
      setFormData({
        topicNumber: selectedData.topicNumber || "",
        numerologyNumber: selectedData.numerologyNumber || "",
        title: selectedData.title || "",
        description: selectedData.description || "",
      });
      setContent(selectedData.description || "");
    }
  }, [isEditing, selectedData]);

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccess(null);

    const payload = {
      topic_no: formData.topicNumber,
      num_no: formData.numerologyNumber,
      desc_title: formData.title,
      desc_details: content, // Use content from JoditEditor
    };

    try {
      let response;
      if (isEditing) {
        response = await axios.put(
          `https://vpandit.caxis.ca/public/api/numerologies/${selectedData.numId}`,
          payload
        );
        setSuccess("Numerology updated successfully!");
        const updatedData = numerologyData.map((item) =>
          item.numId === selectedData.numId ? { ...item, ...formData } : item
        );
        setNumerologyData(updatedData);
      } else {
        response = await axios.post(
          "https://vpandit.caxis.ca/public/api/numerologies",
          payload
        );
        setSuccess("Numerology added successfully!");
        const newRecord = { ...formData, numId: response.data.Num_Id };
        setNumerologyData([...numerologyData, newRecord]);
      }
      setFormData({
        topicNumber: "",
        numerologyNumber: "",
        title: "",
        description: "",
      });
      setContent("");
      onClose();
    } catch (err) {
      console.error("Error:", err);
      setError(
        isEditing
          ? "Failed to update numerology. Please try again."
          : "Failed to add numerology. Please try again."
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="p-4 bg-white rounded-lg shadow-lg mt-6">
      <div className="flex mb-6">
        <h1 className="text-2xl font-bold text-orange-500">
          {isEditing ? "Edit Numerology" : "Add Numerology"}
        </h1>
      </div>

      {error && <p className="text-red-500 mb-4">{error}</p>}
      {success && <p className="text-green-500 mb-4">{success}</p>}

      <form onSubmit={handleSubmit}>
        <div className="mt-4">
          <label
            htmlFor="topicNumber"
            className="block text-sm font-medium text-gray-700"
          >
            Topic Number
          </label>
          <select
            id="topicNumber"
            name="topicNumber"
            value={formData.topicNumber}
            onChange={handleInputChange}
            required
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-orange-500 focus:border-orange-500 sm:text-sm"
          >
            <option value="">Select a number</option>
            {[...Array(99 )].map((_, i) => (
              <option key={i + 1} value={i + 1}>
                {i + 1}
              </option>
            ))}
          </select>
        </div>

        <div className="mt-4">
          <label
            htmlFor="numerologyNumber"
            className="block text-sm font-medium text-gray-700"
          >
            Numerology Number
          </label>
          <input
            type="number"
            id="numerologyNumber"
            name="numerologyNumber"
            value={formData.numerologyNumber}
            onChange={handleInputChange}
            required
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-orange-500 focus:border-orange-500 sm:text-sm"
          />
        </div>

        <div className="mt-4">
          <label
            htmlFor="title"
            className="block text-sm font-medium text-gray-700"
          >
            Title
          </label>
          <input
            type="text"
            id="title"
            name="title"
            value={formData.title}
            onChange={handleInputChange}
            required
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-orange-500 focus:border-orange-500 sm:text-sm"
          />
        </div>

        <div className="mt-4">
          <label
            htmlFor="description"
            className="block text-sm font-medium text-gray-700"
          >
            Description
          </label>
          <JoditEditor
            ref={editor}
            value={content}
            config={config}
            tabIndex={1}
            onBlur={(newContent) => {
              if (newContent !== content) {
                setContent(newContent);
              }
            }}
          />
        </div>

        <div className="mt-6 flex gap-4">
          <button
            type="submit"
            className="bg-orange-500 text-white px-4 py-2 rounded-full"
            disabled={loading}
          >
            {loading ? "Saving..." : isEditing ? "Update" : "Add"}
          </button>
          <button
            type="button"
            onClick={onClose}
            className="bg-gray-500 text-white px-4 py-2 rounded-full"
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddNumerology;
