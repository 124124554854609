import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import JoditEditor from "jodit-react";
import HTMLReactParser from "html-react-parser";
const AddHealthNumerology = ({
  onClose,
  setNumerologyData,
  numerologyData,
  selectedData,
  isEditing,
}) => {
  const [formData, setFormData] = useState({
    topic_no: "",
    gender: "",
    age: "",
    mulank: "",
    bhagyank: "",
    lucky: "",
    per_year: "",
    per_month: "",
    per_day: "",
    title: "",
    description: "",
  });

  const [loading, setLoading] = useState(false);

  // Populate form data if editing
  useEffect(() => {
    if (isEditing && selectedData) {
      setFormData({
        topic_no: selectedData.topicNumber || "",
        gender: selectedData.gender || "",
        age: selectedData.age || "",
        mulank: selectedData.numerologyNumber || "",
        bhagyank: selectedData.bhagyank || "",
        lucky: selectedData.luckyNumber || "",
        per_year: selectedData.personalYear || "",
        per_month: selectedData.personalMonth || "",
        per_day: selectedData.personalDay || "",
        title: selectedData.title || "",
        description: selectedData.description || "",
      });
    }
  }, [isEditing, selectedData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleDescriptionChange = (content) => {
    setFormData({
      ...formData,
      description: content,
    });
  };

  const showNotification = (type, message) => {
    Swal.fire({
      toast: true,
      position: "top-right",
      icon: type,
      title: message,
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const payload = {
      ...formData,
    };

    try {
      const url = isEditing
        ? `https://vpandit.caxis.ca/public/api/health-numerologies/${selectedData.numId}`
        : "https://vpandit.caxis.ca/public/api/health-numerologies";

      const method = isEditing ? "put" : "post";

      const response = await axios[method](url, payload, {
        headers: { "Content-Type": "application/json" },
      });

      const updatedData = isEditing
        ? numerologyData.map((item) =>
            item.numId === selectedData.numId ? { ...item, ...payload } : item
          )
        : [...numerologyData, response.data];

      setNumerologyData(updatedData);
      onClose();
    } catch (err) {
      onClose();
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="p-4 bg-white rounded-lg shadow-lg mt-6">
      <h1 className="text-2xl font-bold text-orange-500 mb-6">
        {isEditing ? "Edit Health Numerology" : "Add Health Numerology"}
      </h1>

      <form onSubmit={handleSubmit} className="grid grid-cols-3 gap-4">
        {Object.keys(formData).map((field) =>
          field === "description" ? null : (
            <div key={field} className="">
              <label
                htmlFor={field}
                className="block text-sm font-medium text-gray-700 capitalize"
              >
                {field.replace("_", " ")}
              </label>
              <input
                type="text"
                id={field}
                name={field}
                value={formData[field]}
                onChange={handleInputChange}
                required
                disabled={loading}
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-orange-500 focus:border-orange-500 sm:text-sm"
              />
            </div>
          )
        )}

        {/* Jodit Editor for Description */}
        <div className="col-span-3">
          <label
            htmlFor="description"
            className="block text-sm font-medium text-gray-700"
          >
            Description
          </label>
          <JoditEditor
            value={formData.description}
            onChange={handleDescriptionChange}
            tabIndex={1} // tabIndex of textarea
          />
        </div>

        <div className="col-span-3 flex gap-4 mt-6">
          <button
            type="submit"
            className="bg-orange-500 text-white px-4 py-2 rounded-full"
            disabled={loading}
          >
            {loading ? "Saving..." : isEditing ? "Update" : "Add"}
          </button>
          <button
            type="button"
            onClick={onClose}
            className="bg-gray-500 text-white px-4 py-2 rounded-full"
            disabled={loading}
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddHealthNumerology;
