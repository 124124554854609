import React from "react";
import { Link } from "react-router-dom";
export default function Not_Found() {
  return (
    <div className="h-[70vh] w-full flex flex-col items-center justify-center px-4">
      {/* Oops! text with galaxy texture */}

      {/* 404 heading */}
      <h1 className="text-[28px] font-bold text-gray-900 mb-4 tracking-wide">
        404 - PAGE NOT FOUND
      </h1>

      {/* Error message */}
      <p className="text-gray-600 text-center max-w-[400px] mb-8">
        The page you are looking for might have been removed had its name
        changed or is temporarily unavailable.
      </p>

      {/* Homepage button */}
      <Link
        to="/"
        className="px-8 py-3 bg-orange-600 text-white rounded-md font-medium
                 hover:bg-orange-700 transition-colors duration-200
                 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2"
      >
        GO TO HOMEPAGE
      </Link>
    </div>
  );
}
