import { Link } from "react-router-dom";
import "./Footer.css";
import img1 from "../../Images/logo.png";
import icon from "../../Assets/play.png";
import { LuTwitter } from "react-icons/lu";
import { FaInstagram } from "react-icons/fa";
import { SlSocialFacebook } from "react-icons/sl";
import { FaWhatsapp } from "react-icons/fa";

export default function Footer() {
  return (
    <footer className="bg-[#002538] text-white">
      <div className="container mx-auto px-4 py-12">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {/* Logo and Description Column */}
          <div className="space-y-4">
            <Link to="/" className="inline-block">
              <img className="w-20 ml-2" src={img1 || "/placeholder.svg"} alt="VPandit Logo" />
              <h2 className="text-2xl font-bold text-orange-500 mt-2 ">VPandit</h2>
            </Link>
            <p className="text-gray-400 mt-4 w-72 ">
              Your trusted source for astrology and spiritual guidance. 
            </p>
           
          </div>

          {/* Our Services Column */}
          <div>
            <h3 className="text-xl font-bold mb-6 relative">
              Our Services
              <span className="absolute bottom-0 left-0 w-12 h-0.5 bg-orange-500"></span>
            </h3>
            <ul className="space-y-3">
              <li>
                <Link to="/numerology" className="text-gray-400 hover:text-orange-500 transition-colors">
                  Numerology
                </Link>
              </li>
              <li>
                <Link to="/panchang" className="text-gray-400 hover:text-orange-500 transition-colors">
                  Panchang
                </Link>
              </li>
              <li>
                <Link to="/pooja-vidhi" className="text-gray-400 hover:text-orange-500 transition-colors">
                  Pooja
                </Link>
              </li>
              <li>
                <Link to="/horoscope" className="text-gray-400 hover:text-orange-500 transition-colors">
                  Horoscopes
                </Link>
              </li>
              <li>
                <Link to="/kundli" className="text-gray-400 hover:text-orange-500 transition-colors">
                  Kundli
                </Link>
              </li>
            </ul>
          </div>

          {/* Quick Links Column */}
          <div>
            <h3 className="text-xl font-bold mb-6 relative">
              Quick Links
              <span className="absolute bottom-0 left-0 w-12 h-0.5 bg-orange-500"></span>
            </h3>
            <ul className="space-y-3">
              <li>
                <Link to="/contact" className="text-gray-400 hover:text-orange-500 transition-colors">
                  Contact Us
                </Link>
              </li>
              <li>
                <Link to="https://vpandit.com/vshop/" className="text-gray-400 hover:text-orange-500 transition-colors">
                  Products
                </Link>
              </li>
            </ul>
          </div>

          {/* Recent Posts Column */}
          <div>
            <h3 className="text-xl font-bold mb-6 relative">
            Social Media
              <span className="absolute bottom-0 left-0 w-12 h-0.5 bg-orange-500"></span>
            </h3>
              {/* Social Media Links */}
              <div className="flex space-x-4 mt-6">
              <a href="#" className="w-10 h-10 rounded-full bg-gray-800 flex items-center justify-center hover:bg-orange-500 transition-colors">
              <LuTwitter />
              </a>
              <a href="#" className="w-10 h-10 rounded-full bg-gray-800 flex items-center justify-center hover:bg-orange-500 transition-colors">
              <FaInstagram />
              </a>
              <a href="#" className="w-10 h-10 rounded-full bg-gray-800 flex items-center justify-center hover:bg-orange-500 transition-colors">
              <SlSocialFacebook />
              </a>
              <a href="#" className="w-10 h-10 rounded-full bg-gray-800 flex items-center justify-center hover:bg-orange-500 transition-colors">
              <FaWhatsapp />
              </a>
            </div>
            <Link
              to="https://play.google.com/store/apps/details?id=com.aark.vpandit"
              target="_blank"
              className="block mt-4"
            >
              <img src={icon || "/placeholder.svg"} className="w-40" alt="Play Store" />
            </Link>
          
          </div>
        </div>
      </div>

      {/* Copyright Section */}
      <div className="border-t border-gray-800">
        <div className="container mx-auto px-4 py-4">
          <p className="text-center text-gray-400 text-sm">
            Copyright © {new Date().getFullYear()} VPandit. All Rights Reserved.
          </p>
        </div>
      </div>
    </footer>
  );
}